<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36 36"
  >

    <rect
      x="6.8"
      y="4.7"
      fill="currentColor"
      width="5.9"
      height="1.1"
    />
    <path
      fill="currentColor"
      d="M12.2,11.2H7.3c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h4.8c0.3,0,0.5-0.2,0.5-0.5v-2.2
				C12.7,11.4,12.5,11.2,12.2,11.2z M11.6,13.3H7.9v-1.1h3.8V13.3z"
    />
    <path
      fill="currentColor"
      d="M25.6,6.8H14.3c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5V7.4
				C26.2,7.1,25.9,6.8,25.6,6.8z M25.1,9H14.9V7.9h10.2V9z"
    />
    <path
      fill="currentColor"
      d="M12.2,6.8H7.3C7,6.8,6.8,7.1,6.8,7.4v2.2c0,0.3,0.2,0.5,0.5,0.5h4.8c0.3,0,0.5-0.2,0.5-0.5V7.4
				C12.7,7.1,12.5,6.8,12.2,6.8z M11.6,9H7.9V7.9h3.8V9z"
    />
    <path
      fill="currentColor"
      d="M25.6,11.2H14.3c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-2.2
				C26.2,11.4,25.9,11.2,25.6,11.2z M25.1,13.3H14.9v-1.1h10.2V13.3z"
    />
    <path
      fill="currentColor"
      d="M20.8,19.4H9.5c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-2.2
				C21.3,19.6,21.1,19.4,20.8,19.4z M20.3,21.5H10v-1.1h10.2V21.5z"
    />
    <path
      fill="currentColor"
      d="M20.8,23.3H9.5c-0.3,0-0.5,0.2-0.5,0.5V26c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-2.2
				C21.3,23.5,21.1,23.3,20.8,23.3z M20.3,25.4H10v-1.1h10.2V25.4z"
    />
    <rect
      x="6.8"
      y="15.5"
      fill="currentColor"
      width="6.5"
      height="1.1"
    />
    <path
      fill="currentColor"
      d="M31.4,13.5c-0.9-0.3-1.9,0.2-2.3,1.1l-0.4,1.2l0,0l-0.4,1.2v-14c0-0.3-0.2-0.5-0.5-0.5H5.2
			c-0.3,0-0.5,0.2-0.5,0.5v31.2c0,0.3,0.2,0.5,0.5,0.5h22.6c0.3,0,0.5-0.2,0.5-0.5V28l3.8-11l0,0l0,0l0.4-1.2
			c0.1-0.2,0.1-0.4,0.1-0.6C32.6,14.5,32.2,13.8,31.4,13.5z M30.6,14.6c0.2-0.1,0.4-0.1,0.5,0c0.3,0.1,0.5,0.4,0.5,0.7
			c0,0.1,0,0.2,0,0.2l-0.2,0.7l-0.7-0.2L30,15.7l0.2-0.7C30.3,14.8,30.4,14.7,30.6,14.6z M25.1,26.4l-0.3,0.9H7.9v-8.6h17.2V26.4z
			 M27.3,33.8H5.7V3.6h21.5v16.6l-1.1,3.1v-5.1c0-0.3-0.2-0.5-0.5-0.5H7.3c-0.3,0-0.5,0.2-0.5,0.5v9.7c0,0.3,0.2,0.5,0.5,0.5h17.1
			L24,29.6c0,0.1,0,0.2,0,0.3h0l0.5,2.4c0,0.2,0.2,0.3,0.4,0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1l1.9-1.6l0,0c0,0,0,0,0,0V33.8
			z M25.2,30.6l0.6,0.2l-0.4,0.4L25.2,30.6z M26.5,29.9l-1.3-0.5l4.4-12.7l1.3,0.5L26.5,29.9z"
    />
    <rect
      x="7.3"
      y="29.5"
      fill="currentColor"
      width="11.9"
      height="1.1"
    />
  </svg>
</template>