import axios from "axios"

import { Document, PdfBrick } from "~/types"

export const fetchAllPdfBricksAction = async (documentUuid: Document["uuid"]): Promise<PdfBrick[] | void> => {
  const url = route("api.documents.pdf-bricks.index", documentUuid)

  try {
    const fetchAllPdfBricksRes = await axios.get<{ data: PdfBrick[] }>(url)

    return fetchAllPdfBricksRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getPdfBrickAction = async (
  documentUuid: Document["uuid"],
  pdfBrickUuid: PdfBrick["uuid"],
): Promise<PdfBrick | void> => {
  const routeString = `api.documents.pdf-bricks.show`
  const url = route(routeString, {
    document: documentUuid,
    pdf_brick: pdfBrickUuid,
  })

  try {
    const getPdfBrickRes = await axios.get<{ data: PdfBrick }>(url)

    return getPdfBrickRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// pdfBrick CRUD
export const createPdfBrickAction = async (
  documentUuid: Document["uuid"],
  pdfBrickToCreate: Partial<PdfBrick>,
): Promise<PdfBrick> => {
  const apiUrl = route(
    `api.documents.pdf-bricks.store`,
    { document: documentUuid },
  )

  const createPdfBrickRes = await axios.post<{ data: PdfBrick }>(apiUrl, pdfBrickToCreate)

  return createPdfBrickRes.data.data
}

export const updatePdfBrickAction = async (
  documentUuid: Document["uuid"],
  pdfBrickToUpdate: Partial<PdfBrick>,
): Promise<PdfBrick> => {
  const apiUrl = route(
    `api.documents.pdf-bricks.update`,
    {
      document: documentUuid,
      pdf_brick: pdfBrickToUpdate.uuid,
    },
  )

  const updatePdfBrickRes = await axios.put<{ data: PdfBrick }>(apiUrl, pdfBrickToUpdate)

  return updatePdfBrickRes.data.data
}

export const removePdfBrickAction = async (
  documentUuid: Document["uuid"],
  pdfBrickUuidToDelete: PdfBrick["uuid"],
): Promise<number | void> => {
  const apiUrl = route(
    `api.documents.pdf-bricks.destroy`,
    {
      document: documentUuid,
      pdf_brick: pdfBrickUuidToDelete,
    },
  )

  const removeRes = await axios.delete(apiUrl)
  return removeRes.status
}
