import { defineStore } from "pinia"
import { reactive, toRefs, toRaw } from "vue"
import { useI18n } from "vue-i18n"

import { Document, FormErrors, LinkedDocument, Party } from "~/types"

import {
  fetchLinkedDocumentsAction,
  createLinkedDocumentAction,
  removeLinkedDocumentAction,
  getAffiliatedPartyDocumentsAction,
} from "./linkedDocumentStoreActions"
import { useNotificationStore } from "../notificationStore"

interface AffiliatedPartyDocument {
  uuid: Document["uuid"]
  name: Document["name"]
  document_type: Document["document_type"]
  parties: Party[]
  created_at: Document["created_at"]
  updated_at: Document["updated_at"]
  origin: Document["origin"]
}

interface Data {
  linkedDocuments: LinkedDocument[]
  uuidsOfUpdatingLinkedDocument: LinkedDocument["uuid"][]
  uuidOfIsRemovingLinkedDocument: LinkedDocument["uuid"]
  linkedDocumentLastSavedMap: Record<LinkedDocument["uuid"], number>
  linkedDocumentErrorsMap: Record<LinkedDocument["uuid"], FormErrors<LinkedDocument>>
  linkedDocumentPayloadKeys: (keyof LinkedDocument)[]
  debounceUuid: LinkedDocument["uuid"]
  debounceTimestamp: number
  isLoadingLinkedDocuments: boolean
  affiliatedPartyDocuments: AffiliatedPartyDocument[]
  isLoadingAffiliatedPartyDocuments: boolean
  isLoadingSearchDocuments: boolean
  isVisibleLinkedDocumentsModal: boolean
}

export const useLinkedDocumentStore = defineStore("linkedDocumentStore", () => {
  const data = reactive<Data>({
    linkedDocuments: [],
    uuidsOfUpdatingLinkedDocument: [],
    uuidOfIsRemovingLinkedDocument: null,
    linkedDocumentLastSavedMap: {},
    linkedDocumentErrorsMap: {},
    linkedDocumentPayloadKeys: [],
    debounceUuid: null,
    debounceTimestamp: 0,
    isLoadingLinkedDocuments: false,
    affiliatedPartyDocuments: [],
    isLoadingAffiliatedPartyDocuments: false,
    isLoadingSearchDocuments: false,
    isVisibleLinkedDocumentsModal: false,
  })

  const { t } = useI18n()

  const { notify } = useNotificationStore()

  // mutations
  const setLinkedDocuments = (linkedDocuments: LinkedDocument[]) => data.linkedDocuments = linkedDocuments
  const pushLinkedDocument = (linkedDocument: LinkedDocument) => data.linkedDocuments = [ ...toRaw(data.linkedDocuments || []), linkedDocument ]
  const setIsVisibleLinkedDocumentsModal = (isVisible: boolean) => data.isVisibleLinkedDocumentsModal = isVisible

  const setAffiliatedPartyDocuments = (affiliatedDocuments: AffiliatedPartyDocument[]) => data.affiliatedPartyDocuments = affiliatedDocuments

  const removeLinkedDocumentFromStore = (partyUuidToRemove: LinkedDocument["uuid"]) => {
    const indexOfLinkedDocumentToRemove = data.linkedDocuments.findIndex((party) => party.uuid === partyUuidToRemove)

    if (indexOfLinkedDocumentToRemove !== -1) {
      data.linkedDocuments.splice(indexOfLinkedDocumentToRemove, 1)
    }
  }

  const setLinkedDocumentErrors = (uuid: LinkedDocument["uuid"], partyErrors: FormErrors<LinkedDocument>) => {
    data.linkedDocumentErrorsMap = {
      ...toRaw(data.linkedDocumentErrorsMap || {}),
      [uuid]: partyErrors,
    }
  }

  // api actions
  const fetchLinkedDocuments = async (
    documentUuid: Document["uuid"],
  ): Promise<LinkedDocument[] | void> => {
    try {
      data.isLoadingLinkedDocuments = true
      const linkedDocuments = await fetchLinkedDocumentsAction(documentUuid)

      if (linkedDocuments) {
        setLinkedDocuments(linkedDocuments)
      }

      return linkedDocuments
    } catch (err) {
      notify({
        title: t("linkedDocuments.errors.fetch"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingLinkedDocuments = false
    }
  }

  // linked document CRUD
  const createLinkedDocument = async (
    documentUuid: Document["uuid"],
    payload: Partial<LinkedDocument>,
  ): Promise<LinkedDocument | void> => {
    try {
      const res = await createLinkedDocumentAction(documentUuid, payload)
      if (res) pushLinkedDocument(res)
      return res
    } catch (err) {
      notify({
        title: t("linkedDocuments.errors.create"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    }
  }

  const removeLinkedDocumentFromEntity = async (
    documentUuid: Document["uuid"],
    linkedDocumentUuid: LinkedDocument["uuid"],
  ): Promise<LinkedDocument[] | void> => {
    data.uuidOfIsRemovingLinkedDocument = linkedDocumentUuid
    try {
      const res = await removeLinkedDocumentAction(documentUuid, linkedDocumentUuid)
      if (res === 200) removeLinkedDocumentFromStore(linkedDocumentUuid)
      return data.linkedDocuments
    } catch (err) {
      notify({
        title: t("linkedDocuments.errors.remove"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsRemovingLinkedDocument = null
    }
  }

  // ui actions

  const getAffiliatedPartyDocuments = async (
    documentUuid: Document["uuid"],
  ): Promise<AffiliatedPartyDocument[] | void> => {
    data.isLoadingAffiliatedPartyDocuments = true
    try {
      const linkedDocuments = await getAffiliatedPartyDocumentsAction(documentUuid)
      if (linkedDocuments) setAffiliatedPartyDocuments(linkedDocuments)
      return linkedDocuments
    } catch (err) {
      notify({
        title: t("linkedDocuments.errors.fetch"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingAffiliatedPartyDocuments = false
    }
  }

  return {
    ...toRefs(data),

    // mutations
    setLinkedDocuments,
    setLinkedDocumentErrors,
    setIsVisibleLinkedDocumentsModal,

    // api actions
    // updateLocalLinkedDocument,
    fetchLinkedDocuments,
    getAffiliatedPartyDocuments,

    // linked document CRUD
    createLinkedDocument,
    removeLinkedDocumentFromEntity,
  }
})
