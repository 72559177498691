import axios from "axios"

import { Attachment, CrudContext, Document, Template } from "~/types"

export const fetchAttachmentsAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
): Promise<Attachment[] | void> => {
  const routeString = `api.${context}s.attachments.index`
  const url = route(routeString, {
    [context]: entityUuid,
  })
  try {
    const fetchAttachments = await axios.get<{ data: Attachment[] }>(url)
    return fetchAttachments.data.data
  } catch (err) {
    console.error(err)
  }
}

// attachment CRUD
export const createAttachmentAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  attachmentToCreate: Partial<Attachment>,
): Promise<Attachment | void> => {
  const apiUrl = route(`api.${context}s.attachments.store`, entityUuid)

  const createAttachmentRes = await axios.post<{ data: Attachment }>(apiUrl, attachmentToCreate)

  return createAttachmentRes.data.data
}

export const updateAttachmentAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  attachmentUuidToUpdate: Attachment["uuid"],
  payload: Partial<Attachment>,
): Promise<Attachment | void> => {
  const apiUrl = `api.${context}s.attachments.update`
  const routeArgs = {
    [context]: entityUuid,
    attachment: attachmentUuidToUpdate,
  }
  const updateAttachmentRes = await axios.put<{ data: Attachment }>(route(apiUrl, routeArgs), payload)
  return updateAttachmentRes.data.data
}

export const removeAttachmentAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  attachmentUuidToRemove: Attachment["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.attachments.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, attachment: attachmentUuidToRemove }
    : { template: entityUuid, attachment: attachmentUuidToRemove }

  const removeAttachmentRes = await axios.delete<{ data: Attachment }>(route(apiUrl, payload))

  return removeAttachmentRes.status
}

export const getAttachmentUrlAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  attachmentUuid: Attachment["uuid"],
): Promise<Attachment | void> => {
  const routeString = `api.${context}s.attachments.show`
  const url = route(routeString, {
    [context]: entityUuid,
    attachment: attachmentUuid,
  })
  const getAttachmentRes = await axios.get<{ data: Attachment }>(url)

  return getAttachmentRes.data.data
}
