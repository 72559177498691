export const numberToLetter = (n: number): string => {
  const ordA = "a".charCodeAt(0)
  const ordZ = "z".charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ""

  while (n > 0) {
    s = String.fromCharCode(n % len + ordA - 1) + s
    n = Math.floor(n / len)
  }

  return s
}

export interface Result {
  type: string
  uuid: string
  number: number
  from: number
  to: number
  parentHeading3Number: number
  parentHeading2Number: number
}

export const formatNumber = (result: Partial<Result> | string): string => {
  let formatted = ""

  if (typeof result === "string") return result

  if (result?.type === "paragraph") {
    const parentHeading3String = result.parentHeading3Number !== 0 ? numberToLetter(result.parentHeading3Number - 1) + "." : ""
    const parentHeading2String = result.parentHeading2Number !== 0 ? result.parentHeading2Number + "." : ""
    formatted = "(" + parentHeading2String + parentHeading3String + result.number.toString() + ")"
  } else if (result?.type === "heading-2") {
    formatted = result.number.toString() + "."
  } else if (result?.type === "heading-3") {
    formatted = numberToLetter(result.number - 1) + ")"
  }

  return formatted
}
