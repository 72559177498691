// external
import { defineStore } from "pinia"
import { computed, reactive, toRefs } from "vue"

import { Editor, JSONContent } from "@tiptap/core"
import { Node, Node as PMNode, ResolvedPos } from "@tiptap/pm/model"

import { debounce, intersection, isEqual, throttle } from "lodash-es"
import tippy, { Instance, Props as TippyProps, roundArrow, sticky } from "tippy.js"

// internal
import { TocItem } from "~/types"

import { generateTextAction, autocompleteTextAction } from "./editorStoreActions"
import { useConditionStore } from "../conditionStore"
import { Transaction } from "@tiptap/pm/state"
import { writeProsemirrorDataToDocx } from "~/editor"

interface Data {
  editor: Editor | null
  editorContext: "proposal" | "document" | "template" | "";
  isEditorEditable: boolean
  commandMenuActiveSection: "sectionRef" | "partyData" | "dynamicField" | "textCompletion" | ""
  tocData: TocItem[]
  numberingTippy: Instance<TippyProps>[]
  activeListItem: {
    node: PMNode,
    pos: number,
  }
  isLoadingAiAutocompletion: boolean
  refUuidsOfSignaturesInEditor: string[]
  isDocxImportModalOpen: boolean
  droppedFile: File | null
  isDisabledAddConditionButton: boolean
  isDirty: boolean
  isDirtyTimer: NodeJS.Timeout | null
  isDirtyTimerHelper: number
  lastSaved: number | null
  prosemirrorDataOnServer: JSONContent
  idsNestedOrderRepresentationMap: Record<string, string>
  isLoadingGetDocxDownload: boolean
}

export const useEditorStore = defineStore(
  "editorStore",
  () => {
    const data = reactive<Data>(
      {
        editor: null,
        editorContext: "",
        isEditorEditable: false,
        commandMenuActiveSection: "",
        tocData: [],
        numberingTippy: null,
        activeListItem: null,
        isLoadingAiAutocompletion: false,
        refUuidsOfSignaturesInEditor: [],
        isDocxImportModalOpen: false,
        droppedFile: null,
        isDisabledAddConditionButton: false,
        isDirty: false,
        isDirtyTimer: null,
        isDirtyTimerHelper: 0,
        lastSaved: null,
        prosemirrorDataOnServer: null,
        idsNestedOrderRepresentationMap: {},
        isLoadingGetDocxDownload: false,
      },
    )

    const conditionStore = useConditionStore()

    // mutations
    const setEditorContext = (context: Data["editorContext"]) => data.editorContext = context

    const setEditor = (editor: Editor) => {
      data.editor = editor
      data.isEditorEditable = editor ? editor.isEditable : false
      setEditorPostprocess()
    }

    const setIsEditorEditable = () => data.isEditorEditable = !!data.editor?.isEditable

    const debouncedSetIsEditorEditable = debounce(setIsEditorEditable, 500)

    const setEditorPostprocess = () => {
      if (!data.editor) return
      data.editor.on("transaction", debouncedSetIsEditorEditable)
    }

    const setIsDirty = (val: boolean) => data.isDirty = val

    const setCommandMenuActiveSection = (openModalName: Data["commandMenuActiveSection"]) => data.commandMenuActiveSection = openModalName

    const setTocData = (tocData: TocItem[]) => data.tocData = tocData

    const setProsemirrorDataOnServer = (content: JSONContent) => data.prosemirrorDataOnServer = content

    const setIdsNestedOrderRepresentationMap = (map: Record<string, string>) => data.idsNestedOrderRepresentationMap = map

    const setNumberingTippy = () => {
      const element = document.getElementById("numbering-menu")

      data.numberingTippy = tippy(
        "body",
        {
          appendTo: () => document.body,
          content: element,
          showOnCreate: false,
          interactive: true,
          trigger: "manual",
          placement: "top-start",
          animation: "scale",
          hideOnClick: true,
          sticky: true,
          plugins: [
            sticky,
          ],
          arrow: roundArrow,
          onHidden () {
            data.activeListItem = null
          },
          onDestroy () {
            data.activeListItem = null
            data.numberingTippy = null
          },
        },
      )
    }

    const setActiveListItem = (listItemData: Data["activeListItem"]) => data.activeListItem = listItemData

    const setIsLoadingAiAutocompletion = (val: boolean) => data.isLoadingAiAutocompletion = val

    const setRefUuidsOfSignaturesInEditor = (refUuids: string[]) => data.refUuidsOfSignaturesInEditor = refUuids

    const setIsDocxImportModalOpen = (val: boolean) => data.isDocxImportModalOpen = val

    const setDroppedFile = (file: File) => data.droppedFile = file

    // actions
    const generateText = async (prompt: string) => {
      const completion = await generateTextAction(prompt)

      return completion
    }

    const autocompleteText = async (prompt: string) => {
      const completion = await autocompleteTextAction(prompt)

      return completion
    }

    // helpers
    const extractSignaturesFromEditor = (e: Editor) => {
      if (!e) {
        setRefUuidsOfSignaturesInEditor([])
        return
      }

      const listOfSignatureUuids = []

      e.state.doc.descendants(
        (node) => {
          if (node.type?.name === "signatureContainer") {
            const refUuids = (node.attrs.signatureBlocks || []).map((el) => el?.refUuid).filter(Boolean)

            listOfSignatureUuids.push(...refUuids)
          }

          if (node.type?.name === "signatureBlock") {
            listOfSignatureUuids.push(node.attrs.refUuid)
          }
        },
      )

      setRefUuidsOfSignaturesInEditor(listOfSignatureUuids.filter(Boolean))
    }

    const checkIfUuidExistsInEditor = (uuid: string) => {
      if (!data.editor) return false

      let exists = false

      data.editor.state.doc.descendants(
        (node) => {
          if (node.attrs.uuid === uuid) {
            exists = true
          }
        },
      )

      return exists
    }

    const throttledExtractSignaturesFromEditor = throttle(extractSignaturesFromEditor, 500)

    const processSignatureOnTransaction = (e: Editor) => {
      if (!e) return
      throttledExtractSignaturesFromEditor(e)
    }

    const processIsDirtyOnTransaction = (t: Transaction) => {
      if (t.docChanged && !data.isDirty) {
        const jsonContent = t.doc.toJSON()
        if (data.prosemirrorDataOnServer && !isEqual(jsonContent, data.prosemirrorDataOnServer)) {
          if (
            (jsonContent?.content.length === 1 && data.prosemirrorDataOnServer?.content.length === 1)
            && [ jsonContent.content[0].type, data.prosemirrorDataOnServer.content[0].type ].every((type) => type === "paragraph")
            && isEqual(jsonContent.content[0].content, data.prosemirrorDataOnServer.content[0].content)
            && !isEqual(jsonContent.content[0].attrs, data.prosemirrorDataOnServer.content[0].attrs)
          ) {
            return
          }
          setIsDirty(true)
          if (!data.isDirtyTimer) startIsDirtyTimer()
        }
      }
    }

    // Function to check if a node has UUID attributes
    const hasUUIDAttributeAndConditionMarker = (node: Node): boolean => {
      const uuid = node?.attrs?.uuid
      if (!uuid) return false
      const query = `.ProseMirror [data-uuid="${uuid}"] .condition-marker div div div, .ProseMirror [data-list-uuid="${uuid}"] .condition-marker div div div`
      const selector = document.querySelector(query)
      return !!selector
    }

    // Function to collect UUIDs of all ancestors of a given node
    const collectAncestorUUIDs = (resolvedPos: ResolvedPos): string[] => {
      const uuids: string[] = []
      for (let depth = resolvedPos.depth; depth > 0; depth--) {
        const ancestorNode = resolvedPos.node(depth)
        if (hasUUIDAttributeAndConditionMarker(ancestorNode)) {
          uuids.push(ancestorNode.attrs.uuid)
        }
      }
      return uuids
    }

    // Function to get ancestor with UUID of type list or table
    const getParentForCommonParentCheck = (resolvedPos: ResolvedPos, startingDepth: number): Node => {
      for (let depth = startingDepth; depth > -1; depth--) {
        const ancestorNode = resolvedPos.node(depth)
        if (hasUUIDAttributeAndConditionMarker(ancestorNode) && (ancestorNode.type.name === "list" || ancestorNode.type.name === "table")) {
          return ancestorNode
        } else if (ancestorNode.type.name === "doc" && resolvedPos.depth < 2) {
          return ancestorNode
        }
      }
      return null
    }

    // Function to collect UUIDs of all descendants of a given node
    const collectDescendantUUIDs = (resolvedPos: ResolvedPos): string[] => {
      const uuids: string[] = []
      // Check if node is paragraph and parent is not list
      const subtract = 1
      //if (resolvedPos.node(resolvedPos.depth).type.name === "paragraph" && [ "tableCell" ].includes(resolvedPos.node(resolvedPos.depth - 1).type.name)) subtract = 0
      const depth = resolvedPos.depth > 1 ? resolvedPos.depth - subtract : 1
      const node = resolvedPos.node(depth)
      node?.descendants(
        (childNode: Node) => {
          if (hasUUIDAttributeAndConditionMarker(childNode)) {
            const uuid = childNode.attrs.uuid
            uuids.push(uuid)
          }
        },
      )
      return uuids
    }

    // Function to find UUIDs of top-level nodes within the selection range
    const findUUIDsInRange = (from: number, to: number, doc: any, deep = false): string[] => {
      const uuids: string[] = []

      // Find out if from and to are sibling nodes that share a parent and depth
      const resolvedFrom = doc.resolve(from)
      const resolvedTo = doc.resolve(to)
      const fromDepth = resolvedFrom.depth
      const toDepth = resolvedTo.depth
      // Find from and to parent as the next node ancestor of type list, table cell or doc
      const fromSuperParent = getParentForCommonParentCheck(resolvedFrom, fromDepth - 2)
      const toSuperParent = getParentForCommonParentCheck(resolvedTo, toDepth - 2)
      let parentToCheck = fromSuperParent === toSuperParent && fromDepth === toDepth ? fromSuperParent : null
      if (!parentToCheck) {
        const fromImmediateParent = getParentForCommonParentCheck(resolvedFrom, fromDepth - 1)
        const toImmediateParent = getParentForCommonParentCheck(resolvedTo, toDepth - 1)
        parentToCheck = fromImmediateParent === toImmediateParent && fromDepth === toDepth ? fromImmediateParent : doc
      }

      doc.nodesBetween(from, to, (node: Node, pos: number, parent: Node) => {
        // If the node is a top-level node within the selection range and has a UUID
        if (hasUUIDAttributeAndConditionMarker(node) && (parent === parentToCheck || deep)) {
          const uuid = node.attrs.uuid
          uuids.push(uuid)
        }
      })

      // If uuids is empty, we need to find a common UUID
      if (!uuids.length) {
        const ancestorFromUuids = collectAncestorUUIDs(doc.resolve(from))
        const ancestorToUuids = collectAncestorUUIDs(doc.resolve(to))
        const commonUUID = findCommonUUID(ancestorFromUuids, ancestorToUuids)
        if (commonUUID) uuids.push(commonUUID)
      }

      return uuids
    }

    // Function to find the common UUID in two arrays, if any
    const findCommonUUID = (array1: string[], array2: string[]): string | null => {
      for (const uuid of array1) {
        if (array2.includes(uuid)) {
          return uuid
        }
      }
      return null
    }

    const setIsDisabledAddConditionButton = () => {
      const { from, to } = data.editor.state.selection

      const startResolvedPos = data.editor.state.doc.resolve(from)
      const endResolvedPos = data.editor.state.doc.resolve(to)

      // Collect every single UUID on every level between the start and end of the selection and check if there is a condition for this uuid
      // Collect UUIDs for start and end of the selection
      const startAncestorUUIDs = collectAncestorUUIDs(startResolvedPos)
      const endAncestorUUIDs = collectAncestorUUIDs(endResolvedPos)
      const startDescendantUUIDs = collectDescendantUUIDs(startResolvedPos)
      const endDescendantUUIDs = collectDescendantUUIDs(endResolvedPos)

      // Determine the common UUID or find UUIDs within the range
      const commonUUID = findCommonUUID(startAncestorUUIDs, endAncestorUUIDs)

      let conditionFound = false

      // Check startUUIDs and endUUIDs for existing condition
      const queryUuids = [ ...startAncestorUUIDs, ...endAncestorUUIDs, ...startDescendantUUIDs, ...endDescendantUUIDs ]
      conditionFound = conditionStore.conditions?.some(
        (condition) => {
          const i = intersection(queryUuids, condition.uuids)
          return i?.length > 0
        },
      )

      if (!commonUUID && !conditionFound) {
        // If no common UUID is found, find UUIDs of top-level nodes in the range
        const queryUuids = findUUIDsInRange(from, to, data.editor.state.doc, true)
        conditionFound = conditionStore.conditions?.some(
          (condition) => intersection(queryUuids, condition.uuids)?.length > 0,
        )
      }
      data.isDisabledAddConditionButton = conditionFound
    }

    const startIsDirtyTimer = () => {
      data.lastSaved = Date.now()
      data.isDirtyTimer = setInterval(() => {
        data.isDirtyTimerHelper = Date.now()
      }, 1000)
    }

    const resetIsDirtyTimer = () => {
      data.lastSaved = null
      clearInterval(data.isDirtyTimer)
      data.isDirtyTimerHelper = null
    }

    const editModeTimerCount = computed(() => {
      if (!data.lastSaved || !data.isDirtyTimerHelper) return null
      return Math.floor((data.isDirtyTimerHelper - data.lastSaved) / 1000)
    })

    const removeIsDirtyHandler = () => {
      setIsDirty(false)
      window.removeEventListener("beforeunload", confirmEditorContentIsDirtyStateNative)
    }

    const confirmEditorContentIsDirtyStateNative = (e) => {
      if (data.isDirty) {
        e.returnValue = null
        return ""
      }
    }

    const getDocxDownload = async (
      includeComments: boolean,
      includeTrackedChanges: boolean,
      locale: string,
      t: (s: string, n: number) => void,
    ) => {
      const currentDoc = data.editor.state.doc as Node
      data.isLoadingGetDocxDownload = true
      try {
        await writeProsemirrorDataToDocx(currentDoc, includeComments, includeTrackedChanges, locale, t)
      } catch (e) {
        console.error(e)
      } finally {
        data.isLoadingGetDocxDownload = false
      }
    }

    return {
      // state
      ...toRefs(data),
      editModeTimerCount,

      // mutations
      setEditorContext,
      setEditor,
      setCommandMenuActiveSection,
      setTocData,
      setNumberingTippy,
      setActiveListItem,
      setIsLoadingAiAutocompletion,
      setRefUuidsOfSignaturesInEditor,
      setIsDocxImportModalOpen,
      setDroppedFile,
      setIsDirty,
      setProsemirrorDataOnServer,
      setIdsNestedOrderRepresentationMap,

      // helpers
      processSignatureOnTransaction,
      extractSignaturesFromEditor,
      hasUUIDAttributeAndConditionMarker,
      collectAncestorUUIDs,
      collectDescendantUUIDs,
      findUUIDsInRange,
      findCommonUUID,
      checkIfUuidExistsInEditor,
      setIsDisabledAddConditionButton,
      processIsDirtyOnTransaction,
      startIsDirtyTimer,
      resetIsDirtyTimer,
      removeIsDirtyHandler,
      confirmEditorContentIsDirtyStateNative,
      getDocxDownload,

      // openai actions
      generateText,
      autocompleteText,
    }
  },
)
