import axios from "axios"

import { Document, Comment } from "~/types"

export const fetchCommentsAction = async (documentUuid: Document["uuid"]): Promise<Comment[] | void> => {
  const url = route("api.documents.comments.index", documentUuid)

  try {
    const fetchCommentsRes = await axios.get<{ data: Comment[] }>(url)

    return fetchCommentsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getCommentAction = async (
  documentUuid: Document["uuid"],
  commentUuid: Comment["uuid"],
): Promise<Comment | void> => {
  const routeString = `api.documents.comments.show`
  const url = route(routeString, {
    document: documentUuid,
    comment: commentUuid,
  })

  try {
    const getCommentRes = await axios.get<{ data: Comment }>(url)

    return getCommentRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const createCommentAction = async (
  documentUuid: Document["uuid"],
  payload: Partial<Comment>,
): Promise<Comment | void> => {
  const apiUrl = route("api.documents.comments.store", documentUuid)

  const createCommentRes = await axios.post(apiUrl, payload)

  return createCommentRes.data.data
}

export const updateCommentAction = async (
  documentUuid: Document["uuid"],
  commentUuid: Document["uuid"],
  payload: Partial<Comment>,
): Promise<Comment | void> => {
  const apiUrl = route("api.documents.comments.update", {
    document: documentUuid,
    comment: commentUuid,
  })

  const updateCommentRes = await axios.patch(apiUrl, payload)

  return updateCommentRes.data.data
}
