import axios from "axios"

import { AffiliatedPartyDocument, Document, LinkedDocument } from "~/types"

export const fetchLinkedDocumentsAction = async (
  documentUuid: Document["uuid"],
): Promise<LinkedDocument[] | void> => {
  const res = await axios.get<{ data: LinkedDocument[] }>(route("api.documents.document-relationships.index", documentUuid))
  return res.data.data
}

export const createLinkedDocumentAction = async (
  documentUuid: Document["uuid"],
  payload: Partial<LinkedDocument>,
): Promise<LinkedDocument | void> => {
  const res = await axios.post<{ data: LinkedDocument }>(route("api.documents.document-relationships.store", documentUuid), payload)
  return res.data.data
}

export const removeLinkedDocumentAction = async (
  documentUuid: Document["uuid"],
  linkedDocumentUuid: LinkedDocument["uuid"],
): Promise<number | void> => {
  const res = await axios.delete(route("api.documents.document-relationships.destroy", {
    document: documentUuid,
    document_relationship: linkedDocumentUuid,
  }))
  return res.status
}

export const getAffiliatedPartyDocumentsAction = async (
  documentUuid: Document["uuid"],
): Promise<AffiliatedPartyDocument[] | void> => {
  const res = await axios.get<{ data: AffiliatedPartyDocument[] }>(route("api.documents.affiliated-party-documents.index", documentUuid))
  return res.data.data
}
