// external
import { router, usePage } from "@inertiajs/vue3"
import axios from "axios"
import { pick } from "lodash-es"
import { toRaw, ref, computed, watch, onMounted, nextTick } from "vue"

// internal
import { AccountUser, MagicTableAllState, Metadata, MetadataType, MultiFieldType, Pagination } from "~/types"
import i18nInstance from "~/utils/i18n"

const { t } = i18nInstance.global

export const useFilters = (
  sortBy = "created_at",
  sortDir = "desc",
  paginationProp = [ "pagination" ],
  filterConfig = [ "query" ],
  metadata: Metadata[] = [],
  storageKey = "",
) => {

  const isSidebarOpen = ref(false)
  const updateDebounce = ref(null)
  const sortDirection = ref(sortDir)
  const sortAttribute = ref(sortBy)
  const filterInitialized = ref(false)
  const filtersToUse = ref(filterConfig)
  const defaultFilterValues = ref({
    query: null,
    teams: [],
    imports: [],
    automations: [],
    tags: [],
    tag_and: false,
    stages: [],
    parties: [],
    external_parties: [],
    document_users: [],
    origins: [],
    archived: false,
    expired: false,
    status: [],
  })
  const filterLabels = ref({})
  const currentRequests = ref(0)
  const isResettingFilter = ref(false)
  const isUpdatingInternal = ref(false)
  const selectedUuids = ref([])
  const filterOptions = ref({
    teams: [],
    stages: [],
    imports: [],
    automations: [],
    tags: [],
    status: [],
    parties: [],
    origins: [],
  })

  const pageProps = computed(() => usePage().props)
  const mau = computed(() => pageProps.value.mau as AccountUser)

  const pinnedFilters = ref([])

  if (!!storageKey && mau.value.customizations?.pinnedFilters?.[storageKey]) {
    pinnedFilters.value = mau.value.customizations.pinnedFilters[storageKey]
  }

  const paginator = computed(() => paginationProp?.length ? pageProps.value[paginationProp[0]] as Pagination<any> : null)
  const allState = ref<MagicTableAllState>(null)


  const perPage = ref(paginator.value?.meta?.per_page ?? 15)

  const isRangeType = (type) => {
    return ([ MultiFieldType.number, MultiFieldType.date, MultiFieldType.timestamp, MultiFieldType.currency, MultiFieldType.currency_duration ].includes(type))
  }

  // init metadata filters
  if (metadata.length) {
    for (const metadataEntry of metadata) {
      if (metadataEntry.value_type === MultiFieldType.bool || metadataEntry.value_type === MultiFieldType.clause) defaultFilterValues.value[metadataEntry.name] = false
      else if (isRangeType(metadataEntry.value_type)) defaultFilterValues.value[metadataEntry.name] = [ null, null ]
      else if (metadataEntry.value_type === MultiFieldType.select) {
        if (!metadataEntry.select_values?.length) {
          filterOptions.value[metadataEntry.name] = []
        } else {
          filterOptions.value[metadataEntry.name] = metadataEntry.select_values.map((option) => {
            let label = option
            if (metadataEntry.name === "contract_type") {
              label = t(`contractTypes.${option}`)
            }
            if (metadataEntry.name === "contract_duration_type") {
              label = t(`contractDurationTypes.${option}.name`)
            }
            return {
              label: label,
              value: option,
            }
          })
        }
        defaultFilterValues.value[metadataEntry.name] = []
      }
      filterLabels.value[metadataEntry.name] = metadataEntry.type === MetadataType.account || metadataEntry.account_metadata_uuid ? metadataEntry.display_name : t(`metadata.system.${metadataEntry.name}.name`)
      filtersToUse.value.push(metadataEntry.name)
    }
  }

  const filterValues = ref({ ...toRaw(defaultFilterValues.value) })

  const isUpdatingList = computed(() => {
    return isUpdatingInternal.value || isResettingFilter.value || currentRequests.value > 0
  })
  const hasActiveFilters = computed(() => {
    return !!filterBubbles.value.length
  })
  const activeFilters = computed(() => {
    const tempActiveFilters = {}

    if (filterInitialized.value) {
      for (const filterKey of filtersToUse.value) {
        const relatedMetadataField = metadata.find((entry) => entry.name === filterKey)

        // special case handling for range filters
        if (isRangeType(relatedMetadataField?.value_type)) {
          // check if either min or max is set
          if (filterValues.value[filterKey][0] !== null || filterValues.value[filterKey][1] !== null) {
            tempActiveFilters[filterKey] = filterValues.value[filterKey]
          }
        } else if (Array.isArray(filterValues.value[filterKey]) && !!filterValues.value[filterKey].length) {
          tempActiveFilters[filterKey] = filterValues.value[filterKey]
        } else if (filterValues.value[filterKey] !== defaultFilterValues.value[filterKey] && filterValues.value[filterKey] !== null && filterValues.value[filterKey] !== "") {
          tempActiveFilters[filterKey] = filterValues.value[filterKey]
        }
      }
    }

    return tempActiveFilters
  })
  const filterData = computed(() => {
    if (filterInitialized.value) {
      const tempFilterValues = pick(activeFilters.value, filtersToUse.value)

      for (let i = 0; i < filtersToUse.value.length; i++) {
        const filterKey = filtersToUse.value[i]
        // convert arrays to comma separated strings
        if (Array.isArray(tempFilterValues[filterKey])) {
          tempFilterValues[filterKey] = tempFilterValues[filterKey].length ? tempFilterValues[filterKey].join(",") : null
        }
        // only take uuid of objects
        if (typeof tempFilterValues[filterKey] === "object" && tempFilterValues[filterKey] !== null) {
          tempFilterValues[filterKey] = tempFilterValues[filterKey].uuid || null
        }
      }

      return {
        ...tempFilterValues,
        sort_direction: sortDirection.value,
        sort_attribute: sortAttribute.value,
        per_page: perPage.value,
      }
    }
    return {}
  })
  const filterBubbles = computed(() => {
    const bubbles = []

    if (filterInitialized.value) {
      for (const filterKey in activeFilters.value) {
        if (filterKey !== "query") {
          const relatedMetadataField = metadata.find((entry) => entry.name === filterKey)

          // range filters (certain metadata)
          if (isRangeType(relatedMetadataField?.value_type)) {
            let fromDisplay = activeFilters.value[filterKey][0] === null || activeFilters.value[filterKey][0] === "" ? "*" : activeFilters.value[filterKey][0]
            let toDisplay = activeFilters.value[filterKey][1] === null || activeFilters.value[filterKey][1] === "" ? "*" : activeFilters.value[filterKey][1]

            if (relatedMetadataField?.value_type === MultiFieldType.date || relatedMetadataField?.value_type === MultiFieldType.timestamp) {
              fromDisplay = fromDisplay.replaceAll("-", "/")
              toDisplay = toDisplay.replaceAll("-", "/")
            }

            bubbles.push({
              label: filterLabels.value[filterKey] || null,
              key: filterKey,
              value: `${activeFilters.value[filterKey][0]}-${activeFilters.value[filterKey][1]}`,
              display: `${fromDisplay} - ${toDisplay}`,
            })
            // multivalue filters (entities like tags, parties,...)
          } else if (Array.isArray(activeFilters.value[filterKey])) {
            for (const entry of activeFilters.value[filterKey]) {
              const relevantFilterOption = filterOptions.value[filterKey]?.length ? filterOptions.value[filterKey].find((option) => {
                if (entry === null) {
                  return option.value === "null"
                }
                return option.value.toString() === entry.toString()
              }) : null
              bubbles.push({
                label: filterLabels.value[filterKey] || null,
                key: filterKey,
                value: entry,
                display: !!relevantFilterOption?.label ? relevantFilterOption.label : entry.toString(),
              })
            }
          } else if (filterOptions.value[filterKey]?.length) {
            const displayValue = filterOptions.value[filterKey].find((option) => option.value.toString() === activeFilters.value[filterKey].toString())?.label
            if (displayValue) {
              bubbles.push({
                label: filterLabels.value[filterKey] || null,
                key: filterKey,
                value: activeFilters.value[filterKey],
                display: displayValue,
              })
            }
            else {
              console.error("Filter option not found", filterKey, activeFilters.value[filterKey], filterOptions.value[filterKey])
            }
          } else {
            bubbles.push({
              label: filterLabels.value[filterKey] || null,
              key: filterKey,
              value: activeFilters.value[filterKey],
              display: activeFilters.value[filterKey],
            })
          }
        }
      }

    }


    return bubbles
  })

  const isArchive = computed(() => {
    return filterValues.value.archived
  })

  watch(filterValues.value, (newVal) => {
    if (filterInitialized.value) {
      const timeout = newVal.hasOwnProperty("query") && newVal["query"] ? 500 : 100
      updateList(timeout)
    }
  })
  watch([ sortAttribute, sortDirection, perPage ], () => {
    updateList()
  })
  watch(paginator, (newVal, oldVal) => {
    if (newVal.meta.total !== oldVal.meta.total || newVal.meta.current_page !== oldVal.meta.current_page) {
      selectedUuids.value = []
      allState.value = null
    }
  })
  watch(allState, (newVal) => {
    if (newVal !== null && paginator.value?.data?.length) {
      selectedUuids.value = paginator.value.data.map((entry) => entry.uuid)
    }
  })

  const initializeFilter = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (filtersToUse.value.length) {
      for (const filterKey of filtersToUse.value) {
        const paramValue = urlParams.get(filterKey)
        const relatedMetadataField = metadata.find((entry) => entry.name === filterKey)

        // special case handling for range filters
        if (isRangeType(relatedMetadataField?.value_type)) {
          if (paramValue) {
            const values: string[] = paramValue.split(",")
            filterValues.value[filterKey][0] = !!values[0] ? values[0] : null
            filterValues.value[filterKey][1] = !!values[1] ? values[1] : null
          }

        } else if (typeof defaultFilterValues.value[filterKey] === "boolean") {
          filterValues.value[filterKey] = paramValue === "true"
        } else if (Array.isArray(defaultFilterValues.value[filterKey])) {
          if (paramValue && paramValue.length && paramValue !== "0") {
            const values: string[] = paramValue.split(",")

            filterValues.value[filterKey] = values
          }
        } else {
          filterValues.value[filterKey] = paramValue || toRaw(defaultFilterValues.value[filterKey])
        }
      }
    }

    const tempSortDirection = urlParams.get("sort_direction")
    if (tempSortDirection) {
      sortDirection.value = tempSortDirection
    }

    const tempSortAttribute = urlParams.get("sort_attribute")
    if (tempSortAttribute) {
      sortAttribute.value = tempSortAttribute
    }

    const tempPerPage = urlParams.get("per_page")
    if (tempPerPage) {
      perPage.value = parseInt(tempPerPage)
    }

    nextTick(() => {
      filterInitialized.value = true
    })
  }

  const updateList = (timeout = 0) => {
    if (filterInitialized.value) {
      isUpdatingInternal.value = true
      selectedUuids.value = []
      allState.value = null
      clearTimeout(updateDebounce.value)
      updateDebounce.value = setTimeout(() => {
        currentRequests.value++

        const payload = {
          ...filterData.value,
          page: 1,
        }

        router.get(window.location.pathname + window.location.hash, payload, {
          replace: true,
          preserveState: true,
          only: paginationProp,
          onFinish: () => {
            currentRequests.value--
            isResettingFilter.value = false
            isUpdatingInternal.value = false
          },
        })
      }, timeout)
    }
  }
  const setQuery = (query) => {
    if (filtersToUse.value.includes("query")) {
      filterValues.value.query = query
    }
  }
  const resetFilter = () => {
    isResettingFilter.value = true
    for (const filterKey of filtersToUse.value) {
      const relatedMetadataField = metadata.find((entry) => entry.name === filterKey)

      // special case handling for range filters
      if (isRangeType(relatedMetadataField?.value_type)) {
        filterValues.value[filterKey] = [ null, null ]
      } else {
        filterValues.value[filterKey] = toRaw(defaultFilterValues.value[filterKey])
      }
    }
  }
  const resetSorting = () => {
    sortDirection.value = sortDir
    sortAttribute.value = sortBy
  }
  const setPerPage = (perPageOption) => {
    perPage.value = perPageOption.value
  }
  const clearBubble = (bubble) => {
    clearFilterByValue(bubble.key, bubble.value)
  }
  const clearFilterByValue = (filterKey, value = null) => {
    const relatedMetadataField = metadata.find((entry) => entry.name === filterKey)

    // special case handling for range filters
    if (isRangeType(relatedMetadataField?.value_type)) {
      filterValues.value[filterKey] = [ null, null ]
    } else if (!Array.isArray(defaultFilterValues.value[filterKey])) {
      filterValues.value[filterKey] = toRaw(defaultFilterValues.value[filterKey])
    } else {
      if (filterValues.value[filterKey].includes(value)) {
        const valueIndex = filterValues.value[filterKey].indexOf(value)
        if (valueIndex !== -1) {
          filterValues.value[filterKey].splice(valueIndex, 1)
        }
      }
    }
  }

  const selectEntry = (uuid: string) => {
    const idx = selectedUuids.value.indexOf(uuid)
    if (idx !== -1) {
      selectedUuids.value.splice(idx, 1)
      allState.value = null
    } else {
      selectedUuids.value.push(uuid)
    }
  }

  const clearSelection = () => {
    selectedUuids.value = []
    allState.value = null
  }

  onMounted(() => {
    initializeFilter()
  })

  const togglePin = (pinName) => {
    if (pinnedFilters.value.includes(pinName)) {
      pinnedFilters.value = pinnedFilters.value.filter((entry) => entry !== pinName)
    } else {
      pinnedFilters.value.push(pinName)
    }

    storePins()
  }

  let customizationDebouncer = null
  const storePins = () => {
    if (!!storageKey && !!mau.value) {

      clearTimeout(customizationDebouncer)
      customizationDebouncer = setTimeout(() => {

        let customizations = mau.value.customizations

        if (!customizations) {
          customizations = {
            pinnedFilters: {
              [storageKey]: pinnedFilters.value,
            },
          }
        } else {
          if (!customizations.pinnedFilters) {
            customizations = {
              ...customizations,
              pinnedFilters: {
                [storageKey]: pinnedFilters.value,
              },
            }
          } else {
            customizations.pinnedFilters[storageKey] = pinnedFilters.value
          }
        }

        axios.patch(route("api.account-users.update", mau.value.uuid), {
          customizations: customizations,
        })
      }, 200)
    }
  }

  return {
    sortAttribute,
    sortDirection,
    isUpdatingList,
    hasActiveFilters,
    filterValues,
    filterData,
    filterBubbles,
    filterOptions,
    perPage,
    allState,
    filtersToUse,
    selectedUuids,
    filterInitialized,
    activeFilters,
    defaultFilterValues,
    isSidebarOpen,
    isArchive,
    pinnedFilters,

    setQuery,
    resetFilter,
    resetSorting,
    setPerPage,
    clearBubble,
    updateList,
    initializeFilter,
    selectEntry,
    storePins,
    togglePin,
    clearSelection,
  }
}
