import { DocumentUser, MultifieldFormat, Metadata, MetadataType, MetadataValue, MultiFieldType, PartyEntityType, Signatory, Template, ConditionOperator, Rule, UiUser, Account, DocumentNotificationRecipients } from "~/types"
import { EMAIL_REGEX } from "~/utils/constants"
import { router, usePage } from "@inertiajs/vue3"
import { AccountUser, Team, Party } from "~/types"
import { union, keys, filter, isObject, isEqual, isEmpty, xorWith } from "lodash-es"
import { DelegateInstance, Instance, Placement, Props as TippyProps } from "tippy.js"
import { z, ZodSchema } from "zod"
import { nextTick } from "vue"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"
import { ComposerTranslation, useI18n } from "vue-i18n"
import { EmailIcon, ImageIcon, MsExcelIcon, MsPowerpointIcon, MsWordIconColor, PDFIcon, ZipIcon } from "~/icons"
import { DocumentIcon } from "@heroicons/vue/24/outline"
import { useAccountStore } from "~/stores"
import { OverlayScrollbar } from "~/components"

export const getUserRepresentation = (user: Partial<DocumentUser> | Partial<AccountUser> | Partial<Signatory> | Partial<UiUser>) => {
  if (!user) return "[ deleted user ]"

  return user?.first_name && user?.last_name
    ? `${user.first_name}\u00A0${user.last_name}`
    : user?.email || "[ deleted user ]"
}

export const getUserInitials = (user: Partial<DocumentUser> | Partial<AccountUser> | Partial<Signatory>) => {
  if (!user) return "?"

  return user?.first_name && user?.last_name
    ? `${user.first_name[0]}${user.last_name[0]}`
    : `${user.email[0]}${user.email[1]}` || "?"
}

export const getInitialsFromName = (name: string) => {
  if (!name) return "?"
  const initials = name.split(" ").map((el) => el[0]).join("").substring(0, 2)
  return initials || "?"
}

export const getPartyRepresentation = (party: Partial<Party>) => {
  if (!party) return "[ deleted party ]"

  return party.name && party.entity_name && party.name !== party.entity_name && party.entity_type !== PartyEntityType.person
    ? `${party.name} (${party.entity_name})`
    : party.name || "[ deleted party ]"
}

export const getTeamByUuid = (uuid: Team["uuid"]) => {
  const teams = usePage().props.teams as Team[]
  if (!teams?.length) return null
  return teams.find((el) => el.uuid === uuid)
}

export const getTemplateByUuid = (uuid: string) => {
  const templates = usePage().props.templates as Template[]
  if (!templates?.length) return null
  return templates.find((el) => el.uuid === uuid)
}

export const formatAddress = (address: string) => address.replace(/(\r\n|\n|\r)/gm, ", ")

interface DynamicFieldTypeRes {
  icon: string
  name: string
}

export const getMultiFieldType = (type: MultiFieldType) => {
  const returnValues: Partial<DynamicFieldTypeRes> = {}

  switch (type) {
    case "text":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M15.246 14H8.754l-1.6 4H5l6-15h2l6 15h-2.154l-1.6-4zm-.8-2L12 5.885 9.554 12h4.892zM3 20h18v2H3v-2z\"/></svg>"
      break
    case "textarea":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M11 4h10v2H11V4zM6 7v4H4V7H1l4-4 4 4H6zm0 10h3l-4 4-4-4h3v-4h2v4zm5 1h10v2H11v-2zm-2-7h12v2H9v-2z\"/></svg>"
      break
    case "select":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M17 5h3l-1.5 2L17 5M3 2h18a2 2 0 0 1 2 2v4c0 1.11-.89 2-2 2h-5v10c0 1.11-.89 2-2 2H3a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2m0 2v4h11V4H3m18 4V4h-5v4h5M3 20h11V10H3v10m2-8h7v2H5v-2m0 4h7v2H5v-2Z\"/></svg>"
      break
    case "list":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M14 10H3v2h11v-2zm0-4H3v2h11V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM3 16h7v-2H3v2z\"/></svg>"
      break
    case "email":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M20 12a8 8 0 1 0-3.562 6.657l1.11 1.664A9.953 9.953 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10v1.5a3.5 3.5 0 0 1-6.396 1.966A5 5 0 1 1 15 8H17v5.5a1.5 1.5 0 0 0 3 0V12zm-8-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z\"/></svg>"
      break
    case "date":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M7,12H9V14H7V12M21,6V20A2,2 0 0,1 19,22H5C3.89,22 3,21.1 3,20V6A2,2 0 0,1 5,4H6V2H8V4H16V2H18V4H19A2,2 0 0,1 21,6M5,8H19V6H5V8M19,20V10H5V20H19M15,14V12H17V14H15M11,14V12H13V14H11M7,16H9V18H7V16M15,18V16H17V18H15M11,18V16H13V18H11Z\" /></svg>"
      break
    case "clause":
      returnValues.icon = "<svg class=\"fill-current\" viewBox=\"0 0 512 512\" xml:space=\"preserve\"><g><polygon points=\"93.539,218.584 275.004,218.584 354.699,138.894 355.448,138.145 355.448,125.045 93.539,125.045 	\"/><polygon points=\"402.213,433.724 46.77,433.724 46.77,78.276 402.213,78.276 402.213,91.467 448.983,56.572   448.983,31.506 0,31.506 0,480.494 448.983,480.494 448.983,289.204 402.213,335.974 	\"/><path d=\"M229.358,274.708H93.539v28.062h120.476C218.602,292.858,223.932,283.312,229.358,274.708z\"/><path d=\"M93.539,349.539v28.062h110.935c-3.275-8.796-4.302-18.334-3.649-28.062H93.539z\"/><path d=\"M290.939,268.789c-15.501,15.501-55.612,80.76-40.11,96.27c15.51,15.51,80.76-24.609,96.27-40.11l63.755-63.77l-56.155-56.15L290.939,268.789z\"/><path d=\"M500.374,115.509c-15.511-15.502-40.649-15.502-56.15,0l-76.682,76.685l56.156,56.15l76.676-76.685C515.875,156.158,515.875,131.019,500.374,115.509z M400.166,202.361l-9.636-9.628l53.684-53.684l9.619,9.618L400.166,202.361z\"/></g></svg>"
      break
    case "timestamp":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M7,12H9V14H7V12M21,6V20A2,2 0 0,1 19,22H5C3.89,22 3,21.1 3,20V6A2,2 0 0,1 5,4H6V2H8V4H16V2H18V4H19A2,2 0 0,1 21,6M5,8H19V6H5V8M19,20V10H5V20H19M15,14V12H17V14H15M11,14V12H13V14H11M7,16H9V18H7V16M15,18V16H17V18H15M11,18V16H13V18H11Z\" /></svg>"
      break
    case "number":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z\" /></svg>"
      break
    case "bool":
      returnValues.icon = "<svg class=\"fill-current\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6.003 11L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z\"/></svg>"
      break
    case "duration":
      returnValues.icon = "<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor'><path stroke-linecap='round' stroke-linejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z' /></svg>"
      break
    case "currency":
    case "currency_duration":
      returnValues.icon = "<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor'><path stroke-linecap='round' stroke-linejoin='round' d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z' /></svg>"
      break

    default: break
  }

  return returnValues
}

export const validateEmail = (str: string) => EMAIL_REGEX.test(str)

export const getHtmlFromProposalContainer = (selector) => {
  const element = document.querySelectorAll(selector)
  const texts = []
  for (let i = 0; i < element.length; i++) {
    let child = element[i]?.firstChild
    while (child) {
      if (child.nodeType === 3) {
        texts.push(child.data)
      } else if (child.draggable) {
        texts.push(child.innerText)
      } else if (child.tagName === "STRONG") {
        texts.push("<b>" + child.innerText + "</b>")
      } else if (child.tagName === "EM") {
        texts.push("<em>" + child.innerText + "</em>")
      } else if (child.tagName === "S") {
        texts.push("<s>" + child.innerText + "</s>")
      }
      child = child.nextSibling
    }
  }
  return texts.join("")
}

export const lookup = (obj, k, v) => {
  for (const key in obj) {
    const value = obj[key]
    if (obj.attrs && obj.attrs.uuid === v) {
      return obj
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      const y = lookup(value, k, v)
      if (y && y.attrs && y.attrs.uuid === v) return y
    }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; ++i) {
        const x = lookup(value[i], k, v)
        if (x && x.attrs && x.attrs.uuid === v) return x
      }
    }
  }
  return null
}

export const tailwindColorToHex = (tailwindClass: string) => {
  const colorParts = tailwindClass.split("-")
  const colorValue = colorParts[1] + (colorParts.length === 3 ? "-" + colorParts[2] : "")

  switch (colorValue) {
    case "blue-600":
      return "#2563eb"
    case "green-600":
      return "#16a34a"
    case "yellow-600":
      return "#ca8a04"
    case "red-600":
      return "#dc2626"
    case "gray-600":
      return "#4b5563"
    case "teal-600":
      return "#0d9488"
    default:
      console.warn("Tailwind color not found in helper")
      return "#9CA3AF"
  }
}

export const convertRgbToHex = (str: string) => {
  if (str.startsWith("#")) return str

  return str.replace(/rgb\(\d+,\s*\d+,\s*\d+\)/g,
    (rgbString) => "#" + rgbString
      .match(/\b(\d+)\b/g)
      .map((digit) => parseInt(digit).toString(16).padStart(2, "0").toUpperCase())
      .join(""),
  )
}

export const convertHslToHex = (str: string) => {
  if (str.startsWith("#")) return str

  const hsl = str.match(/\d+/g)

  const h = parseInt(hsl[0])
  const s = parseInt(hsl[1])
  const l = parseInt(hsl[2])

  const a = s * Math.min(l, 100 - l) / 100

  const f = (n) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color).toString(16).padStart(2, "0")
  }

  return "#" + f(0) + f(8) + f(4)
}

export const convertColorToHex = (str: string) => {
  if (str.startsWith("rgb")) return convertRgbToHex(str)

  if (str.startsWith("hsl")) return convertHslToHex(str)

  return str
}

export const goBack = () => {
  window.history.back()
}

export const splitStringIntoStringsOfFiveWords = (text: string) => {
  const words = text.split(" ")

  const result = []

  let i = 0

  while (i < words.length) {
    result.push(words.slice(i, i + 5).join(" ") + " ")

    i += 5
  }

  return result
}

export const changedKeys = (o1: Record<string, any>, o2: Record<string, any>) => {
  const objectKeys = union(keys(o1), keys(o2))
  return filter(objectKeys, (key) => {
    let check = o1[key] !== o2[key]
    // If the object is nested, we have to use isEuqal to check if the object is changed
    if (isObject(o1[key]) && isObject(o2[key])) check = !isEqual(o1[key], o2[key])
    return check
  })
}

export const scrollTo = (overlayScrollbarsComponentRef: InstanceType<typeof OverlayScrollbar>, element: HTMLElement, callback?: () => void) => {
  if (!element || !overlayScrollbarsComponentRef) return
  const pos = element.getBoundingClientRect()
  let fixedOffset = parseInt((pos.top + overlayScrollbarsComponentRef.instance.elements().content.scrollTop).toFixed())
  const viewportHeight = parseInt(overlayScrollbarsComponentRef.instance.elements().viewport.offsetHeight.toFixed())
  const scrollHeight = parseInt(overlayScrollbarsComponentRef.instance.elements().viewport.scrollHeight.toFixed())
  const viewportOffset = parseInt((viewportHeight / 2).toFixed())
  if (fixedOffset > scrollHeight - viewportOffset) {
    fixedOffset = scrollHeight - viewportOffset
  }
  const scrollPosCalculated = fixedOffset - viewportOffset
  let scrollPos = scrollPosCalculated > 0 ? scrollPosCalculated : 0

  scrollPos = scrollPos - fixedOffset > viewportHeight - viewportOffset ? viewportHeight - viewportOffset : scrollPos
  const onScroll = () => {
    if (parseInt(overlayScrollbarsComponentRef.instance.elements().viewport.scrollTop.toFixed()) === scrollPos) {
      overlayScrollbarsComponentRef.instance.elements().viewport.removeEventListener("scroll", onScroll)
      callback?.()
    }
  }
  overlayScrollbarsComponentRef.instance.elements().viewport.addEventListener("scroll", onScroll)
  onScroll()
  overlayScrollbarsComponentRef.instance.elements().viewport.scrollTo({
    top: scrollPos,
    behavior: "smooth",
  })
}

export const hideTippiesViaSelector = (selector: string, destroy = false) => {
  const elements = document.querySelectorAll(selector)
  if (!elements.length) return
  elements?.forEach((node) => {
    interface TippyNode extends Element {
      _tippy: Instance<TippyProps> | DelegateInstance<TippyProps>
    }
    const tippyNode = node as TippyNode
    if (!tippyNode._tippy) return
    destroy ? tippyNode._tippy?.destroy() : tippyNode._tippy?.hide()
  })
}

export const cyrb53 = (str: string, seed = 0) => {
  if (!str) return seed
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export const validateMultifieldValue = (type: MultiFieldType, value: any, format: MultifieldFormat = null) => {
  let schema: ZodSchema
  switch (type) {
    case MultiFieldType.text || MultiFieldType.textarea || MultiFieldType.list:
      schema = z.string().nullable()
      break
    case MultiFieldType.email:
      schema = z.string().email().nullable()
      break
    case MultiFieldType.number:
      schema = z.coerce.string().nullable().refine((val) => !val || isValidMultifieldNumber(val, format))
      break
    case MultiFieldType.date:
      schema = z.coerce.date().nullable()
      break
    case MultiFieldType.bool:
      schema = z.coerce.boolean().nullable()
      break
    case MultiFieldType.select:
      schema = z.string().nullable()
      break
    case MultiFieldType.duration:
      schema = z.string().nullable()
      break
    case MultiFieldType.currency || MultiFieldType.currency_duration:
      schema = z.string().nullable().refine((val) => !val || isValidMultifieldNumber(val?.split(";")?.[1], format))
      break
    default:
      schema = z.string().nullable()
      break
  }
  const parsedResult = schema.safeParse(value)
  return parsedResult.success
}

export const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

export const getDefaultMultiFieldFormat = (type: MultiFieldType, locale = "en") => {
  // Depends on MultiFieldType, for currency double decimals, for number no decimals
  const defaultDecimals = [ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(type) ? "2" : "0"
  const defaultDecimalSeparator = locale === "de" ? "," : "."
  const defaultThousandsSeparator = locale === "de" ? "." : ","
  return {
    decimals: defaultDecimals,
    decimalSeparator: defaultDecimalSeparator,
    thousandsSeparator: defaultThousandsSeparator,
  }
}

export const isValidMultifieldNumber = (number: string, format: MultifieldFormat = null): boolean => {
  if (!number) return true
  // If settings, build regex accordingly
  let regex: RegExp
  if (format) {
    // When decimals is 0 we don't want to allow decimals or a decimal separator in the regex
    if (format.decimals === "0") {
      regex = new RegExp(`^[+-]?\\d+(?:[,]\\d{3})*$`)
    } else {
      const decimals = format.decimals || "2"
      regex = new RegExp(`^[+-]?\\d+(?:[,]\\d{3})*(?:[.]\\d{1,${decimals}})?$`)
    }
  } else {
    regex = /^[+-]?\d+$/
    // regex = /^[+-]?\d+(?:[,]\d{3})*(?:[.]\d{1,2})?$/
  }
  return regex.test(number)
  // return (/^[+-]?\d+(?:\.\d{1,2})?\.?$/.test(number))
}

export const isValidSingleIntervalISODuration = (duration: string): boolean => {
  if (duration === "P") return true
  const singleIntervalIsoDurationRegex = /^P(?:\d+Y|\d+M|\d+W|\d+D)$/
  return singleIntervalIsoDurationRegex.test(duration)
}

/**
 * Method to check if a string is a valid currency string
 */
export const isValidCurrencyString = (input: string, hasDuration = false): boolean => {
  const currencyStringRegex = !hasDuration ? /^([A-Z]{3});(\d+(\.\d*)?)$/ : /^([A-Z]{3});(\d+(\.\d*)?);(monthly|yearly)$/
  return currencyStringRegex.test(input)
}

/**
 * Method to check in the structure of an SVG if it includes a linearGradient
 */
export const checkIfSvgHasLinearGradient = (svg: string) => {
  const parser = new DOMParser(),
    doc = parser.parseFromString(svg, "image/svg+xml"),
    paths = doc.querySelectorAll("path[fill^='url(#']")

  for (const path of Array.from(paths)) {
    const id = path.getAttribute("fill").replace("url(#", "").replace(")", "")

    const linearGradient = doc.querySelector(`linearGradient#${id}`)

    if (linearGradient) return true
  }
}

export const addNumberSeparators = (value: number | string, separator = ".", decimalPlaces = 2) => {
  if (typeof value === "string") {
    value = parseFloat(value)
  }

  const decimalPlacesNumber = value - Math.floor(value)
  let decimalPlacesString = (decimalPlacesNumber + "").split(".")[1]

  if (decimalPlacesString?.length) {
    decimalPlacesString = decimalPlacesString.slice(0, decimalPlaces)
  }

  const amount = Math.floor(value) + ""
  const separatedString = amount.replace(/\B(?=(\d{3})+(?!\d))/g, separator)

  const commaSeparator = separator === "." ? "," : "."

  return separatedString + (decimalPlacesNumber && decimalPlaces ? commaSeparator + decimalPlacesString : "")
}

export const formatMultifieldNumber = (value: number | string, format: MultifieldFormat = null, type: MultiFieldType = MultiFieldType.number, locale = "en") => {
  // Depends on MultiFieldType, for currency double decimals, for number no decimals
  const defaultDecimals = type === MultiFieldType.currency || type === MultiFieldType.currency_duration ? 2 : 0
  const defaultDecimalSeparator = locale === "de" ? "," : "."
  const defaultThousandsSeparator = locale === "de" ? "." : ","

  const amount = parseFloat(value + "")
  const decimals = format?.decimals ? parseInt(format?.decimals) : defaultDecimals
  const amountParts = amount.toFixed(decimals).split(".")

  const thousandsSeparator = format?.thousandsSeparator || (format?.thousandsSeparator === null ? "" : defaultThousandsSeparator)
  const wholePart = addNumberSeparators(amountParts[0], thousandsSeparator)

  const decimalSeparator = format?.decimalSeparator || defaultDecimalSeparator
  if (decimals === 0) {
    return wholePart
  }
  return `${wholePart}${decimalSeparator}${amountParts[1]}`
}

export const convertCurrencyString = (input: string, format: MultifieldFormat = null, locale = "en", translate: (s: string, n: number) => void = null): string => {

  if (!input) return

  const parts = input.split(";")

  if (parts.length < 2 || !input) return

  const currency = parts[0]
  const amount = parts[1]
  const duration = parts[2]

  const formattedAmount = formatMultifieldNumber(amount, format, MultiFieldType.currency, locale)

  let output = `${currency} ${formattedAmount}`

  if (duration && !translate) {
    const { t } = useI18n()
    const localizedDuration = t(`duration.${duration}`)
    output += ` ${localizedDuration}`
  } else if (duration && translate) {
    output += ` ${translate(`duration.${duration}`, 0)}`
  }

  return output
}

export const dataURLtoFile = (dataurl, filename) => {

  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([ u8arr ], filename, { type: mime })
}

export const showLoadingIndicator = async (elementId: string): Promise<(() => void)> => {
  await nextTick()
  const dotElement = document.getElementById(elementId)
  if (!dotElement) return
  let dotCount = 1
  const intervalId = setInterval(() => {
    dotElement.textContent = ".".repeat(dotCount)
    dotCount = (dotCount % 3) + 1
  }, 500)
  return () => clearInterval(intervalId)
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes"
  if (bytes === 1) return "1 Byte"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [ " Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB" ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

export const sanitizeHex = (hex, includeHash = false) => {
  if (hex.charAt(0) === "#") {
    hex = hex.substr(1)
  }

  if (hex.length === 3) {
    const r = hex.charAt(0)
    const g = hex.charAt(1)
    const b = hex.charAt(2)

    hex = r + r + g + g + b + b

    if (includeHash) {
      hex = "#" + hex
    }

    return hex
  } else if (hex.length !== 6) {
    // whatever this is, it's not a valid hex code
    return (includeHash ? "#" : "") + "000000"
  } else {
    return hex
  }
}

// return the grayscale value (0-255) of a given hex code
export const hexToGrayscaleValue = (hex) => {
  hex = sanitizeHex(hex)

  // Convert each component from hex to decimal
  const red = parseInt(hex.substr(0, 2), 16)
  const green = parseInt(hex.substr(2, 2), 16)
  const blue = parseInt(hex.substr(4, 2), 16)

  // Calculate the grayscale value using the luminosity approach
  const gray = red * 0.3 + green * 0.59 + blue * 0.11

  // Round to the nearest integer
  return Math.round(gray)
}

// Export method to return the hex value of a color
export const hexColor = (color: string) => {
  if (color && color.includes("rgb")) {
    color = color.match(/\d+/g).map((x) => {
      const hex = parseInt(x, 10).toString(16)
      return hex.length === 1 ? "0" + hex : hex
    }).join("")
  } else if (color && color.includes("#")) {
    color = color?.replace("#", "") || null
  } else {
    color = null
  }
  return color
}

export const isValidHexColor = (hex) => {
  const regex = /^#([A-Fa-f0-9]{6})$/i
  return regex.test(hex)
}

export const useDetectOutsideClick = (component: HTMLElement, callback: () => void) => {
  if (!component) return
  const listener = (event: PointerEvent) => {
    if (event.target !== component && event.composedPath().includes(component)) {
      return
    }
    else if (event.composedPath().some((element: HTMLElement) => element.classList && element.classList.contains("tippy-content"))) {
      return
    }
    if (typeof callback === "function") {
      callback()
    }
  }
  window.addEventListener("click", listener)
  return listener
}

export const abbreviate = (number, decPlaces = 2) => {
  const isNegative = number < 0
  number = Math.abs(number)
  decPlaces = Math.pow(10, decPlaces)

  const abbrev = [ "k", "m", "b", "t" ]

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i++
      }

      number = (isNegative ? "-" : "") + number + abbrev[i]
      break
    }
  }

  return number
}

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual))

export const hideTippy = (event) => {
  const target = event?.target
  if (!target) return
  const popper = target.closest("[data-tippy-root")
  popper?._tippy?.hide()
}

export const mobileTippyOnHideCallback = (instance: Instance) => {
  const template = instance.reference.getAttribute("data-template")
  const element = document.getElementById(template)
  if (!!element) {
    const breakpoints = useBreakpoints(breakpointsTailwind)
    const isMobile = breakpoints.isSmaller("md")
    if (isMobile) {
      setTimeout(() => {
        const appendContainer = document.getElementById("partyAndSignatoryPopovers") || document.body
        appendContainer?.appendChild(element)
      }, 300)
    } else {
      const domRect = element.getBoundingClientRect()
      const appendContainer = document.getElementById("partyAndSignatoryPopovers") || document.body
      appendContainer?.appendChild(element)
      const fakeElement = document.createElement("div")
      fakeElement.innerHTML = (
        `<div class="popover popover-primary popover-mobile-fullscreen" style="width: ${domRect.width}px; height: ${domRect.height}px"></div>`
      ).trim()
      instance.setContent(fakeElement)
    }
  }
}

export const focusFirstFocusable = (element: HTMLElement) => {
  if (!element) return
  let focusable = element?.querySelectorAll("[data-tabindex='1']") as NodeListOf<HTMLElement>
  if (!focusable?.length) focusable = element?.querySelectorAll("input, select, textarea, [tabindex]:not([tabindex='-1'])") as NodeListOf<HTMLElement>
  const firstFocusable = focusable ? focusable[0] : null
  firstFocusable ? firstFocusable.focus() : null
}

export const setTippyPlacementOnMount = (instance: Instance) => {
  if (!instance.reference.getAttribute("data-placement")) return
  instance.popperInstance?.setOptions({
    placement: instance.reference.getAttribute("data-placement") as Placement,
  })
}

// Type guard function
function isMetadataValue (entry: Metadata | MetadataValue): entry is MetadataValue {
  return (entry as MetadataValue).metadata !== undefined
}

export const sortByMetadataName = <T extends Metadata[] | MetadataValue[]>(entries: T, t: ComposerTranslation, sortDirection = "desc"): T => {
  const tmpEntries = [ ...entries ]

  if (sortDirection) {
    tmpEntries.sort((a, b) => {

      let metadataA: Metadata
      let metadataB: Metadata

      if (isMetadataValue(a)) {
        metadataA = a.metadata
      } else {
        metadataA = a
      }

      if (isMetadataValue(b)) {
        metadataB = b.metadata
      } else {
        metadataB = b
      }

      const nameA = (metadataA.type === MetadataType.account || !!metadataA.account_metadata_uuid) ? metadataA.display_name : t("metadata.system." + metadataA.name + ".name")
      const nameB = (metadataB.type === MetadataType.account || !!metadataB.account_metadata_uuid) ? metadataB.display_name : t("metadata.system." + metadataB.name + ".name")

      if (sortDirection === "desc") {

        return (nameA > nameB) ? 1 : ((nameB > nameA) ? -1 : 0)
      } else {

        return (nameB > nameA) ? 1 : ((nameA > nameB) ? -1 : 0)
      }
    })
  }

  return tmpEntries as T
}

export const filterByMetadataName = <T extends Metadata[] | MetadataValue[]>(entries: T, t: ComposerTranslation, query = null): T => {
  if (!query?.length || !entries.length) return entries

  return [ ...entries ].filter((entry) => {

    let metadata: Metadata

    if (isMetadataValue(entry)) { // use type guard here
      metadata = entry.metadata
    } else {
      metadata = entry
    }

    const displayName = (metadata.type === MetadataType.account || !!metadata.account_metadata_uuid) ? metadata.display_name : t("metadata.system." + metadata.name + ".name")
    const description = (metadata.type === MetadataType.account || !!metadata.account_metadata_uuid) ? metadata.description : t("metadata.system." + metadata.name + ".description")
    const keywords = metadata.keywords ?? ""

    const hayStack = displayName + (description || "") + keywords

    return hayStack.toLowerCase().includes(query.toLowerCase())
  }) as T
}

export const getMetadataDisplayName = (metadata: Metadata, t: ComposerTranslation) => {
  return (metadata.type === MetadataType.account || !!metadata.account_metadata_uuid) ? metadata.display_name : t("metadata.system." + metadata.name + ".name")
}

export const getMetadataDisplayNameByKey = (key, metadata: Metadata[], t: ComposerTranslation) => {
  const metadataEntry = metadata.find((md) => md.name === key)
  if (!metadataEntry) {
    return null
  }
  return getMetadataDisplayName(metadataEntry, t)
}

export const getMimeType = (file, fallback = null) => {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ""
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case "89504e47":
      return "image/png"
    case "47494638":
      return "image/gif"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"
    default:
      return fallback
  }
}

export const detectAndSetBrowserLocale = () => {
  // Get the browser's language
  const browserLanguage = navigator.language || (navigator as any).userLanguage

  // Split the language code if it contains a hyphen
  let languageCode = browserLanguage.split("-")[0]

  // Use "en" as fallback
  if (![ "en", "de" ].includes(languageCode)) {
    languageCode = "en"
  }

  // Set the lang attribute of the HTML tag
  document.querySelector("html").setAttribute("lang", languageCode)

  return languageCode
}

export const readPdfFile = async (url: string): Promise<ArrayBuffer | void> => {
  const response = await fetch(url)
  if (!response) return
  const blob = await response.blob()
  if (!blob) return
  const contentBuffer = await blob.arrayBuffer()
  return contentBuffer
}

export const getSha256Hash = async (data: BufferSource) => {
  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

export const cropSignatureCanvas = (canvas: HTMLCanvasElement) => {

  if (!canvas || canvas.width === 0 || canvas.height === 0) {
    console.warn("Canvas is empty or has no dimensions. Cannot crop.")
    return
  }

  // First duplicate the canvas to not alter the original
  const croppedCanvas = document.createElement("canvas"),
    croppedCtx = croppedCanvas.getContext("2d", { willReadFrequently: true })

  croppedCanvas.width = canvas.width
  croppedCanvas.height = canvas.height

  croppedCtx.drawImage(canvas, 0, 0)

  // Next do the actual cropping
  let w = croppedCanvas.width
  let h = croppedCanvas.height
  let x: number, y: number, index: number

  const pix = { x: [], y: [] }
  const imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height)

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4
      if (imageData.data[index + 3] > 0) {
        pix.x.push(x)
        pix.y.push(y)

      }
    }
  }
  pix.x.sort((a, b) => {
    return a - b
  })
  pix.y.sort((a, b) => {
    return a - b
  })
  const n = pix.x.length - 1

  w = pix.x[n] - pix.x[0]
  h = pix.y[n] - pix.y[0]
  const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h)

  croppedCanvas.width = w
  croppedCanvas.height = h
  croppedCtx.putImageData(cut, 0, 0)

  return croppedCanvas
}

export const copyCanvasToNewCanvas = (sourceCanvas: HTMLCanvasElement, newWidth: number, newHeight: number): HTMLCanvasElement => {
  // Create a new canvas element
  const newCanvas = document.createElement("canvas")
  const ctx = newCanvas.getContext("2d")
  if (!ctx) {
    throw new Error("Failed to get canvas context")
  }

  newCanvas.width = newWidth
  newCanvas.height = newHeight

  // Calculate scaling factors
  const scale = Math.min(newWidth / sourceCanvas.width, newHeight / sourceCanvas.height, 2)

  // Calculate the dimensions of the scaled image
  const scaledWidth = sourceCanvas.width * scale
  const scaledHeight = sourceCanvas.height * scale

  // Calculate position to center the image
  const dx = (newWidth - scaledWidth) / 2
  const dy = (newHeight - scaledHeight) / 2

  // Clear the new canvas
  ctx.clearRect(0, 0, newWidth, newHeight)

  // Draw the source canvas on the new canvas, centered
  ctx.drawImage(sourceCanvas, dx, dy, scaledWidth, scaledHeight)

  return newCanvas
}

export const extractUUIDFromString = (str: string) => {
  const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i
  const match = str.match(uuidPattern)
  return match ? match[0] : null
}

const splitExpressionIntoRules = (complexExpression: string): { expressionParts: string[], parentOperators: string[] } => {
  // Define a regular expression to match each multifield_compare expression and the operators
  const expressionRegex = /multifield_compare\("[^"]+", dynamic_field\["[^"]+"\], "([^"]*)"\)/g
  const operatorRegex = /\s*(\&\&|\|\|)\s*/g

  // Find all multifield_compare expressions
  const expressionParts = complexExpression.match(expressionRegex)
  if (!expressionParts) return

  // Split by multifield_compare to capture the operators in between
  const splitByExpression = complexExpression.split(operatorRegex)

  // Capture operators; the first item won't have an operator before it, hence starting from the second item
  const parentOperators: string[] = []
  splitByExpression.forEach((part) => {
    const operatorMatch = part.match(operatorRegex)
    if (operatorMatch) {
      parentOperators.push(operatorMatch[0].trim())
    }
  })

  return { expressionParts, parentOperators }
}

const extractPartsFromExpressionRule = (expression: string): { operator: ConditionOperator; dynamicFieldRefUuid: string; matchValue: string } => {
  // Define a regular expression to match the overall format and capture the needed parts
  const regex = /multifield_compare\("([^"]*)", dynamic_field\["([^"]*)"\], "([^"]*)"\)/
  const match = expression.match(regex)

  if (!match) return

  // Extract the captured groups from the match
  const [ , operator, dynamicFieldRefUuid, matchValue ] = match

  return { operator: operator as ConditionOperator, dynamicFieldRefUuid, matchValue }
}

export const parseRulesFromSymfonyExpression = (expression: string): Rule[] => {
  if (!expression) return []

  const splitExpression = splitExpressionIntoRules(expression)

  if (!splitExpression) return []

  const { expressionParts, parentOperators } = splitExpression

  const rules: Rule[] = []

  for (let i = 0; i < expressionParts.length; i += 1) {

    const extractedParts = extractPartsFromExpressionRule(expressionParts[i])

    if (!extractedParts) return

    const { operator, dynamicFieldRefUuid, matchValue } = extractedParts

    /* // Extract match value from parts[i + 2], if undefined, set to undefined
    let matchValueCasted = matchValue === "undefined" ? undefined : matchValue

    // For null values, set to null if the operator is either equal or not equal (this is necessary for is_empty and is_not_empty)
    if (matchValue === "NULL" && [ ConditionOperator.EQUAL, ConditionOperator.NOT_EQUAL ].includes(operator)) {
      matchValueCasted = null
    } */

    const ruleToPush = {
      dynamicFieldRefUuid: dynamicFieldRefUuid,
      operator: operator,
      matchValue: matchValue,
      parentOperator: parentOperators?.[i - 1] || undefined,
    }

    rules.push(ruleToPush)

  }

  return rules
}

export const canScroll = (el, scrollAxis) => {
  if (0 === el[scrollAxis]) {
    el[scrollAxis] = 1
    if (1 === el[scrollAxis]) {
      el[scrollAxis] = 0
      return true
    }
  } else {
    return true
  }
  return false
}

export const isScrollableX = (el) => {
  return (el.scrollWidth > el.clientWidth) && canScroll(el, "scrollLeft") && ("hidden" !== getComputedStyle(el).overflowX)
}

export const isScrollableY = (el) => {
  return (el.scrollHeight > el.clientHeight) && canScroll(el, "scrollTop") && ("hidden" !== getComputedStyle(el).overflowY)
}

export const isScrollable = (el) => {
  return isScrollableX(el) || isScrollableY(el)
}

export const getFileTypeIconByMimeType = (mimeType: string) => {
  switch (mimeType) {
    case "application/pdf":
      return PDFIcon
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return MsWordIconColor
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return MsExcelIcon
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return MsPowerpointIcon
    case "application/zip":
      return ZipIcon
    case "text/plain":
    case "application/rtf":
      return DocumentIcon
    case "message/rfc822":
      return EmailIcon
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
    case "image/gif":
    case "image/bmp":
    case "image/svg+xml":
    case "image/webp":
      return ImageIcon
    default:
      return DocumentIcon
  }
}

export const isFileEncrypted = async (file: File): Promise<boolean> => {
  let isPasswordProtected = false

  const fileText = await file.text()
  // Looking for the encryption dictionary might not be sufficient to distinguish between
  // password protection and other forms of encryption like digital signatures.
  // This approach is inherently limited and might not work for all PDFs.
  if (fileText.includes("/Encrypt")) {
    const encryptionSection = fileText.substring(
      fileText.lastIndexOf("<<"),
      fileText.indexOf(">>", fileText.lastIndexOf("<<")) + 2,
    )

    // A simplistic check for patterns that might indicate password protection
    // This is not reliable for all PDFs as structures can vary significantly
    if (encryptionSection.includes("/Encrypt") && encryptionSection.includes("/P")) {
      isPasswordProtected = true
    }
  }

  return isPasswordProtected
}

export const decodeFilterString = (filterString: string, decode = false) => {
  if (decode) {
    const decodedBytes = atob(filterString)
    const textDecoder = new TextDecoder("utf-8")
    const decodedString = textDecoder.decode(new Uint8Array(decodedBytes.split("").map((char) => char.charCodeAt(0))))
    return decodedString
  }
  return filterString.replaceAll("_", " ")
}

export const generateBoundingBoxOffsetLabel = (el: HTMLElement, innerText: string, bgClass: string) => {

  const boundingBoxElement = document.createElement("div")
  boundingBoxElement.classList.add("absolute", "text-xs", "text-white", "font-medium", "rounded-md", "px-1", "py-0.5", "truncate", "select-none", bgClass)
  boundingBoxElement.style.top = `-24px`
  boundingBoxElement.style.right = `-2px`
  boundingBoxElement.style.zIndex = "1"
  boundingBoxElement.textContent = innerText

  el.appendChild(boundingBoxElement)
}

export const getCookie = (cname) => {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

declare const window: any


export const trackEventInJune = (eventName: string) => {
  const pageProps = usePage().props
  if (!pageProps || !pageProps?.account) return
  if (!window.analytics || !window.analytics.track) return

  window.analytics.track(eventName, {}, {
  // Add the GROUP_ID here to track this event on behalf of a workspace
    context: { groupId: (pageProps?.account as Account)?.uuid },
  })
}

export const identifyInJune = (mau: AccountUser) => {
  if (!window.analytics || !window.analytics.track || !mau) return
  window.analytics.identify(mau.uuid, {
    email: mau.email,
  })
}

export const logout = () => {
  useAccountStore().$reset()
  router.post(route("logout"))
}


interface GetFilteredRecipientsParams {
  users?: DocumentUser[],
  recipients?: DocumentNotificationRecipients,
  allAccountUsers?: AccountUser[],
}

// Type guard function to check if a user is a DocumentUser
function isDocumentUser (user: AccountUser | DocumentUser): user is DocumentUser {
  return !!(user as DocumentUser).document_uuid
}

function isExternalUser (user: DocumentUser) {
  return !user.account_user && !user.account_user_uuid
}


export const getFilteredRecipients = ({
  users,
  recipients,
  allAccountUsers = [],
}: GetFilteredRecipientsParams = {}) => {
  if (recipients === null) return users

  const documentUserAccountUserUuids = [ ...users ].filter((user) => !isExternalUser(user)).map((user) => user.account_user?.uuid || user.account_user_uuid)

  const accountUsersNotInUserList = allAccountUsers.filter((accountUser) => !documentUserAccountUserUuids.includes(accountUser.uuid))

  const relevantUserList:(AccountUser|DocumentUser)[] = [ ...users, ...accountUsersNotInUserList ]

  return relevantUserList.filter((user) => {
    let isInList = true

    if (isDocumentUser(user)) {
      if (recipients.document_user_uuids?.includes(user.uuid)) {
        return true
      }

      if (recipients.exclude_document_user_uuids?.includes(user.uuid)) {
        return false
      }

      if (!recipients.scope.external && isExternalUser(user)) {
        isInList = false
      }
      if (!recipients.scope.internal && !isExternalUser(user)) {
        isInList = false
      }
      if (!recipients.roles.some((role) => user.roles?.includes(role))) {
        isInList = false
      }
    } else {
      if (!recipients.team_uuids?.length || !user.teams?.length) {
        isInList = false
      } else {
        const userTeamUuids = user.teams.map((team) => team.uuid)
        isInList = recipients.team_uuids.some((recipientTeamUuid) => userTeamUuids.includes(recipientTeamUuid))
      }
    }

    return isInList
  })
}

export const convertIsoDurationToLowestUnit = (isoDuration) => {
  if (!isoDuration) return isoDuration

  const isoRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/
  const matches = isoDuration.match(isoRegex)

  if (!matches) {
    throw new Error("Invalid ISO 8601 duration string")
  }

  // Extract the values from the regex matches, default to 0 if undefined
  const years = parseInt(matches[1]) || 0
  const months = parseInt(matches[2]) || 0
  const weeks = parseInt(matches[3]) || 0
  const days = parseInt(matches[4]) || 0

  // Count the number of present units
  const unitCount = [ years, months, weeks, days ].filter((unit) => unit > 0).length

  // If there is only one unit, return the original duration
  if (unitCount === 1) {
    return isoDuration
  }

  // Convert based on the lowest unit present
  if (days > 0) {
    const totalDays = (years * 365) + (months * 30) + (weeks * 7) + days
    return `P${totalDays}D`
  } else if (weeks > 0) {
    const totalWeeks = (years * 52) + Math.floor(months * 4.345) + weeks
    return `P${totalWeeks}W`
  } else if (months > 0) {
    const totalMonths = (years * 12) + months
    return `P${totalMonths}M`
  }

  return isoDuration
}
