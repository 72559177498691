
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  ><defs><linearGradient
    id="vscodeIconsFileTypeZip0"
    x1="17.65"
    x2="21.099"
    y1="26.056"
    y2="26.056"
    gradientUnits="userSpaceOnUse"
  ><stop
    offset="0"
    stop-color="#4d4d4d"
  /><stop
    offset=".5"
    stop-color="#fff"
  /><stop
    offset="1"
    stop-color="#4d4d4d"
  /></linearGradient><linearGradient
    id="vscodeIconsFileTypeZip1"
    x1="17.65"
    x2="21.099"
    y1="23.756"
    y2="23.756"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip2"
    x1="17.65"
    x2="21.099"
    y1="21.456"
    y2="21.456"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip3"
    x1="17.65"
    x2="21.099"
    y1="19.156"
    y2="19.156"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip4"
    x1="17.65"
    x2="21.099"
    y1="16.857"
    y2="16.857"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip5"
    x1="17.65"
    x2="21.099"
    y1="14.557"
    y2="14.557"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip6"
    x1="17.65"
    x2="21.099"
    y1="12.257"
    y2="12.257"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip7"
    x1="17.65"
    x2="21.099"
    y1="9.957"
    y2="9.957"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip8"
    x1="17.65"
    x2="21.099"
    y1="7.657"
    y2="7.657"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZip9"
    x1="16.237"
    x2="19.686"
    y1="27.217"
    y2="27.217"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipa"
    x1="16.237"
    x2="19.686"
    y1="24.918"
    y2="24.918"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipb"
    x1="16.237"
    x2="19.686"
    y1="22.618"
    y2="22.618"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipc"
    x1="16.237"
    x2="19.686"
    y1="20.318"
    y2="20.318"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipd"
    x1="16.237"
    x2="19.686"
    y1="18.018"
    y2="18.018"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipe"
    x1="17.65"
    x2="21.099"
    y1="28.356"
    y2="28.356"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipf"
    x1="16.237"
    x2="19.686"
    y1="15.718"
    y2="15.718"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipg"
    x1="16.237"
    x2="19.686"
    y1="13.419"
    y2="13.419"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZiph"
    x1="16.237"
    x2="19.686"
    y1="11.119"
    y2="11.119"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipi"
    x1="16.237"
    x2="19.686"
    y1="8.819"
    y2="8.819"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipj"
    x1="16.237"
    x2="19.686"
    y1="29.514"
    y2="29.514"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipk"
    x1="16.985"
    x2="20.446"
    y1="11.196"
    y2="11.196"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipl"
    x1="18.096"
    x2="19.336"
    y1="5.329"
    y2="5.329"
    href="#vscodeIconsFileTypeZip0"
  /><linearGradient
    id="vscodeIconsFileTypeZipm"
    x1="16.029"
    x2="21.403"
    y1="5.591"
    y2="5.591"
    href="#vscodeIconsFileTypeZip0"
  /></defs><path
    fill="#c09553"
    d="M27.667 27.667V17.333L23 15V2H4.333v28h23.334Zm-4.667 0v-9.111l2.333 1.222v7.889Z"
  /><path
    fill="url(#vscodeIconsFileTypeZip0)"
    d="M17.65 25.559h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip1)"
    d="M17.65 23.26h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip2)"
    d="M17.65 20.96h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip3)"
    d="M17.65 18.66h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip4)"
    d="M17.65 16.36h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip5)"
    d="M17.65 14.06h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip6)"
    d="M17.65 11.76h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip7)"
    d="M17.65 9.461h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip8)"
    d="M17.65 7.161h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZip9)"
    d="M16.237 26.721h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipa)"
    d="M16.237 24.421h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipb)"
    d="M16.237 22.121h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipc)"
    d="M16.237 19.821h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipd)"
    d="M16.237 17.522h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipe)"
    d="M17.65 27.859h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipf)"
    d="M16.237 15.222h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipg)"
    d="M16.237 12.922h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZiph)"
    d="M16.237 10.622h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipi)"
    d="M16.237 8.322h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipj)"
    d="M16.237 29.018h3.45v.993h-3.45z"
  /><path
    fill="url(#vscodeIconsFileTypeZipk)"
    d="M20.1 5.911a.554.554 0 0 0-.548-.53h-.009v.557h.092l.206 2.514h-2.252l.211-2.514h.094v-.557h-.044a.552.552 0 0 0-.547.53l-.314 10.569a.513.513 0 0 0 .515.531h2.432a.512.512 0 0 0 .513-.531Zm-1.384 10.5a1.171 1.171 0 1 1 1.171-1.171a1.171 1.171 0 0 1-1.171 1.168Z"
  /><path
    fill="url(#vscodeIconsFileTypeZipl)"
    d="M18.096 3.802h1.24v3.054h-1.24z"
  /><path
    fill="url(#vscodeIconsFileTypeZipm)"
    d="M21.4 4.56a2.549 2.549 0 0 0-2.549-2.549h-.276a2.55 2.55 0 0 0-2.436 3.3v.006a7.986 7.986 0 0 1 .261 2.561a1.811 1.811 0 0 0 .641 1.271l.109-3.441a.593.593 0 0 1 .6-.551h.135v-1.57h1.653v1.57h.1a.6.6 0 0 1 .605.551l.121 3.462a1.813 1.813 0 0 0 .666-1.292V7.72a7.693 7.693 0 0 1 .236-2.359a2.543 2.543 0 0 0 .134-.801"
  /></svg>
</template>
