import { defineStore } from "pinia"
import { computed, reactive, toRefs } from "vue"

import { CrudContext } from "~/types"

interface Data {
  crudContext: CrudContext,
  sidebarScrollContainer: any,
  uiHintCollection: Record<string, string>[],
  pdfViewerTimeout: NodeJS.Timeout | null,
}

export const useSharedStore = defineStore(
  "sharedStore",
  () => {
    const data = reactive<Data>(
      {
        crudContext: null,
        sidebarScrollContainer: null,
        uiHintCollection: [],
        pdfViewerTimeout: null,
      },
    )

    // computed variables
    const isProduction = computed<boolean>(() => window.location.origin === "https://app.fynk.com")

    // mutations
    const setCrudContext = (crudContext: CrudContext) => data.crudContext = crudContext
    const pushUiHint = (uiHint: Record<string, string>) => data.uiHintCollection.push(uiHint)
    const setUiHintCollection = (uiHintCollection: Record<string, string>[]) => data.uiHintCollection = uiHintCollection
    const setPdfViewerTimeout = (pdfViewerTimeout: NodeJS.Timeout) => data.pdfViewerTimeout = pdfViewerTimeout

    // ui actions
    const setSidebarScrollContainer = (sidebarScrollContainer: HTMLDivElement) => data.sidebarScrollContainer = sidebarScrollContainer

    return {
      ...toRefs(data),
      isProduction,

      // mutations
      setCrudContext,
      pushUiHint,
      setUiHintCollection,
      setPdfViewerTimeout,

      // ui actions
      setSidebarScrollContainer,
    }
  },
)
