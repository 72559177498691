// external
import { storeToRefs } from "pinia"
import axios from "axios"
import { DelegateInstance, Instance, Placement, Props as TippyProps, delegate, roundArrow, sticky } from "tippy.js"

// internal
import { useDocumentStore, usePartyStore } from "~/stores"
import { CrudContext, DocumentStage, DocumentUser, ExtendedTippyProps } from "~/types"
import tippy, { documentUserCustomProp } from "~/utils/tippyDocumentUser"
import { focusFirstFocusable, mobileTippyOnHideCallback, setTippyPlacementOnMount, tippyMobile } from "~/utils"

// Make tippy setup globally available
export const useTippies = (
  context: CrudContext,
  checkIfDisabledByPusher: (...args: any[]) => boolean = () => false,
  whisperFocus: (...args: any[]) => any = () => ({}),
  whisperBlur: (...args: any[]) => any = () => ({}),
) => {

  const documentStore = useDocumentStore()
  const { isLockedDocument } = storeToRefs(documentStore)

  const partyStore = usePartyStore()

  const setUpAddDynamicFieldTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: ".add-dynamic-field-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: () => document.getElementById("mainContentContainer"),
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        const template = instance.reference.getAttribute("data-template")
        element = document.getElementById(template)
        instance.setContent(element)
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  const setUpDynamicFieldTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: "[data-dynamic-field-node].dynamic-field-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: (ref) => { return ref.closest("#proposalContainer") || document.getElementById("mainContentContainer")},
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        if (isLockedDocument.value) {
          return false
        } else {
          const template = instance.reference.getAttribute("data-template")
          element = document.getElementById(template)
          instance.setContent(element)
        }
      },
      onHide (instance) {
        const template = instance.reference.getAttribute("data-template")
        const element = document.getElementById(template)
        if (!!element) {
          const domRect = element.getBoundingClientRect()
          const appendContainer = document.getElementById("editorContainerPopovers") || document.body
          appendContainer?.appendChild(element)
          const fakeElement = document.createElement("div")
          fakeElement.innerHTML = (
            `<div class="relative">
                <div
                    class="popover popover-primary popover-mobile-fullscreen"
                    style="height: ${domRect.height}px; width: ${domRect.width}px"
                ></div>
              </div>`
          ).trim()
          instance.setContent(fakeElement)
          instance.popperInstance?.setOptions({
            placement: instance.reference.getAttribute("data-placement") as Placement,
          })
        }
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  const setUpDynamicFieldSettingsTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: "[data-dynamic-field-button]",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: () => document.getElementById("mainContentContainer"),
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        if (context === CrudContext.document) {
          if (checkIfDisabledByPusher(instance.reference?.id)) {
            return false
          } else {
            whisperFocus({ target: { id: instance.reference?.id } })
          }
        }
        if (isLockedDocument.value) {
          return false
        } else {
          const template = instance.reference.getAttribute("data-template")
          element = document.getElementById(template)
          instance.setContent(element)
        }
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
        if (context === CrudContext.document) {
          whisperBlur({ target: { id: instance.reference?.id } })
        }
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  const setUpSignatoryTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: ".edit-signatory-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      appendTo: (ref) => { return ref.closest("#partiesAndSignatoriesConfirmModal") || document.getElementById("mainContentContainer") || document.getElementById("wizardContainer")},
      theme: "indigo",
      arrow: roundArrow,
      animation: "scale",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        if (context === CrudContext.document) {
          if (checkIfDisabledByPusher(instance.reference?.id)) {
            return false
          } else {
            whisperFocus({ target: { id: instance.reference?.id } })
          }
        }
        if (isLockedDocument.value) {
          return false
        } else {
          const template = instance.reference.getAttribute("data-template")
          element = document.getElementById(template)
          instance.setContent(element)
        }
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
        if (context === CrudContext.document) {
          whisperBlur({ target: { id: instance.reference?.id } })
        }
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  const setUpPartyTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: ".edit-party-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: (ref) => { return ref.closest("#partiesAndSignatoriesConfirmModal") || document.getElementById("mainContentContainer")},
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        if (context === CrudContext.document) {
          if (checkIfDisabledByPusher(instance.reference?.id)) {
            return false
          } else {
            whisperFocus({ target: { id: instance.reference?.id } })
          }
        }
        if (isLockedDocument.value) {
          return false
        } else {
          const template = instance.reference.getAttribute("data-template")
          element = document.getElementById(template)
          instance.setContent(element)
        }
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
        if (context === CrudContext.document) {
          whisperBlur({ target: { id: instance.reference?.id } })
        }
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
        partyStore.setTriggerPartyPopoverTab(null)
      },
    })
  }

  const setUpAddSignatoryTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: ".add-signatory-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: (ref) => { return ref.closest("#partiesAndSignatoriesConfirmModal") || document.getElementById("mainContentContainer") || document.getElementById("wizardContainer")},
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        const template = instance.reference.getAttribute("data-template")
        element = document.getElementById(template)
        instance.setContent(element)
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  const setUpAddPartyTippies = (): DelegateInstance<TippyProps>[] => {
    let element: HTMLElement
    return delegate("body", {
      target: ".add-party-button",
      content: "",
      placement: "left",
      onMount (instance) {
        setTippyPlacementOnMount(instance)
      },
      allowHTML: true,
      animation: "scale",
      arrow: roundArrow,
      appendTo: (ref) => { return ref.closest("#partiesAndSignatoriesConfirmModal") || document.getElementById("mainContentContainer")},
      theme: "indigo",
      trigger: "click",
      sticky: true,
      plugins: [ sticky, tippyMobile ],
      hideOnClick: true,
      interactive: true,
      onShow (instance) {
        const template = instance.reference.getAttribute("data-template")
        element = document.getElementById(template)
        instance.setContent(element)
      },
      onHide (instance) {
        mobileTippyOnHideCallback(instance)
      },
      onShown () {
        focusFirstFocusable(element)
      },
      onHidden (instance) {
        instance.setContent("")
      },
    })
  }

  interface pusherFocusData {
    id: string
    isDocumentUser: boolean
  }

  // Create micro-tooltips for pusher focus disabling
  const setUpPusherFocusTippy = (data: pusherFocusData, documentUser: DocumentUser): Instance<ExtendedTippyProps>[] => {
    if (!data.id) return
    let references = "#" + data.id
    if (data.id.includes("dynamic-field_")) {
      references = "#" + data.id.replace("input", "popover") + ", #" + data.id.replace("popover", "input")
    }
    if (!documentUser) return
    const tooltipElement = document.createElement("div")
    tooltipElement.innerHTML =
          `<span class="flex h-4 w-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
            <img class="relative inline-flex rounded-full w-4 h-4 ring-2 ring-yellow-200" alt="" src="${documentUser?.profile_photo_url}" />
          </span>`
    const instances = tippy(references, {
      documentUser: documentUser.uuid,
      content: tooltipElement.innerHTML,
      appendTo: (ref) => { return (ref.parentNode as HTMLElement) || document.getElementById("mainContentContainer") },
      allowHTML: true,
      animation: "scale",
      trigger: "manual",
      placement: "right-start",
      showOnCreate: true,
      hideOnClick: false,
      theme: "pusher",
      offset: [ -5, -15 ],
      sticky: true,
      plugins: [ sticky, documentUserCustomProp ],
      popperOptions: {
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
        ],
      },
    })
    return instances
  }

  // Create a tippy showing a hint for the stage of a document
  const showStageTippy = (stage: DocumentStage, stageTippy: Instance<ExtendedTippyProps>[], uiHintCollection: Record<string, string>[], objectUuid: string) => {
    if (stageTippy?.length) {
      stageTippy.forEach((instance: Instance<ExtendedTippyProps>) => instance.destroy())
    }
    const selector = "#stageIndicator_" + stage
    let element: HTMLElement = null
    return tippy(selector, {
      content: "",
      appendTo: () => document.getElementById("mainContentContainer"),
      allowHTML: true,
      theme: "indigo",
      arrow: roundArrow,
      animation: "scale",
      trigger: "manual",
      sticky: true,
      plugins: [ sticky ],
      hideOnClick: true,
      showOnCreate: true,
      interactive: true,
      onShow (instance) {
        const element = document.getElementById("documentStagePopover")
        instance.setContent(element)
      },
      onHide () {
        element = document.getElementById("documentStagePopover")
        let uiHintType: string
        if (stage === DocumentStage.draft) uiHintType = "document_created"
        if (stage === DocumentStage.approved_draft) uiHintType = "review_ready"
        if (stage === DocumentStage.review) uiHintType = "review_active"
        if (stage === DocumentStage.approved_review) uiHintType = "signing_ready"
        if (stage === DocumentStage.signing) uiHintType = "signing_active"
        if (stage === DocumentStage.signed) uiHintType = "signed"
        if (uiHintType) {
          const apiRoute = route("api.documents.ui-hints.store", { document: objectUuid })

          const payload = { type: uiHintType }

          axios
            .post(apiRoute, payload)
            .then(() => uiHintCollection.push(payload))
            .catch((err) => console.error(err))
        }
      },
      onHidden (instance) {
        const appendContainer = document.getElementById("documentStagePopoverContainer") || document.body
        appendContainer?.appendChild(element)
        instance.setContent("")
      },
    })
  }

  return {
    setUpSignatoryTippies,
    setUpDynamicFieldTippies,
    setUpDynamicFieldSettingsTippies,
    setUpPartyTippies,
    setUpAddSignatoryTippies,
    setUpAddPartyTippies,
    setUpPusherFocusTippy,
    showStageTippy,
    setUpAddDynamicFieldTippies,
  }
}

export default useTippies
