import { debounce, omit, pick, uniq, without, isEqual, filter, find } from "lodash-es"
import { defineStore } from "pinia"
import { computed, nextTick, reactive, toRaw, toRefs } from "vue"
import { useI18n } from "vue-i18n"

import { Document, DocumentUser, FormErrors, Template, CrudContext, AccountUser, UserNotification, DocumentUserRoleEnum, Party, NotificationType } from "~/types"
import { changedKeys } from "~/utils"
import { useNotificationStore } from "../notificationStore"
import { useSignatureStore } from "../signatureStore"
import { useDocumentStore } from "../documentStore"
import { useCommentStore } from "../commentStore"

import {
  fetchAllUsersAction,
  fetchAllAccountUsersAction,
  createUserAction,
  updateUserAction,
  removeUserFromEntityAction,
  createUserInvitationAction,
  getDocumentUserAction,
  fetchAllUserNotificationsAction,
} from "./userStoreActions"

const usersContext = [ "document", "template" ]

const fieldsToAlwaysOmit = [ "profile_photo_url" ]
const fieldsToOmitWhenAccountUser = [ "first_name", "last_name", "email", "title", "mobile_phone", "profile_photo_url" ]

interface Data {
  users: DocumentUser[]
  isLoadingUsers: boolean
  uuidsOfUpdatingUser: DocumentUser["uuid"][]
  userLastSavedMap: Record<DocumentUser["uuid"], number>
  userErrorsMap: Record<DocumentUser["uuid"], FormErrors<DocumentUser>>
  allAccountUsers: AccountUser[]
  isFetchingAllAccountUsers: boolean
  isFetchingUserNotifications: boolean
  documentUserUuidBeingLoaded: DocumentUser["uuid"]
  userUuidBeingRemovedFromEntity: DocumentUser["uuid"]
  debounceTimestamp: number
  debounceUuid: DocumentUser["uuid"]
  payloadKeys: string[]
  userNotifications: UserNotification[]
  addUserPartyUuid?: Party["uuid"]
  uuidsOfIsLoadingSendInvitation: DocumentUser["uuid"][]
  documentUserLastUpdatedViaPusher?: DocumentUser["uuid"]
  documentUsersForSharing?: Partial<DocumentUser>[]
  idxOfIsCreatingUser?: number[]
}

export const useUserStore = defineStore("userStore", () => {
  const data = reactive<Data>({
    users: [],
    isLoadingUsers: false,
    uuidsOfUpdatingUser: [],
    userLastSavedMap: {},
    userErrorsMap: {},
    allAccountUsers: [],
    isFetchingAllAccountUsers: false,
    isFetchingUserNotifications: false,
    documentUserUuidBeingLoaded: null,
    userUuidBeingRemovedFromEntity: null,
    debounceTimestamp: null,
    debounceUuid: null,
    payloadKeys: [],
    userNotifications: null,
    addUserPartyUuid: null,
    uuidsOfIsLoadingSendInvitation: [],
    documentUserLastUpdatedViaPusher: null,
    documentUsersForSharing: [],
    idxOfIsCreatingUser: [],
  })

  const { t } = useI18n()

  const { notify } = useNotificationStore()

  const signatureStore = useSignatureStore()
  const { signatureBlocks } = toRefs(signatureStore)
  const { createSignatureBlock } = useSignatureStore()

  const documentStore = useDocumentStore()
  const commentStore = useCommentStore()

  // computed variables

  const availableAccountUsers = computed(
    () => {
      if (!data.allAccountUsers?.length) return []

      let relevantAccountUsers = [ ...data.allAccountUsers ]

      // for external users, only members from own party or signatories are relevant
      if (!documentStore.mau) {
        relevantAccountUsers = relevantAccountUsers.filter((accountUser) => {
          const documentUserOfAccountUser = data.users?.find((documentUser) => documentUser.account_user?.uuid === accountUser.uuid)
          return accountUser.party_uuid === documentStore.mdu?.party_uuid || documentUserOfAccountUser?.roles.includes(DocumentUserRoleEnum.signatory)
        })
      }

      return relevantAccountUsers
    },
  )

  const alreadyAttachedAccountUserUuids = computed<AccountUser["uuid"][]>(() => {
    return data.users?.map((user) => user.account_user?.uuid).filter((uuid) => uuid !== null) || []
  })

  // mutations
  const setUsers = (users: (DocumentUser)[]) => data.users = users
  const setAllAccountUsers = (allAccountUsers: (AccountUser)[]) => data.allAccountUsers = allAccountUsers
  const setAddUserPartyUuid = (partyUuid: Party["uuid"]) => data.addUserPartyUuid = partyUuid

  const setUserNotifications = (invitations: UserNotification[]) => data.userNotifications = invitations
  const setDocumentUsersForSharing = (users: DocumentUser[]) => data.documentUsersForSharing = users

  const setDocumentUserLastUpdatedViaPusher = (userUuid: DocumentUser["uuid"]) => data.documentUserLastUpdatedViaPusher = userUuid

  const pushOrUpdateUserNotification = (notification: UserNotification) => {
    const copyOfUserNotifications = [ ...toRaw(data.userNotifications) ]
    const indexOfInvitation = copyOfUserNotifications.findIndex((el) => el.uuid === notification.uuid)
    if (indexOfInvitation !== -1) {
      copyOfUserNotifications[indexOfInvitation] = notification
    } else {
      copyOfUserNotifications.push(notification)
    }
  }

  const pushUser = (user: DocumentUser) => data.users = [ ...toRaw(data.users || []), user ]

  const pushOrUpdateUser = (user: Partial<DocumentUser>) => {
    const localIndexOfUser = data.users.findIndex(({ uuid }) => uuid === user.uuid)

    const usersCopy = [ ...toRaw(data.users) ]

    if (localIndexOfUser !== -1) {
      usersCopy[localIndexOfUser] = {
        ...usersCopy[localIndexOfUser],
        ...user,
      }

      data.users = usersCopy

      if (documentStore.mdu?.uuid === user.uuid) {
        documentStore.setMdu({
          ...documentStore.mdu,
          ...user,
        })
      }

      return
    }

    data.users = [
      ...usersCopy,
      user,
    ] as DocumentUser[] // TODO: change this to real types
  }

  const removeUserFromStore = (userUuidToRemove: DocumentUser["uuid"]) => {
    const indexOfUserToRemove = data.users.findIndex((user) => user.uuid === userUuidToRemove)

    if (indexOfUserToRemove !== -1) {
      data.users.splice(indexOfUserToRemove, 1)
    }
  }

  const addUpdatingUser = (uuid: DocumentUser["uuid"]) => data.uuidsOfUpdatingUser = uniq([ ...toRaw(data.uuidsOfUpdatingUser), uuid ])

  const removeUpdatingUser = (uuid: DocumentUser["uuid"]) => data.uuidsOfUpdatingUser = without([ ...toRaw(data.uuidsOfUpdatingUser) ], uuid)

  const setUserLastSaved = (uuid: DocumentUser["uuid"]) => {
    data.userLastSavedMap = {
      ...toRaw(data.userLastSavedMap || {}),
      [uuid]: Date.now(),
    }
  }

  const setUserErrors = (uuid: DocumentUser["uuid"], userErrors: FormErrors<DocumentUser>) => {
    data.userErrorsMap = {
      ...toRaw(data.userErrorsMap || {}),
      [uuid]: userErrors,
    }
  }

  // api actions
  const fetchAllUsers = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<DocumentUser[] | void> => {
    if (!usersContext.includes(context)) {
      console.error("context should be one of", usersContext, " but given", context)
      return
    }

    try {
      data.isLoadingUsers = true

      const users = await fetchAllUsersAction(context, entityUuid)

      setUsers(users)

      return users
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingUsers = false
    }
  }
  const fetchAllUserNotifications = async (
    documentUuid: Document["uuid"],
  ): Promise<UserNotification[] | void> => {

    try {
      data.isFetchingUserNotifications = true

      const invitations = await fetchAllUserNotificationsAction(documentUuid)

      setUserNotifications(invitations)

      return invitations
    } catch (err) {
      console.error(err)
    } finally {
      data.isFetchingUserNotifications = false
    }
  }

  const fetchAllAccountUsers = async (): Promise<AccountUser[] | void> => {
    try {
      data.isFetchingAllAccountUsers = true

      const allAccountUsers = await fetchAllAccountUsersAction()

      setAllAccountUsers(allAccountUsers)

      return allAccountUsers
    } catch (err) {
      console.error(err)
    } finally {
      data.isFetchingAllAccountUsers = false
    }
  }

  const getDocumentUser = async (documentUuid: Document["uuid"], documentUserUuid: DocumentUser["uuid"]): Promise<DocumentUser | void> => {
    try {
      data.documentUserUuidBeingLoaded = documentUserUuid
      const documentUser = await getDocumentUserAction(documentUuid, documentUserUuid)
      if (documentUser) {
        pushOrUpdateUser(documentUser)
      }
      if (data.documentUserLastUpdatedViaPusher === documentUserUuid) {
        await nextTick()
        setDocumentUserLastUpdatedViaPusher(null)
      }
      return documentUser
    } catch (err) {
      console.error(err)
    } finally {
      data.documentUserUuidBeingLoaded = null
    }
  }

  // user CRUD
  const createSignatureBlockIfNeeded = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    payload: Partial<DocumentUser>,
    partyUuid: Party["uuid"],
  ): Promise<boolean> => {
    // If this is the first signatory
    // create a signature block for the respective party
    if (partyUuid && payload.roles?.includes(DocumentUserRoleEnum.signatory)) {
      const hasSignatureBlock = signatureBlocks.value?.some((el) => el.party_uuid === partyUuid && !el.deleted_at)
      if (!hasSignatureBlock) {
        const createdSignatureBlock = await createSignatureBlock(context, entityUuid, partyUuid)
        if (createdSignatureBlock) return true
        return false
      }
    }
    return true
  }

  const createUser = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    payload: Partial<DocumentUser>,
    skipCreateSignatureBlock = false,
  ): Promise<DocumentUser | void> => {

    if (!skipCreateSignatureBlock) {
      const createdSignatureBlockIfNeeded = await createSignatureBlockIfNeeded(context, entityUuid, payload, payload.party_uuid)
      if (!createdSignatureBlockIfNeeded) return
    }

    const createdUser = await createUserAction(context, entityUuid, payload)
    if (createdUser) pushUser(createdUser)

    return createdUser
  }


  const updateUser = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    user: Partial<DocumentUser>,
    originalUser: Partial<DocumentUser>,
    timestamp: number,
    payloadKeys: string[],
  ): Promise<DocumentUser | void> => {
    try {

      const payload = pick(user, payloadKeys)

      const createdSignatureBlockIfNeeded = await createSignatureBlockIfNeeded(context, entityUuid, payload, payload.party_uuid || originalUser.party_uuid)
      if (!createdSignatureBlockIfNeeded) return

      const updatedUser = await updateUserAction(context, entityUuid, payload, originalUser.document_user_uuid || originalUser.uuid)
      if (updatedUser) {
        if (!(data.debounceTimestamp > timestamp)) pushOrUpdateUser(updatedUser)
        setUserLastSaved(user.uuid)
        setUserErrors(user.uuid, {})
      }
      return updatedUser

    } catch (err) {
      const isBackendError = !!err.response?.data?.errors

      if (isBackendError) {
        setUserErrors(user.uuid, err.response.data.errors)
      } else {
        notify({
          title: t("userSettings.errors.updateUser"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    } finally {
      removeUpdatingUser(user.uuid)
    }
  }

  const removeUserFromEntity = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    userUuidToRemove: DocumentUser["uuid"],
  ) : Promise<DocumentUser[] | void> => {
    try {
      data.userUuidBeingRemovedFromEntity = userUuidToRemove
      const removeRes = await removeUserFromEntityAction(
        context,
        entityUuid,
        userUuidToRemove,
      )

      if (removeRes === 200) removeUserFromStore(userUuidToRemove)

      // leave document
      if (documentStore.mdu && userUuidToRemove === documentStore.mdu.uuid) {
        documentStore.setMdu(null)
      }

      // reload comments
      if (context === "document" && commentStore.comments.length) {
        commentStore.fetchComments(entityUuid)
      }

      return data.users
    } catch (err) {
      console.error(err)

      notify({
        title: t("userSettings.errors.removeUser"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.userUuidBeingRemovedFromEntity = null
    }
  }

  const debouncedUpdateUser = debounce(updateUser, 1000)
  const leadingDebouncedUpdateUser = debounce(updateUser, 1000, { leading: true })

  // ui actions
  const updateLocalUser = async (
    user: Partial<DocumentUser>,
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    noDebounce = false,
    leadingDebounce = false,
  ): Promise<DocumentUser | void> => {
    if (!user) return
    const originalUser = { ...toRaw(data.users.find((u) => u.uuid === user.uuid)) }

    const fieldsToOmit = user.account_user?.uuid
      ? fieldsToOmitWhenAccountUser
      : fieldsToAlwaysOmit

    const rawUser = omit({ ...toRaw(user) }, fieldsToOmit)

    const payloadKeys = changedKeys(originalUser, rawUser)

    const timestamp = Date.now()
    if (data.debounceUuid !== user.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
      debouncedUpdateUser?.flush()
      data.debounceUuid = user.uuid
      data.payloadKeys = payloadKeys
    }
    data.debounceTimestamp = timestamp

    pushOrUpdateUser(user)
    addUpdatingUser(user.uuid)
    let res: DocumentUser | void
    if (noDebounce) {
      res = await updateUser(
        context,
        entityUuid,
        rawUser,
        originalUser,
        timestamp,
        payloadKeys,
      )
    } else if (leadingDebounce) {
      res = await leadingDebouncedUpdateUser(
        context,
        entityUuid,
        rawUser,
        originalUser,
        timestamp,
        payloadKeys,
      )
    } else {
      res = await debouncedUpdateUser(
        context,
        entityUuid,
        rawUser,
        originalUser,
        timestamp,
        payloadKeys,
      )
    }
    return res
  }

  const removeUserAsSignatory = async (
    userUuid: DocumentUser["uuid"],
    entityUuid: Document["uuid"] | Template["uuid"],
    crudContext: CrudContext,
  ) => {
    if (!entityUuid || !crudContext) return
    // Look for documentUser based on uuid and check if the user has other roles than signatory
    const documentUser = data.users?.find((el) => el.uuid === userUuid)
    const hasOtherRoles = documentUser?.roles?.some((el) => el !== DocumentUserRoleEnum.signatory)
    // If user has other roles, we only remove the signatory role
    if (hasOtherRoles) {
      const newRoles = without(documentUser?.roles, DocumentUserRoleEnum.signatory)
      const payload = { ...toRaw(documentUser), roles: newRoles }
      try {
        const updatedUser = await updateLocalUser(payload, crudContext, entityUuid, true)
        setUserErrors(userUuid, null)
        return !!(updatedUser)
      } catch (err) {
        setUserErrors(userUuid, err.response?.data?.errors || null)
      }
    } else {
    // If user only has signatory role, we remove the user from the entity
      const res = await removeUserFromEntity(crudContext, entityUuid, userUuid)
      if (res) return true
    }
  }

  const sendUserInvitation = async (
    documentUuid: Document["uuid"],
    userUuid: DocumentUser["uuid"],
    message = "",
  ) : Promise<UserNotification | void> => {
    data.uuidsOfIsLoadingSendInvitation.push(userUuid)
    try {
      const createdNotification = await createUserInvitationAction(documentUuid, userUuid, message)
      pushOrUpdateUserNotification(createdNotification)
      if (createdNotification) return createdNotification
    } catch (err) {
      notify({
        title: t("userSettings.errors.sendInvitation"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidsOfIsLoadingSendInvitation = without(data.uuidsOfIsLoadingSendInvitation, userUuid)
    }
  }

  const getUserNotifications = (documentUser: DocumentUser, types: NotificationType | NotificationType[]): UserNotification[] => {
    // If types is not an array, make it one
    if (!Array.isArray(types)) types = [ types ]

    // Check if userNotifications contains an Notification with matching document_user_uuid and type document-invited
    const notifications = filter(
      data.userNotifications,
      (notification) =>
        notification?.document_user_uuid === documentUser.uuid &&
        types.includes(notification?.notification_type),
    )

    return notifications
  }

  const sendInvitations = async (
    message: string,
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    roles: DocumentUserRoleEnum[],
    skipInvite = false,
  ): Promise<UserNotification[] | void> => {

    const returnEvents = []
    const res = await Promise.all(
      data.documentUsersForSharing.map(
        async (el, idx) => {
          if (!el.email && !el.account_user?.uuid) return
          data.idxOfIsCreatingUser.push(idx)
          // If there is no document user, newly create, otherwise update role
          if (!el.uuid) {
            const createdUser = await createUserForInvitation(el, crudContext, entityUuid, roles)
            returnEvents.push(createdUser)
            if (!createdUser) return
            if (!documentStore.currentDocument) return
            if (skipInvite) return
            const sendInvitationRes = await sendUserInvitation(documentStore.currentDocument?.uuid, createdUser.uuid, message)
            returnEvents.push(sendInvitationRes)
            if (!sendInvitationRes) return
          } else {
            const updatedUser = await handleUpdateRoleOfExistingUser(el.uuid, crudContext, entityUuid)
            returnEvents.push(updatedUser)
            if (!updatedUser) return
            if (!documentStore.currentDocument) return
            if (skipInvite) return
            const sendInvitationReturnEvent = await sendUserInvitation(documentStore.currentDocument?.uuid, updatedUser.uuid, message)
            returnEvents.push(sendInvitationReturnEvent)
            if (!sendInvitationReturnEvent) return
          }
        },
      ),
    )
    if (!res) return
    data.idxOfIsCreatingUser = []
    data.uuidsOfIsLoadingSendInvitation = []
    return returnEvents
  }

  // Method to update the role of an existing document user
  const handleUpdateRoleOfExistingUser = async (userUuid: DocumentUser["uuid"], crudContext: CrudContext, entityUuid: Document["uuid"] | Template["uuid"]) => {
    const user = data.users?.find((el) => el.uuid === userUuid)
    if (!user) return
    const newRoles = !user.roles?.includes(DocumentUserRoleEnum.collaborator) && !user.roles?.includes(DocumentUserRoleEnum.owner) ? [ ...user.roles, DocumentUserRoleEnum.collaborator ] : [ ...user.roles ]
    const payload = { ...toRaw(user), roles: newRoles }
    return await updateLocalUser(payload, crudContext, entityUuid, true)
  }

  // Function to create / add a new user
  const createUserForInvitation = async (
    user: Partial<DocumentUser>,
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template ["uuid"],
    roles: DocumentUserRoleEnum[],
  ): Promise<DocumentUser | void> => {

    // Make sure somebody without an account users cannot add users to another party
    if (!documentStore.mau && user.party_uuid && user.party_uuid !== documentStore.mdu?.party_uuid) return
    let partyUuid = null
    if (!documentStore.mau) partyUuid = documentStore.mdu?.party_uuid
    else partyUuid = user.party_uuid

    // Deliver entire user as payload for external users, and only role/party/signatory for internal users
    const payload = !user.account_user?.uuid
      ? toRaw({
        email: user.email,
        roles: roles,
        party_uuid: partyUuid,
      })
      : toRaw({
        account_user_uuid: user.account_user?.uuid,
        roles: roles,
        party_uuid: partyUuid,
      })

    try {

      const createdUser = await createUser(crudContext, entityUuid, payload)

      if (!createdUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ createdUser }) }))

      setUserErrors("0", null)

      return createdUser

    } catch (err) {
      setUserErrors("0", err.response?.data?.errors || null)

      notify({
        title: t("userSettings.errors.addUser"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    }
  }

  const setDocumentUserForSharing = (documentUser: Partial<DocumentUser>, index: number, partyUuid: Party["uuid"]) => {
    // If there is an existing documentUser (with a matching account_user?.uuid or email), update the uuid property
    const existingDocumentUser = find(data.users, (user) => (user.account_user?.uuid && user.account_user?.uuid === documentUser?.account_user?.uuid) || user.email === documentUser?.email)
    if (existingDocumentUser && documentUser) {
      documentUser.uuid = existingDocumentUser.uuid
      documentUser.party_uuid = existingDocumentUser.party_uuid
    }
    data.documentUsersForSharing[index] = !documentUser?.party_uuid ? { ...documentUser, party_uuid: partyUuid } : { ...documentUser }
  }

  const addDocumentUserForSharing = (user: DocumentUser) => {
    data.documentUsersForSharing.push(user)
  }

  const getUserBackendErrors = (userUuid: DocumentUser["uuid"]) : Partial<Record<keyof DocumentUser, string[]>> => {
    const errors: Partial<Record<keyof DocumentUser, string[]>> = {}
    const backendErrors = data.userErrorsMap?.[userUuid] || {}
    Object.keys(backendErrors)
      .forEach(
        (key) => {
          errors[key] = [ ...(errors[key] || []), ...(backendErrors[key] || []) ]
        },
      )
    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )
    return errors
  }

  const removeNewUser = (userIdx: number) => {
    data.documentUsersForSharing?.splice(userIdx, 1)
  }

  return {
    ...toRefs(data),
    availableAccountUsers,
    alreadyAttachedAccountUserUuids,

    // mutations
    setUsers,
    setAllAccountUsers,
    pushUser,
    pushOrUpdateUser,
    setUserErrors,
    setAddUserPartyUuid,
    setDocumentUserLastUpdatedViaPusher,
    setDocumentUsersForSharing,
    setUserNotifications,

    // api actions
    fetchAllUsers,
    fetchAllUserNotifications,
    fetchAllAccountUsers,
    removeUserFromEntityAction,
    getDocumentUser,

    // user CRUD,
    createUser,
    updateUser,
    removeUserFromEntity,

    // ui actions
    createSignatureBlockIfNeeded,
    updateLocalUser,
    removeUserAsSignatory,
    sendUserInvitation,
    getUserNotifications,
    sendInvitations,
    handleUpdateRoleOfExistingUser,
    createUserForInvitation,
    setDocumentUserForSharing,
    addDocumentUserForSharing,
    getUserBackendErrors,
    removeNewUser,
  }
})
