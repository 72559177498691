// external
import { defineStore } from "pinia"
import { reactive, toRefs, computed } from "vue"

// internal
import { AccountUser, Task, CustomTask, Pagination, TaskableType, PotentialAssignees } from "~/types"
import { createCustomTaskAction, fetchAccountUsersAction, fetchIncomingTasksAction, fetchOutgoingCustomTasksAction, updateTaskAction, updateCustomTaskAction, fetchPotentialAssigneesAction } from "./taskStoreActions"
import { usePage } from "@inertiajs/vue3"

const PAGE_SIZE = 10

interface Data {
  showTaskModal: boolean
  showCreationForm: boolean
  accountUsers: AccountUser[]
  incomingTasks: Pagination<Task[]>
  outgoingCustomTasks: Pagination<CustomTask[]>
  isFetchingIncoming: boolean
  isFetchingOutgoing: boolean
  isFetchingAccountUsers: boolean
  isCreatingTask: boolean
  creationErrors: any,
  isUpdatingUuids: string[]
  currentPerPage: number
  potentialAssignees: PotentialAssignees
  isFetchingAssignees: boolean
}

interface FetchParams {
  perPage?: number;
  page?: number;
  includeResolved?: boolean;
}

export const useTaskStore = defineStore("taskStore", () => {
  const data = reactive<Data>({
    showTaskModal: false,
    showCreationForm: false,
    accountUsers: null,
    incomingTasks: null,
    outgoingCustomTasks: null,
    isFetchingIncoming: false,
    isFetchingOutgoing: false,
    isFetchingAccountUsers: false,
    isCreatingTask: false,
    creationErrors: null,
    isUpdatingUuids: [],
    currentPerPage: PAGE_SIZE,
    potentialAssignees: null,
    isFetchingAssignees: false,
  })

  const fetchAccountUsers = async () => {
    if (!data.isFetchingAccountUsers) {
      try {
        data.isFetchingAccountUsers = true
        const accountUsers = await fetchAccountUsersAction()
        data.isFetchingAccountUsers = false

        if (accountUsers) {
          data.accountUsers = accountUsers
        }
      } catch (e) {
        console.error(e)
        data.isFetchingAccountUsers = false
      }
    }
  }

  const fetchIncomingTasks = async ({
    perPage = 10,
    page = 1,
    includeResolved = false,
  }: FetchParams = {}) => {
    try {
      data.isFetchingIncoming = true
      const pagination:Pagination<Task[]>  = await fetchIncomingTasksAction({
        includeResolved: includeResolved,
        perPage,
        page,
      })
      data.isFetchingIncoming = false

      if (pagination) {
        data.incomingTasks = pagination
      }

      return pagination
    } catch (e) {
      console.error(e)
      data.isFetchingIncoming = false
    }
  }

  const fetchOutgoingCustomTasks = async ({
    perPage = 10,
    page = 1,
    includeResolved = false,
  }: FetchParams = {}) => {
    try {
      data.isFetchingOutgoing = true
      const pagination:Pagination<CustomTask[]> = await fetchOutgoingCustomTasksAction({
        includeResolved: includeResolved,
        perPage,
        page,
      })
      data.isFetchingOutgoing = false

      if (pagination) {
        data.outgoingCustomTasks = pagination
      }

      return pagination
    } catch (e) {
      console.error(e)
      data.isFetchingOutgoing = false
    }
  }

  const createCustomTask = async (task:Partial<CustomTask>) => {
    if (!data.isCreatingTask) {
      try {
        data.isCreatingTask = true
        const createdTask = await createCustomTaskAction(task)
        data.isCreatingTask = false

        if (createdTask) {
          data.creationErrors = null
          fetchIncomingTasks()
          fetchOutgoingCustomTasks()
          data.showCreationForm = false
        }
      } catch (e) {
        console.error(e)
        data.creationErrors = e
        data.isCreatingTask = false
      }
    }
  }

  const updateTask = async (task:Partial<Task>) => {
    if (!data.isUpdatingUuids.includes(task.uuid)) {
      try {
        data.isUpdatingUuids.push(task.uuid)
        const updatedTask = await updateTaskAction(task)
        data.isUpdatingUuids = data.isUpdatingUuids.filter((uuid) => uuid !== task.uuid)

        if (updatedTask) {
          return updatedTask
        }
      } catch (e) {
        console.error(e)
        data.isUpdatingUuids = data.isUpdatingUuids.filter((uuid) => uuid !== task.uuid)
      }
    }
  }

  const updateCustomTask = async (customTask:Partial<CustomTask>) => {
    if (!data.isUpdatingUuids.includes(customTask.uuid)) {
      try {
        data.isUpdatingUuids.push(customTask.uuid)
        const updatedCustomTask = await updateCustomTaskAction(customTask)
        data.isUpdatingUuids = data.isUpdatingUuids.filter((uuid) => uuid !== customTask.uuid)

        if (updatedCustomTask) {
          return updatedCustomTask
        }
      } catch (e) {
        console.error(e)
        data.isUpdatingUuids = data.isUpdatingUuids.filter((uuid) => uuid !== customTask.uuid)
      }
    }
  }

  const resolveCustomTaskByUuid = async (taskUuid: Task["uuid"]) => {
    updateTask({
      uuid: taskUuid,
      resolved_at: "now",
    })
  }

  const cancelCustomTaskByUuid = async (customTaskUuid: CustomTask["uuid"]) => {
    updateCustomTask({
      uuid: customTaskUuid,
      canceled_at: "now",
    })
  }

  const fetchPotentialAssignees = async (
    type: TaskableType,
    uuid: string,
  ) => {
    try {
      data.isFetchingAssignees = true
      const potentialAssignees = await fetchPotentialAssigneesAction(type, uuid)
      data.isFetchingAssignees = false

      if (potentialAssignees) {
        data.potentialAssignees = potentialAssignees
        return potentialAssignees
      }
    } catch (e) {
      console.error(e)
      data.isFetchingAssignees = false
    }
  }

  const isLoading = computed(() => data.isFetchingAccountUsers || data.isFetchingIncoming || data.isFetchingOutgoing)
  const taskCount = computed(() => usePage().props.openTasks as number || 0)

  const assigneeOptions = computed<AccountUser[]>(() => {
    if (!data.potentialAssignees?.account_users?.length) return []
    return data.potentialAssignees.account_users
  })

  return {
    // data
    ...toRefs(data),

    // getters
    isLoading,
    taskCount,
    assigneeOptions,

    // actions
    fetchAccountUsers,
    fetchIncomingTasks,
    fetchOutgoingCustomTasks,
    createCustomTask,
    updateTask,
    updateCustomTask,
    resolveCustomTaskByUuid,
    cancelCustomTaskByUuid,
    fetchPotentialAssignees,
  }
})
