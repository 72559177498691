import dayjs from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"
import relativeTime from "dayjs/plugin/relativeTime"
import localizedFormat from "dayjs/plugin/localizedFormat"
import duration from "dayjs/plugin/duration"
import customParseFormat from "dayjs/plugin/customParseFormat"
import en from "dayjs/locale/en"
import de from "dayjs/locale/de"

const locale = document.querySelector("html").getAttribute("lang")

// TODO: switch to more scalable solution via imports
const locales = {
  en: {
    ...en,
    "relativeTime": {
      "future": "in %s",
      "past": "%s ago",
      "s": "<1m",
      "m": "1m",
      "mm": "%dm",
      "h": "1h",
      "hh": "%dh",
      "d": "1d",
      "dd": "%dd",
      "M": "1mth",
      "MM": "%dmths",
      "y": "1y",
      "yy": "%dy",
    },
  },
  de: {
    ...de,
    "relativeTime": {
      "future": "in %s",
      "past": "vor %s",
      "s": "<1 Minute>",
      "m": "1 Minute",
      "mm": "%d Min",
      "h": "1 Std",
      "hh": "%d Std",
      "d": "1 Tag",
      "dd": "%d Tagen",
      "M": "1 Monat",
      "MM": "%d Monaten",
      "y": "1 Jahr",
      "yy": "%d Jahren",
    },
  },
}

dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

dayjs.locale(locale, locales[locale])

// strict thresholds
const thresholds = [
  { l: "s", r: 1 },
  { l: "m", r: 1 },
  { l: "mm", r: 59, d: "minute" },
  { l: "h", r: 1 },
  { l: "hh", r: 23, d: "hour" },
  { l: "d", r: 1 },
  { l: "dd", r: 29, d: "day" },
  { l: "M", r: 1 },
  { l: "MM", r: 11, d: "month" },
  { l: "y" },
  { l: "yy", d: "year" },
]

const config = {
  thresholds: thresholds,
}

dayjs.extend(relativeTime, config)

export const setDayjsLocale = (locale = "en") => {
  dayjs.locale(locale, locales[locale])
}

export const formatDateAndTime = (dateString) => {
  const date = dayjs(dateString)
  return date.format("DD.MM.YYYY · HH:mm")
}

export const formatDateAndTimeForSignatureDisplay = (dateString) => {
  const date = dayjs(dateString)
  return date.format("YYYY-MM-DD HH:mm:ss")
}

export const formatDateLocalized = (dateString, locale: string) => {
  const date = dayjs(dateString)
  return date.locale(locale).format("L")
}

export const formatDateSimple = (dateString) => {
  const date = dayjs(dateString)
  return date.format("L")
}

export const formatDateRelative = (dateString, withoutSuffix = false) => {
  const date = dayjs(dateString)
  return date.fromNow(withoutSuffix)
}

export const isDateInPast = (dateString) => {
  const date = dayjs(dateString)
  const now = dayjs()

  return date.isBefore(now)
}

export const formatTime = (dateString) => {
  const date = dayjs(dateString)
  return date.format("HH:mm")
}

export const formatDateString = (dateString) => {
  const date = dayjs(dateString)
  return date.format("YYYY-MM-DD")
}

export const getDifference = (dateString: string, unit = "day", includeCommas = false) => {
  const now = dayjs()
  return now.diff(dateString, (unit as any), includeCommas)
}

export const isoDurationToWords = (durationString: string, translate: (s: string, n: number) => void) => {

  /* const regex = /^P(?:\d+Y|\d+M|\d+W|\d+D)$/
  if (!regex.test(durationString)) {
    throw new Error("Invalid ISO 8601 duration string")
  } */

  const durationObject = dayjs.duration(durationString)

  const durationWords = []

  if (durationObject.years() !== 0) {
    durationWords.push(`${durationObject.years()} ${translate("duration.Y", durationObject.years() || 0)}`)
  }

  if (durationObject.months() !== 0) {
    durationWords.push(`${durationObject.months()} ${translate("duration.M", durationObject.months() || 0)}`)
  }

  // Check if duration object has only full weeks
  const daysDividableBy7 = durationObject.days() % 7 === 0
  const isoStringIncludesWeeks = durationString.includes("W")

  if (durationObject.months() === 0 && durationObject.years() === 0 && daysDividableBy7 && isoStringIncludesWeeks && durationObject.weeks() !== 0) {
    durationWords.push(`${durationObject.weeks()} ${translate("duration.W", durationObject.weeks() || 0)}`)
  }

  if (durationObject.days() !== 0 && (!daysDividableBy7 || !isoStringIncludesWeeks)) {
    durationWords.push(`${durationObject.days()} ${translate("duration.D", durationObject.days() || 0)}`)
  }

  return durationWords.join(", ")
}

export const validateDateViaDayJs = (dateString: string) => {
  return dayjs(dateString, "YYYY-MM-DD", true).isValid()
}
