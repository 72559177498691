import { OnboardingUsecase } from "~/types"
import { ComplianceCheckIcon, StartupIcon, SoftwareIcon, RealEstateIcon, CommercialIcon } from "~/icons"

export const usecases: OnboardingUsecase[] = [
  {
    id: "compliance_governance",
    contractTypes: [
      "employment_contract",
      "terms_and_conditions",
      "non_disclosure_agreement",
      "privacy_policy",
    ],
    icon: ComplianceCheckIcon,
  },
  {
    id: "startups",
    contractTypes: [
      "investment_agreement",
      "loan_agreement",
      "letter_of_intent",
      "partnership_agreement",
    ],
    icon: StartupIcon,
  },
  {
    id: "software",
    contractTypes: [
      "license_agreement",
      "data_processing_agreement",
      "service_agreement",
    ],
    icon: SoftwareIcon,
  },
  {
    id: "real_estate",
    contractTypes: [
      "rental_agreement",
      "deed_of_trust",
    ],
    icon: RealEstateIcon,
  },
  {
    id: "commercial_transactions",
    contractTypes: [
      "purchase_agreement",
      "service_agreement",
    ],
    icon: CommercialIcon,
  },
]
