import { defineStore } from "pinia"
import { computed, reactive, toRaw, toRefs } from "vue"

import { useI18n } from "vue-i18n"
import { useNotificationStore } from "../notificationStore"

import { StoredFile, CrudContext, Document, Template, Comment, UiStoredFile } from "~/types"
import { createStoredFileAction, fetchStoredFilesAction, getStoredFileUrlAction, updateStoredFileAction, removeStoredFileAction } from "./fileStorageStoreActions"
import { useConfirmationStore } from "../confirmationStore"

interface Data {
  storedFiles: StoredFile[]
  isLoadingSaveStoredFileCount?: Record<number, number>
  backendErrors:Partial<Record<keyof StoredFile, string[]>>
  uuidOfIsRemovingStoredFile: StoredFile["uuid"] | null
  uuidOfIsLoadingGetStoredFileUrl?: StoredFile["uuid"] | null
  uuidOfIsUpdatingStoredFile?: StoredFile["uuid"] | null
  isLoadingStoredFiles: boolean
  commentFiles: UiStoredFile[]
  isLoadingShowStoredFile: StoredFile["uuid"] | null
  activeStoredFileUuid: StoredFile["uuid"] | null
  storedFileUrl: string
  isActiveEditStoredFile: StoredFile["uuid"] | null
}

export const useFileStorageStore = defineStore("fileStorageStore", () => {

  const { t } = useI18n()
  const { notify } = useNotificationStore()

  const confirmationStore = useConfirmationStore()
  const { setShowConfirmModal, setConfirmOptions } = confirmationStore


  const data = reactive<Data>({
    storedFiles: null,
    isLoadingSaveStoredFileCount: {},
    backendErrors: null,
    uuidOfIsRemovingStoredFile: null,
    uuidOfIsLoadingGetStoredFileUrl: null,
    uuidOfIsUpdatingStoredFile: null,
    isLoadingStoredFiles: false,
    commentFiles: [],
    isLoadingShowStoredFile: null,
    activeStoredFileUuid: null,
    storedFileUrl: "",
    isActiveEditStoredFile: null,
  })

  // computed
  const visibleStoredFiles = computed(() => data.storedFiles?.filter((storageFile) => !storageFile.deleted_at))
  const deletedStoredFiles = computed(() => data.storedFiles?.filter((storageFile) => storageFile.deleted_at))

  // mutations
  const setStoredFiles = (storageFiles: StoredFile[]) => data.storedFiles = storageFiles
  const pushStoredFile = (storageFile: StoredFile) => data.storedFiles.push(storageFile)
  const pushOrUpdateStoredFile = (storageFile: Partial<StoredFile>) => {
    const localIndexOfStoredFile = data.storedFiles.findIndex(({ uuid }) => uuid === storageFile.uuid)
    const storageFilesCopy = [ ...toRaw(data.storedFiles) ]
    if (localIndexOfStoredFile !== -1) {
      storageFilesCopy[localIndexOfStoredFile] = {
        ...storageFilesCopy[localIndexOfStoredFile],
        ...storageFile,
      }
      data.storedFiles = storageFilesCopy

      return
    }
  }
  const markStoredFileAsDeleted = (storageFileUuid: StoredFile["uuid"]) => {
    const storageFile = data.storedFiles.find((storageFile) => storageFile.uuid === storageFileUuid)
    const changedStoredFile: StoredFile = { ...toRaw(storageFile), deleted_at: (new Date()).toString() }
    pushOrUpdateStoredFile(changedStoredFile)
  }
  const setBackendErrors = (errors: Partial<Record<keyof StoredFile, string[]>>) => data.backendErrors = errors

  // upload and store
  const uploadStoredFile = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    file_uuid: string,
    file_name: string,
    commentUuid: Comment["uuid"] | "" = "",
    parentCommentUuid: Comment["uuid"] = "",
  ): Promise<StoredFile | void> => {
    if (!file_uuid || !file_name) return
    const storageFileUuid = file_uuid
    const storageFileFileName =  file_name
    data.isLoadingSaveStoredFileCount = {
      ...data.isLoadingSaveStoredFileCount,
      [ parentCommentUuid || 0 ]: (data.isLoadingSaveStoredFileCount[ parentCommentUuid || 0 ] || 0) + 1,
    }
    const payload = {
      uuid: storageFileUuid,
      filename: storageFileFileName,
    }
    try {
      const storageFile = await createStoredFileAction(crudContext, entityUuid, payload, commentUuid)
      data.backendErrors = null
      if (storageFile) pushStoredFile(storageFile)
      return storageFile
    } catch (err) {
      data.backendErrors = err.response?.data?.errors
      notify({
        title: t("fileStorage.storageFileUploadError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingSaveStoredFileCount = {
        ...data.isLoadingSaveStoredFileCount,
        [ parentCommentUuid || 0 ]: (data.isLoadingSaveStoredFileCount[ parentCommentUuid || 0 ] || 0) - 1,
      }
    }
  }

  const fetchStoredFiles = async (crudContext: CrudContext, entityUuid: Document["uuid"] | Template["uuid"]): Promise<void> => {

    try {
      data.isLoadingStoredFiles = true
      const res = await fetchStoredFilesAction(crudContext, entityUuid)
      if (res) setStoredFiles(res)
    } catch (err) {
      notify({
        title: t("fileStorage.storageFileFetchError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingStoredFiles = false
    }
  }

  const updateStoredFile = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    storageFileUuid: StoredFile["uuid"],
    payload: Partial<StoredFile>,
  ): Promise<void> => {
    data.uuidOfIsUpdatingStoredFile = storageFileUuid
    try {
      const res = await updateStoredFileAction(crudContext, entityUuid, storageFileUuid, payload)
      if (res) pushOrUpdateStoredFile(res)
    } catch (err) {
      notify({
        title: t("fileStorage.storageFileUpdateError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsUpdatingStoredFile = null
    }
  }


  const removeStoredFile = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    storageFileUuid: StoredFile["uuid"],
  ): Promise<void> => {
    data.uuidOfIsRemovingStoredFile = storageFileUuid
    try {
      const res = await removeStoredFileAction(crudContext, entityUuid, storageFileUuid)
      if (res === 200) {
        markStoredFileAsDeleted(storageFileUuid)
      }
    } catch (err) {
      notify({
        title: t("fileStorage.storageFileDeleteError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsRemovingStoredFile = null
    }
  }

  // ui action
  const getStoredFileUrl = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    storageFileUuid: StoredFile["uuid"],
  ): Promise<string> => {
    try {
      const res = await getStoredFileUrlAction(crudContext, entityUuid, storageFileUuid)
      if (res) return res.url
    } catch (err) {
      notify({
        title: t("fileStorage.storageFileShowError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsLoadingGetStoredFileUrl = null
    }
  }


  // ui actions

  const handleRemoveCommentFile = (
    file: UiStoredFile,
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ) => {
    if (file.uuid) {
      setShowConfirmModal(true)
      setConfirmOptions({
        title: t("fileStorage.storedFileRemoveConfirmTitle"),
        description: t("fileStorage.storedFileRemoveConfirmDescription"),
        buttonText: t("common.remove"),
        callback: () => {
          removeStoredFile(crudContext, entityUuid, file.uuid)
          setShowConfirmModal(false)
        },
      })
    } else {
      data.commentFiles = data.commentFiles.filter((f) => f.file_uuid !== file.file_uuid)
    }
  }

  const handleShowStoredFile = async (
    storedFileUuid: StoredFile["uuid"],
    forceDownload = false,
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<void> => {
    if (!storedFileUuid) return
    if (data.isActiveEditStoredFile === storedFileUuid) return
    data.isLoadingShowStoredFile = storedFileUuid
    if (!forceDownload) data.activeStoredFileUuid = storedFileUuid
    const url = await getStoredFileUrl(crudContext, entityUuid, storedFileUuid)
    const storedFile = data.storedFiles.find((storedFile) => storedFile.uuid === storedFileUuid)
    if (isViewable(storedFile?.filename) && !forceDownload) {
      data.storedFileUrl = url
    } else {
      window.open(
        url,
        "_blank", // <- This is what makes it open in a new window.
      )
    }
  }

  const isViewable = (filename: string): boolean => {
    if (!filename) return false
    let extension = filename.split(".").pop()
    // Force lowercase
    extension = extension?.toLowerCase()
    return [ "pdf", "jpg", "jpeg", "png", "gif", "webp" ].includes(extension)
  }

  // computed

  const isViewableActiveStoredFile = computed<boolean>(
    () => {
      return isViewable(activeStoredFileFilename.value)
    },
  )

  const activeStoredFileFilename = computed<string>(
    () => {
      const storedFile = data.storedFiles.find((storedFile) => storedFile.uuid === data.activeStoredFileUuid)
      return storedFile?.filename
    },
  )

  return {
    ...toRefs(data),
    visibleStoredFiles,
    deletedStoredFiles,
    isViewableActiveStoredFile,
    activeStoredFileFilename,

    // mutations
    setStoredFiles,
    pushStoredFile,
    markStoredFileAsDeleted,
    setBackendErrors,

    // crud
    fetchStoredFiles,
    updateStoredFile,
    removeStoredFile,
    uploadStoredFile,
    getStoredFileUrl,

    // ui actions
    handleRemoveCommentFile,
    handleShowStoredFile,
    isViewable,
  }
})
