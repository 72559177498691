<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M24.1,6.1v2h-1v6h1v2h-4v-2h1v-6h-1v-2H24.1z" />
    <path
      style="opacity:0.5"
      d="M1.9,13.9v-1.8h1.8l2.2-2.2V8.1c0-1.1-0.9-2-2-2h-2c-1.1,0-2,0.9-2,2v7.8L1.9,13.9z M1.9,8.1h2v2h-2V8.1z
	 M15.7,7C15.9,7.3,16,7.7,16,8.1v1.5c0,0.8-0.7,1.5-1.5,1.5c0.8,0,1.5,0.7,1.5,1.5v1.5c0,1.1-0.9,2-2,2h-4v-2h4v-2h-2v-1.4l0.6-0.6
	H14V8.6L15.7,7z M8.6,14H9v2H7v-0.4L8.6,14z M17,16h2v-2h-2V16 M2.2,18.5L15.7,5.1l-1.4-1.4L0.8,17.1L2.2,18.5"
    />
  </svg>
</template>