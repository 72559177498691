import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"
import { Bookmark } from "~/types"
import { fetchBookmarksAction, createBookmarkAction, deleteBookmarkAction } from "./bookmarkStoreActions"

interface Data {
  bookmarks: Bookmark[]
  isFetchingBookmarks: boolean
  isCreatingBookmark: boolean
  isDeletingBookmark: boolean
}

export const useBookmarkStore = defineStore("bookmarkStore", () => {
  const data = reactive<Data>(
    {
      bookmarks: [],
      isFetchingBookmarks: false,
      isCreatingBookmark: false,
      isDeletingBookmark: false,
    },
  )

  const fetchBookmarks = async (
    listName: string,
  ) => {
    data.isFetchingBookmarks = true
    data.bookmarks = []
    try {
      const bookmarks = await fetchBookmarksAction(listName)
      if (bookmarks) {
        data.bookmarks = bookmarks
      }
      return bookmarks
    } catch (err) {
      console.error(err)
    } finally {
      data.isFetchingBookmarks = false
    }
  }

  const createBookmark = async (
    payload: Partial<Bookmark>,
  ) => {
    data.isCreatingBookmark = true
    try {
      const bookmark = await createBookmarkAction(payload)
      if (bookmark) {
        data.bookmarks.push(bookmark)
      }
      return bookmark
    } catch (err) {
      console.error(err)
    } finally {
      data.isCreatingBookmark = false
    }
  }

  const deleteBookmark = async (
    uuid: Bookmark["uuid"],
  ) => {
    data.isDeletingBookmark = true
    try {
      await deleteBookmarkAction(uuid)

      const bookmarkIndex = data.bookmarks.findIndex((bookmark) => bookmark.uuid === uuid)
      if (bookmarkIndex !== -1) {
        data.bookmarks.splice(bookmarkIndex, 1)
      }
    } catch (err) {
      console.error(err)
    } finally {
      data.isDeletingBookmark = false
    }
  }

  return {
    ...toRefs(data),

    // mutations

    // crud
    fetchBookmarks,
    createBookmark,
    deleteBookmark,

    // ui actions
  }
})
