import { uniqueId } from "lodash-es"

export class PubSubService {
  eventAndListenersMap: Record<string, Record<string, ((...args: any[]) => any)>> = {}

  publish<T> (eventName: string, args: T): void {
    if (!this.eventAndListenersMap[eventName]) {
      console.warn("Event ", eventName, " is not in the eventAndListenersMap")
      return
    }

    for (const [ , fn ] of Object.entries(this.eventAndListenersMap[eventName])) {
      fn?.(args)
    }
  }

  subscribe (eventName: string, fn: (...args: any[]) => any): string {
    if (!this.eventAndListenersMap[eventName]) this.eventAndListenersMap[eventName] = {}

    const listenerId = uniqueId("globalPubSubServicePrefix")

    this.eventAndListenersMap[eventName][listenerId] = fn

    return listenerId
  }

  unsubscribe (eventName: string, listenerId: string): void {
    if (!this.eventAndListenersMap[eventName]?.[listenerId]) return

    delete this.eventAndListenersMap[eventName]?.[listenerId]
  }
}

export const globalPubSubService = new PubSubService()
