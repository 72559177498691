import axios from "axios"

interface OpenAiCompletionRes {
  text: string
}

export const generateTextAction = async (context: string) => {
  const apiUrl = route("api.ai-generate-text")
  const res = await axios.post<OpenAiCompletionRes>(
    apiUrl,
    {
      context: context,
    },
  )

  return res.data.text
}

export const autocompleteTextAction = async (context: string) => {
  const apiUrl = route("api.ai-autocomplete-text")
  const res = await axios.post<OpenAiCompletionRes>(
    apiUrl,
    {
      context: context,
    },
  )

  return res.data.text
}
