<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36 36"
  >
    <path
      fill="currentColor"
      d="M23.3,26.2h-3.6v-9.7H25v7.4c0.3-0.2,0.7-0.4,1.1-0.4V13.2c0-0.3-0.2-0.5-0.5-0.5H7.3
		c-0.3,0-0.5,0.2-0.5,0.5v13.6c0,0.3,0.2,0.5,0.5,0.5h15.8C23.1,26.9,23.2,26.6,23.3,26.2z M19.7,13.7H25v1.7h-5.4V13.7z M18.6,26.2
		H7.8v-9.7h10.8V26.2z M18.6,15.4H7.8v-1.7h10.8V15.4z"
    />
    <path
      fill="currentColor"
      d="M27.3,31.5C27.3,31.5,27.2,31.5,27.3,31.5l-0.1,2.2H5.7V3.6h21.5v20.5c0,0,0.1,0,0.1,0c0.3,0,0.7,0.1,1,0.2
		V3.1c0-0.3-0.2-0.5-0.5-0.5H5.2c-0.3,0-0.5,0.2-0.5,0.5v31.2c0,0.3,0.2,0.5,0.5,0.5h22.6c0.3,0,0.5-0.2,0.5-0.5v-2.9
		C28,31.5,27.6,31.5,27.3,31.5z"
    />
    <rect
      x="6.8"
      y="5.3"
      fill="currentColor"
      width="4.2"
      height="1.1"
    />
    <rect
      x="6.9"
      y="7.5"
      fill="currentColor"
      width="7.8"
      height="1.1"
    />
    <rect
      x="6.8"
      y="9.5"
      fill="currentColor"
      width="1.8"
      height="1.1"
    />
    <rect
      x="9.8"
      y="9.5"
      fill="currentColor"
      width="1.8"
      height="1.1"
    />
    <rect
      x="12.8"
      y="9.5"
      fill="currentColor"
      width="1.8"
      height="1.1"
    />
    <rect
      x="15.8"
      y="9.5"
      fill="currentColor"
      width="1.8"
      height="1.1"
    />
    <rect
      x="8.9"
      y="17.6"
      fill="currentColor"
      width="4"
      height="1.1"
    />
    <rect
      x="14"
      y="17.6"
      fill="currentColor"
      width="3.5"
      height="1.1"
    />
    <rect
      x="20.7"
      y="17.6"
      fill="currentColor"
      width="3.2"
      height="1.1"
    />
    <rect
      x="8.9"
      y="19.7"
      fill="currentColor"
      width="2"
      height="1.1"
    />
    <rect
      x="12"
      y="19.7"
      fill="currentColor"
      width="5.6"
      height="1.1"
    />
    <rect
      x="8.9"
      y="21.9"
      fill="currentColor"
      width="4"
      height="1.1"
    />
    <rect
      x="14"
      y="21.9"
      fill="currentColor"
      width="3.5"
      height="1.1"
    />
    <rect
      x="8.9"
      y="24"
      fill="currentColor"
      width="2"
      height="1.1"
    />
    <rect
      x="12"
      y="24"
      fill="currentColor"
      width="5.6"
      height="1.1"
    />
    <rect
      x="20.7"
      y="19.7"
      fill="currentColor"
      width="3.2"
      height="1.1"
    />
    <rect
      x="20.7"
      y="21.9"
      fill="currentColor"
      width="3.2"
      height="1.1"
    />
    <rect
      x="20.7"
      y="24"
      fill="currentColor"
      width="3.2"
      height="1.1"
    />
    <path
      fill="currentColor"
      d="M33.2,25l-0.8-0.9l-1.4,1.3c-1.3-2-4.1-2.6-6.1-1.3c-2,1.3-2.6,4.1-1.3,6.1c1.3,2,4.1,2.6,6.1,1.3
	c1.6-1.1,2.4-3.1,1.8-4.9L33.2,25z M27.2,30.9c-1.7,0-3.2-1.4-3.2-3.1c0-1.7,1.4-3.2,3.1-3.2c1.1,0,2.2,0.6,2.8,1.6l-2.3,2.1
	l-1.3-1.8l-1,0.7l1.7,2.3c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2l2.4-2.2c0,0.1,0,0.2,0,0.2
	C30.4,29.5,29,30.9,27.2,30.9z"
    />
  </svg>
</template>