import { debounce, pick, uniq, without, isEqual } from "lodash-es"
import { defineStore } from "pinia"
import tippy, { Instance, Props as TippyProps, roundArrow, sticky } from "tippy.js"
import { reactive, toRaw, toRefs, nextTick } from "vue"
import { useI18n } from "vue-i18n"

import { Condition, Document, Template, FormErrors, CrudContext, DocumentTab, TemplateTab, DynamicContentTab, ConditionOperator } from "~/types"
import { changedKeys, scrollTo } from "~/utils"
import { useNotificationStore } from "../notificationStore"
import { useSharedStore } from "../sharedStore"
import { useDocumentStore } from "../documentStore"
import { useTemplateStore } from "../templateStore"
import { useEditorStore } from "../editorStore"

import {
  fetchConditionsAction,
  createConditionAction,
  updateConditionAction,
  removeConditionAction,
} from "./conditionStoreActions"


interface Data {
  conditions: Condition[]
  conditionTippy: Record<string, any>
  isLoadingConditions: boolean
  uuidsOfUpdatingCondition: Condition["uuid"][]
  conditionLastSavedMap: Record<Condition["uuid"], number>
  conditionErrorsMap: Record<Condition["uuid"], FormErrors<Condition>>
  conditionUuidBeingRemoved: Condition["uuid"] | null
  activeConditions: Partial<Condition>[]
  debounceTimestamp: number
  debounceUuid: Condition["uuid"]
  payloadKeys: string[]
  newCondition: Partial<Condition> | null
  uuidOfLastHighlightedCondition: string
}

const conditionContext = [ "document", "template" ]

export const useConditionStore = defineStore("conditionStore", () => {

  const { t } = useI18n()

  const emptyCondition: Partial<Condition> = Object.freeze({
    name: t("conditions.untitled"),
    operator: ConditionOperator.EQUAL,
    match_value: "",
  })

  const data = reactive<Data>(
    {
      conditions: [],
      conditionTippy: null,
      isLoadingConditions: false,
      uuidsOfUpdatingCondition: [],
      conditionLastSavedMap: {},
      conditionErrorsMap: {},
      conditionUuidBeingRemoved: null,
      activeConditions: [],
      debounceTimestamp: null,
      debounceUuid: null,
      payloadKeys: [],
      newCondition: Object.assign({}, emptyCondition),
      uuidOfLastHighlightedCondition: "",
    },
  )

  const { notify } = useNotificationStore()

  const sharedStore = useSharedStore()
  const documentStore = useDocumentStore()
  const templateStore = useTemplateStore()
  const editorStore = useEditorStore()

  // mutations
  const setConditions = (conditions: Condition[]) => data.conditions = conditions
  const setActiveConditions = (activeConditions: Partial<Condition>[]) => data.activeConditions = activeConditions
  const setConditionsTippy = (instance: Instance<TippyProps>[]) => data.conditionTippy = instance

  const pushCondition = (condition: Condition) => data.conditions = [ ...toRaw(data.conditions || []), condition ]

  const pushOrUpdateCondition = (d: Partial<Condition>) => {
    const localIndexOfConditions = data.conditions.findIndex(({ uuid }) => uuid === d.uuid)

    const conditionsCopy = [ ...toRaw(data.conditions) ]

    if (localIndexOfConditions !== -1) {
      conditionsCopy[localIndexOfConditions] = {
        ...conditionsCopy[localIndexOfConditions],
        ...d,
      }

      data.conditions = conditionsCopy

      return
    }

    data.conditions = [
      ...conditionsCopy,
      d,
    ] as Condition[] // TODO: change this to real types
  }

  const removeConditionFromStore = (conditionUuidToRemove: Condition["uuid"]) => {
    const indexOfConditionToRemove = data.conditions.findIndex(({ uuid }) => uuid === conditionUuidToRemove)

    if (indexOfConditionToRemove === -1) return

    const copyArr = [ ...toRaw(data.conditions) ]
    copyArr.splice(indexOfConditionToRemove, 1)

    data.conditions = copyArr
  }

  const addUpdatingCondition = (uuid: Condition["uuid"]) => data.uuidsOfUpdatingCondition = uniq([ ...toRaw(data.uuidsOfUpdatingCondition), uuid ])

  const removeUpdatingCondition = (uuid: Condition["uuid"]) => data.uuidsOfUpdatingCondition = without([ ...toRaw(data.uuidsOfUpdatingCondition) ], uuid)

  const setConditionLastSaved = (uuid: Condition["uuid"]) => {
    data.conditionLastSavedMap = {
      ...toRaw(data.conditionLastSavedMap || {}),
      [uuid]: Date.now(),
    }
  }

  const setConditionErrors = (uuid: Condition["uuid"], ConditionErrors: FormErrors<Condition>) => {
    data.conditionErrorsMap = {
      ...toRaw(data.conditionErrorsMap || {}),
      [uuid]: ConditionErrors,
    }
  }

  const setNewCondition = (condition: Partial<Condition>) => data.newCondition = condition

  const updateNewCondition = (payload: Partial<Condition>) => {
    data.newCondition = {
      ...toRaw(data.newCondition || {}),
      ...payload,
    }
  }

  // api actions
  const fetchConditions = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<Condition[] | void> => {
    if (!conditionContext.includes(context)) {
      console.error("context should be one of", conditionContext, " but given", context)
      return
    }

    try {
      data.isLoadingConditions = true
      const conditions = await fetchConditionsAction(context, entityUuid) || []
      setConditions(conditions)
      return conditions
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingConditions = false
    }
  }

  // conditions CRUD
  const createCondition = async (
    entityUuid: Template["uuid"],
    payload: Partial<Condition>,
  ): Promise<Condition | void> => {
    const createdCondition = await createConditionAction(entityUuid, payload)

    if (createdCondition) pushCondition(createdCondition)

    return createdCondition
  }


  const updateCondition = async (
    entityUuid: Template["uuid"],
    condition: Partial<Condition>,
    originalCondition: Partial<Condition>,
    timestamp: number,
    payloadKeys: string[],
  ): Promise<Condition | void> => {
    try {

      const payload = pick(condition, payloadKeys)

      const updatedCondition = await updateConditionAction(entityUuid, payload, originalCondition.uuid)

      if (updatedCondition) {
        if (!(data.debounceTimestamp > timestamp)) pushOrUpdateCondition(updatedCondition)
        setConditionLastSaved(condition.uuid)
        setConditionErrors(condition.uuid, {})
      }

      return updatedCondition
    } catch (err) {
      const isBackendError = !!err.response?.data?.errors

      if (isBackendError) setConditionErrors(condition.uuid, err.response.data.errors)
      else {
        notify(
          {
            title: t("conditions.errors.update"),
            message: err.response?.data?.message || err.message,
            type: "error",
          },
        )
      }
    } finally {
      removeUpdatingCondition(condition.uuid)
    }
  }

  const removeConditionFromEntity = async (
    entityUuid: Template["uuid"],
    conditionUuidToRemove: Condition["uuid"],
  ) : Promise<Condition[] | void> => {
    try {
      data.conditionUuidBeingRemoved = conditionUuidToRemove

      await removeConditionAction(
        entityUuid,
        conditionUuidToRemove,
      )

      removeConditionFromStore(conditionUuidToRemove)
      setActiveConditions([])

      return data.conditions
    } catch (err) {
      console.error(err)

      notify(
        {
          title: t("conditions.errors.remove"),
          message: err.response?.data?.message || err.message,
          type: "error",
        },
      )
    } finally {
      data.conditionUuidBeingRemoved = null
    }
  }

  const debouncedUpdateCondition = debounce(updateCondition, 1000)

  // ui actions
  const updateLocalCondition = async (
    condition: Partial<Condition>,
    entityUuid: Document["uuid"] | Template["uuid"],
    noDebounce = false,
  ): Promise<Condition | void> => {
    if (!condition) return

    const originalCondition = { ...toRaw(data.conditions.find((c) => c.uuid === condition.uuid)) }
    const rawCondition = { ...toRaw(condition) }
    const payloadKeys = changedKeys(originalCondition, rawCondition)

    const timestamp = Date.now()
    if (data.debounceUuid !== condition.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
      debouncedUpdateCondition?.flush()
      data.debounceUuid = condition.uuid
      data.payloadKeys = payloadKeys
    }
    data.debounceTimestamp = timestamp

    pushOrUpdateCondition(condition)
    addUpdatingCondition(condition.uuid)
    let res: Condition | void
    if (noDebounce) {
      res = await updateCondition(
        entityUuid,
        rawCondition,
        originalCondition,
        timestamp,
        payloadKeys,
      )
    } else {
      res = await debouncedUpdateCondition(
        entityUuid,
        rawCondition,
        originalCondition,
        timestamp,
        payloadKeys,
      )
    }
    return res
  }

  const conditionsClickHandler = (uuids: string[] = [], forceUuid = false, attachPopoverToSidebar = false, conditionUuid: Condition["uuid"] = null) => {
    if (!uuids && !conditionUuid) return
    if (editorStore.editorContext !== "template") return false

    // Build an array that contains the matching data.conditions object for each uuid that is found, or a new object if not found
    const foundConditions = uuids.map((uuid) => data.conditions.find((el) => el.uuids?.includes(uuid) || (el.uuid && el.uuid === conditionUuid)) || ({ uuid: "", uuids, operator: ConditionOperator.EQUAL, name: "Untitled" }))
    setActiveConditions(foundConditions)

    const element = document.getElementById("conditionPopover")

    const uuidsForSelector = forceUuid ? uuids : foundConditions[0]?.uuids || []

    setTimeout(() => {
      let selector = findFirstElementEditorBasedOnConditionUuids(uuidsForSelector)?.querySelector(".click-listener")
      if (!selector || attachPopoverToSidebar) {
        const sidebarQuery = `condition_input_${foundConditions[0]?.uuid}`
        const sidebarSelector = document.getElementById(sidebarQuery)
        if (sidebarSelector) selector = sidebarSelector
      }
      if (!selector) {
        console.error("Could not find selector for condition popover")
        return
      }
      const conditionsTippy = tippy(
        selector,
        {
          content () {
            return element
          },
          appendTo: () => document.getElementById("mainContentContainer"),
          animation: "scale",
          allowHTML: true,
          theme: "slate",
          arrow: roundArrow,
          interactive: true,
          trigger: "manual",
          showOnCreate: true,
          placement: "auto",
          sticky: true,
          plugins: [ sticky ],
          onHide () {
            setActiveConditions([])
          },
          onHidden () {
            data.activeConditions?.length
              ? null
              : document.getElementById("editorContainerPopovers").appendChild(element)
          },
        },
      )

      setConditionsTippy([ conditionsTippy ])
    })

    return false
  }

  const findFirstElementEditorBasedOnConditionUuids = (uuids: string[]) => {
    if (!uuids) return null
    const queryString = uuids?.map((uuid) => `[data-uuid="${uuid}"] .condition-marker, [data-list-uuid="${uuid}"] .condition-marker`).join(", ")
    const editorElements = document.querySelectorAll(queryString) as NodeListOf<HTMLElement>

    // Make array of elements sortable by sourceIndex
    const editorElementsArray = Array.from(editorElements)

    editorElementsArray.sort(function (a, b) {
      if ( a === b) return 0
      if ( a.compareDocumentPosition(b) & 2) {
        // b comes before a
        return 1
      }
      return -1
    })

    const editorElement = editorElementsArray[0]
    return editorElement
  }

  const jumpToCondition = async (
    conditionUuid: Condition["uuid"],
    attachPopoverToSidebar = false,
  ) => {
    const highlightClasses = [ "bg-yellow-50", "ring-inset", "ring-2", "ring-yellow-300" ]
    // Remove pre-existing highlights
    const highlightedElements = document.querySelectorAll(".condition-input_wrapper")
    highlightedElements?.forEach((el) => el.classList.remove(...highlightClasses))

    if (!conditionUuid) return

    const uuids = data.conditions.find((el) => el.uuid === conditionUuid)?.uuids
    // Build a querystring from all uuids
    let documentElement = findFirstElementEditorBasedOnConditionUuids(uuids)
    if (!documentElement) {
      // Find first id or data-uuid or data-list-uuid match with an uuid in uuids
      const queryString = uuids?.map((uuid) => `[data-uuid="${uuid}"], [data-list-uuid="${uuid}"]`).join(", ")
      documentElement = document.querySelector(queryString) as HTMLElement
      documentElement?.classList.add(...highlightClasses)
    }
    const mainContentScrollContainer = sharedStore.crudContext === CrudContext.document ? documentStore.mainContentScrollContainer : templateStore.mainContentScrollContainer
    if (documentElement && mainContentScrollContainer) scrollTo(mainContentScrollContainer, documentElement, () => {
      conditionsClickHandler(uuids, false, attachPopoverToSidebar, conditionUuid)
    })

    // If necessary, switch to conditions tab
    if (sharedStore.crudContext === CrudContext.document) {
      documentStore.setActiveTabKey(DocumentTab.dynamicContent)
      documentStore.setActiveDynamicContentTab(DynamicContentTab.conditions)
    } else if (sharedStore.crudContext === CrudContext.template) {
      templateStore.setActiveTabKey(TemplateTab.conditions)
    }
    location.hash = DocumentTab.metadata
    await nextTick()

    const sidebarElement = document.getElementById("condition_input_" + conditionUuid)

    scrollTo(sharedStore.sidebarScrollContainer, sidebarElement, () => {
      // If the condition uuids are not found in editor, we need to trigger the popover for the sidebar
      if (!documentElement) conditionsClickHandler(uuids, false, true, conditionUuid)
    })

    sidebarElement?.classList.add(...highlightClasses)
    data.uuidOfLastHighlightedCondition = uuids[0]

  }

  return {
    ...toRefs(data),

    // mutations
    setConditions,
    setConditionsTippy,
    pushCondition,
    pushOrUpdateCondition,
    setConditionErrors,
    setNewCondition,
    updateNewCondition,

    // actions
    fetchConditions,
    removeConditionAction,

    // condition CRUD,
    createCondition,
    updateCondition,
    removeConditionFromEntity,

    // ui actions,
    updateLocalCondition,
    jumpToCondition,
    conditionsClickHandler,
  }
})
