import axios from "axios"

import { Document, Party, Template, CrudContext } from "~/types"

export const fetchAllPartiesAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
) => {
  const routeString = `api.${context}s.parties.index`

  const url = route(routeString, entityUuid)

  try {
    const fetchPartiesRes = await axios.get<{ data: Party[] }>(url)

    return fetchPartiesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchAccountPartiesAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
) => {
  const routeString = `api.${context}s.attachable-account-parties.index`

  const url = route(routeString, entityUuid)

  try {
    const fetchAccountPartiesRes = await axios.get<{ data: Party[] }>(url)

    return fetchAccountPartiesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getPartyAction = async (
  documentUuid: Document["uuid"],
  partyUuid: Party["uuid"],
): Promise<Party | void> => {
  const routeString = `api.documents.parties.show`
  const url = route(routeString, {
    document: documentUuid,
    party: partyUuid,
  })

  try {
    const getPartyRes = await axios.get<{ data: Party }>(url)

    return getPartyRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// party CRUD
export const createPartyAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  partyToCreate: Partial<Party>,
): Promise<Party | void> => {
  const apiUrl = route(`api.${context}s.parties.store`, entityUuid)

  const createPartyRes = await axios.post<{ data: Party }>(apiUrl, partyToCreate)

  return createPartyRes.data.data
}

export const fetchPartyAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  partyUuid: Party["uuid"],
): Promise<Party> => {
  const apiUrl = route(
    `api.${context}s.parties.show`,
    {
      [context]: entityUuid,
      party: partyUuid,
    },
  )

  const fetchPartyRes = await axios.get<{ data: Party }>(apiUrl)

  return fetchPartyRes.data.data
}

export const updatePartyAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  party: Partial<Party>,
  partyUuid: Party["uuid"],
): Promise<Party | void> => {
  const apiRoute = `api.${context}s.parties.update`

  const routeData = {
    party: partyUuid,
    [context]: entityUuid,
  }

  const url = route(apiRoute, routeData)

  const updatePartyRes = await axios.patch<{ data: Party }>(url, party)

  return updatePartyRes.data.data
}

export const removePartyFromEntityAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  partyUuidToRemove: Party["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.parties.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, party: partyUuidToRemove }
    : { template: entityUuid, party: partyUuidToRemove }

  const removePartyRes = await axios.delete<{ data: Party }>(route(apiUrl, payload))

  return removePartyRes.status
}

export const getPartySuggestionsAction = async (
  query: string,
): Promise<Partial<Party>[] | void> => {
  const apiUrl = route("api.party-suggestions", { query })

  const partySuggestionsRes = await axios.get<{ data: Partial<Party>[] }>(apiUrl)

  return partySuggestionsRes.data.data
}
