// external
import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"

// internal
import { Notification, NotificationType, PaginationMeta, ToasterNotification } from "~/types"
import { getUuid, globalPubSubService, PubSubService } from "~/utils"
import { fetchNotificationsAction, updateNotificationAction, markAllAsReadAction, updateNotificationSettingAction, updateNotificationCountAction } from "./notificationStoreActions"

const notifyEventName = "notify"

interface Data {
  globalPubSubService: PubSubService
  notifications: Notification[]
  toasterNotifications: ToasterNotification[]
  showNotificationModal: boolean
  unreadNotificationsCount: number
  isLoadingNotifications: boolean
  updatingNotificationUuids: Notification["uuid"][]
  isMarkingAllAsRead: boolean,
  paginationMeta: PaginationMeta,
}

export const useNotificationStore = defineStore("notificationStore", () => {
  const data = reactive<Data>({
    globalPubSubService,
    notifications: [],
    toasterNotifications: [],
    showNotificationModal: false,
    unreadNotificationsCount: 0,
    isLoadingNotifications: false,
    isMarkingAllAsRead: false,
    updatingNotificationUuids: [],
    paginationMeta: null,
  })

  const addToasterNotification = (toasterNotification: ToasterNotification) => {
    data.toasterNotifications.push({
      ...toasterNotification,
      uuid: toasterNotification.uuid ? toasterNotification.uuid : getUuid(),
      duration: toasterNotification.duration ? toasterNotification.duration : toasterNotification.type === "error" ? 6000 : 3000,
    })
  }

  const removeToasterNotification = (uuid: string) => {
    const idx = data.toasterNotifications.findIndex((toasterNotification) => toasterNotification.uuid === uuid)

    if (idx !== -1) {
      data.toasterNotifications.splice(idx, 1)
    }
  }

  const subscribeToNotification = (fn: (args: ToasterNotification) => void): string => {
    return data.globalPubSubService.subscribe(notifyEventName, fn)
  }

  const unSubscribeToNotification = (listenerId: string) => {
    data.globalPubSubService.unsubscribe(notifyEventName, listenerId)
  }

  const pushOrUpdateNotification = (notification: Notification) => {
    const localIndex = data.notifications.findIndex(({ uuid }) => uuid === notification.uuid)

    if (localIndex !== -1) {
      data.notifications[localIndex] = {
        ...data.notifications[localIndex],
        ...notification,
      }
      return
    } else {
      data.notifications.push(notification)
    }
  }

  const subscribeToPusher = (userUuid) => {
    Echo.private(`users.${userUuid}`)
      .listen(
        "Pusher\\User\\NotificationReceived",
        (e) => {
          data.unreadNotificationsCount = e.unreadNotificationsCount
          if (data.showNotificationModal) {
            fetchNotifications()
          }
        },
      )
  }

  const fetchNotifications = async (page = 1): Promise<Notification[]> => {
    if (!data.isLoadingNotifications) {
      data.isLoadingNotifications = true

      try {
        if (page === 1) {
          data.notifications = []
        }

        const pagination = await fetchNotificationsAction(page)

        if (pagination?.data?.length) {
          data.notifications = [ ...data.notifications, ...pagination.data ] as Notification[]
        }

        if (!!pagination?.meta) {
          data.paginationMeta = pagination.meta
        }

        return data.notifications
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingNotifications = false
      }
    }
  }


  const markAsRead = async (notificationUuid: Notification["uuid"]): Promise<Notification | void> => {
    try {
      if (!data.updatingNotificationUuids.includes(notificationUuid)) {
        data.updatingNotificationUuids.push(notificationUuid)
        const notification = await updateNotificationAction({
          uuid: notificationUuid,
          is_read: true,
        })

        if (notification) {
          pushOrUpdateNotification(notification)
          data.unreadNotificationsCount--
          updateNotificationCountAction()
        }

        return notification
      }
    } catch (err) {
      console.error(err)
    } finally {
      const idx = data.updatingNotificationUuids.indexOf(notificationUuid)
      if (idx !== -1) {
        data.updatingNotificationUuids.splice(idx, 1)
      }
    }
  }

  const markAllAsRead = async () => {
    try {
      if (!data.isMarkingAllAsRead) {
        data.isMarkingAllAsRead = true

        const notifications = await markAllAsReadAction()

        data.unreadNotificationsCount = 0
        updateNotificationCountAction()

        if (notifications) {
          data.notifications = notifications
        } else {
          data.notifications = data.notifications.map((notification) => {
            return {
              ...notification,
              is_read: true,
            }
          })
        }

        return notifications
      }
    } catch (err) {
      console.error(err)
    } finally {
      data.isMarkingAllAsRead = false
    }
  }

  const deactivateNotificationType = async (type: NotificationType) => {
    try {
      await updateNotificationSettingAction(type)
      fetchNotifications()
      updateNotificationCountAction()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * ShowMessage a notification
   * @param args
   *
   * @example
   * ```ts
   * notify({
   *   type: "success",
   *   title: "Success",
   *   message: "This is a success message",
   * })
   * ```
   *
   * @example
   * ```ts
   * notify({
   *   type: "error",
   *   title: "Error",
   *   message: "This is an error message",
   * })
   * ```
   */
  const notify = (args: ToasterNotification) => {
    data.globalPubSubService.publish(notifyEventName, args)
  }

  return {
    // data
    ...toRefs(data),

    // actions
    notify,
    subscribeToNotification,
    unSubscribeToNotification,
    addToasterNotification,
    removeToasterNotification,
    subscribeToPusher,
    markAsRead,
    markAllAsRead,
    fetchNotifications,
    deactivateNotificationType,
  }
})
