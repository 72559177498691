import axios from "axios"

import { Document, Party, Signature, SignatureBlock, SignaturePayload, SignatureAesProcess, SignatureQesProcess, SigningPhase, CrudContext, Template, SignatureSeal, UserSignatureSeal, SignatureSealPayload } from "~/types"
import { SignatureSecurityLevel } from "~/types/enums"

export const fetchAllSignaturesAction = async (documentUuid: Document["uuid"]): Promise<Signature[] | void> => {
  const url = route("api.documents.signatures.index", documentUuid)

  try {
    const fetchAllSignaturesRes = await axios.get<{ data: Signature[] }>(url)

    return fetchAllSignaturesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllSignatureBlocksAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
): Promise<SignatureBlock[] | void> => {
  const url = route(`api.${context}s.signature-blocks.index`, entityUuid)

  try {
    const fetchAllSignatureBlocksRes = await axios.get<{ data: SignatureBlock[] }>(url)

    return fetchAllSignatureBlocksRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllSigningPhasesAction = async (documentUuid: Document["uuid"]): Promise<SigningPhase[] | void> => {
  const url = route("api.documents.signature-phases.index", documentUuid)

  try {
    const fetchAllSigningPhasesRes = await axios.get<{ data: SigningPhase[] }>(url)

    return fetchAllSigningPhasesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// signature CRUD
export const createSignatureAction = async (
  documentUuid: Document["uuid"],
  signingPhaseUuid: SigningPhase["uuid"],
  signatureToCreate: Partial<SignaturePayload>,
  signatureSecurityLevel: SignatureSecurityLevel,
): Promise<Signature | void> => {
  const apiUrl = route(`api.documents.signing-phases.signature-${signatureSecurityLevel}.store`, { document: documentUuid, signing_phase: signingPhaseUuid })

  const createSignatureRes = await axios.post<{ data: Signature }>(apiUrl, signatureToCreate)

  return createSignatureRes.data.data
}

// signature process CRUD
export const createSignatureProcessAction = async (
  documentUuid: Document["uuid"],
  signingPhaseUuid: SigningPhase["uuid"],
  signatureSecurityLevel: SignatureSecurityLevel,
  payload: Partial<SignatureAesProcess | SignatureQesProcess>,
): Promise<SignatureAesProcess | SignatureQesProcess | void> => {
  const routeString = `api.documents.signing-phases.signature-${signatureSecurityLevel}-processes.store`
  const routeParams = {
    document: documentUuid,
    signing_phase: signingPhaseUuid,
  }
  const apiUrl = route(routeString, routeParams)
  const createSignatureProcessRes = await axios.post<{ data: SignatureAesProcess | SignatureQesProcess }>(apiUrl, payload)
  return createSignatureProcessRes.data.data
}

// signature block CRUD
export const createSignatureBlockAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  partyUuid: Party["uuid"],
  style: SignatureBlock["style"],
): Promise<SignatureBlock | void> => {
  const apiUrl = route(`api.${context}s.signature-blocks.store`, entityUuid)

  const payload = {
    party_uuid: partyUuid,
    style: style,
  }

  const createSignatureBlockRes = await axios.post<{ data: SignatureBlock }>(apiUrl, payload)

  return createSignatureBlockRes.data.data
}

export const updateSignatureBlockAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  signatureBlockUuidToUpdate: SignatureBlock["uuid"],
  style: SignatureBlock["style"],
): Promise<SignatureBlock | void> => {
  const apiUrl = `api.${context}s.signature-blocks.update`

  const payload = {
    style: style,
  }

  const updateSignatureBlockRes = await axios.put<{ data: SignatureBlock }>(route(apiUrl, {
    [context]: entityUuid,
    signature_block: signatureBlockUuidToUpdate,
  }), payload)

  return updateSignatureBlockRes.data.data
}

export const removeSignatureBlockAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  signatureBlockUuidToRemove: SignatureBlock["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.signature-blocks.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, signature_block: signatureBlockUuidToRemove }
    : { template: entityUuid, signature_block: signatureBlockUuidToRemove }

  const removeSignatureBlockRes = await axios.delete<{ data: SignatureBlock }>(route(apiUrl, payload))

  return removeSignatureBlockRes.status
}

export const fetchAllUserSignatureSealsAction = async () => {
  const routeString = `api.user-signature-seals.index`
  const url = route(routeString)

  try {
    const fetchAllUserSignatureSealsRes = await axios.get<{ data: UserSignatureSeal[] }>(url)

    return fetchAllUserSignatureSealsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const createUserSignatureSealAction = async (
  userSignatureSeal: Partial<UserSignatureSeal>,
) => {
  const routeString = `api.user-signature-seals.store`
  const url = route(routeString)

  try {
    const createUserSignatureSealRes = await axios.post<{ data: UserSignatureSeal }>(url, userSignatureSeal)

    return createUserSignatureSealRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const removeUserSignatureSealAction = async (
  signatureSealUuid: UserSignatureSeal["uuid"],
) => {
  const routeString = `api.user-signature-seals.destroy`
  const url = route(routeString, signatureSealUuid)

  try {
    const removeUserSignatureSealRes = await axios.delete<{ data: UserSignatureSeal }>(url)
    return removeUserSignatureSealRes.status
  } catch (err) {
    console.error(err)
  }
}

export const generateSignatureSealAction = async (
  payload: SignatureSealPayload,
) => {
  if (payload.type === "type") return payload
  const typeString = payload.type === "draw" ? "drawing" : "photo"
  const routeString = `api.generate-signature-seal-from-${typeString}`
  const url = route(routeString)
  try {
    const generateSignatureSealRes = await axios.post<{ data: SignatureSeal }>(url, payload)
    return generateSignatureSealRes.data.data
  } catch (err) {
    console.error(err)
  }
}
