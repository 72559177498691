import axios from "axios"

import { Document, Metadata, MetadataValue, Template, CrudContext, CalendarData } from "~/types"

export const fetchMetadataValuesAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
) => {
  const routeString = `api.${context}s.metadata-values.index`
  const url = route(routeString, entityUuid)

  try {
    const fetchMetadataValuesRes = await axios.get<{ data: MetadataValue[] }>(url)

    return fetchMetadataValuesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchMetadataAction = async () => {
  const routeString = `api.metadata.index`
  const url = route(routeString)

  try {
    const fetchAllAccountMetadataValuesRes = await axios.get<{ data: Metadata[] }>(url)

    return fetchAllAccountMetadataValuesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getMetadataValueAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  metadataValueUuid: MetadataValue["uuid"],
): Promise<MetadataValue | void> => {
  const routeString = `api.${context}s.metadata-values.show`
  const url = route(routeString, {
    [context]: entityUuid,
    metadata_value: metadataValueUuid,
  })

  try {
    const getMetadataValuesRes = await axios.get<{ data: MetadataValue }>(url)

    return getMetadataValuesRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchCalendarDataAction = async (documentUuid: Document["uuid"]): Promise<CalendarData | void> => {
  const routeString = `api.documents.calendar-data.show`
  const url = route(routeString, {
    document: documentUuid,
  })
  try {
    const getCalendarDataRes = await axios.get<{ data: CalendarData }>(url)
    return getCalendarDataRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// metadataValue CRUD
export const createMetadataValueAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  metadataValueToCreate: Partial<MetadataValue>,
): Promise<MetadataValue | void> => {
  const apiUrl = route(`api.${context}s.metadata-values.store`, { [context]: entityUuid })

  const createMetadataValueRes = await axios.post<{ data: MetadataValue }>(apiUrl, metadataValueToCreate)

  return createMetadataValueRes.data.data
}

export const updateMetadataValueAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  metadataValue: Partial<MetadataValue>,
  metadataValueUuid: MetadataValue["uuid"],
): Promise<MetadataValue | void> => {
  const apiUrl = `api.${context}s.metadata-values.update`

  const routeData = {
    metadata_value: metadataValueUuid,
    [context]: entityUuid,
  }

  const url = route(apiUrl, routeData)

  const updateMetadataValueRes = await axios.patch<{data: MetadataValue}>(url, metadataValue)

  return updateMetadataValueRes.data.data
}

export const removeMetadataValueFromEntityAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  metadataValueUuidToRemove: MetadataValue["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.metadata-values.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, metadata_value: metadataValueUuidToRemove }
    : { template: entityUuid, metadata_value: metadataValueUuidToRemove }

  const removeMetadataValueRes = await axios.delete<{ data: MetadataValue }>(route(apiUrl, payload))

  return removeMetadataValueRes.status
}
