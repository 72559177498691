<template>
  <svg viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M16.17,21.64c-0.07-0.28-0.18-0.61-0.31-0.98s-0.28-0.76-0.46-1.17c-0.17-0.41-0.36-0.83-0.56-1.25
		c-0.2-0.43-0.4-0.84-0.61-1.23c-0.21-0.39-0.41-0.76-0.61-1.09s-0.39-0.61-0.56-0.84c-0.27,0.22-0.58,0.46-0.91,0.73
		c-0.34,0.27-0.69,0.55-1.05,0.85c-0.37,0.29-0.74,0.59-1.12,0.89c-0.38,0.29-0.75,0.57-1.12,0.82c-0.29,0.21-0.63,0.44-1.02,0.7
		s-0.8,0.53-1.23,0.82c-0.43,0.28-0.87,0.56-1.32,0.82c-0.45,0.27-0.88,0.51-1.29,0.72c-0.41,0.21-0.79,0.38-1.15,0.51
		c-0.35,0.13-0.64,0.2-0.86,0.2c-0.36,0-0.61-0.12-0.77-0.34c-0.15-0.23-0.23-0.49-0.23-0.77c0-0.26,0.03-0.48,0.09-0.66
		c0.06-0.18,0.21-0.32,0.44-0.44c1.96-0.95,3.82-2.02,5.57-3.23c1.75-1.2,3.35-2.54,4.79-4c-0.08-0.21-0.17-0.47-0.29-0.8
		s-0.24-0.68-0.38-1.06c-0.14-0.38-0.28-0.77-0.43-1.18s-0.3-0.8-0.44-1.18c-0.14-0.38-0.27-0.73-0.39-1.04
		c-0.12-0.31-0.22-0.57-0.3-0.77C9.47,6.21,9.27,5.82,9.04,5.51C8.82,5.2,8.61,4.92,8.41,4.67c-0.2-0.25-0.36-0.5-0.49-0.75
		c-0.13-0.24-0.2-0.53-0.2-0.87c0-0.15,0.03-0.31,0.1-0.47S7.98,2.26,8.1,2.12c0.12-0.14,0.27-0.26,0.44-0.34
		C8.7,1.69,8.89,1.64,9.1,1.64c0.23,0,0.44,0.04,0.64,0.13c0.2,0.08,0.38,0.19,0.55,0.32c0.16,0.13,0.31,0.28,0.44,0.45
		c0.13,0.17,0.24,0.34,0.34,0.51c0.32,0.58,0.62,1.24,0.91,1.98s0.55,1.47,0.79,2.2c0.24,0.73,0.47,1.39,0.68,2
		c0.21,0.61,0.39,1.07,0.55,1.39c0.65-0.68,1.25-1.34,1.82-1.98c0.57-0.63,1.1-1.25,1.59-1.86c0.49-0.6,0.95-1.2,1.36-1.79
		c0.41-0.59,0.78-1.19,1.1-1.8c0.16-0.3,0.29-0.6,0.39-0.91s0.24-0.58,0.41-0.82c0.13-0.14,0.29-0.25,0.49-0.34
		C21.34,1.04,21.58,1,21.85,1c0.2,0,0.36,0.04,0.5,0.12c0.14,0.08,0.25,0.19,0.33,0.32c0.08,0.13,0.14,0.28,0.18,0.45
		c0.04,0.17,0.06,0.34,0.06,0.52c0,0.13-0.01,0.26-0.02,0.39s-0.04,0.24-0.06,0.34c-0.14,0.49-0.33,0.95-0.58,1.39
		c-0.24,0.44-0.53,0.88-0.84,1.31s-0.67,0.86-1.04,1.29C20,7.56,19.61,8.01,19.21,8.48c-0.58,0.67-1.11,1.28-1.6,1.81
		c-0.48,0.53-0.9,0.99-1.25,1.38s-0.64,0.7-0.86,0.94c-0.22,0.24-0.37,0.42-0.43,0.53c0,0.09,0.03,0.19,0.08,0.3
		c0.06,0.11,0.12,0.21,0.18,0.32c0.75,1.18,1.37,2.21,1.86,3.09c0.49,0.88,0.88,1.62,1.18,2.24c0.3,0.61,0.5,1.11,0.62,1.48
		s0.18,0.64,0.18,0.81c0,0.15-0.02,0.31-0.06,0.49c-0.04,0.17-0.11,0.33-0.21,0.48c-0.1,0.15-0.22,0.28-0.38,0.38
		c-0.15,0.1-0.34,0.16-0.55,0.16C17.07,22.88,16.47,22.46,16.17,21.64z"
    />
  </svg>
</template>