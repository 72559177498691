<template>
  <svg
    viewBox="0 0 24 24"
  >
    <path
      d="M6 17h12a1 1 0 010 2H6a1 1 0 010-2zm0-8h4a1 1 0 010 2H6a1 1 0 010-2zm0 4h4a1 1 0 010 2H6a1 1 0 010-2zm8-4h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1zM6 5h12a1 1 0 010 2H6a1 1 0 110-2z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
