<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    fill="currentColor"
  ><path
    d="M52.3 52.4h-3.6V34.9c0-.5-.4-1-1-1H44c-.5 0-1 .4-1 1v17.5h-3V41.9c0-.5-.4-1-1-1h-3.7c-.5 0-1 .4-1 1v10.6h-3.1V36.6c0-.5-.4-1-1-1h-3.7c-.5 0-1 .4-1 1v15.8h-3.1v-7.7c0-.5-.4-1-1-1h-3.7c-.5 0-1 .4-1 1v7.7h-2.6v-25c0-.5-.4-1-1-1s-1 .4-1 1v26c0 .5.4 1 1 1h38.5c.5 0 1-.4 1-1s.2-1-.3-1zm-33.1 0v-6.7h1.7v6.7h-1.7zm8.6 0V37.6h1.7v14.8h-1.7zm8.7 0v-9.6h1.7v9.6h-1.7zm8.6 0V35.9h1.7v16.6h-1.7z"
    class="color010101 svgShape"
  /><path
    d="m20.7 38.5 8.1-7.6 8 5.9c.4.3 1 .2 1.3-.1l8.8-9.7v2.5c0 .5.4 1 1 1 .5 0 1-.4 1-1v-5c0-1.6-2.3-.7-5.9-.9-.5 0-1 .4-1 1s.4 1 1 1h2.8l-8.4 9.2-7.9-5.8c-.4-.3-.9-.2-1.2.1l-8.6 8.1c-.4.4-.4 1 0 1.4 0 .3.6.3 1-.1z"
    class="color010101 svgShape"
  /><path
    d="M63.4 57h-4.3V27.6c1.4.5 2.9 0 3.9-1.2 1.2-1.5.9-3.7-.7-4.9l-6.6-5V5.1c0-.5-.4-1-1-1h-10c-.5 0-1 .4-1 1v2.4l-8.4-6.3c-1.2-.9-3-.9-4.2 0L3.7 21.5c-1.6 1.1-1.9 3.3-.7 4.9.9 1.2 2.5 1.7 3.9 1.2V57H2.6c-.5 0-1 .4-1 1v6.5c0 .5.4 1 1 1h60.8c.5 0 1-.4 1-1V58c0-.5-.4-1-1-1zM45.7 6.1h8.1v9L45.7 9V6.1zM4.6 25.2c-.5-.7-.4-1.7.3-2.2L32.4 2.7c.5-.4 1.3-.4 1.9 0C55.7 19 54.2 17.9 54.4 18l6.7 5.1c.7.5.8 1.5.3 2.2-.5.7-1.5.8-2.2.3-.1-.1-.6-.5-.5-.4L33.9 6.3c-.3-.3-.8-.3-1.2 0L7.3 25.1l-.5.4c-.7.6-1.7.4-2.2-.3zm4.2 1.2L33.3 8.3l23.8 18.1V57H8.8V26.4zm53.7 37.2h-59V59h58.9v4.6z"
    class="color010101 svgShape"
  /></svg>
</template>
