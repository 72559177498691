<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M6 22q-.825 0-1.413-.588T4 20v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 22H6ZM4 11V4q0-.825.588-1.413T6 2h8l6 6v3h-2V9h-5V4H6v7H4Zm5 4v-2h6v2H9Zm8 0v-2h6v2h-6ZM1 15v-2h6v2H1Zm11-4Zm0 6Z"
    />
  </svg>
</template>
