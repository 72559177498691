
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  ><path
    fill="#2dcc9f"
    d="M30 5.851v20.298H2V5.851z"
  /><path
    fill="#fff"
    d="M24.232 8.541a2.2 2.2 0 1 0 1.127.623a2.212 2.212 0 0 0-1.127-.623M18.111 20.1q-2.724-3.788-5.45-7.575L4.579 23.766h10.9q1.316-1.832 2.634-3.663M22.057 16q-2.793 3.882-5.584 7.765h11.169Q24.851 19.882 22.057 16"
  /></svg>
</template>
