import { Instance, Placement } from "tippy.js"

import {
  breakpointsTailwind,
  useBreakpoints,
} from "@vueuse/core"
import { throttle } from "lodash-es"

const mobilePosition = {
  name: "mobilePosition",
  enabled: true,
  phase: "beforeWrite" as any,
  requires: [ "computeStyles" ],
  fn: ({ state }) => {
    state.styles.popper.position = "fixed"
    state.styles.popper.top = "0"
    state.styles.popper.left = "0"
    state.styles.popper.bottom = "0"
    state.styles.popper.right = "0"
    state.styles.popper.transform = "none"
  },
  effect: ({ state }) => {
    state.elements.popper.style.position = "fixed"
    state.elements.popper.style.top = "0"
    state.elements.popper.style.left = "0"
    state.elements.popper.style.bottom = "0"
    state.elements.popper.style.right = "0"
    state.elements.popper.style.transform = "none"
  },
}

const adjustForMobile = (instance: Instance) => {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.isSmaller("md")
  if (isMobile) {
    instance.setProps({
      popperOptions: {
        modifiers: [ mobilePosition ],
      },
      animation: "slide",
    })
  } else {
    const instancePlacement = instance.reference?.getAttribute("data-placement")
    instance.setProps({
      popperOptions: {
        modifiers: [],
      },
      placement: (instancePlacement || "auto") as Placement,
      animation: "scale",
    })
  }
}

const throttledAdjustForMobile = throttle(adjustForMobile, 100)

export const tippyMobile = {
  name: "tippyMobile",
  defaultValue: true,
  fn (instance: Instance) {
    return {
      onShow () {
        adjustForMobile(instance)
        window.addEventListener("resize", () => throttledAdjustForMobile(instance))
      },
      onHide () {
        window.removeEventListener("resize", () => throttledAdjustForMobile(instance))
      },
    }
  },
}
