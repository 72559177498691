import { defineStore } from "pinia"

import { computed, reactive, toRefs } from "vue"
import { Account, AccountUsage, AccountUser, ContractType, CurrentSubscription, SubscriptionType } from "~/types"

import { fetchContractTypesAction } from "./accountStoreActions"
import { throttle } from "lodash-es"

interface Data {
  mau: AccountUser
  account: Account
  userCustomizations: any,
  accountUsage: AccountUsage
  currentSubscription: CurrentSubscription
  planFeatures: any
  isLoadingSubscription: boolean
  contractTypes: ContractType[]
}


export const useAccountStore = defineStore("accountStore", () => {
  const data = reactive<Data>(
    {
      mau: null,
      account: null,
      userCustomizations: null,
      accountUsage: null,
      currentSubscription: null,
      planFeatures: null,
      isLoadingSubscription: false,
      contractTypes: [],
    },
  )

  // Computed variables
  const subscriptionType = computed<"trial" | "free" | "paid" | "internal">(() => {
    if (data.currentSubscription?.object === SubscriptionType.demo) return "trial"
    if (data.currentSubscription?.object === SubscriptionType.limited) return "free"
    if (data.currentSubscription?.object === SubscriptionType.stripe) return "paid"
    if (data.currentSubscription?.object === SubscriptionType.unlimited) return "internal"
    return null
  })

  const subscriptionPlan = computed<string>(() => {
    if (!data.currentSubscription) return null
    return data.currentSubscription.plan?.nickname
  })

  const setMau = (mau: AccountUser) => {
    if (!mau) return
    data.mau = mau
    data.userCustomizations = mau.customizations
  }
  const setAccount = (account: Account) => data.account = account
  const setAccountUsage = (accountUsage: AccountUsage) => data.accountUsage = accountUsage
  const setCurrentSubscription = (currentSubscription: CurrentSubscription) => {
    if (!currentSubscription) return
    data.currentSubscription = currentSubscription
    if (currentSubscription.plan?.metadata) {
      data.planFeatures = Object.keys(currentSubscription.plan.metadata).sort().reduce(
        (obj, key) => {
          const sanitizedKey = key.split("_")[1]
          obj[sanitizedKey] = currentSubscription.plan.metadata[key]
          return obj
        },
        {},
      )
    } else {
      data.planFeatures = null
    }
  }
  const setUserCustomizations = (customizations: any) => data.userCustomizations = customizations
  const setContractTypes = (contractTypes: ContractType[]) => data.contractTypes = contractTypes

  const throttledFetchContractTypes = throttle(fetchContractTypesAction, 1000)

  // ui actions
  const fetchContractTypes = async (includeAliases = false) => {
    if ((data.contractTypes.length > 0 && !includeAliases) || data.contractTypes?.some((el) => el.aliases?.length > 0)) return
    const contractTypes = await throttledFetchContractTypes()
    if (contractTypes) {
      setContractTypes(contractTypes)
    }
  }

  return {
    ...toRefs(data),

    // computed variables
    subscriptionType,
    subscriptionPlan,

    // mutations
    setMau,
    setAccount,
    setUserCustomizations,
    setAccountUsage,
    setCurrentSubscription,
    setContractTypes,

    // ui actions
    fetchContractTypes,
  }
})
