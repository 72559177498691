<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1509.89 232.55"
  ><g
    id="Layer_2"
    data-name="Layer 2"
  ><g id="eIDs"><g
    id="Layer_162"
    data-name="Layer 162"
  ><g
    id="Layer_1-2"
    data-name="Layer 1-2"
  ><path
    style="fill: #5179b3"
    d="M81.12,32.45H16.22A16.66,16.66,0,0,1,0,16.22H0A16.66,16.66,0,0,1,16.22,0h64.9A16.65,16.65,0,0,1,97.34,16.22h0A16.65,16.65,0,0,1,81.12,32.45Z"
  /><path
    style="fill: #5179b3"
    d="M81.12,162.24H16.22A16.66,16.66,0,0,1,0,146H0a16.66,16.66,0,0,1,16.22-16.22h64.9A16.65,16.65,0,0,1,97.34,146h0A16.65,16.65,0,0,1,81.12,162.24Z"
  /><path
    style="fill: #5179b3"
    d="M81.12,227.13H16.22A16.65,16.65,0,0,1,0,210.91H0a16.65,16.65,0,0,1,16.22-16.22h64.9a16.64,16.64,0,0,1,16.22,16.22h0A16.65,16.65,0,0,1,81.12,227.13Z"
  /><path
    style="fill: #5179b3"
    d="M210.91,97.34H146a16.65,16.65,0,0,1-16.22-16.22h0A16.65,16.65,0,0,1,146,64.9h64.9a16.64,16.64,0,0,1,16.22,16.22h0A16.65,16.65,0,0,1,210.91,97.34Z"
  /><path
    style="fill: #5179b3"
    d="M210.91,162.24H146A16.66,16.66,0,0,1,129.79,146h0A16.66,16.66,0,0,1,146,129.79h64.9A16.65,16.65,0,0,1,227.13,146h0A16.65,16.65,0,0,1,210.91,162.24Z"
  /><path
    style="fill: #5179b3"
    d="M340.7,32.45H275.8a16.66,16.66,0,0,1-16.22-16.23h0A16.66,16.66,0,0,1,275.8,0h64.9a16.65,16.65,0,0,1,16.22,16.22h0A16.65,16.65,0,0,1,340.7,32.45Z"
  /><path
    style="fill: #5179b3"
    d="M340.7,97.34H275.8a16.65,16.65,0,0,1-16.22-16.22h0A16.65,16.65,0,0,1,275.8,64.9h64.9a16.64,16.64,0,0,1,16.22,16.22h0A16.65,16.65,0,0,1,340.7,97.34Z"
  /><path
    style="fill: #5179b3"
    d="M340.7,227.13H275.8a16.65,16.65,0,0,1-16.22-16.22h0a16.65,16.65,0,0,1,16.22-16.22h64.9a16.64,16.64,0,0,1,16.22,16.22h0A16.65,16.65,0,0,1,340.7,227.13Z"
  /><path
    style="fill: #5179b3"
    d="M495.37,141.69c0,34.61,19.46,53,42.18,53,20.55,0,43.26-17.31,43.26-53s-21.63-51.92-42.18-51.92c-22.71,0-43.26,16.23-43.26,51.92M495.37,0V84.36a63.81,63.81,0,0,1,56.24-30.28c32.45,0,71.38,27,71.38,86.53,0,61.65-36.77,91.93-74.63,91.93a58.51,58.51,0,0,1-51.91-29.2v23.79H454.27V0Z"
  /><path
    style="fill: #5179b3"
    d="M684.64,142.77c0,35.69,21.63,51.92,42.18,51.92,22.72,0,42.19-15.15,42.19-51.92s-19.47-53-42.19-53c-19.46,0-42.18,16.23-42.18,53m-41.1-2.16C643.54,82.2,681.4,53,719.25,53a58.49,58.49,0,0,1,50.84,26V57.32h41.1V227.13h-41.1V203.34a65.67,65.67,0,0,1-56.24,28.12c-32.45,0-70.31-28.12-70.31-90.85"
  /><path
    style="fill: #5179b3"
    d="M892.31,130.87v96.26h-41.1V58.41h41.1V83.28a57.65,57.65,0,0,1,51.91-29.2c29.21,0,57.33,15.14,57.33,60.57V227.13h-41.1V129.79c0-28.12-14.06-38.94-32.45-38.94-20.55,1.08-35.69,13-35.69,40"
  /><polygon
    style="fill: #5179b3"
    points="1040.48 0 1040.48 227.13 1081.59 227.13 1081.59 186.03 1104.3 157.91 1150.81 227.13 1196.23 227.13 1129.17 128.71 1187.58 58.41 1141.07 58.41 1081.59 136.28 1081.59 0 1040.48 0"
  /><rect
    style="fill: #5179b3"
    x="1236.25"
    y="7.57"
    width="42.18"
    height="219.56"
  /><path
    style="fill: #5179b3"
    d="M1400.65,7.57h-80V227.13h80c59.49,0,109.24-35.69,109.24-110.32S1460.14,7.57,1400.65,7.57Zm0,180.63H1362.8V46.51h37.85c34.61,0,63.82,22.71,63.82,71.38S1435.26,188.2,1400.65,188.2Z"
  /></g></g></g></g></svg>
</template>