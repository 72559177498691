import axios from "axios"
import { router } from "@inertiajs/vue3"

import { Notification, NotificationType, Pagination } from "~/types"

export const fetchNotificationsAction = async (page = 1): Promise<Pagination<Notification[]>> => {
  const url = route("api.notifications.index")

  try {
    const response = await axios.get<Pagination<Notification[]>>(url, { params: { page: page } })
    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const updateNotificationAction = async (
  notification: Partial<Notification>,
): Promise<Notification | void> => {
  const url = route("api.notifications.update", notification.uuid)

  try {
    const response = await axios.patch(url, notification)
    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const markAllAsReadAction = async (): Promise<Notification[] | void> => {
  const url = route("api.notifications.bulk-mark-all-as-read-at")

  try {
    const response = await axios.post(url, {})
    return response.data.data
  } catch (err) {
    console.error(err)
  }
}


export const updateNotificationSettingAction = async (
  notificationType: NotificationType,
): Promise<NotificationType | void> => {
  const url = route("api.user-notification-settings.update", notificationType)

  try {
    const response = await axios.patch(url, { value: [] })
    return response.data.data
  } catch (err) {
    console.error(err)
  }
}

export const updateNotificationCountAction = () => {
  router.reload({
    only: [ "unreadNotifications" ],
  })
}
