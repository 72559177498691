import axios from "axios"

import { Document, Checkpoint, Template, CrudContext, CheckpointStep, CheckpointStepPayload, AccountUser, CheckpointStepApprover, DocumentStage, CheckpointPass } from "~/types"

// CHECKPOINTS
export const fetchCheckpointsAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
) => {
  const routeString = `api.${context}s.checkpoints.index`

  const url = route(routeString, entityUuid)

  try {
    const fetchCheckpointsRes = await axios.get<{ data: Checkpoint[] }>(url)

    return fetchCheckpointsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const createCheckpointAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  stage: DocumentStage,
): Promise<Checkpoint | void> => {
  const apiUrl = route(
    `api.${context}s.checkpoints.store`,
    { [context]: entityUuid },
  )

  const createCheckpointRes = await axios.post<{ data: Checkpoint }>(apiUrl, {
    document_stage: stage,
  })

  return createCheckpointRes.data.data
}

export const removeCheckpointAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
): Promise<number | void> => {
  const removeCheckpointRes = await axios
    .delete(
      route(`api.${context}s.checkpoints.destroy`, {
        [context]: entityUuid,
        checkpoint: checkpointUuid,
      }),
    )

  return removeCheckpointRes.status
}

// CHECKPOINT STEPS
export const createCheckpointStepAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
  payload: CheckpointStepPayload,
): Promise<CheckpointStep | void> => {
  const apiUrl = route(
    `api.${context}s.checkpoints.checkpoint-steps.store`, {
      [context]: entityUuid,
      checkpoint: checkpointUuid,
    })

  const createCheckpointStepRes = await axios.post<{ data: CheckpointStep }>(apiUrl, payload)

  return createCheckpointStepRes.data.data
}

export const removeCheckpointStepAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
  checkpointStepUuid: CheckpointStep["uuid"],
): Promise<number | void> => {
  const removeCheckpointStepRes = await axios
    .delete(
      route(`api.${context}s.checkpoints.checkpoint-steps.destroy`, {
        [context]: entityUuid,
        checkpoint: checkpointUuid,
        checkpoint_step: checkpointStepUuid,
      }),
    )

  return removeCheckpointStepRes.status
}

export const updateCheckpointStepAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
  checkpointStepUuid: CheckpointStep["uuid"],
  payload: Partial<CheckpointStep>,
): Promise<CheckpointStep | void> => {
  const updateCheckpointStepRes = await axios
    .patch(
      route(`api.${context}s.checkpoints.checkpoint-steps.update`, {
        [context]: entityUuid,
        checkpoint: checkpointUuid,
        checkpoint_step: checkpointStepUuid,
      }),
      payload,
    )

  return updateCheckpointStepRes.data.data
}

// CHECKPOINT STEP APPROVERS
export const createCheckpointStepApproverAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
  checkpointStepUuid: CheckpointStep["uuid"],
  accountUserUuid: AccountUser["uuid"],
): Promise<CheckpointStepApprover | void> => {
  const apiUrl = route(
    `api.${context}s.checkpoints.checkpoint-steps.checkpoint-step-approvers.store`,
    {
      [context]: entityUuid,
      checkpoint: checkpointUuid,
      checkpoint_step: checkpointStepUuid,
    },
  )

  const createCheckpointStepApproverRes = await axios.post<{ data: CheckpointStepApprover }>(apiUrl, {
    account_user_uuid: accountUserUuid,
  })

  return createCheckpointStepApproverRes.data.data
}

export const removeCheckpointStepApproverAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  checkpointUuid: Checkpoint["uuid"],
  checkpointStepUuid: CheckpointStep["uuid"],
  checkpointStepApproverUuid: CheckpointStepApprover["uuid"],
): Promise<number | void> => {
  const removeCheckpointStepApproverRes = await axios
    .delete(
      route(`api.${context}s.checkpoints.checkpoint-steps.checkpoint-step-approvers.destroy`, {
        [context]: entityUuid,
        checkpoint: checkpointUuid,
        checkpoint_step: checkpointStepUuid,
        checkpoint_step_approver: checkpointStepApproverUuid,
      }),
    )

  return removeCheckpointStepApproverRes.status
}

export const fetchCheckpointPassesAction = async (
  uuid: Document["uuid"],
): Promise<CheckpointPass[] | void> => {
  const checkpointPassesRes = await axios.get<{ data: CheckpointPass[] }>(route( "api.documents.checkpoint-passes.index", uuid))
  return checkpointPassesRes.data.data
}
