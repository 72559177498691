// external
import { Placement, delegate, roundArrow } from "tippy.js"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"
import { onMounted } from "vue"

export function usePageTippies () {

  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isNotMobile = breakpoints.greater("sm")

  const setUpContextTippies = () => {
    delegate("body", {
      target: "[data-tippy-context]",
      arrow: roundArrow,
      animation: "scale",
      theme: "context",
      appendTo: () => document.body,
      onMount (instance) {
        instance.popperInstance?.setOptions({
          placement: instance.reference.getAttribute("data-placement") as Placement,
        })
      },
      onShow (instance) {
        if (!isNotMobile.value) return false
        if (instance.reference.getAttribute("data-tippy-context") === "false") return false
      },
    })
  }

  const setUpListTippies = () => {
    delegate("body", {
      target: "[data-tippy-list]",
      arrow: roundArrow,
      animation: "scale",
      theme: "list",
      appendTo: "parent",
      onMount (instance) {
        instance.popperInstance?.setOptions({
          placement: instance.reference.getAttribute("data-placement") as Placement,
        })
      },
    })
  }

  const setUpHelpTippies = () => {
    delegate("body", {
      target: "[data-tippy-help]",
      arrow: roundArrow,
      animation: "scale",
      theme: "help",
      appendTo: (ref) => {
        const dataAppend = document.querySelector(ref.getAttribute("data-tippy-append-to"))
        if (dataAppend) return dataAppend
        return ref.parentElement
      },
      onMount (instance) {
        instance.popperInstance?.setOptions({
          placement: instance.reference.getAttribute("data-placement") as Placement,
        })
      },
      onShow (instance) {
        if (!instance.reference?.getAttribute("data-tippy-content") || instance.reference?.getAttribute("data-tippy-content") === "") return false
        // Set the content to the data-tippy-content attribute
        instance.setContent(instance.reference.getAttribute("data-tippy-content"))
      },
    })
  }

  onMounted(() => {
    setUpContextTippies()
    setUpListTippies()
    setUpHelpTippies()
  })

  return {
    setUpContextTippies,
    setUpListTippies,
    setUpHelpTippies,
  }
}
