import axios from "axios"
import { CustomWidget, Dashboard } from "~/types"

export const createDashboardAction = async (
  payload: Partial<Dashboard>,
): Promise<Dashboard | void> => {
  const res = await axios.post(route("api.dashboards.store"), payload)
  return res.data.data
}

export const updateDashboardAction = async (
  payload: Partial<Dashboard>,
): Promise<Dashboard | void> => {
  const res = await axios.patch(route("api.dashboards.update", payload.uuid), payload)
  return res.data.data
}

export const deleteDashboardAction = async (
  uuid: Dashboard["uuid"],
): Promise<void> => {
  const res = await axios.delete(route("api.dashboards.destroy", uuid))
  return res.data.data
}

export const createCustomWidgetAction = async (
  payload: Partial<CustomWidget>,
): Promise<CustomWidget | void> => {
  const res = await axios.post(route("api.custom-widgets.store"), payload)
  return res.data.data
}

export const getCustomWidgetAction = async (
  uuid: CustomWidget["uuid"],
): Promise<CustomWidget | void> => {
  const res = await axios.get(route("api.custom-widgets.show", uuid))
  return res.data
}

export const updateCustomWidgetAction = async (
  payload: Partial<CustomWidget>,
): Promise<CustomWidget | void> => {
  const res = await axios.patch(route("api.custom-widgets.update", payload.uuid), payload)
  return res.data
}

export const deleteCustomWidgetAction = async (
  uuid: CustomWidget["uuid"],
): Promise<void> => {
  await axios.delete(route("api.custom-widgets.destroy", uuid))
}
