import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"

import { Editor } from "@tiptap/core"

interface Item {
  name?: string
  title?: string
  preview?: string
  type?: string
  uuid?: string
  command?: () => any
}

interface Data {
  sections: Item[]
  uuidsNestedOrderRepresentationMap: Record<string, string>
}

export const useSectionRefStore = defineStore(
  "sectionRefStore",
  () => {
    const data = reactive<Data>(
      {
        sections: [],
        uuidsNestedOrderRepresentationMap: {},
      },
    )

    // mutations
    const setUuidsNestedOrderRepresentationMap = (map: Record<string, string>) => {
      data.uuidsNestedOrderRepresentationMap = map
    }

    const extractSectionsFromEditor = (e: Editor) => {
      if (e.storage.doc.editorContext === "proposal") return

      const sectionsData: Item[] = []

      e.state.doc.descendants(
        (node) => {
          if (node.type?.name !== "list" || node.attrs.kind !== "ordered") return

          sectionsData.push(
            {
              title: data.uuidsNestedOrderRepresentationMap[node.attrs.uuid],
              preview: node.firstChild.textContent,
              uuid: node.attrs.uuid,
            },
          )
        },
      )

      data.sections = sectionsData
    }

    return {
      ...toRefs(data),

      // mutations
      setUuidsNestedOrderRepresentationMap,
      extractSectionsFromEditor,
    }
  },
)
