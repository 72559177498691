import { defineStore } from "pinia"
import { nextTick, reactive, toRefs, watch, toRaw } from "vue"
import tippy, { roundArrow, Props as TippyProps, sticky, Instance } from "tippy.js"

import { Comment, Document, DiscussionTab, DocumentTab } from "~/types"
import { useDocumentStore } from "../documentStore"
import { scrollTo } from "~/utils"

import { createCommentAction, fetchCommentsAction, updateCommentAction, getCommentAction } from "./commentStoreActions"

interface Data {
  comments: Comment[]
  isLoadingComments: boolean
  isCreatingComment: boolean
  updatingCommentUuid: Comment["uuid"] | null
  highlightedCommentUuids: Comment["uuid"][] | null
  activeCommentProsemirrorDataUuid: string
  commentTippyProsemirrorDataUuid: string
  makeProposal: boolean
  resolveProposal: Comment
  proposalContent: any
  commentTippy: Instance<TippyProps>
  discussionTabId: DiscussionTab
  commentUuidBeingLoaded: Comment["uuid"]
  isActiveAddMentionedUserModal: boolean
  isHighlightedViaClick: boolean

}

export const useCommentStore = defineStore("commentStore", () => {
  const data = reactive<Data>({
    comments: [],
    isLoadingComments: false,
    isCreatingComment: false,
    updatingCommentUuid: null,
    highlightedCommentUuids: [],
    activeCommentProsemirrorDataUuid: null,
    commentTippyProsemirrorDataUuid: null,
    makeProposal: false,
    resolveProposal: null,
    proposalContent: null,
    commentTippy: null,
    discussionTabId: DiscussionTab.open,
    commentUuidBeingLoaded: "",
    isActiveAddMentionedUserModal: false,
    isHighlightedViaClick: false,

  })

  // mutations
  const setComments = (comments: Comment[]) => data.comments = comments
  const pushComment = (comment: Comment) => data.comments.push(comment)

  const pushOrUpdateComment = (c: Partial<Comment>) => {
    const localIndexOfComment = data.comments.findIndex(({ uuid }) => uuid === c.uuid)

    const commentsCopy = [ ...toRaw(data.comments) ]

    if (localIndexOfComment !== -1) {
      commentsCopy[localIndexOfComment] = {
        ...commentsCopy[localIndexOfComment],
        ...c,
      }

      data.comments = commentsCopy

      return
    }

    data.comments = [
      ...commentsCopy,
      c,
    ] as Comment[] // TODO: change this to real types
  }

  const setIsActiveAddMentionedUserModal = (isActive: boolean) => data.isActiveAddMentionedUserModal = isActive

  const setIsHighlightedViaClick = (isHighlighted: boolean) => data.isHighlightedViaClick = isHighlighted

  const setIsLoadingComments = (isLoading: boolean) => data.isLoadingComments = isLoading

  const setActiveCommentProsemirrorDataUuid = (uuid: string) => data.activeCommentProsemirrorDataUuid = uuid

  const setMakeProposal = (makeProposal) => data.makeProposal = makeProposal

  const setResolveProposal = (resolveProposal) => data.resolveProposal = resolveProposal

  const setProposalContent = (proposalContent) => data.proposalContent = proposalContent

  const setHighlightedCommentUuids = (highlightedCommentUuids) => data.highlightedCommentUuids = highlightedCommentUuids ? [ ...highlightedCommentUuids ] : []

  const setDiscussionTabId = (discussionTabId:DiscussionTab) => data.discussionTabId = discussionTabId

  // api actions
  const fetchComments = async (documentUuid: Document["uuid"]): Promise<Comment[] | void> => {
    if (!documentUuid) {
      console.error("valid document uuid not provided. documentUuid: ", documentUuid)
      return
    }

    try {
      setIsLoadingComments(true)

      const comments = await fetchCommentsAction(documentUuid) || []

      setComments(comments)

      return comments
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoadingComments(false)
    }
  }

  const getComment = async (documentUuid: Document["uuid"], commentUuid: Comment["uuid"]): Promise<Comment | void> => {
    try {
      data.commentUuidBeingLoaded = commentUuid
      const comment = await getCommentAction(documentUuid, commentUuid)
      if (comment) {
        pushOrUpdateComment(comment)
      }
      return comment
    } catch (err) {
      console.error(err)
    } finally {
      data.commentUuidBeingLoaded = null
    }
  }

  const documentStore = useDocumentStore()

  // comment CRUD
  const createComment = async (
    documentUuid: Document["uuid"],
    payload: Partial<Comment>,
  ) => {
    data.isCreatingComment = true
    try {
      const createdComment = await createCommentAction(documentUuid, payload)
      if (createdComment) {
        pushComment(createdComment)
        nextTick(() => {
          documentStore.getMainEditor()?.commands.directDecoration()
        })
      }
      return createdComment
    } catch (err) {
      console.error(err)
    } finally {
      data.isCreatingComment = false
      if (data.commentTippy) {
        data.commentTippy.hide()
      }
    }
  }

  const updateComment = async (
    documentUuid: Document["uuid"],
    commentUuid: Comment["uuid"],
    payload: Partial<Comment>,
  ) => {
    data.updatingCommentUuid = commentUuid
    try {
      const updatedComment = await updateCommentAction(documentUuid, commentUuid, payload)
      if (updatedComment) {
        pushOrUpdateComment(updatedComment)
        nextTick(() => {
          documentStore.getMainEditor()?.commands.directDecoration()
        })
      }
      return updatedComment
    } catch (err) {
      console.error(err)
    } finally {
      data.updatingCommentUuid = null
    }
  }


  const highlightCommentsInList = (comments: Comment[]) => {
    if (!comments.length) {
      console.warn("No comment to highlight provided")
      return
    }

    if (comments.length === 1 && comments[0].prosemirror_data_uuid) {
      setActiveCommentProsemirrorDataUuid(comments[0].prosemirror_data_uuid)
    }

    const highlightedCommentUuids = comments.map((comment) => comment.uuid) || []
    setHighlightedCommentUuids(highlightedCommentUuids)
    documentStore.setActiveTabKey(DocumentTab.discussions)

    const unresolvedComment = comments.find((comment) => !comment.is_resolved)
    const commentToFocus = unresolvedComment || comments[0]

    setDiscussionTabId(!unresolvedComment ? DiscussionTab.resolved : DiscussionTab.open)
    setTimeout(() => { document.getElementById(`comment_${commentToFocus.uuid}`)?.focus() })
  }

  const highlightCommentsInListByProsemirrorDataUuid = (prosemirrorDataUuid: string) => {
    const comments = data.comments.filter((entry) => entry.prosemirror_data_uuid === prosemirrorDataUuid)

    if (!comments.length) return

    highlightCommentsInList(comments)
  }

  const highlightCommentsInListByUuid = (uuid: Comment["uuid"]) => {
    const comment = data.comments.find((entry) => entry.uuid === uuid)
    if (comment) {
      if (comment.comment_uuid) {
        const parentComment = data.comments.find((entry) => entry.uuid === comment.comment_uuid)
        highlightCommentsInList([ parentComment ])
      } else {
        highlightCommentsInList([ comment ])
      }
    }
  }

  const getCommentsByProsemirrorDataUuid = (prosemirrorDataUuid: string) => {
    return data.comments.filter((comment) => comment.prosemirror_data_uuid === prosemirrorDataUuid)
  }

  // ui actions
  const triggerCommentTippy = (prosemirrorDataUuid: string) => {
    setActiveCommentProsemirrorDataUuid(prosemirrorDataUuid)
    data.commentTippyProsemirrorDataUuid = prosemirrorDataUuid
    const positionTarget = document.querySelector(`.ProseMirror [data-uuid="${prosemirrorDataUuid}"], .ProseMirror [data-list-uuid="${prosemirrorDataUuid}"]`)
    const triggerTarget = document.querySelector(`.ProseMirror [data-uuid="${prosemirrorDataUuid}"] .highlight-marker, .ProseMirror [data-list-uuid="${prosemirrorDataUuid}"] .highlight-marker`)
    data.commentTippy = tippy(
      positionTarget,
      {
        triggerTarget: triggerTarget,
        content: "",
        getReferenceClientRect: () => {
          const positionTargetRect = positionTarget.getBoundingClientRect()
          const referenceClientRect: DOMRect = {
            top: positionTargetRect.top,
            left: positionTargetRect.left + positionTargetRect.width - 1,
            right: positionTargetRect.right,
            bottom: positionTargetRect.bottom,
            width: 20,
            height: positionTargetRect.height,
            x: positionTargetRect.x + positionTargetRect.width - 1,
            y: positionTargetRect.y,
            toJSON: () => { return {} },
          }
          return referenceClientRect
        },
        appendTo: () => document.getElementById("mainContentContainer"),
        animation: "scale",
        allowHTML: true,
        theme: "yellow",
        arrow: roundArrow,
        interactive: true,
        trigger: "manual",
        hideOnClick: true,
        plugins: [ sticky ],
        sticky: true,
        showOnCreate: true,
        placement: "right",
        onShow (instance) {
          const element = document.getElementById("commentPopover")
          instance.setContent(element)
        },
        onHide (instance) {
          if (data.isActiveAddMentionedUserModal) return false
          const element = document.getElementById("commentPopover")
          const domRect = element.getBoundingClientRect()
          const appendHolder = document.getElementById("editorContainerPopovers") || document.body
          appendHolder.appendChild(element)
          const fakeElement = document.createElement("div")
          fakeElement.innerHTML = (
            "<div class=\"relative md:w-96\"><div class=\"popover popover-secondary bg-gray-100 divide-y divide-gray-100\" style=\"height: " +
                domRect.height +
                "px\"></div></div></div>"
          ).trim()
          instance.setContent(fakeElement)
          instance.popperInstance?.setOptions({
            placement: "right",
          })
        },
        onShown () {
          // TODO: FOcus editor
        },
        onHidden (instance) {
          data.commentTippyProsemirrorDataUuid = null
          instance.setContent("")
          setActiveCommentProsemirrorDataUuid("")
          setHighlightedCommentUuids(null)
        },
      },
    )
  }

  watch(
    () => data.activeCommentProsemirrorDataUuid,
    (uuid, oldUuid) => {
      if (uuid === oldUuid) return

      if (uuid) highlightCommentsInListByProsemirrorDataUuid(uuid)
    },
  )

  const scrollToCommentInDocument = (uuid: string): void => {
    const element: HTMLDivElement = document.querySelector(`[data-uuid="${uuid}"].has-highlight, [data-list-uuid="${uuid}"].has-highlight`)

    if (!element) {
      // TO DO - how to handle comments without a uuid-based reference element in the editor
      console.warn("UUID-based reference element missing for comment jump.")
      return
    }

    const mainContentScrollContainer = documentStore.getMainContentScrollContainer()

    scrollTo(mainContentScrollContainer, element, () => {
      //
    })

  }

  return {
    ...toRefs(data),

    // mutations
    setComments,
    setIsLoadingComments,
    setIsActiveAddMentionedUserModal,
    setIsHighlightedViaClick,
    setActiveCommentProsemirrorDataUuid,
    setMakeProposal,
    setResolveProposal,
    setHighlightedCommentUuids,
    setDiscussionTabId,
    setProposalContent,
    getCommentsByProsemirrorDataUuid,
    pushOrUpdateComment,

    // api actions
    fetchComments,
    getComment,

    // comment CRUD
    createComment,
    updateComment,

    // ui actions
    triggerCommentTippy,
    scrollToCommentInDocument,
    highlightCommentsInListByProsemirrorDataUuid,
    highlightCommentsInListByUuid,
  }
})
