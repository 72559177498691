import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"

import { ConfirmOptions } from "~/types"

interface Data {
  showConfirmModal: boolean
  confirmOptions: ConfirmOptions
  confirmationCallback: (data: any) => void
  confirmationData: any
}

export const useConfirmationStore = defineStore("confirmationStore", () => {
  const data = reactive<Data>({
    showConfirmModal: false,
    confirmOptions: {
      title: "Confirmation required",
      description: "Are you sure that you want to proceed?",
      buttonText: "Confirm",
      data: null,
    },
    confirmationCallback: () => {},
    confirmationData: null,
  })

  const setShowConfirmModal = (show: boolean) => {
    if (show) {
      window.addEventListener("keydown", handleConfirmationModalEnterKey)
    } else {
      window.removeEventListener("keydown", handleConfirmationModalEnterKey)
      resetConfirmationData()
    }
    data.showConfirmModal = show
  }
  const setConfirmOptions = (options) => {
    data.confirmOptions = {
      ...options,
      buttonText: options.buttonText ?? "Confirm",
    }
    // If callback was set in options, set it to the confirmationCallback
    if (options.callback) setConfirmationCallback(options.callback)
    else setConfirmationCallback(() => {})
    // If confirmation data was set in options, set it to the confirmationData
    if (options.data) data.confirmationData = options.data
    else data.confirmationData = null
  }
  const setConfirmationCallback = (callback) => data.confirmationCallback = callback
  const resetConfirmationData = () => data.confirmationData = null

  const handleConfirmationModalEnterKey = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      data.confirmationCallback(data.confirmationData)
      setShowConfirmModal(false)
    }
  }

  return {
    // data
    ...toRefs(data),

    // actions
    setShowConfirmModal,
    setConfirmationCallback,
    resetConfirmationData,
    setConfirmOptions,
  }
})
