import axios from "axios"

import { StoredFile, CrudContext, Document, Template, Comment } from "~/types"

export const fetchStoredFilesAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
): Promise<StoredFile[] | void> => {
  const routeString = `api.${context}s.stored-files.index`
  const url = route(routeString, {
    [context]: entityUuid,
  })
  try {
    const fetchStoredFiles = await axios.get<{ data: StoredFile[] }>(url)
    return fetchStoredFiles.data.data
  } catch (err) {
    console.error(err)
  }
}

// stored file CRUD
export const createStoredFileAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  storedFileToCreate: Partial<StoredFile>,
  commentUuid: Comment["uuid"] | null = null,
): Promise<StoredFile | void> => {
  let routeArgs = {
    [context]: entityUuid,
  }
  if (commentUuid) {
    routeArgs = {
      ...routeArgs,
      comment: commentUuid,
    }
  }
  const apiUrl = route(`api.${context}s.stored-files.store`, routeArgs)

  const createStoredFileRes = await axios.post<{ data: StoredFile }>(apiUrl, storedFileToCreate)

  return createStoredFileRes.data.data
}

export const updateStoredFileAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  storedFileUuidToUpdate: StoredFile["uuid"],
  payload: Partial<StoredFile>,
): Promise<StoredFile | void> => {
  const apiUrl = `api.${context}s.stored-files.update`
  const routeArgs = {
    [context]: entityUuid,
    stored_file: storedFileUuidToUpdate,
  }
  const apiRoute = route(apiUrl, routeArgs)
  const updateStoredFileRes = await axios.put<{ data: StoredFile }>(apiRoute, payload)
  return updateStoredFileRes.data.data
}

export const removeStoredFileAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  storedFileUuidToRemove: StoredFile["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.stored-files.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, stored_file: storedFileUuidToRemove }
    : { template: entityUuid, stored_file: storedFileUuidToRemove }

  const removeStoredFileRes = await axios.delete<{ data: StoredFile }>(route(apiUrl, payload))

  return removeStoredFileRes.status
}

export const getStoredFileUrlAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  storedFileUuid: StoredFile["uuid"],
): Promise<StoredFile | void> => {
  const routeString = `api.${context}s.stored-files.show`
  const url = route(routeString, {
    [context]: entityUuid,
    stored_file: storedFileUuid,
  })
  const getStoredFileRes = await axios.get<{ data: StoredFile }>(url)

  return getStoredFileRes.data.data
}
