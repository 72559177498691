export enum MultiFieldType {
  text = "text",
  email = "email",
  number = "number",
  currency = "currency",
  currency_duration = "currency_duration",
  select = "select",
  date = "date",
  bool = "bool",
  duration = "duration",
  textarea = "textarea",
  list = "list",
  timestamp = "timestamp",
  clause = "clause",
}

export enum DocumentContentType {
  prosemirror_data = "prosemirror_data",
  html = "html",
  pdf = "pdf",
}

export enum DocumentOrigin {
  signed_pdf = "signed_pdf",
  template = "template",
  scratch = "scratch",
  pdf = "pdf",
}

export enum DocumentStage {
  draft = "draft",
  approved_draft = "approved_draft",
  review = "review",
  approved_review = "approved_review",
  signing = "signing",
  signed = "signed",
}

export enum DocumentUserRoleEnum {
  collaborator = "document-collaborator",
  signatory = "document-signatory",
  owner = "document-owner",
}

export enum DynamicFieldAutofillType {
  document_created_date = "document_created_date",
  document_review_date = "document_review_date",
  document_signing_date = "document_signing_date",
}

export enum CheckpointPassStatus {
  upcoming = "upcoming",
  ready = "ready",
  active = "active",
  approved = "approved",
  rejected = "rejected",
  canceled = "canceled",
}

export enum CheckpointPassStepStatus {
  upcoming = "upcoming",
  active = "active",
  approved = "approved",
  rejected = "rejected",
  canceled = "canceled",
}

export enum CheckpointPassStepApprovalStatus {
  approved = "approved",
  rejected = "rejected"
}

export enum CheckpointStepPolicy {
  one = "one",
  two = "two",
  all = "all",
}

export enum ConditionOperator {
  EQUAL = "equal",
  NOT_EQUAL = "not_equal",
  GREATER_THAN = "greater_than",
  LESS_THAN = "less_than",
  GREATER_THAN_OR_EQUAL = "greater_than_or_equal",
  LESS_THAN_OR_EQUAL = "less_than_or_equal",
  AND = "&&",
  OR = "||",
  EMPTY = "empty",
  NOT_EMPTY = "not_empty",
  CONTAINS = "contains",
  NOT_CONTAINS = "not_contains",
  STARTS_WITH = "starts_with",
  ENDS_WITH = "ends_with",
}

export enum DocumentRevisionChangeType {
  document_prosemirror_data_changed = "document_prosemirror_data_changed",
  party_created = "party_created",
  party_changed = "party_changed",
  party_deleted = "party_deleted",
  document_user_changed = "document_user_changed",
  document_user_created = "document_user_created",
  document_user_deleted = "document_user_deleted",
  dynamic_field_changed = "dynamic_field_changed",
  signature_created = "signature_created",
}

export enum DocumentWorkflowState {
  document_trashed = "document_trashed",
  document_archived = "document_archived",
  in_approved_stage = "in_approved_stage",
  in_signing_stage = "in_signing_stage",
  has_active_checkpoint_passes = "has_active_checkpoint_passes",
  has_active_working_copy = "has_active_working_copy",
  locked = "locked",
  no_document_user = "no_document_user",
  all_proposals_resolved = "all_proposals_resolved",
  can_start_signing = "can_start_signing",
  mdu_can_start_signing = "mdu_can_start_signing",
}

export enum NotificationType {
  COMMENT_MENTION = "comment_mention",
  COMMENT_PROPOSAL_REJECTED = "comment_proposal_rejected",
  COMMENT_CREATED = "comment_created",
  COMMENT_REPLIED = "comment_replied",
  DOCUMENT_INVITED = "document_invited",

  CHECKPOINT_APPROVAL_REQUIRED = "checkpoint_approval_required",
  PARTY_READY_FOR_SIGNING = "party_ready_for_signing",
  DOCUMENT_MOVED_TO_SIGNING_PHASE = "document_moved_to_signing_phase",
  DOCUMENT_SIGNING_PHASE_REVERTED = "document_signing_phase_reverted",
  DOCUMENT_FULLY_SIGNED = "document_fully_signed",
  DOCUMENT_RENEWAL_REMINDER = "document_renewal_reminder",
  DOCUMENT_EXPIRING_REMINDER = "document_expiring_reminder",
  DOCUMENT_EFFECTIVE_REMINDER = "document_effective_reminder",
  DOCUMENT_CANCELLATION_NOTICE_PERIOD_REMINDER = "document_cancellation_notice_period_reminder",
  DOCUMENT_EXPORT_COMPLETED = "document_export_completed",

  IMPORT_STATUS_AI_ANALYSES_REVIEW = "import_status_ai_analyses_review",

  SIGNATURE_REQUIRED = "signature_required",
  SIGNATURE_REQUIRED_REMINDER = "signature_required_reminder",
  DOCUMENT_INVITED_CC = "document_invited_cc",

  CUSTOM_TASK = "custom_task",
  CUSTOM_TASK_RESOLVED = "custom_task_resolved",
}

export enum UserNotificationChannel {
  database = "database",
  mail = "mail",
}

export enum SignatureSecurityLevel {
  AES = "aes",
  QES = "qes",
  SES = "ses",
  preemtive = "preemptive",
}

export enum MetadataValueStatus {
  MISSING_INFORMATION = "missing_information",
  CONFLICTING_INFORMATION = "conflicting_information",
  NOT_APPLICABLE = "not_applicable",
  COMPUTED = "computed",
}

export enum ContractDurationType {
  open_ended = "open_ended",
  fixed_term = "fixed_term",
  no_term = "no_term",
}

export enum DocumentType {
  contract = "contract",
  quote = "quote",
  form = "form",
  other = "other",
}

export enum TaskType {
  signatureRequired = "signature_required",
  commentMention = "comment_mention",
  checkpointApprovalRequired = "checkpoint_approval_required",
  custom = "custom",
}

export enum MainContentTab {
  documentContent = "document-content",
  summary = "summary",
  attachments = "attachments",
}

export enum ProposedChangeTab {
  all = "all",
  parties = "parties",
  dates = "dates",
  metadata = "metadata",
  clauses = "clauses",
}

export enum DynamicContentTab {
  dynamicFields = "dynamic_fields",
  conditions = "conditions",
}

export enum ActivityTab {
  activityLog = "activity_log",
  accessLog = "access_log",
}

export enum EditorContentTab {
  dynamicFields = 1,
  parties = 2,
  signatureBlocks = 3,
}

export enum DiscussionTab {
  open = 1,
  resolved = 2,
}

export enum SignatureTab {
  current = 1,
  archived = 2,
}

export enum CheckpointTab {
  active = 1,
  upcoming = 2,
  finished = 3,
}

export enum DocumentTab {
  signatures = "signatures",
  documentContent = "document-content",
  general = "general",
  dates = "dates",
  metadata = "metadata",
  dynamicContent = "dynamic-content",
  parties = "parties",
  discussions = "discussions",
  fileStorage = "file-storage",
  activity = "activity",
  notifications = "notifications",
  checkpoints = "checkpoints",
  linked = "linked",
}

export enum TemplateTab {
  documentContent = "document-content",
  general = "general",
  dates = "dates",
  metadata = "metadata",
  conditions = "conditions",
  sharing = "sharing",
  parties = "parties",
  checkpoints = "checkpoints",
  fileStorage = "file-storage",
  notifications = "notifications",
}

export enum DashboardTab {
  general = "general",
  widgets = "widgets",
  filter = "filter",
}

export enum UserRole {
  superadmin = 1,
  regular = 2,
}

export enum DocumentCalendarEntryType {
  expiration = "expiration_date",
  terminated = "terminated_date",
  renewal = "auto_renewal",
  notice = "cancellation_notice_date",
  effective = "effective_date",
  duration_start = "duration_start_date",
  duration_end = "duration_end_date"
}

export enum MetadataType {
  system_autofilled = "system_autofilled",
  system_computed = "system_computed",
  system = "system",
  reference = "system_reference",
  account = "account",
}

export enum DocumentRelationship {
  parent = "parent",
  amendment = "amendment",
  renewal = "renewal",
  addition = "addition",
  replacement = "replacement",
}

export enum DocumentRelationshipRole {
  controller = "controller",
  peripheral = "peripheral",
}
export enum MetadataValueSource {
  user = "user",
  computed = "computed",
  autofilled = "autofilled",
  dynamic_field = "dynamic_field",
}

export enum DurationTypeRelatedMetadata {
  duration_start_date = "duration_start_date",
  duration_end_date = "duration_end_date",
  renewal_duration = "renewal_duration",
  duration_term = "duration_term",
  cancellation_notice_period = "cancellation_notice_period",
  cancellation_notice_date = "cancellation_notice_date",
  expiration_date = "expiration_date",
  terminated_date = "terminated_date",
  effective_date = "effective_date",
}

export enum PartyEntityType {
  business = "business",
  person = "person",
  tbd = "tbd",
}

export enum CrudContext {
  document = "document",
  template = "template",
  proposal = "proposal",
}

export enum AccountUserInvitationStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined",
  expired = "expired",
}

export enum DashboardScope {
  personal = "account_user",
  account = "account",
  team = "team",
}

export enum AiAnalysisStatus {
  created = "created",
  pending = "pending",
  executing = "executing",
  review = "review",
  reviewed = "reviewed",
  applied = "applied",
  failed = "failed",
  expired = "expired",
}

export enum ChartType {
  bar = "bar",
  column = "column",
  pie = "pie",
  donut = "donut",
  line = "line",
  number = "number"
}

export enum WidgetNumberMetric {
  sum = "sum",
  average = "average",
  median = "median",
  highest = "highest",
  lowest = "lowest"
}

export enum SubscriptionType {
  unlimited = "unlimited-subscription",
  demo = "demo-subscription",
  stripe = "subscription",
  limited = "limited-subscription"
}

export enum CurrencyDurationOption {
  monthly = "monthly",
  yearly = "yearly",
}

export enum MetadataTab {
  all = "all",
  account = "account",
  reference = "reference",
  system = "system",
}

export enum DocumentNotificationType {
  event = "event",
  reminder = "reminder",
  recurring = "recurring"
}

export enum DashboardDefaultKey {
  personal = "personal",
  account = "account"
}

export enum AutomationStatus {
  READY_FOR_IMPORT = "ready_for_import",
  VALIDATED = "validated",
  PROCESSING = "processing",
  PROCESSED = "processed",
  FAILED = "failed"
}

export enum SignatureBlockStyle {
  regular = "regular",
  minimal = "minimal",
}

export enum ImportStatus {
  CREATED = "created",
  DOCUMENTS_CREATED = "documents_created",
  AI_ANALYSES_REVIEW = "ai_analyses_review",
  AI_ANALYSES_APPLIED = "ai_analyses_applied"
}

export enum JuneEvents {
  DOCUMENT_SAVED_AS_DOCX = "document_saved_as_docx",
  TABLE_KANBAN_USED = "table_kanban_used",
  EDITOR_PAGE_BREAK_INSERTED = "editor_page_break_inserted",
  EDITOR_TABLE_OF_CONTENTS_INSERTED = "editor_table_of_contents_inserted",
  EDITOR_TEXT_GENERATED_VIA_AI = "editor_text_generated_via_ai",
  EDITOR_SECTION_REF_INSERTED = "editor_section_ref_inserted",
  EDITOR_DYNAMIC_FIELD_INSERTED = "editor_dynamic_field_inserted",
  EDITOR_DYNAMIC_FIELD_DROPPED = "editor_dynamic_field_dropped",
  EDITOR_PARTY_INSERTED = "editor_party_inserted",
  EDITOR_PARTY_DROPPED = "editor_party_dropped",
  EDITOR_COLUMNS_INSERTED = "editor_columns_inserted",
  EDITOR_IMAGE_INSERTED = "editor_image_inserted",
  EDITOR_IMAGE_DROPPED = "editor_image_dropped",
  EDITOR_IMAGE_PASTED = "editor_image_pasted",
  EDITOR_SIGNATURE_BLOCK_DROPPED = "editor_signature_block_dropped",
  EDITOR_SIGNATURE_CONTAINER_INSERTED = "editor_signature_container_inserted",
  EDITOR_DOCX_IMPORTED_TEMPLATE = "editor_docx_imported_template",
  EDITOR_AI_TEXT_GENERATED = "editor_ai_text_generated",
  EDITOR_AI_TEXT_COMPLETED = "editor_ai_text_completed",
}

export enum CustomTaskType {
  REQUIRE_ONE = "require_one",
  REQUIRE_ALL = "require_all"
}

export enum TaskableType {
  DOCUMENT = "App\\Models\\Document",
  TEMPLATE = "App\\Models\\Template",
  IMPORT = "App\\Models\\Import",
  AUTOMATION = "App\\Models\\Automation",
}

export enum MagicTableAllState {
  PAGE = "page",
  ALL = "all",
}

export enum DocumentExportStatus {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum DocumentExportType {
  ARCHIVE = "archive",
  METADATA = "metadata",
}
