import { debounce } from "lodash-es"
import { defineStore } from "pinia"
import { reactive, toRefs, toRaw } from "vue"

import { Document, PdfBrick, PdfBrickPayload } from "~/types"
import {
  fetchAllPdfBricksAction,
  createPdfBrickAction,
  removePdfBrickAction,
  updatePdfBrickAction,
  getPdfBrickAction,
} from "./pdfBrickStoreActions"

interface Data {
  pdfBricks: PdfBrick[]
  isLoadingPdfBricks: boolean
  pdfBrickUuidBeingLoaded: PdfBrick["uuid"]
  uuidOfRemovingPdfBrick: PdfBrick["uuid"]
  debounceTimestamp: number
  debounceUuid: PdfBrick["uuid"]
}

export const usePdfBrickStore = defineStore(
  "pdfBrickStore",
  () => {
    const data = reactive<Data>(
      {
        pdfBricks: [],
        isLoadingPdfBricks: false,
        pdfBrickUuidBeingLoaded: null,
        uuidOfRemovingPdfBrick: null,
        debounceTimestamp: null,
        debounceUuid: null,
      },
    )

    // mutations
    const setPdfBricks = (pdfBricks: PdfBrick[]) => data.pdfBricks = pdfBricks

    const pushPdfBrick = (pdfBrick: PdfBrick) => data.pdfBricks = [ ...toRaw(data.pdfBricks || []), pdfBrick ]

    const pushOrUpdateLocalPdfBrick = (pdfBrick: PdfBrick) => {
      if (!pdfBrick) return

      const pdfBrickIndex = data.pdfBricks.findIndex((pdfBrickItem) => pdfBrickItem.uuid === pdfBrick.uuid)

      if (pdfBrickIndex === -1) {
        if (!pdfBrick.uuid) throw new Error("pdfBrick uuid is required")
        pushPdfBrick(pdfBrick)
      } else {
        const pdfBricksCopy = [ ...toRaw(data.pdfBricks || []) ]

        pdfBricksCopy[pdfBrickIndex] = {
          ...(pdfBricksCopy[pdfBrickIndex] || {}),
          ...pdfBrick,
        }

        data.pdfBricks = pdfBricksCopy
      }
    }

    const removeLocalPdfBrick = (pdfBrickUuid: PdfBrick["uuid"]) => {
      if (!pdfBrickUuid) return

      const pdfBricksCopy = [ ...toRaw(data.pdfBricks || []) ]

      const indexOfPdfBrick = pdfBricksCopy.findIndex((pdfBrick) => pdfBrick.uuid === pdfBrickUuid)

      pdfBricksCopy.splice(indexOfPdfBrick, 1)

      data.pdfBricks = pdfBricksCopy
    }

    // api actions
    const fetchAllPdfBricks = async (documentUuid: Document["uuid"]): Promise<PdfBrick[] | void> => {
      if (!documentUuid) {
        console.error("invalid documentUuid: ", documentUuid)
        return
      }

      data.isLoadingPdfBricks = true

      try {
        const pdfBricks = await fetchAllPdfBricksAction(documentUuid) || []

        const pdfBricksEnrichedWithDocumentUuid = pdfBricks.map((pdfBrick) => ({
          ...pdfBrick,
          document_uuid: documentUuid,
        }))

        setPdfBricks(pdfBricksEnrichedWithDocumentUuid)

        return pdfBricks
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingPdfBricks = false
      }
    }

    const getPdfBrick = async (documentUuid: Document["uuid"], pdfBrickUuid: PdfBrick["uuid"]): Promise<PdfBrick | void> => {
      try {
        data.pdfBrickUuidBeingLoaded = pdfBrickUuid
        const pdfBrick = await getPdfBrickAction(documentUuid, pdfBrickUuid)
        if (pdfBrick) {
          pushOrUpdateLocalPdfBrick({ ...pdfBrick, document_uuid: documentUuid })
        }
        return pdfBrick
      } catch (err) {
        console.error(err)
      } finally {
        data.pdfBrickUuidBeingLoaded = null
      }
    }

    // pdfBrick CRUD
    const createPdfBrick = async (
      documentUuid: Document["uuid"],
      payload: Partial<PdfBrickPayload>,
    ): Promise<PdfBrick> => {
      const createdPdfBrick = await createPdfBrickAction(documentUuid, payload)

      if (createdPdfBrick) pushPdfBrick({ ...createdPdfBrick, document_uuid: documentUuid })

      return createdPdfBrick
    }

    const updatePdfBrick = async (
      documentUuid: Document["uuid"],
      pdfBrick: Partial<PdfBrick>,
      timestamp: number,
    ): Promise<PdfBrick> => {
      if (!pdfBrick?.uuid) {
        console.error("invalid pdfBrickUuid: ", { pdfBrick })
        return
      }

      const updatedPdfBrick = await updatePdfBrickAction(documentUuid, pdfBrick)

      if (updatedPdfBrick && !(data.debounceTimestamp > timestamp)) {
        pushOrUpdateLocalPdfBrick({ ...updatedPdfBrick, document_uuid: documentUuid })
      }

      return updatedPdfBrick
    }

    const debouncedUpdatePdfBrick = debounce(
      updatePdfBrick,
      500,
      {
        leading: false,
        trailing: true,
      },
    )

    const removePdfBrick = async (
      documentUuid: Document["uuid"],
      pdfBrickUuid: PdfBrick["uuid"],
    ): Promise<void> => {
      if (!pdfBrickUuid) {
        console.error("invalid pdfBrickUuid: ", pdfBrickUuid)
        return
      }

      try {
        data.uuidOfRemovingPdfBrick = pdfBrickUuid
        const removeRes = await removePdfBrickAction(documentUuid, pdfBrickUuid)

        if (removeRes === 200) removeLocalPdfBrick(pdfBrickUuid)
      } catch (err) {
        console.error(err)
      } finally {
        data.uuidOfRemovingPdfBrick = null
      }
    }

    // ui actions
    const updateLocalPdfBrick = (
      documentUuid: Document["uuid"],
      pdfBrick: PdfBrick,
    ) => {
      if (!pdfBrick?.uuid) {
        console.error("invalid pdfBrick: ", { pdfBrick })
        return
      }

      pushOrUpdateLocalPdfBrick({ ...pdfBrick, document_uuid: documentUuid })

      const timestamp = Date.now()
      if (data.debounceUuid !== pdfBrick.uuid) {
        debouncedUpdatePdfBrick?.flush()
        data.debounceUuid = pdfBrick.uuid
      }
      data.debounceTimestamp = timestamp

      debouncedUpdatePdfBrick(documentUuid, pdfBrick, timestamp)
    }

    return {
      ...toRefs(data),

      // mutations
      setPdfBricks,
      pushPdfBrick,
      pushOrUpdateLocalPdfBrick,
      removeLocalPdfBrick,

      // api actions
      fetchAllPdfBricks,
      getPdfBrick,

      // pdfBrick CRUD
      createPdfBrick,
      updatePdfBrick,
      removePdfBrick,

      // ui actions
      updateLocalPdfBrick,
    }
  },
)
