<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
  ><path
    fill="currentColor"
    d="M216 56v58.7c0 84-71.3 111.8-85.5 116.5a7.2 7.2 0 0 1-5 0C111.3 226.5 40 198.7 40 114.7V56a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8Z"
    opacity=".2"
  /><path
    fill="currentColor"
    d="M208 40H48a16 16 0 0 0-16 16v58.7c0 89.4 75.8 119.1 91 124.1a16 16 0 0 0 10 0c15.2-5 91-34.7 91-124.1V56a16 16 0 0 0-16-16Zm0 74.7c0 78.2-66.4 104.4-80 108.9c-13.5-4.5-80-30.6-80-108.9V56h160ZM88 128a8 8 0 0 1 8-8h24V96a8 8 0 0 1 16 0v24h24a8 8 0 0 1 0 16h-24v24a8 8 0 0 1-16 0v-24H96a8 8 0 0 1-8-8Z"
  /></svg>
</template>