import { defineStore } from "pinia"
import { reactive, toRefs, toRaw, computed } from "vue"
import { storeToRefs } from "pinia"
import { Instance } from "tippy.js"

import { useTippies } from "~/composables"
import { useDocumentStore } from "../documentStore"
import { useUserStore } from "../userStore"
import { ActiveUser, AiAnalysis, Comment, CrudContext, Document, DocumentEditorSession, DocumentTab, DocumentUser, DynamicField, ExtendedTippyProps, MetadataValue, Party, PdfBrick, SigningPhase, WorkingCopy } from "~/types"
import { useMetadataStore } from "../metadataStore"
import { useDynamicFieldStore } from "../dynamicFieldStore"
import { useCheckpointStore } from "../checkpointStore"
import { useConditionStore } from "../conditionStore"
import { useAiStore } from "../aiStore"
import { useSignatureStore } from "../signatureStore"
import { usePartyStore } from "../partyStore"
import { usePdfBrickStore } from "../pdfBrickStore"
import { useCommentStore } from "../commentStore"
import { showLoadingIndicator } from "~/utils"
import { useFileStorageStore } from "../fileStorageStore"
import { useAttachmentStore } from "../attachmentStore"

interface Data {
  pusherFocusCollection: any[]
  pusherFocusTippyInstances: Instance<ExtendedTippyProps>[]
  presenceChannel: any
  privateChannel: any
  pusherConnectionError: boolean
  pusherChannelError: boolean
  pusherConnectionStatus: string
  activeUsers: ActiveUser[]
}
export const usePusherStore = defineStore(
  "pusherStore",
  () => {
    const data = reactive<Data>(
      {
        pusherFocusCollection: [],
        pusherFocusTippyInstances: [],
        presenceChannel: null,
        privateChannel: null,
        pusherConnectionError: false,
        pusherChannelError: false,
        pusherConnectionStatus: "connected",
        activeUsers: [],
      },
    )

    const documentStore = useDocumentStore()
    const { mdu, mau, currentDocument, activeTabKey } = storeToRefs(documentStore)

    const dynamicFieldStore = useDynamicFieldStore()
    const metadataStore = useMetadataStore()
    const checkpointStore = useCheckpointStore()
    const conditionStore = useConditionStore()
    const aiStore = useAiStore()
    const signatureStore = useSignatureStore()
    const partyStore = usePartyStore()
    const pdfBrickStore = usePdfBrickStore()
    const commentStore = useCommentStore()
    const fileStorageStore = useFileStorageStore()
    const attachmentStore = useAttachmentStore()

    const userStore = useUserStore()
    const { users } = storeToRefs(userStore)

    const pusherChannelsConnected = computed<boolean>(() => !!((!mdu.value || data.presenceChannel) && (!mau.value || data.privateChannel)))
    const pusherError = computed<boolean>(() => data.pusherConnectionError || data.pusherChannelError || !pusherChannelsConnected.value)

    // Set up pusher channels for document
    const setUpPusherChannelsForDocument = () => {

      setPresenceChannel(Echo.join(`documents.${currentDocument.value.uuid}`)
        .here(
          (members: ActiveUser[]) => setActiveUsers(members),
        )
        .joining(
          (member: ActiveUser) => pushActiveUser(member),
        )
        .leaving(
          (member: ActiveUser) => removeActiveUser(member),
        )
        .listen(
          "Pusher\\Document\\DocumentIsLockedChanged",
          () => documentStore.getDocumentLockState(currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\DynamicFieldCreated",
          (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),
        )
        .listen(
          "Pusher\\Document\\DynamicFieldUpdated",
          (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),
        )
        .listen(
          "Pusher\\Document\\ConditionChanged",
          () => conditionStore.fetchConditions(CrudContext.document, currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\DynamicFieldChanged",
          (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),
        )
        .listen(
          "Pusher\\Document\\DocumentStageChanged",
          documentStore.refreshStage,
        )
        .listen(
          "Pusher\\Document\\SigningPhaseCreated",
          (signingPhase: SigningPhase) => signatureStore.setActiveSigningPhase(signingPhase),
        )
        .listen(
          "Pusher\\Document\\DocumentUserCreated",
          (documentUser: { uuid: DocumentUser["uuid"] }) => userStore.getDocumentUser(currentDocument.value?.uuid, documentUser?.uuid),
        )
        .listen(
          "Pusher\\Document\\DocumentUserUpdated",
          (documentUser: { uuid: DocumentUser["uuid"] }) => {
            userStore.setDocumentUserLastUpdatedViaPusher(documentUser?.uuid)
            userStore.getDocumentUser(currentDocument.value?.uuid, documentUser?.uuid)
          },
        )
        .listen(
          "Pusher\\Document\\UserNotificationChanged",
          () => userStore.fetchAllUserNotifications(currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\PartyCreated",
          (party: { uuid: Party["uuid"] }) => partyStore.getParty(currentDocument.value?.uuid, party?.uuid),
        )
        .listen(
          "Pusher\\Document\\PartyUpdated",
          (party: { uuid: Party["uuid"] }) => partyStore.getParty(currentDocument.value?.uuid, party?.uuid),
        )
        .listen(
          "Pusher\\Document\\CommentCreated",
          (comment: { uuid: Comment["uuid"] }) => commentStore.getComment(currentDocument.value?.uuid, comment?.uuid),
        )
        .listen(
          "Pusher\\Document\\CommentUpdated",
          (comment: { uuid: Comment["uuid"] }) => commentStore.getComment(currentDocument.value?.uuid, comment?.uuid),
        )
        .listen(
          "Pusher\\Document\\PdfBrickCreated",
          (pdfBrick: { uuid: PdfBrick["uuid"] }) => pdfBrickStore.getPdfBrick(currentDocument.value?.uuid, pdfBrick?.uuid),
        )
        .listen(
          "Pusher\\Document\\PdfBrickUpdated",
          (pdfBrick: { uuid: PdfBrick["uuid"] }) => pdfBrickStore.getPdfBrick(currentDocument.value?.uuid, pdfBrick?.uuid),
        )
        .listen(
          "Pusher\\Document\\SignatureCreated",
          async () => {
            await signatureStore.fetchAllSignatures(currentDocument.value?.uuid)
            let res: string | void = null
            if (currentDocument.value?.content_type === "html") {
              res = await documentStore.fetchHtmlContent(currentDocument.value?.uuid)
            } else if (currentDocument.value?.content_type === "pdf") {
              res = await documentStore.fetchPdfUrl(currentDocument.value?.uuid)
            }
            if (res) signatureStore.bindEventsOnSignatureBlocks()
          },
        )
        .listen(
          "Pusher\\Document\\SignatureBlockCreated",
          () => signatureStore.fetchAllSignatureBlocks(CrudContext.document, currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\ProsemirrorDataUpdated",
          () => documentStore.fetchEditorContent(currentDocument.value?.uuid, true),
        )
        .listen(
          "Pusher\\Document\\DocumentUpdated",
          (document: { uuid: Document["uuid"] }) => documentStore.fetchDocument(document?.uuid),
        )
        .listen(
          "Pusher\\Document\\DocumentActivitiesChanged",
          () => {
            if (activeTabKey.value === DocumentTab.activity || activeTabKey.value === DocumentTab.signatures) {
              documentStore.fetchActivities(currentDocument.value?.uuid)
              documentStore.fetchRevisions(currentDocument.value?.uuid)
            }
          },
        )
        .listen(
          "Pusher\\Document\\StoredFileCreated",
          () => fileStorageStore.fetchStoredFiles(CrudContext.document, currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\StoredFileChanged",
          () => fileStorageStore.fetchStoredFiles(CrudContext.document, currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\AttachmentCreated",
          () => attachmentStore.fetchAttachments(CrudContext.document, currentDocument.value?.uuid),
        )
        .listen(
          "Pusher\\Document\\AttachmentChanged",
          () => attachmentStore.fetchAttachments(CrudContext.document, currentDocument.value?.uuid),

        )
        .listenForWhisper(
          "focus",
          (data) => handlePusherFocus(data),
        )
        .listenForWhisper(
          "blur",
          (data) => handlePusherBlur(data),
        )
        .error(
          (err) => {
            data.pusherChannelError = true
            console.error(err)
          },
        ))

      if (!!mau.value) {

        setPrivateChannel(Echo.private(`documents-internal.${currentDocument.value.uuid}`)
          .listen(
            "Pusher\\Document\\DocumentEditorSessionUpdated",
            (documentEditorSession: { uuid: DocumentEditorSession["uuid"] }) => {
              if (!currentDocument.value) return
              documentStore.getDocumentEditorSession(currentDocument.value?.uuid, documentEditorSession?.uuid)
            },
          )
          .listen(
            "Pusher\\Document\\DocumentActivitiesChanged",
            () => {
              if (activeTabKey.value === DocumentTab.activity || activeTabKey.value === DocumentTab.signatures) {
                documentStore.fetchActivities(currentDocument.value?.uuid)
                documentStore.fetchRevisions(currentDocument.value?.uuid)
              }
            },
          )
          .listen(
            "Pusher\\Document\\ProsemirrorDataUpdated",
            () => documentStore.fetchEditorContent(currentDocument.value?.uuid, true),
          )
          .listen(
            "Pusher\\Document\\DocumentUpdated",
            (document: { uuid: Document["uuid"] }) => documentStore.fetchDocument(document?.uuid),
          )
          .listen(
            "Pusher\\Document\\DocumentCheckpointPassesChanged",
            () => checkpointStore.fetchCheckpointPasses(currentDocument.value?.uuid),
          )
          .listen(
            "Pusher\\Document\\DynamicFieldCreated",
            (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),

          )
          .listen(
            "Pusher\\Document\\DynamicFieldUpdated",
            (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),
          )
          .listen(
            "Pusher\\Document\\ConditionChanged",
            () => conditionStore.fetchConditions(CrudContext.document, currentDocument.value?.uuid),
          )
          .listen(
            "Pusher\\Document\\DynamicFieldChanged",
            (dynamicField: { uuid: DynamicField["uuid"] }) => dynamicFieldStore.getDynamicField(CrudContext.document, currentDocument.value?.uuid, dynamicField?.uuid),
          )
          .listen(
            "Pusher\\Document\\MetadataValueCreated",
            (metadataValue: { uuid: MetadataValue["uuid"] }) => metadataStore.getMetadataValue(CrudContext.document, currentDocument.value?.uuid, metadataValue?.uuid),
          )
          .listen(
            "Pusher\\Document\\MetadataValuesChanged",
            () => metadataStore.fetchMetadataValues(CrudContext.document, currentDocument.value?.uuid),
          )
          .listen(
            "Pusher\\Document\\MetadataValueDeleted",
            (metadataValue: { uuid: MetadataValue["uuid"] }) => metadataStore.removeMetadataValueFromStore(metadataValue.uuid),
          )
          .listen(
            "Pusher\\Document\\WorkingCopyCreated",
            (workingCopy: { uuid: WorkingCopy["uuid"] }) => documentStore.getWorkingCopy(currentDocument.value?.uuid, workingCopy?.uuid),
          )
          .listen(
            "Pusher\\Document\\WorkingCopyPublished",
            () => documentStore.setWorkingCopy(null),
          )
          .listen(
            "Pusher\\Document\\AiAnalysisStarted",
            (aiAnalysis: { uuid: AiAnalysis["uuid"] }) => {
              aiStore.getAiAnalysis(currentDocument.value?.uuid, aiAnalysis?.uuid)
            },
          )
          .listen(
            "Pusher\\Document\\AiAnalysisStatusChanged",
            (aiAnalysis: { uuid: AiAnalysis["uuid"] }) => {
              aiStore.getAiAnalysis(currentDocument.value?.uuid, aiAnalysis?.uuid)
            },
          )
          .error(
            (err) => {
              data.pusherChannelError = true
              console.error(err)
            },
          ))

      }
      // Add a listener for connection state changes
      Echo.connector.pusher.connection.bind("state_change", (states) => {
        const currentConnectionState = states.current
        if (currentConnectionState === "connected") {
          data.pusherConnectionStatus = "connected"
          data.pusherConnectionError = false
        } else {
          data.pusherConnectionStatus = "reconnecting"
          showLoadingIndicator("pusherConnectionStatusLoader")
          data.pusherConnectionError = true
        }
      })
    }

    // Function to push form field focus to pusher
    const whisperFocus = (e) => {
      if (!mdu.value || !data.presenceChannel || !e.target) return
      // Ignore if the element cannot be found
      const element = document.getElementById(e.target.id)
      if (!element) return
      data.presenceChannel.whisper(
        "focus",
        {
          uuid: e.target.id,
          documentUser: mdu.value?.uuid,
        },
      )
    }
    const whisperBlur = (e) => {
      if (!mdu.value || !data.presenceChannel || !e.target) return
      // Ignore if the element cannot be found
      const element = document.getElementById(e.target.id)
      if (!element) return
      data.presenceChannel.whisper(
        "blur",
        {
          uuid: e.target.id,
          documentUser: mdu.value?.uuid,
        },
      )
    }

    const checkIfDisabledByPusher = (id) => {
      return data.pusherFocusCollection.some((el) => el.id === id)
    }

    const {
      setUpPusherFocusTippy,
    } = useTippies(
      CrudContext.document,
      checkIfDisabledByPusher,
      whisperFocus,
      whisperBlur,
    )

    // mutations
    const setPusherFocusCollection = (collection: any[]) => data.pusherFocusCollection = collection
    const setFocusTippyInstances = (instances: any[]) => data.pusherFocusTippyInstances = instances
    const setPrivateChannel = (channel: any) => data.privateChannel = channel
    const setPresenceChannel = (channel: any) => data.presenceChannel = channel
    const setActiveUsers = (members: ActiveUser[]) => data.activeUsers = members
    const pushActiveUser = (member: ActiveUser) => data.activeUsers.push(member)
    const removeActiveUser = (member: ActiveUser) => {
      const activeUsersCopy = [ ...toRaw(data.activeUsers) ]
      data.activeUsers = activeUsersCopy.filter((el: ActiveUser) => el.account_user?.uuid !== member.account_user?.uuid && el.document_user_uuid !== member.document_user_uuid)
    }

    // ui actions

    const handlePusherFocus = (item: any) => {
      const documentUser = users.value.find((el) => el.uuid === item.documentUser)
      if (!documentUser) {
        console.warn("Document user with uuid " + item.documentUser + " not found")
        return
      }
      const pusherFocusCollectionCopy = [ ...toRaw(data.pusherFocusCollection), item ]
      setPusherFocusCollection(pusherFocusCollectionCopy)

      const newTippyInstances = setUpPusherFocusTippy(item, documentUser)
      const pusherFocusTippyInstancesCopy = [
        ...(Array.isArray(toRaw(data.pusherFocusTippyInstances)) ? toRaw(data.pusherFocusTippyInstances) : []),
        ...(Array.isArray(newTippyInstances) ? newTippyInstances : []),
      ]
      setFocusTippyInstances(pusherFocusTippyInstancesCopy)

    }

    const handlePusherBlur = (item: any) => {
      const pusherFocusCollectionCopy = [ ...toRaw(data.pusherFocusCollection) ]
      const filteredPusherFocusCollection = pusherFocusCollectionCopy.filter(
        (el) => el.id !== item.id,
      )
      setPusherFocusCollection(filteredPusherFocusCollection)

      // Filter instances for documentUser and destroy related tooltips
      // Destroy all tippies that are no longer used to indicate a focus
      const instances = data.pusherFocusTippyInstances.filter(
        (el) => el.props?.documentUser === item.documentUser,
      )
      instances?.length > 0 ? instances.forEach((el) => el.destroy()) : null
      // Clean up the instance array by filtering only for focus indicators that are still active
      const pusherFocusTippyInstancesCopy = [ ...toRaw(data.pusherFocusTippyInstances) ]
      const filteredFocusTippyInstancesCopy = pusherFocusTippyInstancesCopy.filter(
        (el) => el.props?.documentUser !== item.documentUser,
      )
      setFocusTippyInstances(filteredFocusTippyInstancesCopy)

    }

    return {
      ...toRefs(data),
      pusherError,

      // channel actions
      setUpPusherChannelsForDocument,

      // mutations
      setPusherFocusCollection,
      setFocusTippyInstances,
      setPrivateChannel,
      setPresenceChannel,

      // ui actions
      checkIfDisabledByPusher,
      handlePusherFocus,
      handlePusherBlur,
      whisperFocus,
      whisperBlur,
    }
  },
)
