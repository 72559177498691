import axios from "axios"

import { Document, DocumentUser, Template, CrudContext, AccountUser, UserNotification, NotificationType } from "~/types"

export const fetchAllUsersAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
) => {
  const routeString = `api.${context}s.document-users.index`

  const url = route(routeString, entityUuid)

  try {
    const fetchUsersRes = await axios.get<{ data: DocumentUser[] }>(url)

    return fetchUsersRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllAccountUsersAction = async () => {
  const routeString = `api.account-users.index`
  const url = route(routeString)

  try {
    const fetchAllAccountUsersRes = await axios.get<{ data: AccountUser[] }>(url)

    return fetchAllAccountUsersRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllUserNotificationsAction = async (
  documentUuid: Document["uuid"],
) => {
  const routeString = `api.documents.user-notifications.index`
  const url = route(routeString, documentUuid)

  try {
    const fetchAllUserNotificationsRes = await axios.get<{ data: UserNotification[] }>(url)

    return fetchAllUserNotificationsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getDocumentUserAction = async (
  documentUuid: Document["uuid"],
  documentUserUuid: DocumentUser["uuid"],
): Promise<DocumentUser | void> => {
  const routeString = `api.documents.document-users.show`
  const url = route(routeString, {
    document: documentUuid,
    document_user: documentUserUuid,
  })

  try {
    const getDocumentUserRes = await axios.get<{ data: DocumentUser }>(url)

    return getDocumentUserRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// user CRUD
export const createUserAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  userToCreate: Partial<DocumentUser>,
): Promise<DocumentUser | void> => {
  const apiUrl = route(`api.${context}s.document-users.store`, entityUuid)

  const createUserRes = await axios.post<{ data: DocumentUser }>(apiUrl, userToCreate)

  return createUserRes.data.data
}

// export const fetchUserAction = async (
//   context: CrudContext,
//   entityUuid: Document["uuid"] | Template["uuid"],
//   userUuid: DocumentUser["uuid"],
// ): Promise<DocumentUser | void> => {
//   try {

//   } catch (err) {

//   }
// }

export const updateUserAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  user: Partial<DocumentUser>,
  documentUserUuid: DocumentUser["uuid"],
): Promise<DocumentUser | void> => {
  const apiUrl = `api.${context}s.document-users.update`

  const routeData = {
    document_user: documentUserUuid,
    [context]: entityUuid,
  }

  const url = route(apiUrl, routeData)

  const updateUserRes = await axios.patch<{data: DocumentUser}>(url, user)

  return updateUserRes.data.data
}

export const removeUserFromEntityAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  userUuidToRemove: DocumentUser["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.document-users.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, document_user: userUuidToRemove }
    : { template: entityUuid, document_user: userUuidToRemove }

  const removeUserRes = await axios.delete<{ data: DocumentUser }>(route(apiUrl, payload))

  return removeUserRes.status
}

export const createUserInvitationAction = async (
  documentUuid: Document["uuid"],
  userUuid: DocumentUser["uuid"],
  message: string,
) => {
  const apiUrl = route("api.documents.document-users.user-notifications.store", {
    document: documentUuid,
    document_user: userUuid,
  })
  try {
    const sendInvitationRes = await axios.post<{ data: UserNotification }>(apiUrl, { message: message, notification_type: NotificationType.DOCUMENT_INVITED })
    return sendInvitationRes.data.data
  } catch (err) {
    console.error(err)
  }
}

