<template>
  <svg
    id="Ebene_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 187.5 27.9"
    enable-background="new 0 0 187.5 27.9"
    xml:space="preserve"
  >
    <g id="V3_x5F_24_x5F_2-_x2014_-tom">
      <g
        id="Group"
        transform="translate(100.000000, 144.000027)"
      >
        <polygon
          id="Fill-1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          points="-26.1,-122.1 -23.1,-122.1 -23.1,-139.3
			-26.1,-139.3 		"
        />
        <path
          id="Fill-2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M-10.8-125.4c0.7-0.3,1.2-0.7,1.7-1.3
			c0.5-0.5,0.8-1.1,1.1-1.8c0.3-0.7,0.4-1.4,0.4-2.2c0-0.8-0.1-1.5-0.4-2.2c-0.3-0.7-0.6-1.3-1.1-1.8c-0.5-0.5-1-0.9-1.7-1.3
			c-0.7-0.3-1.4-0.5-2.3-0.5h-2.6v11.6h2.6C-12.2-124.9-11.5-125.1-10.8-125.4 M-9.7-138.6c1,0.4,1.9,1,2.7,1.8
			c0.7,0.8,1.3,1.7,1.7,2.7c0.4,1,0.6,2.2,0.6,3.4c0,1.2-0.2,2.4-0.6,3.4c-0.4,1-1,2-1.7,2.7c-0.7,0.8-1.6,1.4-2.7,1.8
			c-1,0.4-2.2,0.6-3.4,0.6h-5.5v-17.2h5.5C-11.9-139.3-10.7-139.1-9.7-138.6"
        />
        <path
          id="Fill-4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M15.3-128.9l-2.6-6.7l-2.6,6.7H15.3z M7.5-122.1h-3
			l6.7-17.2h3l6.7,17.2h-3l-1.6-4.1H9.1L7.5-122.1z"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="23"
            y="-133.7"
            width="10.5"
            height="11.9"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>

        <mask
          id="mask-2_00000183237617370793128980000007566645842623826589_"
          maskUnits="userSpaceOnUse"
          x="23"
          y="-133.7"
          width="10.5"
          height="11.9"
        >
          <g filter="url(#Adobe_OpacityMaskFilter)">

            <polygon
              id="path-1_00000041293236947131384590000012793349933957735842_"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              points="
					23,-133.7 33.5,-133.7 33.5,-121.8 23,-121.8 				"
            />
          </g>
        </mask>

        <path
          id="Fill-6"
          mask="url(#mask-2_00000183237617370793128980000007566645842623826589_)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="
			M25.6-122.1c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.8-0.9-1.4c-0.2-0.5-0.4-1.2-0.4-1.9v-7.5h2.9v6.7c0,0.9,0.2,1.5,0.6,1.9
			c0.4,0.4,1,0.6,1.7,0.6c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.4-0.5,0.6-0.7v-7.8h2.9v11.6h-2.7v-1.7
			c-0.2,0.4-0.5,0.7-0.9,1c-0.3,0.3-0.6,0.5-1.1,0.7c-0.4,0.2-1,0.3-1.6,0.3C26.7-121.8,26.1-121.9,25.6-122.1"
        />
        <path
          id="Fill-9"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M37.8-126.1c0.3,0.3,0.7,0.6,1,0.8
			c0.3,0.2,0.7,0.4,1.1,0.6c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.6-0.3c0.4-0.2,0.7-0.4,0.7-0.8c0-0.4-0.2-0.6-0.5-0.8
			c-0.3-0.1-0.9-0.3-1.8-0.5c-0.6-0.1-1.1-0.3-1.6-0.5c-0.5-0.2-1-0.4-1.4-0.7c-0.4-0.3-0.7-0.6-0.9-1c-0.2-0.4-0.4-0.9-0.4-1.4
			c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.8,1-1.2c0.4-0.3,0.9-0.6,1.6-0.8c0.6-0.2,1.3-0.3,2.1-0.3c0.7,0,1.3,0.1,1.8,0.2
			c0.5,0.1,1,0.3,1.4,0.5c0.5,0.2,0.9,0.5,1.2,0.7l-1.5,2.2c-0.3-0.2-0.5-0.4-0.9-0.5c-0.3-0.1-0.6-0.2-0.9-0.3
			c-0.4-0.1-0.7-0.1-1.1-0.1c-0.6,0-1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.6,0.8c0,0.4,0.2,0.6,0.6,0.8c0.4,0.1,1.1,0.3,2.1,0.5
			c0.6,0.1,1.1,0.3,1.6,0.4c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5c0,0.5-0.1,1-0.4,1.4
			c-0.3,0.4-0.6,0.8-1.1,1.1c-0.5,0.3-1,0.6-1.6,0.8c-0.6,0.2-1.3,0.3-2,0.3c-0.9,0-1.6-0.1-2.3-0.3c-0.7-0.2-1.2-0.5-1.6-0.7
			c-0.5-0.3-1-0.7-1.3-1.1L37.8-126.1z"
        />
        <path
          id="Fill-11"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M48.3-133.7h2.3v-3.5h2.9v3.5h3.5v2.7h-3.5v4.7
			c0,0.7,0.2,1.2,0.5,1.4c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.3-0.1,0.4-0.2l1.1,2.4
			c-0.2,0.1-0.5,0.3-0.8,0.4c-0.2,0.1-0.5,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1.2,0.1c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.3-0.8
			c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.5-0.3-1.1-0.3-1.7v-5.2h-2.3V-133.7z"
        />
        <path
          id="Fill-13"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M66.7-134c0.2,0,0.4,0.1,0.6,0.1
			c0.2,0.1,0.4,0.1,0.6,0.2l-1,2.7c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0.1-1.2,0.2
			c-0.3,0.2-0.6,0.3-0.8,0.5c-0.3,0.2-0.5,0.5-0.6,0.8v7.6h-2.9v-11.6h2.8v1.7c0.2-0.4,0.5-0.7,0.8-1c0.3-0.2,0.6-0.5,1-0.7
			c0.4-0.2,0.9-0.3,1.4-0.3C66.2-134,66.4-134,66.7-134"
        />
        <path
          id="Fill-15"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M70.4-122.1h2.9v-11.6h-2.9V-122.1z M70.5-138.9
			c0.4-0.4,0.8-0.6,1.4-0.6c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6
			c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.4C69.9-138,70.1-138.5,70.5-138.9L70.5-138.9z"
        />
        <path
          id="Fill-17"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#466272"
          d="M83-124.5c0.3-0.1,0.6-0.3,0.8-0.5
			c0.3-0.2,0.5-0.4,0.7-0.7v-1.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.7-0.1-1.1-0.1c-0.2,0-0.5,0-0.7,0.1
			c-0.3,0.1-0.5,0.1-0.7,0.3s-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.6,0.2,0.7c0.1,0.2,0.3,0.3,0.5,0.5
			c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0,0.5,0.1,0.7,0.1C82.3-124.3,82.6-124.3,83-124.5 M86.1-132.9c0.9,0.8,1.4,1.8,1.4,3.2v7.6h-2.7
			v-1.5c-0.2,0.3-0.6,0.6-0.9,0.9c-0.3,0.2-0.7,0.4-1.2,0.6s-1,0.3-1.7,0.3c-0.5,0-1.1-0.1-1.6-0.3c-0.5-0.2-1-0.4-1.3-0.8
			c-0.4-0.3-0.7-0.7-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.7c0-0.6,0.1-1.1,0.4-1.5c0.2-0.5,0.6-0.9,1-1.2c0.4-0.3,0.9-0.6,1.4-0.8
			c0.5-0.2,1.1-0.3,1.8-0.3c0.5,0,1,0,1.4,0.1c0.4,0.1,0.7,0.2,1,0.3c0.3,0.1,0.6,0.3,0.8,0.4v-0.7c0-0.4-0.1-0.7-0.2-1
			c-0.2-0.3-0.4-0.5-0.6-0.6c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.6-0.1-0.8-0.1c-0.4,0-0.8,0-1.3,0.1c-0.4,0.1-0.8,0.2-1.1,0.3
			c-0.4,0.1-0.8,0.3-1.1,0.4l-1-2.4c0.4-0.2,0.9-0.4,1.4-0.5c0.4-0.1,0.9-0.2,1.5-0.4c0.6-0.1,1.1-0.2,1.7-0.2
			C83.8-134,85.1-133.6,86.1-132.9"
        />
        <defs>

          <filter
            id="Adobe_OpacityMaskFilter_00000078752102836481323660000005387810808705720978_"
            filterUnits="userSpaceOnUse"
            x="-75.4"
            y="-144"
            width="37"
            height="27.9"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>

        <mask
          id="mask-6_00000103987012181254237510000002196536823157361072_"
          maskUnits="userSpaceOnUse"
          x="-75.4"
          y="-144"
          width="37"
          height="27.9"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000078752102836481323660000005387810808705720978_)">

            <path
              id="path-5_00000086660922232852376480000011685599087510461840_"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="
					M-61.9-144c-0.5,0-0.9,0.1-1.3,0.3l0,0c-0.4,0.2-0.8,0.5-1,0.9l0,0l-2.7,4.6h-8.5v3.9h9.2c0.5,0,0.9-0.1,1.3-0.3l0,0
					c0.4-0.2,0.8-0.5,1-0.9l0,0l2.7-4.6h15.7c0.9,0,1.7,0.3,2.3,0.9l0,0c0.6,0.6,0.9,1.4,0.9,2.3l0,0v13.6c0,0.9-0.3,1.7-0.9,2.3
					l0,0c-0.6,0.6-1.4,0.9-2.3,0.9l0,0h-15.6l-4.7-8.2c-0.2-0.4-0.6-0.7-1-0.9l0,0c-0.4-0.2-0.9-0.3-1.3-0.3l0,0h-7.2v3.9h6.5
					l4.6,8.1c0.2,0.4,0.6,0.7,1,1l0,0c0.4,0.3,0.9,0.4,1.3,0.4l0,0h17.1c1.8,0,3.4-0.6,4.6-1.8l0,0c1.2-1.2,1.8-2.8,1.8-4.6l0,0v-15
					c0-1.8-0.6-3.4-1.8-4.6l0,0c-1.2-1.2-2.8-1.8-4.6-1.8l0,0H-61.9z M-52.3-134.8c-0.6,0.6-0.9,1.4-0.9,2.2l0,0
					c0,0.9,0.3,1.6,1,2.3l0,0c0.7,0.7,1.4,1,2.3,1l0,0c0.9,0,1.6-0.3,2.2-0.9l0,0c0.6-0.6,0.9-1.4,0.9-2.3l0,0
					c0-0.9-0.3-1.7-0.9-2.3l0,0c-0.6-0.6-1.4-0.9-2.3-0.9l0,0C-50.9-135.8-51.7-135.4-52.3-134.8L-52.3-134.8z"
            />
          </g>
        </mask>

        <linearGradient
          id="Fill-4_00000062894059030625727960000006359016256997770378_"
          gradientUnits="userSpaceOnUse"
          x1="-1319.7532"
          y1="513.1517"
          x2="-1319.7532"
          y2="510.6778"
          gradientTransform="matrix(14.9541 0 0 -11.2766 19678.8691 5642.6255)"
        >
          <stop
            offset="0"
            style="stop-color:#466272"
          />
          <stop
            offset="3.331034e-02"
            style="stop-color:#486475"
          />
          <stop
            offset="1"
            style="stop-color:#8AA3BD"
          />
        </linearGradient>

        <path
          id="Fill-4_00000146494230745969232050000013631123966633365944_"
          mask="url(#mask-6_00000103987012181254237510000002196536823157361072_)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="url(#Fill-4_00000062894059030625727960000006359016256997770378_)"
          d="
			M-61.9-144c-0.5,0-0.9,0.1-1.3,0.3l0,0c-0.4,0.2-0.8,0.5-1,0.9l0,0l-2.7,4.6h-8.5v3.9h9.2c0.5,0,0.9-0.1,1.3-0.3l0,0
			c0.4-0.2,0.8-0.5,1-0.9l0,0l2.7-4.6h15.7c0.9,0,1.7,0.3,2.3,0.9l0,0c0.6,0.6,0.9,1.4,0.9,2.3l0,0v13.6c0,0.9-0.3,1.7-0.9,2.3l0,0
			c-0.6,0.6-1.4,0.9-2.3,0.9l0,0h-15.6l-4.7-8.2c-0.2-0.4-0.6-0.7-1-0.9l0,0c-0.4-0.2-0.9-0.3-1.3-0.3l0,0h-7.2v3.9h6.5l4.6,8.1
			c0.2,0.4,0.6,0.7,1,1l0,0c0.4,0.3,0.9,0.4,1.3,0.4l0,0h17.1c1.8,0,3.4-0.6,4.6-1.8l0,0c1.2-1.2,1.8-2.8,1.8-4.6l0,0v-15
			c0-1.8-0.6-3.4-1.8-4.6l0,0c-1.2-1.2-2.8-1.8-4.6-1.8l0,0H-61.9z M-52.3-134.8c-0.6,0.6-0.9,1.4-0.9,2.2l0,0c0,0.9,0.3,1.6,1,2.3
			l0,0c0.7,0.7,1.4,1,2.3,1l0,0c0.9,0,1.6-0.3,2.2-0.9l0,0c0.6-0.6,0.9-1.4,0.9-2.3l0,0c0-0.9-0.3-1.7-0.9-2.3l0,0
			c-0.6-0.6-1.4-0.9-2.3-0.9l0,0C-50.9-135.8-51.7-135.4-52.3-134.8L-52.3-134.8z"
        />
        <polygon
          id="Fill-7"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#E6320F"
          points="-79.7,-134 -79.7,-138.2 -98.9,-138.2
			-96.9,-134 		"
        />

        <polygon
          id="Fill-9_00000179621958674216905900000009045926060194017466_"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#E6320F"
          points="
			-79.7,-125.4 -79.7,-129.7 -94.8,-129.7 -92.7,-125.4 		"
        />
      </g>
    </g>
  </svg>
</template>