<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97z"
    />
    <path
      fill="currentColor"
      d="M6.8,7.6C7.1,7.5,7.4,7.6,7.5,8c0.1,0.3-0.1,0.6-0.4,0.7C6.2,8.9,5.6,9.5,5.4,10c-0.1,0.1-0.1,0.3,0,0.4
	c0,0.1,0.1,0.1,0.1,0.2c0.3,0.2,1,0.3,2.2,0c0.7-0.2,1.4-0.4,2.1-0.6c0.7-0.2,1.3-0.4,1.7-0.5c0.7-0.1,1.5-0.2,2.1-0.2
	c0.8,0,1.4,0.3,1.6,0.9c0.1,0.4,0.1,0.7,0.1,1c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.7-0.1c0.7-0.1,1.4-0.3,2.1-0.6
	c0.4-0.1,0.7-0.3,1.1-0.4c0.3-0.1,0.6,0.1,0.7,0.4c0.1,0.3-0.1,0.6-0.4,0.7c-0.3,0.1-0.7,0.2-1,0.4c-0.8,0.3-1.5,0.6-2.4,0.7
	c-1.5,0.1-1.9-0.3-1.9-1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6c0-0.1-0.2-0.1-0.6-0.1c-0.5,0-1.2,0.1-1.8,0.2
	c-0.3,0.1-0.9,0.2-1.5,0.4c-0.7,0.2-1.4,0.4-2.2,0.6c-1.5,0.4-2.6,0.2-3.1-0.2c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.3,0-0.7,0.1-1
	C4.7,8.8,5.5,8,6.8,7.6L6.8,7.6z"
    />
  </svg>
</template>