import { debounce, pick, uniq, without, isEqual, orderBy } from "lodash-es"
import { defineStore } from "pinia"
import tippy, { DelegateInstance, Instance, Props as TippyProps, roundArrow } from "tippy.js"
import { reactive, computed, toRaw, toRefs, watch } from "vue"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"
import { useI18n } from "vue-i18n"

import { changedKeys, focusFirstFocusable, scrollTo } from "~/utils"
import { Document, DynamicField, Template, FormErrors, CrudContext, Condition, DocumentStage, DocumentTab, TemplateTab, EditorContentTab, DocumentContentType, DocumentVariable } from "~/types"

import { useMetadataStore } from "../metadataStore"
import { useNotificationStore } from "../notificationStore"
import { useConditionStore } from "../conditionStore"
import { usePartyStore } from "../partyStore"
import { useDocumentStore } from "../documentStore"
import { useSharedStore } from "../sharedStore"
import { useCheckpointStore } from "../checkpointStore"
import { useTemplateStore } from "../templateStore"
import { useEditorStore } from "../editorStore"

import {
  fetchDynamicFieldsAction,
  createDynamicFieldAction,
  updateDynamicFieldAction,
  removeDynamicFieldAction,
  getDynamicFieldAction,
  syncDynamicFieldOrderAction,
} from "./dynamicFieldStoreActions"

const dynamicFieldContext = [ "document", "template" ]

const emptyDynamicField = Object.freeze<Partial<DynamicField>>(
  {
    scope: "internal",
    is_mandatory: true,
    value: null,
  },
)

interface Data {
  dynamicFields: DynamicField[]
  isLoadingDynamicFields: boolean
  uuidsOfUpdatingDynamicField: DynamicField["uuid"][]
  dynamicFieldLastSavedMap: Record<DynamicField["uuid"], number>
  dynamicFieldErrorsMap: Record<DynamicField["uuid"], FormErrors<DynamicField>>
  buttonPopover: Instance<TippyProps>[] | null
  buttonPopoverDynamicFieldUuid: DynamicField["uuid"] | null
  dynamicFieldUuidBeingRemoved: DynamicField["uuid"]
  dynamicFieldUuidBeingLoaded: DynamicField["uuid"]
  newDynamicField: Partial<DynamicField> | null
  debounceTimestamp: number
  debounceUuid: DynamicField["uuid"]
  payloadKeys: string[],
  isSyncingDynamicFieldOrder: boolean
  dataTransferData: string
  sortDirection: "asc" | "desc" | null
  uuidOfLastHighlightedDynamicField: string
}

export const useDynamicFieldStore = defineStore("dynamicFieldStore", () => {

  const { t } = useI18n()

  const data = reactive<Data>({
    dynamicFields: [],
    isLoadingDynamicFields: false,
    uuidsOfUpdatingDynamicField: [],
    dynamicFieldLastSavedMap: {},
    dynamicFieldErrorsMap: {},
    buttonPopover: null,
    buttonPopoverDynamicFieldUuid: null,
    dynamicFieldUuidBeingRemoved: null,
    dynamicFieldUuidBeingLoaded: null,
    newDynamicField: Object.assign({}, emptyDynamicField),
    debounceTimestamp: null,
    debounceUuid: null,
    payloadKeys: [],
    isSyncingDynamicFieldOrder: false,
    dataTransferData: "",
    sortDirection: null,
    uuidOfLastHighlightedDynamicField: "",
  })

  const dynamicFieldsSorted = computed(() => {
    if (!data.dynamicFields?.length) return []
    if (!data.sortDirection) return orderBy(data.dynamicFields, [ "order" ])
    const sortString = data.sortDirection === "asc" ? "asc" : "desc"
    return orderBy(data.dynamicFields, [ "name" ], [ sortString ])
  })

  const pendingDynamicFields = computed<DynamicField[]>(() => {
    if (!dynamicFieldsSorted.value || !dynamicFieldsSorted.value?.length) return []
    return dynamicFieldsSorted.value?.filter(
      (el) => {
        const scopeCheck = pendingDynamicFieldScope.value === "internal_and_external" ? true : el.scope === pendingDynamicFieldScope.value
        const mandatoryCheck =  el.is_mandatory && ((!el.value && !el.autofill_type && el.type !== "bool") || (el.value === null && !el.autofill_type && el.type === "bool"))
        return scopeCheck && mandatoryCheck
      },
    )
  })

  const pendingDynamicFieldsIncludingExternallyChangeable = computed(() => dynamicFieldsSorted.value?.filter((el) => {
    return ((!el.value && !el.autofill_type && el.type !== "bool") || (el.value === null && !el.autofill_type && el.type === "bool")) && el.is_mandatory
  }))

  const optionalButReferencedDynamicFieldsWithoutValue = computed(() => {

    const foundDynamicFields = dynamicFieldsSorted.value?.filter((el) => {
    // Find all dynamicFields that are optional, but have a positive countOfIsValueUsedInDocument
      const isUsedInDocument = countOfIsValueUsedInDocument(el.uuid)
      const isOptional = !el.is_mandatory
      const isInternal = el.scope === "internal"
      const isWithoutValue = (!el.value && !el.autofill_type && el.type !== "bool") || (el.value === null && !el.autofill_type && el.type === "bool")
      return isUsedInDocument && isOptional && isInternal && isWithoutValue
    }).map((el) => {
      return {
        ...el,
        countOfIsValueUsedInDocument: countOfIsValueUsedInDocument(el.uuid),
      }
    })
    return foundDynamicFields
  },
  )

  // We need this because we do not realize when a dynamicField has just been inserted
  // into the document content and has therefore become mandatory
  const emptyDynamicFields = computed(() => data.dynamicFields?.filter((el) => {
    return ((!el.value && !el.autofill_type && el.type !== "bool") || (el.value === null && !el.autofill_type && el.type === "bool"))
  }))

  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isNotMobile = breakpoints.greater("sm")

  const { notify } = useNotificationStore()
  const conditionStore = useConditionStore()
  const partyStore = usePartyStore()
  const templateStore = useTemplateStore()
  const documentStore = useDocumentStore()
  const editorStore = useEditorStore()
  const checkpointStore = useCheckpointStore()
  const sharedStore = useSharedStore()
  const metadataStore = useMetadataStore()

  const isExternalPartiesReadyForSigning = computed<boolean>(() => partyStore.parties?.every((el) => el.is_ready_for_signing || el.account_party_uuid))
  const pendingDynamicFieldScope = computed<"internal" | "internal_and_external">(() => {
    if (isExternalPartiesReadyForSigning.value || !documentStore.mau || (documentStore.currentDocument?.stage === DocumentStage.review && checkpointStore.readyCheckpoints?.length)) return "internal_and_external"
    return "internal"
  })

  // mutations
  const setDynamicFields = (dynamicFields: DynamicField[]) => data.dynamicFields = dynamicFields
  const pushDynamicField = (dynamicField: DynamicField) => data.dynamicFields = [ ...toRaw(data.dynamicFields || []), dynamicField ]

  const pushOrUpdateDynamicField = (d: Partial<DynamicField>) => {
    const localIndexOfDynamicFields = data.dynamicFields.findIndex(({ uuid }) => uuid === d.uuid)

    const dynamicFieldsCopy = [ ...toRaw(data.dynamicFields) ]

    if (localIndexOfDynamicFields !== -1) {
      dynamicFieldsCopy[localIndexOfDynamicFields] = {
        ...dynamicFieldsCopy[localIndexOfDynamicFields],
        ...d,
      }

      data.dynamicFields = dynamicFieldsCopy

      return
    }

    data.dynamicFields = [
      ...dynamicFieldsCopy,
      d,
    ] as DynamicField[] // TODO: change this to real types
  }

  const removeDynamicFieldFromStore = (dynamicFieldUuidToRemove: DynamicField["uuid"]) => {
    const indexOfDynamicFieldToRemove = data.dynamicFields.findIndex((dynamicField) => dynamicField.uuid === dynamicFieldUuidToRemove)

    if (indexOfDynamicFieldToRemove !== -1) {
      data.dynamicFields.splice(indexOfDynamicFieldToRemove, 1)
    }
  }

  const addUpdatingDynamicField = (uuid: DynamicField["uuid"]) => data.uuidsOfUpdatingDynamicField = uniq([ ...toRaw(data.uuidsOfUpdatingDynamicField), uuid ])

  const removeUpdatingDynamicField = (uuid: DynamicField["uuid"]) => data.uuidsOfUpdatingDynamicField = without([ ...toRaw(data.uuidsOfUpdatingDynamicField) ], uuid)

  const setDynamicFieldLastSaved = (uuid: DynamicField["uuid"]) => {
    data.dynamicFieldLastSavedMap = {
      ...toRaw(data.dynamicFieldLastSavedMap || {}),
      [uuid]: Date.now(),
    }
  }

  const setDynamicFieldErrors = (uuid: DynamicField["uuid"], dynamicFieldErrors: FormErrors<DynamicField>) => {
    data.dynamicFieldErrorsMap = {
      ...toRaw(data.dynamicFieldErrorsMap || {}),
      [uuid]: dynamicFieldErrors,
    }
  }

  const setNewDynamicField = (dynamicField: Partial<DynamicField>) => data.newDynamicField = dynamicField

  const updateNewDynamicField = (payload: Partial<DynamicField>) => {
    data.newDynamicField = {
      ...toRaw(data.newDynamicField || {}),
      ...payload,
    }
  }

  const setDataTransferData = (dataTransferData: string) => data.dataTransferData = dataTransferData
  const clearDataTransferData = () => data.dataTransferData = ""

  // api actions
  const fetchDynamicFields = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<DynamicField[] | void> => {
    if (!dynamicFieldContext.includes(context)) {
      console.error("context should be one of", dynamicFieldContext, " but given", context)
      return
    }
    try {
      data.isLoadingDynamicFields = true
      const dynamicFields = await fetchDynamicFieldsAction(context, entityUuid) || []
      setDynamicFields(dynamicFields)
      return dynamicFields
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingDynamicFields = false
    }
  }

  // api actions
  const getDynamicField = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    dynamicFieldUuid: DynamicField["uuid"],
  ): Promise<DynamicField | void> => {
    if (!dynamicFieldContext.includes(context)) {
      console.error("context should be one of", dynamicFieldContext, " but given", context)
      return
    }
    try {
      data.dynamicFieldUuidBeingLoaded = dynamicFieldUuid
      const dynamicField = await getDynamicFieldAction(context, entityUuid, dynamicFieldUuid)
      if (dynamicField) {
        pushOrUpdateDynamicField(dynamicField)
      }
      return dynamicField
    } catch (err) {
      console.error(err)
    } finally {
      data.dynamicFieldUuidBeingLoaded = null
    }
  }

  // dynamicField CRUD
  const createDynamicField = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    payload: Partial<DynamicField>,
  ): Promise<DynamicField | void> => {
    const createdDynamicField = await createDynamicFieldAction(context, entityUuid, payload)

    if (createdDynamicField) pushDynamicField(createdDynamicField)

    return createdDynamicField
  }


  const updateDynamicField = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    dynamicField: Partial<DynamicField>,
    originalDynamicField: Partial<DynamicField>,
    timestamp: number,
    payloadKeys: string[],
  ): Promise<DynamicField | void> => {
    try {

      const payload = pick(dynamicField, payloadKeys)

      const updatedDynamicField = await updateDynamicFieldAction(context, entityUuid, payload, originalDynamicField.uuid)

      if (updatedDynamicField) {
        if (!(data.debounceTimestamp > timestamp)) pushOrUpdateDynamicField(updatedDynamicField)
        setDynamicFieldLastSaved(dynamicField.uuid)
        setDynamicFieldErrors(dynamicField.uuid, {})
      }

      return updatedDynamicField
    } catch (err) {
      const isBackendError = !!err.response?.data?.errors

      if (isBackendError) {
        setDynamicFieldErrors(dynamicField.uuid, err.response.data.errors)
      }
      else {
        notify({
          title: t("dynamicFields.errors.update"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    } finally {
      removeUpdatingDynamicField(dynamicField.uuid)
    }
  }

  const removeDynamicFieldFromEntity = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    dynamicFieldUuidToRemove: DynamicField["uuid"],
  ) : Promise<DynamicField[] | void> => {
    try {
      data.dynamicFieldUuidBeingRemoved = dynamicFieldUuidToRemove

      const removeRes =  await removeDynamicFieldAction(
        context,
        entityUuid,
        dynamicFieldUuidToRemove,
      )

      if (removeRes === 200) removeDynamicFieldFromStore(dynamicFieldUuidToRemove)

      return data.dynamicFields
    } catch (err) {
      console.error(err)

      notify({
        title: t("dynamicFields.errors.remove"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.dynamicFieldUuidBeingRemoved = null
    }
  }

  const removeDynamicField = async (dynamicFieldUuid: DynamicField["uuid"], entity: Document | Template) => {
    if (entity) {
      if (sharedStore.crudContext === CrudContext.document && documentStore.currentDocument?.content_type === DocumentContentType.prosemirror_data) {
        const isDynamicFieldReferencedInDocumentContent = await documentStore.checkForReferences("dynamicField", dynamicFieldUuid)
        if (isDynamicFieldReferencedInDocumentContent) {
          const dynamicFieldRefUuid = data.dynamicFields.find((el) => el.uuid === dynamicFieldUuid)?.ref_uuid
          documentStore.showReferenceAlert("dynamicField", dynamicFieldRefUuid)
          return
        }
      } else if (sharedStore.crudContext === CrudContext.template) {
        const dynamicFieldRefUuid = data.dynamicFields.find((el) => el.uuid === dynamicFieldUuid)?.ref_uuid
        const isDynamicFieldReferencedInTemplateContent = templateStore.checkForReferences("dynamicField", dynamicFieldRefUuid)
        if (isDynamicFieldReferencedInTemplateContent) {
          documentStore.showReferenceAlert("dynamicField", dynamicFieldRefUuid)
          return
        }
      }

      const dynamicFieldRefUuid = data.dynamicFields.find((el) => el.uuid === dynamicFieldUuid)?.ref_uuid
      const isDynamicFieldReferencedInCondition = conditionStore.conditions?.find((condition) => condition.expression?.includes(dynamicFieldRefUuid))
      const isDynamicFieldLinkedToMetadataValue = metadataStore.metadataValues?.find((metadataValue) => metadataValue.reference_dynamic_field_uuid === dynamicFieldUuid)

      if (isDynamicFieldReferencedInCondition) {
        documentStore.showReferenceAlert("dynamicField", dynamicFieldRefUuid, "condition", isDynamicFieldReferencedInCondition.uuid)
        return
      }
      else if (isDynamicFieldLinkedToMetadataValue) {
        documentStore.showReferenceAlert("dynamicField", dynamicFieldRefUuid, "metadataValue", isDynamicFieldLinkedToMetadataValue.uuid)
        return
      }

      removeDynamicFieldFromEntity(sharedStore.crudContext, entity.uuid, dynamicFieldUuid)

      // ugly hack to destroy tippy after DOM is gone
      const elements = document.querySelectorAll("[data-dynamic-field-button]")
      if (!elements.length) return
      elements?.forEach((node) => {
        interface TippyNode extends Element {
          _tippy: DelegateInstance<TippyProps>
        }

        const tippyNode = node as TippyNode
        if (tippyNode._tippy) {
          tippyNode._tippy.destroy()
        }
      })
    }
  }

  const debouncedUpdateDynamicField = debounce(updateDynamicField, 1000)

  // ui actions
  const updateLocalDynamicField = (
    dynamicField: Partial<DynamicField>,
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ) => {
    if (!dynamicField) return

    if (!entityUuid) {
      console.error("entity uuid not given for", dynamicField)
      return
    }

    const originalDynamicField = { ...toRaw(data.dynamicFields.find((d) => d.uuid === dynamicField.uuid)) }
    const rawDynamicField = { ...toRaw(dynamicField) }
    const payloadKeys = changedKeys(originalDynamicField, rawDynamicField)

    const timestamp = Date.now()
    if (data.debounceUuid !== dynamicField.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
      debouncedUpdateDynamicField?.flush()
      data.debounceUuid = dynamicField.uuid
      data.payloadKeys = payloadKeys
    }
    data.debounceTimestamp = timestamp

    pushOrUpdateDynamicField(dynamicField)
    addUpdatingDynamicField(dynamicField.uuid)
    debouncedUpdateDynamicField(
      context,
      entityUuid,
      rawDynamicField,
      originalDynamicField,
      timestamp,
      payloadKeys,
    )
  }

  const jumpToDynamicField = (
    dynamicFieldRefUuid: DynamicField["ref_uuid"],
    triggerClick = true,
  ) => {
    const highlightClasses = [ "bg-yellow-50", "ring-inset", "ring-2", "ring-yellow-300" ]
    // Remove pre-existing highlights
    const highlightedElements = document.querySelectorAll(".dynamic-field_input_wrapper")
    highlightedElements?.forEach((el) => el.classList.remove(...highlightClasses))

    if (!dynamicFieldRefUuid) return

    const editorElements = document.querySelectorAll<HTMLDivElement>("[data-dynamic-field-node=\"" + dynamicFieldRefUuid + "\"]")
    let editorElement: HTMLDivElement = editorElements[0]?.closest("[data-node-view-wrapper]")
    let conditionElement: HTMLElement = null
    if (!editorElement) {
      const relatedCondition = conditionStore.conditions?.find((el: Condition) => el.expression?.includes(dynamicFieldRefUuid))
      if (relatedCondition) {
        conditionElement = document.getElementById(relatedCondition.uuids?.[0])
        if (conditionElement) editorElement = (conditionElement as HTMLDivElement)
      }
    }
    const mainContentScrollContainer = sharedStore.crudContext === CrudContext.document ? documentStore.mainContentScrollContainer : templateStore.mainContentScrollContainer
    if (editorElement) scrollTo(mainContentScrollContainer, editorElement, () => triggerClick ? editorElements[0]?.click() : null)

    const sidebarElement = document.getElementById("dynamic-field_input_" + dynamicFieldRefUuid)

    const sidebarElementHolder = sidebarElement?.closest<HTMLElement>(".dynamic-field_input_wrapper_" + dynamicFieldRefUuid)

    if (sidebarElement) {
      if (sharedStore.crudContext === CrudContext.document) {
        documentStore.setActiveTabKey(DocumentTab.dynamicContent, (!isNotMobile.value || !!editorElement))
      } else {
        templateStore.setActiveTabKey(TemplateTab.documentContent, (!isNotMobile.value || !!editorElement))
        setTimeout(() => templateStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields }), 100)
      }
      setTimeout(() => scrollTo(sharedStore.sidebarScrollContainer, sidebarElementHolder, () => null), 100)
    }

    sidebarElementHolder?.classList.add(...highlightClasses)

    if (triggerClick) {
      if (!editorElements.length && sidebarElementHolder) {
        focusFirstFocusable(sidebarElementHolder)
        data.buttonPopoverDynamicFieldUuid = pendingDynamicFields.value[0]?.uuid

        data.buttonPopover = tippy([ sidebarElementHolder ], {
          appendTo: sidebarElementHolder,
          content: "",
          arrow: roundArrow,
          animation: "scale",
          hideOnClick: true,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "top",
          onShow: (instance) => {
            const element = document.getElementById("wizardButtonPopover")
            if (element) instance.setContent(element)
          },
          onHide (instance) {
            const element = document.getElementById("wizardButtonPopover")
            if (!element) return
            const domRect = element.getBoundingClientRect()
            document.getElementById("sidebarPopovers").appendChild(element)
            const fakeElement = document.createElement("div")
            fakeElement.innerHTML = (
              "<div class=\"relative md:w-72\"><div class=\"bg-gray-100 border-2 border-indigo-900 rounded-md divide-y divide-gray-100 shadow-lg ring ring-3 ring-indigo-500 ring-opacity-30\" style=\"height: " +
            domRect.height +
            "px\"></div></div></div>"
            ).trim()
            instance.setContent(fakeElement)
          },
          onHidden (instance) {
            instance.setContent("")
          },
        })
      }

    }
    data.uuidOfLastHighlightedDynamicField = dynamicFieldRefUuid
  }

  const syncDynamicFieldOrder = async (
    context: CrudContext,
    entityUuid: Template["uuid"] | Document["uuid"],
    order: DynamicField["uuid"][],
  ) => {
    data.isSyncingDynamicFieldOrder = true
    try {
      await syncDynamicFieldOrderAction(context, entityUuid, order)
    } catch (err) {
      notify({
        title: "Error saving dynamic field order",
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isSyncingDynamicFieldOrder = false
    }
  }

  const countOfIsValueUsedInDocument = (dynamicFieldUuid: DynamicField["uuid"]): number => {
    if (!documentStore.currentDocument || !documentStore.documentVariables) return 0
    const valueFound = documentStore.documentVariables?.find((item) => item.class === "App\\Models\\DynamicField" && item.uuid === dynamicFieldUuid)
    if (!valueFound) return 0
    return valueFound?.content_counter || 0
  }

  const setDynamicFieldOrderToDocumentOrder = async (
    context: CrudContext,
    entityUuid: Template["uuid"] | Document["uuid"],
  ): Promise<void> => {
    let references = []
    if (context === CrudContext.template) {
      editorStore.editor?.view?.state?.doc?.descendants?.((node) => {
        if (node.type.name !== "dynamicField" || !node.attrs?.refUuid) return
        const dynamicFieldUuid = data.dynamicFields.find((item) => item.ref_uuid === node.attrs?.refUuid)?.uuid
        if (!dynamicFieldUuid) return
        references.push(dynamicFieldUuid)
      })
    } else {
      await documentStore.fetchDocumentVariables()
      const variables = documentStore.documentVariables || []
      references = variables?.filter((item: DocumentVariable) => item.class === "App\\Models\\DynamicField").map((item: DocumentVariable) => item.uuid) || []
    }
    const dynamicFieldUuidsInDocumentOrderSet = new Set(references)
    const dynamicFieldUuidsNotInDocumentOrder = orderBy(data.dynamicFields, [ "order" ], [ "asc" ])?.filter((item) => !dynamicFieldUuidsInDocumentOrderSet.has(item.uuid))?.map((item) => item.uuid) || []
    const newOrder = [ ...dynamicFieldUuidsInDocumentOrderSet, ...dynamicFieldUuidsNotInDocumentOrder ]
    await syncDynamicFieldOrder(context, entityUuid, newOrder)
    // Re-order dynaicFields in store based on "newOrder", also settings the dynamic fields order attribute
    const reorderedDynamicFields = data.dynamicFields.map((item) => {
      const order = newOrder.indexOf(item.uuid)
      return { ...item, order }
    })
    setDynamicFields(reorderedDynamicFields)
  }

  const toggleSortDirection = () => {
    if (data.sortDirection === "asc") {
      data.sortDirection = "desc"
    } else if (data.sortDirection === "desc") {
      data.sortDirection = null
    } else {
      data.sortDirection = "asc"
    }
  }

  watch(
    pendingDynamicFields,
    (val: DynamicField[]) => {
      if (val.length) return
      data.buttonPopover?.[0]?.hide()
    },
  )

  return {
    ...toRefs(data),
    pendingDynamicFields,
    pendingDynamicFieldsIncludingExternallyChangeable,
    optionalButReferencedDynamicFieldsWithoutValue,
    emptyDynamicFields,
    dynamicFieldsSorted,

    // mutations
    setDynamicFields,
    pushDynamicField,
    pushOrUpdateDynamicField,
    setDynamicFieldErrors,
    setNewDynamicField,
    updateNewDynamicField,
    setDataTransferData,
    clearDataTransferData,

    // api actions
    fetchDynamicFields,
    removeDynamicFieldAction,

    // dynamicField CRUD,
    createDynamicField,
    getDynamicField,
    updateDynamicField,
    removeDynamicFieldFromEntity,
    removeDynamicField,

    // ui actions,
    jumpToDynamicField,
    updateLocalDynamicField,
    countOfIsValueUsedInDocument,
    setDynamicFieldOrderToDocumentOrder,
    syncDynamicFieldOrder,
    toggleSortDirection,
  }
})
