// external
import axios from "axios"

// internal
import { AccountUser, Task, CustomTask, Pagination, TaskableType, PotentialAssignees } from "~/types"

export const fetchAccountUsersAction = async (): Promise<AccountUser[]> => {
  const response = await axios.get<{ data: AccountUser[] }>(route("api.account-users.index"))
  return response.data.data
}

export const fetchIncomingTasksAction = async ({
  includeResolved,
  perPage,
  page,
}): Promise<Pagination<Task[]>> => {
  const response = await axios.get<Pagination<Task[]>>(route("api.incoming-tasks.index"), {
    params: {
      per_page: perPage,
      page: page,
      include_resolved: includeResolved,
    },
  })
  return response.data
}

export const fetchOutgoingCustomTasksAction = async ({
  includeResolved,
  perPage,
  page,
}): Promise<Pagination<CustomTask[]>> => {
  const response = await axios.get<Pagination<CustomTask[]>>(route("api.outgoing-custom-tasks.index"), {
    params: {
      per_page: perPage,
      page: page,
      include_resolved: includeResolved,
    },
  })
  return response.data
}

export const fetchPotentialAssigneesAction = async (
  type: TaskableType,
  uuid: string,
): Promise<PotentialAssignees> => {
  const response = await axios.post<PotentialAssignees>(route("api.data.available-users"), {
    type: type,
    uuid: uuid,
  })
  return response.data
}

export const createCustomTaskAction = async (
  task: Partial<CustomTask>,
): Promise<Task> => {
  const response = await axios.post<Task>(route("api.custom-tasks.store"), task)
  return response.data
}

export const updateTaskAction = async (
  task: Partial<Task>,
): Promise<Task> => {
  const response = await axios.patch<Task>(route("api.tasks.update", task.uuid), task)
  return response.data
}

export const updateCustomTaskAction = async (
  customTask: Partial<CustomTask>,
): Promise<CustomTask> => {
  const response = await axios.patch<CustomTask>(route("api.custom-tasks.update", customTask.uuid), customTask)
  return response.data
}
