<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
  ><g
    data-name="OUTLINE"
  ><path
    d="m494.768 435.008-22.358-67.076a7.985 7.985 0 0 1-.41-2.529V40a24.027 24.027 0 0 0-24-24H64a24.027 24.027 0 0 0-24 24v325.403a7.993 7.993 0 0 1-.41 2.53l-22.358 67.073a23.942 23.942 0 0 0-1.232 7.59V472a24.027 24.027 0 0 0 24 24h432a24.027 24.027 0 0 0 24-24v-29.403a23.935 23.935 0 0 0-1.232-7.59ZM56 40a8.01 8.01 0 0 1 8-8h384a8.01 8.01 0 0 1 8 8v320H56Zm-2.234 336h404.469l18.666 56H307.314A15.898 15.898 0 0 0 296 436.687L284.687 448l-57.373.001-11.315-11.316A15.898 15.898 0 0 0 204.687 432H35.099ZM480 472a8.01 8.01 0 0 1-8 8H40a8.01 8.01 0 0 1-8-8v-24l172.686-.001 11.315 11.316A15.898 15.898 0 0 0 227.313 464h57.373A15.898 15.898 0 0 0 296 459.313L307.313 448H480Z"
  /><path
    d="M376 120H270.627a7.947 7.947 0 0 1-5.657-2.344L242.343 95.03A23.843 23.843 0 0 0 225.373 88H136a24.027 24.027 0 0 0-24 24v168a24.027 24.027 0 0 0 24 24h240a24.027 24.027 0 0 0 24-24V144a24.027 24.027 0 0 0-24-24Zm8 160a8.01 8.01 0 0 1-8 8H136a8.01 8.01 0 0 1-8-8V112a8.01 8.01 0 0 1 8-8h89.373a7.947 7.947 0 0 1 5.657 2.344l22.627 22.627a23.843 23.843 0 0 0 16.97 7.029H376a8.01 8.01 0 0 1 8 8Z"
  /><path
    d="M202.343 162.343 156.687 208l45.656 45.657 11.314-11.314L179.313 208l34.344-34.343-11.314-11.314zM233.007 244.116l39.998-72.001 13.986 7.77-39.998 72zM298.343 173.657 332.687 208l-34.344 34.343 11.314 11.314L355.313 208l-45.656-45.657-11.314 11.314z"
  /></g></svg>
</template>
