// external
import { cloneDeep } from "lodash-es"

// internal
import { DocumentNotification, DocumentNotificationType } from "~/types"

export function useDocumentNotificationHelpers (context = null) {


  const isInCurrentContext = (notificationSetting) => {
    if (context) {
      return notificationSetting.notification_settable_type?.toLowerCase().includes(context)
    } else {
      return notificationSetting.notification_settable_type?.toLowerCase().includes("account")
    }
  }

  const hasSpecificRecipients = (notificationSetting: DocumentNotification) => {
    return notificationSetting.recipients !== null
  }

  const getUniqueIdentifier = (notificationSetting: DocumentNotification) => {
    return `${notificationSetting.uuid}_${notificationSetting.notification_settable_type}_${notificationSetting.notification_settable_uuid}`
  }

  const reducePayload = (notificationSetting) => {
    const payload = cloneDeep({ ...notificationSetting })

    delete payload["notification_settable_type"]
    delete payload["notification_settable_uuid"]

    if (notificationSetting.type !== DocumentNotificationType.recurring) {
      delete payload["recurring_condition"]
    }

    if (notificationSetting.type !== DocumentNotificationType.reminder) {
      delete payload["trigger_time_condition"]
      delete payload["trigger_time_date"]
    }
    return payload
  }

  return {
    isInCurrentContext,
    hasSpecificRecipients,
    getUniqueIdentifier,
    reducePayload,
  }
}
