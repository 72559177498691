import axios from "axios"
import { AiAnalysis, Document } from "~/types"

export const createAiAnalysisAction = async (
  documentUuid: Document["uuid"],
): Promise<AiAnalysis | void> => {
  const res = await axios.post<any>(route("api.documents.ai-analyses.store", documentUuid))
  return res.data.data
}

export const getAiAnalysisAction = async (
  documentUuid: Document["uuid"],
  aiAnalysisUuid: AiAnalysis["uuid"],
): Promise<AiAnalysis | void> => {
  const extractionRes = await axios.get<{ data: AiAnalysis }>(route("api.documents.ai-analyses.show", {
    document: documentUuid,
    ai_analysis: aiAnalysisUuid,
  }))
  return extractionRes.data.data
}
