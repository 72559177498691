
import { debounce, pick, uniq, without, isEqual } from "lodash-es"
import { defineStore } from "pinia"
import { reactive, computed, toRaw, toRefs } from "vue"
import { differenceWith } from "lodash-es"
import { useI18n } from "vue-i18n"

import { Document, FormErrors, Template, CrudContext, Metadata, MetadataValue, MultiFieldType, MetadataType, CalendarData, BoundingBox, DocumentContentType, DocumentOrigin, ProseMirrorUuidBoundingBox } from "~/types"
import { useNotificationStore } from "../notificationStore"

import {
  fetchMetadataValuesAction,
  fetchMetadataAction,
  createMetadataValueAction,
  updateMetadataValueAction,
  removeMetadataValueFromEntityAction,
  getMetadataValueAction,
  fetchCalendarDataAction,
} from "./metadataStoreActions"
import { changedKeys, generateBoundingBoxOffsetLabel, getMetadataDisplayName, isValidMultifieldNumber, scrollTo } from "~/utils"
import { OverlayScrollbar, PdfViewer } from "~/components"

interface Data {
  metadataValues: MetadataValue[]
  isLoadingMetadataValues: boolean
  uuidsOfUpdatingMetadataValue: MetadataValue["uuid"][]
  metadataValueLastSavedMap: Record<MetadataValue["uuid"], number>
  metadataValueErrorsMap: Record<MetadataValue["uuid"], FormErrors<MetadataValue>>
  metadata: Metadata[]
  isLoadingMetadata: boolean
  metadataValueUuidBeingLoaded: MetadataValue["uuid"]
  metadataValueUuidBeingRemovedFromEntity: MetadataValue["uuid"]
  debounceTimestamp: number
  debounceUuid: MetadataValue["uuid"]
  payloadKeys: string[]
  hasChangedDurationType: boolean
  calendarData: CalendarData
  isLoadingCalendarData: boolean
}

const metadataContext = [ "document", "template" ]

export const useMetadataStore = defineStore("metadataStore", () => {

  const { t } = useI18n()

  const data = reactive<Data>({
    metadata: [],
    metadataValues: [],
    isLoadingMetadataValues: false,
    uuidsOfUpdatingMetadataValue: [],
    metadataValueLastSavedMap: {},
    metadataValueErrorsMap: {},
    isLoadingMetadata: false,
    metadataValueUuidBeingLoaded: null,
    metadataValueUuidBeingRemovedFromEntity: null,
    debounceTimestamp: null,
    debounceUuid: null,
    payloadKeys: [],
    hasChangedDurationType: false,
    calendarData: null,
    isLoadingCalendarData: false,
  })

  const { notify } = useNotificationStore()

  // computed properties
  const boundingBoxes = computed<{ key: Metadata["name"], boundingBox: BoundingBox }[]>(() => {
    if (!data.metadataValues?.length) return []
    const filteredMetadataValuesWithBoundingBoxes = (data.metadataValues?.filter((el) => el.bounding_box) || [])
    // For each bounding_box inside el.bounding_box, return an object with key and boundingBox
    const mappedBoxes = []
    for (const metadataValue of filteredMetadataValuesWithBoundingBoxes) {
      for (const boundingBox of metadataValue.bounding_box) {
        mappedBoxes.push({
          key: metadataValue.metadata?.name,
          boundingBox,
        })
      }
    }
    return mappedBoxes
  })

  const prosemirrorUuidBoundingBoxes = computed<{ key: Metadata["name"], prosemirrorUuid: string }[]>(() => {
    if (!data.metadataValues?.length) return []
    const filteredMetadataValuesWithProsemirrorUuids = data.metadataValues?.filter((el) => el.prosemirror_uuids)
    // For each prosemirror_uuids inside el.prosemirror_uuids, return an object with key and prosemirrorUuids
    const mappedUuids = []
    for (const metadataValue of filteredMetadataValuesWithProsemirrorUuids) {
      for (const prosemirrorUuid of metadataValue.prosemirror_uuids) {
        mappedUuids.push({
          key: metadataValue.metadata?.name,
          prosemirrorUuid: prosemirrorUuid,
        })
      }
    }
    return mappedUuids
  })

  const pendingMetadataValues = computed<MetadataValue[]>(() => {
    const mandatoryMetadata = data.metadata?.filter((el) => el.is_mandatory)
    const emptyMetadataValues = data.metadataValues?.filter((el) => !el.value)
    const mandatoryMetadataValues = differenceWith(
      emptyMetadataValues,
      mandatoryMetadata,
      ({ metadata }, { uuid }) => metadata.uuid === uuid,
    )
    return mandatoryMetadataValues
  })

  // mutations
  const setMetadataValues = (metadataValues: (MetadataValue)[]) => data.metadataValues = metadataValues

  const setMetadata = (metadata: (Metadata)[]) => {
    if (!metadata?.length) {
      data.metadata = []
    } else {
      data.metadata = metadata.sort((a, b) => {
        const nameA = a.type === MetadataType.account ? a.display_name : t("metadata.system." + a.name + ".name")
        const nameB = b.type === MetadataType.account ? b.display_name : t("metadata.system." + b.name + ".name")

        return (nameA > nameB) ? 1 : ((nameB > nameA) ? -1 : 0)
      })
    }
  }

  const setCalendarData = (calendarData: CalendarData) => data.calendarData = calendarData

  const pushMetadataValue = (metadataValue: MetadataValue) => data.metadataValues = [ ...toRaw(data.metadataValues || []), metadataValue ]

  const pushOrUpdateMetadataValue = (metadataValue: Partial<MetadataValue>) => {
    const localIndexOfMetadataValue = data.metadataValues.findIndex(({ uuid }) => uuid === metadataValue.uuid)

    const metadataValuesCopy = [ ...toRaw(data.metadataValues) ]

    if (localIndexOfMetadataValue !== -1) {
      metadataValuesCopy[localIndexOfMetadataValue] = {
        ...metadataValuesCopy[localIndexOfMetadataValue],
        ...metadataValue,
      }

      data.metadataValues = metadataValuesCopy

      return
    }

    data.metadataValues = [
      ...metadataValuesCopy,
      metadataValue,
    ] as MetadataValue[] // TODO: change this to real types
  }

  const removeMetadataValueFromStore = (metadataValueUuidToRemove: MetadataValue["uuid"]) => {
    const indexOfMetadataValueToRemove = data.metadataValues.findIndex((metadataValue) => metadataValue.uuid === metadataValueUuidToRemove)

    if (indexOfMetadataValueToRemove !== -1) {
      data.metadataValues.splice(indexOfMetadataValueToRemove, 1)
    }
  }

  const addUpdatingMetadataValue = (uuid: MetadataValue["uuid"]) => data.uuidsOfUpdatingMetadataValue = uniq([ ...toRaw(data.uuidsOfUpdatingMetadataValue), uuid ])

  const removeUpdatingMetadataValue = (uuid: MetadataValue["uuid"]) => data.uuidsOfUpdatingMetadataValue = without([ ...toRaw(data.uuidsOfUpdatingMetadataValue) ], uuid)

  const setMetadataValueLastSaved = (uuid: MetadataValue["uuid"]) => {
    data.metadataValueLastSavedMap = {
      ...toRaw(data.metadataValueLastSavedMap || {}),
      [uuid]: Date.now(),
    }
  }

  const setMetadataValueErrors = (uuid: MetadataValue["uuid"], metadataValueErrors: FormErrors<MetadataValue>) => {
    data.metadataValueErrorsMap = {
      ...toRaw(data.metadataValueErrorsMap || {}),
      [uuid]: metadataValueErrors,
    }
  }

  const setHasChangedDurationType = (hasChangedDurationType: boolean) => data.hasChangedDurationType = hasChangedDurationType

  // api actions
  const fetchMetadataValues = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<MetadataValue[] | void> => {
    if (!metadataContext.includes(context)) {
      console.error("context should be one of", metadataContext, " but given", context)
      return
    }
    try {
      data.isLoadingMetadataValues = true
      const metadataValues = await fetchMetadataValuesAction(context, entityUuid)
      setMetadataValues(metadataValues)
      return metadataValues
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingMetadataValues = false
      setHasChangedDurationType(false)
    }
  }

  const fetchMetadata = async (): Promise<Metadata[] | void> => {
    try {
      data.isLoadingMetadata = true
      const metadata = await fetchMetadataAction()
      setMetadata(metadata)
      return metadata
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingMetadata = false
    }
  }

  const getMetadataValue = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    metadataValueUuid: MetadataValue["uuid"],
  ): Promise<MetadataValue | void> => {
    if (!metadataContext.includes(context)) {
      console.error("context should be one of", metadataContext, " but given", context)
      return
    }
    try {
      data.metadataValueUuidBeingLoaded = metadataValueUuid
      const metadataValue = await getMetadataValueAction(context, entityUuid, metadataValueUuid)
      if (metadataValue) {
        pushOrUpdateMetadataValue(metadataValue)
      }
      return metadataValue
    } catch (err) {
      console.error(err)
    } finally {
      data.metadataValueUuidBeingLoaded = null
    }
  }

  const fetchCalendarData = async (documentUuid: Document["uuid"]): Promise<CalendarData | void> => {
    try {
      data.isLoadingCalendarData = true
      const calendarData = await fetchCalendarDataAction(documentUuid)
      if (calendarData) setCalendarData(calendarData)
      return calendarData
    }
    catch (err) {
      console.error(err)
    }
    finally {
      data.isLoadingCalendarData = false
    }
  }

  // metadataValue CRUD
  const createMetadataValue = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    payload: Partial<MetadataValue>,
  ): Promise<MetadataValue | void> => {
    const createdMetadataValue = await createMetadataValueAction(context, entityUuid, payload)

    if (createdMetadataValue) pushMetadataValue(createdMetadataValue)

    return createdMetadataValue
  }


  const updateMetadataValue = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    metadataValue: Partial<MetadataValue>,
    originalMetadataValue: Partial<MetadataValue>,
    timestamp: number,
    payloadKeys: string[],
  ): Promise<MetadataValue | void> => {
    try {

      const payload = pick(metadataValue, payloadKeys)

      // always include value
      payload.value = metadataValue.value

      const updatedMetadataValue = await updateMetadataValueAction(context, entityUuid, payload, originalMetadataValue.uuid)

      if (updatedMetadataValue) {
        if (!(data.debounceTimestamp > timestamp)) pushOrUpdateMetadataValue(updatedMetadataValue)
        setMetadataValueLastSaved(metadataValue.uuid)
        setMetadataValueErrors(metadataValue.uuid, {})
      }

      return updatedMetadataValue
    } catch (err) {
      const isBackendError = !!err.response?.data?.errors

      if (isBackendError) {
        setMetadataValueErrors(metadataValue.uuid, err.response.data.errors)
      }
      else {
        notify({
          title: t("metadata.errors.update"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    } finally {
      removeUpdatingMetadataValue(metadataValue.uuid)
    }
  }

  const removeMetadataValueFromEntity = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    metadataValueUuidToRemove: MetadataValue["uuid"],
  ) : Promise<MetadataValue[] | void> => {
    try {
      data.metadataValueUuidBeingRemovedFromEntity = metadataValueUuidToRemove

      const removeRes = await removeMetadataValueFromEntityAction(
        context,
        entityUuid,
        metadataValueUuidToRemove,
      )

      if (removeRes === 200) removeMetadataValueFromStore(metadataValueUuidToRemove)

      return data.metadataValues
    } catch (err) {
      console.error(err)

      notify({
        title: t("metadata.errors.remove"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.metadataValueUuidBeingRemovedFromEntity = null
    }
  }

  const debouncedUpdateMetadataValue = debounce(updateMetadataValue, 1000)

  // ui actions
  const updateLocalMetadataValue = async (
    metadataValue: Partial<MetadataValue>,
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ) => {
    if (!metadataValue) return
    const originalMetadataValue = { ...toRaw(data.metadataValues.find((m) => m.uuid === metadataValue.uuid)) }
    let rawMetadataValue = { ...toRaw(metadataValue) }
    const payloadKeys = without(changedKeys(originalMetadataValue, rawMetadataValue), "metadata")

    const metadataType = metadataValue.metadata?.value_type

    if (metadataType === MultiFieldType.number && metadataValue.value) {

      rawMetadataValue = { ...rawMetadataValue, value: metadataValue.value.replace(/,/g, ".") }

      // If the value ends on "." we need to stop
      if (rawMetadataValue.value.endsWith(".")) return

      if (!isValidMultifieldNumber(rawMetadataValue.value, { decimals: "10" })) {

        setMetadataValueErrors(metadataValue.uuid, { value: [ t("dynamicFields.errors.onlyNumbers") ] })
        return

      }
    }

    const timestamp = Date.now()
    if (data.debounceUuid !== metadataValue.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
      debouncedUpdateMetadataValue?.flush()
      data.debounceUuid = metadataValue.uuid
      data.payloadKeys = payloadKeys
    }
    data.debounceTimestamp = timestamp

    // We need to push the rawMetadataValue in case it was a number and we had to make format changes
    if (!payloadKeys.includes("value_source")) pushOrUpdateMetadataValue(rawMetadataValue)
    addUpdatingMetadataValue(metadataValue.uuid)
    return await debouncedUpdateMetadataValue(
      context,
      entityUuid,
      rawMetadataValue,
      originalMetadataValue,
      timestamp,
      payloadKeys,
    )
  }

  const handleShowBoundingBox = async (metadataValue: MetadataValue, document: Document, pdfViewerComponent: InstanceType<typeof PdfViewer>, scrollContainer: InstanceType<typeof OverlayScrollbar>, prosemirrorUuidBoundingBoxes: ProseMirrorUuidBoundingBox[], callback: () => void) => {
    if (document?.content_type === DocumentContentType.pdf || [ DocumentOrigin.signed_pdf, DocumentOrigin.pdf ].includes(document?.origin)) {
      await pdfViewerComponent?.scrollToBoundingBoxAndHighlight(metadataValue.metadata?.name, scrollContainer, metadataValue.bounding_box, callback)
    }
    else if ([ DocumentContentType.html, DocumentContentType.prosemirror_data ].includes(document?.content_type) || [ DocumentOrigin.scratch, DocumentOrigin.template ].includes(document?.origin)) {
      createAndScrollToHtmlBoundingBox(metadataValue, scrollContainer, prosemirrorUuidBoundingBoxes, callback)
    }
  }

  const createAndScrollToHtmlBoundingBox = (metadataValue: MetadataValue, scrollContainer: InstanceType<typeof OverlayScrollbar>, prosemirrorUuidBoundingBoxes: ProseMirrorUuidBoundingBox[], callback: any) => {

    if (!prosemirrorUuidBoundingBoxes?.length) return
    const referencesToHighlight = prosemirrorUuidBoundingBoxes.filter((el) => el.key === metadataValue.metadata?.name)

    destroyHtmlBoundingBoxes()

    referencesToHighlight?.forEach((reference) => {

      const refMetadata = data.metadata?.find((el) => el.name === reference.key)
      if (!refMetadata) return
      const isClause = refMetadata.value_type === MultiFieldType.clause
      const metadataLabel = getMetadataDisplayName(refMetadata, t)

      const referenceElement = scrollContainer.element?.querySelector<HTMLCanvasElement>(`#aiReviewRenderedHtmlContainer [data-uuid="${reference.prosemirrorUuid}"], #aiReviewRenderedHtmlContainer [data-list-uuid="${reference.prosemirrorUuid}"], #renderedHtmlContainer [data-uuid="${reference.prosemirrorUuid}"], #renderedHtmlContainer [data-list-uuid="${reference.prosemirrorUuid}"], #editorContainer [data-uuid="${reference.prosemirrorUuid}"], #editorContainer [data-list-uuid="${reference.prosemirrorUuid}"]`)

      if (!referenceElement) return

      const colorSchema = isClause ? [ "bg-purple-500/10", "border-purple-700", "bg-purple-700" ] : [ "bg-indigo-500/10", "border-indigo-700", "bg-indigo-700" ]

      const boundingBoxElement = document.createElement("div")
      boundingBoxElement.classList.add("bounding-box", "absolute", "invisible", "border-2", "rounded-md", colorSchema[0], colorSchema[1])
      boundingBoxElement.style.top = `-0.5rem`
      boundingBoxElement.style.right = `-0.5rem`
      boundingBoxElement.style.bottom = `-0.5rem`
      boundingBoxElement.style.left = `-0.5rem`
      boundingBoxElement.style.zIndex = "1"
      boundingBoxElement.setAttribute("data-key", reference.key)

      generateBoundingBoxOffsetLabel(boundingBoxElement, metadataLabel, colorSchema[2])
      referenceElement.appendChild(boundingBoxElement)
    })

    scrollToBoundingBoxAndHighlight(metadataValue.metadata?.name, scrollContainer, prosemirrorUuidBoundingBoxes, callback)
  }

  const scrollToBoundingBoxAndHighlight = (key: Metadata["name"], scrollContainer: InstanceType<typeof OverlayScrollbar>, prosemirrorUuidBoundingBoxes: ProseMirrorUuidBoundingBox[], callback: () => void) => {
    if (!key || !scrollContainer || !prosemirrorUuidBoundingBoxes?.length) return
    const boundingBoxElementsOfKey = document.querySelectorAll(`#aiReviewRenderedHtmlContainer [data-key="${key}"], #renderedHtmlContainer [data-key="${key}"], #editorContainer [data-key="${key}"]`)
    const firstElement = boundingBoxElementsOfKey[0] as HTMLElement
    if (!firstElement) return
    scrollTo(scrollContainer, firstElement, () => {
      boundingBoxElementsOfKey.forEach((el) => {
        el.classList.remove("invisible")
        el.classList.add("bounce-enter-active")
      })
      if (callback) callback()
    })
  }

  const destroyHtmlBoundingBoxes = () => {
    const boundingBoxElements = document.querySelectorAll(`#aiReviewRenderedHtmlContainer [data-key], #renderedHtmlContainer [data-key], #editorContainer [data-key]`)
    boundingBoxElements.forEach((el) => el.remove())
  }

  const handleHideBoundingBoxes = (document: Document, pdfViewerComponent: any) => {
    if (document?.content_type === DocumentContentType.pdf) {
      pdfViewerComponent?.hideBoundingBoxes()
    }
    else if ([ DocumentContentType.html, DocumentContentType.prosemirror_data ].includes(document?.content_type)) {
      destroyHtmlBoundingBoxes()
    }
  }

  return {
    ...toRefs(data),
    pendingMetadataValues,
    boundingBoxes,
    prosemirrorUuidBoundingBoxes,

    // mutations
    setMetadata,
    setMetadataValues,
    pushMetadataValue,
    pushOrUpdateMetadataValue,
    setMetadataValueErrors,
    removeMetadataValueFromStore,
    setHasChangedDurationType,

    // api actions
    fetchMetadataValues,
    fetchMetadata,
    fetchCalendarData,
    removeMetadataValueFromEntityAction,
    getMetadataValue,

    // metadataValue CRUD,
    createMetadataValue,
    updateMetadataValue,
    removeMetadataValueFromEntity,

    // ui actions
    updateLocalMetadataValue,
    handleShowBoundingBox,
    handleHideBoundingBoxes,
  }
})
