<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  ><path
    fill="#D32D27"
    d="M31 27a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v18z"
  /><path
    fill="#FFF"
    d="M25 16.063h-5v-5h-4v5h-5V20h5v5.063h4V20h5z"
  /></svg>
</template>
