<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      class="secondary"
      d="M11.47,6.92l2.62-2.62L12.8,3.02c-0.26-0.26-0.72-0.26-0.98,0l-1.64,1.64c-0.28,0.26-0.28,0.72,0,0.99"
    />
    <polygon
      class="secondary"
      points="14.83,5.04 12.21,7.66 19.95,15.4 22.56,15.4 22.56,12.78 "
    />
    <path
      class="primary"
      d="M4.54,13.93c0.44-0.14,0.91,0.11,1.04,0.55c0.14,0.44-0.11,0.91-0.55,1.04c-1.29,0.39-2.15,1.16-2.43,1.87c-0.08,0.19-0.1,0.37-0.07,0.51c0.02,0.11,0.09,0.21,0.2,0.29c0.47,0.34,1.48,0.43,3.18,0.02c1.01-0.25,2.08-0.58,3.04-0.87c0.97-0.3,1.83-0.56,2.42-0.67c1.09-0.2,2.18-0.34,3.06-0.3c1.15,0.06,2.02,0.43,2.32,1.31c0.19,0.57,0.14,1.07,0.09,1.52c-0.02,0.16-0.03,0.31-0.03,0.41c0,0,0.18-0.01,1.02-0.08c0.98-0.08,1.99-0.46,2.99-0.84c0.51-0.2,1.02-0.39,1.6-0.57c0.44-0.13,0.91,0.12,1.04,0.56c0.13,0.44-0.12,0.91-0.56,1.04c-0.45,0.14-0.97,0.34-1.49,0.53c-1.11,0.42-2.24,0.85-3.45,0.95c-2.22,0.18-2.74-0.45-2.82-1.47c-0.02-0.24,0.01-0.47,0.03-0.72c0.03-0.28,0.06-0.59-0.01-0.81c-0.03-0.1-0.35-0.15-0.81-0.17c-0.74-0.04-1.7,0.09-2.68,0.27c-0.49,0.09-1.3,0.34-2.23,0.63c-0.97,0.3-2.07,0.64-3.14,0.9c-2.24,0.54-3.72,0.31-4.56-0.29c-0.47-0.34-0.75-0.79-0.85-1.29c-0.1-0.47-0.04-0.97,0.16-1.47C1.49,15.66,2.74,14.48,4.54,13.93L4.54,13.93z"
    />
  </svg>
</template>

<style scoped>
.primary {
  fill: theme("colors.indigo.200");
}

.secondary {
  fill: theme("colors.indigo.500");
}
</style>
