<template>
  <svg
    id="Lager_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 315.9 298.8"
    xml:space="preserve"
    class="BankIdButton_icon_eIwIl"
  ><path
    d="M99.7 156l13.2-83.2H98.8c-6.6 0-15.1-3.7-17.6-10.5-.8-2.3-2.7-10.2 8.2-17.9 3.9-2.7 6.4-5.7 6.9-8 .5-2.4-.1-4.5-1.8-6.1-2.4-2.3-7.1-3.6-13.1-3.6-10.1 0-17.2 5.8-17.9 10-.5 3.1 1.9 5.6 4 7.2 6.3 4.7 7.8 11.5 3.9 17.9-4 6.6-12.7 10.9-22 11H35c-1.2 8.1-20.8 132.3-22.3 142.1h77.8c.7-4.4 4.3-27.8 9.2-58.9zM8.4 243.6h31.9c13.6 0 16.9 6.9 15.9 13.2-.8 5.1-4.3 8.9-10.3 11.4 7.6 2.9 10.6 7.4 9.5 14.5-1.4 8.9-9.1 15.5-19.2 15.5H0l8.4-54.6zm21.1 22.6c6.2 0 9.1-3.3 9.7-7.2.6-4.2-1.3-7.1-7.5-7.1h-5.5L24 266.2h5.5zm-3.4 23.6c6.4 0 10.1-2.6 11-7.9.7-4.6-1.9-7.3-8.1-7.3h-6.2l-2.4 15.3h5.7zM100.1 298.6c-8.3.6-12.3-.3-14.3-3.9-4.4 2.7-9.3 4.1-14.5 4.1-9.4 0-12.7-4.9-11.8-10.3.4-2.6 1.9-5.1 4.3-7.2 5.2-4.5 18-5.1 23-8.5.4-3.8-1.1-5.2-5.8-5.2-5.5 0-10.1 1.8-18 7.2l1.9-12.4c6.8-4.9 13.4-7.2 21-7.2 9.7 0 18.3 4 16.7 14.6l-1.9 12c-.7 4.2-.5 5.5 4.2 5.6l-4.8 11.2zm-14.4-18.8c-4.4 2.8-12.6 2.3-13.5 8.1-.4 2.7 1.3 4.7 4 4.7 2.6 0 5.8-1.1 8.4-2.9-.2-1-.1-2 .2-3.9l.9-6zM115.7 255.9h16.6l-.9 5.5c5.3-4.5 9.3-6.2 14.5-6.2 9.3 0 13.6 5.7 12.1 15l-4.3 27.9h-16.6l3.6-23.1c.7-4.2-.6-6.2-3.8-6.2-2.6 0-5 1.4-7.3 4.5l-3.8 24.7h-16.6l6.5-42.1zM170.9 243.6h16.6l-4.2 26.8 15.9-14.5h20.5l-20.4 18 16.4 24.2h-20.9l-12.6-19.5h-.2l-3 19.5h-16.6l8.5-54.5zM229.7 243.6h19.1l-8.4 54.5h-19.1l8.4-54.5zM258.1 243.6h27.3c21.1 0 27.2 15.3 25.2 28-1.9 12.4-11.7 26.5-30.2 26.5h-30.8l8.5-54.5zm17.7 41.5c9.3 0 14.4-4.6 15.9-14.3 1.1-7.2-1.1-14.3-11.4-14.3h-5.1l-4.4 28.6h5zM204.2 0h-79.5l-10.6 67.3h13.5c7.4 0 14.4-3.4 17.4-8.3 1-1.6 1.4-3 1.4-4.3 0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9 0-.6 0-1.1.1-1.6 1.1-7.1 10.7-14.8 23.4-14.8 7.6 0 13.4 1.8 16.9 5.1 3.1 2.9 4.3 7 3.4 11.3-1.1 5.1-6.2 9.3-9.1 11.4-7.7 5.4-6.7 10.1-6.2 11.5 1.6 4.2 7.7 6.9 12.4 6.9h20.6v.1c28 .2 43 13.1 38.3 43.1-4.4 27.9-25.8 39.9-51.3 40.1L174.7 215h14.9c62.9 0 114.3-40.4 124.4-104.2C326.5 31.7 276.3 0 204.2 0z"
    class="bankid-st0"
  /></svg>
</template>