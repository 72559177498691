<template>
  <svg
    vxmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M1.9,5.8c-1.1,0-2,0.9-2,2v8h2v-4h2v4h2v-8c0-1.1-0.9-2-2-2H1.9 M1.9,7.8h2v2h-2V7.8z" />
    <path d="M24.1,5.8v2h-1v6h1v2h-4v-2h1v-6h-1v-2H24.1z" />
    <path
      d="M16,13.8v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V7.8c0-1.1-0.9-2-2-2h-4v2h4v2h-2v2h2v2h-4v2h4
	C15.1,15.8,16,14.9,16,13.8"
    />
    <polyline points="7,15.7 9,15.7 9,13.7 7,13.7 7,15.7 " />
    <polyline points="0,19.2 19,19.2 19,17.2 0,17.2 0,19.2 " />
    <polyline points="17,15.7 19,15.7 19,13.7 17,13.7 17,15.7 " />
  </svg>
</template>