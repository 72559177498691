<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36 36"
  >
    <path
      fill="currentColor"
      d="M29.7,3c0-0.3-0.3-0.6-0.6-0.6h-16c-0.4-1-1.4-1.7-2.5-1.7c-1.1,0-2.1,0.7-2.5,1.7H7.4C7,2.4,6.8,2.7,6.8,3
	v31.3c0,0.3,0.3,0.6,0.6,0.6h21.8c0.3,0,0.6-0.3,0.6-0.6v-0.9 M10.6,1.9c0.5,0,0.9,0.2,1.2,0.6H9.4C9.7,2.1,10.1,1.9,10.6,1.9z
	 M28.6,33.8H7.9V3.6h4.3c0,0,0,0,0,0.1v5.2c0,0.5-0.4,1-0.9,1.1c-0.5,0-1-0.5-0.9-1.1V8.5c0,0,0,0,0-0.1V5.9c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.2,0.1,0.2,0.3v2.5h1.1V5.9c0-0.8-0.6-1.4-1.3-1.5c-0.8,0-1.4,0.7-1.3,1.5v1.4c0,0,0,0,0,0.1v1.6c0,1.2,0.9,2.2,2.1,2.2
	c1.2,0,2.1-1,2.1-2.2V3.7c0,0,0,0,0-0.1h15.2V33.8z"
    />
    <rect
      x="24.5"
      y="5.9"
      fill="currentColor"
      width="2.9"
      height="1.1"
    />
    <rect
      x="22.2"
      y="8.1"
      fill="currentColor"
      width="5.1"
      height="1.1"
    />
    <rect
      x="9.1"
      y="13.3"
      fill="currentColor"
      width="18.3"
      height="1.1"
    />
    <rect
      x="9.1"
      y="15.6"
      fill="currentColor"
      width="18.3"
      height="1.1"
    />
    <rect
      x="9.1"
      y="17.9"
      fill="currentColor"
      width="18.3"
      height="1.1"
    />
    <rect
      x="9.1"
      y="20.2"
      fill="currentColor"
      width="8"
      height="1.1"
    />
    <rect
      x="19.4"
      y="27.7"
      fill="currentColor"
      width="7.4"
      height="1.1"
    />
    <path
      fill="currentColor"
      d="M20.9,26c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.2-0.1-0.5,0.1-0.8c0.1-0.1,0.3-0.2,0.4-0.2
	c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.5,0.2,0.7c0.2,0.3,0.5,0.4,0.8,0.4
	c0.1,0,0.2,0,0.2,0c0.3-0.1,0.6-0.4,0.7-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.5,0.2-0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0.1,0.5
	c0.1,0.5,0.5,1,1.1,1h0c0.2,0,0.5-0.1,0.7-0.3l1-0.6l-0.6-1l-1,0.6l-0.1,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.6
	c-0.1-0.6-0.7-1-1.2-0.9c-0.3,0.1-0.6,0.2-0.7,0.5c-0.2,0.2-0.3,0.5-0.4,0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.6-0.5-1.1-1-1.3
	c-0.7-0.2-1.4,0.1-1.8,0.6c-0.3,0.5-0.4,1.1-0.2,1.7c0.1,0.5,0.4,0.9,0.7,1.3L20.9,26z"
    />
  </svg>
</template>