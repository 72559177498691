import axios from "axios"
import { AccountUser, Template, Document, CrudContext, Image } from "~/types"

interface CreateImageRes {
  data: {
    created_by_account_user: AccountUser
    image_data: string
    uuid: number
    ref_uuid: string
  }
}
export const createImageAction = async (
  uuid: string,
  entityUuid: Template["uuid"] | Document["uuid"],
  context: CrudContext,
): Promise<CreateImageRes["data"]> => {
  try {
    const url = route(
      `api.${context}s.images.store`,
      entityUuid,
    )

    const res = await axios.post<CreateImageRes>(
      url,
      {
        image_uuid: uuid,
      },
    )

    return res.data.data
  } catch (err) {
    console.error(err, "\n\n err: createImageAction", { uuid })
  }
}

interface ReadImageRes {
  data: Image
}
export const readImageAction = async (
  uuid: Image["uuid"],
  entityUuid: Template["uuid"] | Document["uuid"],
  context: CrudContext,
): Promise<ReadImageRes["data"]> => {
  try {
    const url = route(
      `api.${context}s.images.show`,
      {
        image: uuid,
        [context]: entityUuid,
      },
    )

    const res = await axios.get<ReadImageRes>(url)

    return res.data.data
  } catch (err) {
    console.error(err, "\n\n err: readImageAction", { uuid })
  }
}

export const fetchImagesAction = async (
  entityUuid: Template["uuid"] | Document["uuid"],
  context: CrudContext,
): Promise<ReadImageRes["data"][]> => {
  try {
    const url = route(
      `api.${context}s.images.index`,
      entityUuid,
    )

    const res = await axios.get<{ data: Image[] }>(url)

    return res.data.data
  } catch (err) {
    console.error(err, "\n\n err: fetchImagesAction", { entityUuid, context })
  }
}
