import { createI18n } from "vue-i18n"

// app
import en from "~/locales/en.json"
import de from "~/locales/de.json"

const locales = { en, de }

const locale = document.querySelector("html").getAttribute("lang")?.split("-")?.[0]

const instance = createI18n({
  locale: locale, // set locale,
  fallbackLocale: "en",
  messages: locales,
})
export default instance

export const i18n = instance.global
