import { JSONContent } from "@tiptap/core"
import axios from "axios"

import { Activity, DocumentEditorSession, Document, DocumentRevision, DocumentVariable, WorkingCopy, DocumentUser, Tag, Comment, SigningPhase, DocumentWorkflowState } from "~/types"

export const fetchDocumentAction = async (
  uuid: Document["uuid"],
): Promise<Document | void> => {
  const fetchDocumentRes = await axios.get<{ data: Document }>(route( "api.documents.show", uuid))
  return fetchDocumentRes.data.data
}

export const updateDocumentAction = async (
  uuid: Document["uuid"],
  payload: Partial<Document>,
): Promise<Document | void> => {
  const updateDocumentRes = await axios.patch<{ data: Document }>(route("api.documents.update", uuid), payload)
  return updateDocumentRes.data.data
}

export const fetchRevisionsAction = async (
  uuid: Document["uuid"],
): Promise<DocumentRevision[] | void> => {
  const fetchRevisionsRes = await axios.get<{ data: DocumentRevision[] }>(route( "api.documents.document-revisions.index", uuid))
  return fetchRevisionsRes.data.data
}

export const fetchActivitiesAction = async (
  uuid: Document["uuid"],
): Promise<Activity[] | void> => {
  const fetchActivitiesRes = await axios.get<{ data: Activity[] }>(route( "api.documents.document-activities.index", uuid))
  return fetchActivitiesRes.data.data
}

export const getDocumentEditorSessionAction = async (
  documentUuid: Document["uuid"],
  documentEditorSessionUuid: DocumentEditorSession["uuid"],
): Promise<DocumentEditorSession | void> => {
  const routeString = `api.documents.document-editor-sessions.show`
  const url = route(routeString, {
    document: documentUuid,
    document_editor_session: documentEditorSessionUuid,
  })

  try {
    const getDocumentEditorSessionRes = await axios.get<{ data: DocumentEditorSession }>(url)

    return getDocumentEditorSessionRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const createDocumentEditorSessionAction = async (
  uuid: Document["uuid"],
  // This is needed so that the comment can be resolved in backend
  commentUuid: Comment["uuid"] = "",
): Promise<DocumentEditorSession | void> => {
  let payload = {}
  // This is needed so that the comment can be resolved in backend
  if (commentUuid) payload = { comment_uuid: commentUuid }
  const documentEditorSessionRes = await axios.post<{ data: DocumentEditorSession }>(route( "api.documents.document-editor-sessions.store", uuid), payload)
  return documentEditorSessionRes.data.data
}

export const updateDocumentEditorSessionAction = async (
  uuid: Document["uuid"],
  editorSessionUuid: DocumentEditorSession["uuid"],
  payload: Partial<DocumentEditorSession> = null,
): Promise<DocumentEditorSession | void> => {
  const documentEditorSessionRes = await axios.put<{ data: any }>(route( "api.documents.document-editor-sessions.update", {
    document_editor_session: editorSessionUuid,
    document: uuid,
  }), payload)
  return documentEditorSessionRes.data.data
}

export const destroyDocumentEditorSessionAction = async (
  uuid: Document["uuid"],
  editorSessionUuid: DocumentEditorSession["uuid"],
): Promise<any | void> => {
  const documentEditorSessionRes = await axios.delete<{ data: any }>(route( "api.documents.document-editor-sessions.destroy", {
    document_editor_session: editorSessionUuid,
    document: uuid,
  }))
  return documentEditorSessionRes
}

export const fetchEditorContentAction = async (
  uuid: Document["uuid"],
): Promise<JSONContent | void> => {
  const fetchEditorContentRes = await axios.get<{ data: { uuid: number, prosemirror_data: JSONContent} }>(route( "api.documents.document-prosemirror-data.index", uuid))
  return fetchEditorContentRes.data.data.prosemirror_data
}

export const fetchHtmlContentAction = async (
  uuid: Document["uuid"],
): Promise<string | void> => {
  const fetchHtmlContentRes = await axios.get<{ data: { uuid: number, html: string} }>(route( "api.documents.document-html.show", uuid))
  return fetchHtmlContentRes.data.data.html
}

export const fetchPdfUrlAction = async (
  uuid: Document["uuid"],
): Promise<string | void> => {
  const fetchPdfUrlRes = await axios.get<{ data: { uuid: number, pdf_url: string} }>(route( "api.documents.document-pdf.show", uuid))
  return fetchPdfUrlRes.data.data.pdf_url
}

export const getWorkingCopyAction = async (
  documentUuid: Document["uuid"],
  workingCopyUuid: WorkingCopy["uuid"],
): Promise<WorkingCopy | void> => {
  const routeString = `api.documents.working-copies.show`
  const url = route(routeString, {
    document: documentUuid,
    workingCopy: workingCopyUuid,
  })

  try {
    const getWorkingCopyRes = await axios.get<{ data: WorkingCopy }>(url)

    return getWorkingCopyRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const createWorkingCopyAction = async (
  uuid: Document["uuid"],
): Promise<WorkingCopy | void> => {
  const workingCopyRes = await axios.post<{ data: WorkingCopy }>(route( "api.documents.working-copies.store", uuid))
  return workingCopyRes.data.data
}

export const publishWorkingCopyAction = async (
  documentUuid: Document["uuid"],
  workingCopyUuid: WorkingCopy["uuid"],
): Promise<WorkingCopy | void> => {
  const workingCopyRes = await axios.put<{ data: WorkingCopy }>(route( "api.documents.working-copies.update", {
    document: documentUuid,
    working_copy: workingCopyUuid,
  }), {
    is_published: true,
  })
  return workingCopyRes.data.data
}

interface aiIntro {
  text?: string
  error?: string
}

export const generateAiIntroAction = async (
  documentUuid: Document["uuid"],
): Promise<aiIntro | void> => {
  const introRes = await axios.get<aiIntro>(route("api.documents.ai-intro", {
    document: documentUuid,
  }))
  return introRes.data
}

export const fetchDocumentVariablesAction = async (
  uuid: Document["uuid"],
): Promise<DocumentVariable[] | void> => {
  const fetchDocumentVariablesRes = await axios.get<{ data: DocumentVariable[] }>(route( "api.documents.document-variables.index", uuid))
  return fetchDocumentVariablesRes.data.data
}

export const fetchDocumentSigningPhasesAction = async (
  uuid: Document["uuid"],
): Promise<SigningPhase[] | void> => {
  const fetchSigningPhasesRes = await axios.get<{ data: SigningPhase[] }>(route( "api.documents.signing-phases.index", uuid))
  return fetchSigningPhasesRes.data.data
}

export const fetchDocumentWorkflowStateAction = async (
  uuid: Document["uuid"],
): Promise<Record<DocumentWorkflowState, boolean> | void> => {
  const fetchWorkflowStatesRes = await axios.get<{ data: Record<DocumentWorkflowState, boolean> }>(route( "api.documents.document-workflow-state.index", uuid))
  return fetchWorkflowStatesRes.data.data
}

interface PdfDownloadRes {
  pdf_url?: string
}

export const getPdfDownloadAction = async (documentUuid: Document["uuid"], documentRevisionUuid: DocumentRevision["uuid"]): Promise<PdfDownloadRes | void> => {
  if (!documentUuid || !documentRevisionUuid) return
  const apiUrl = route("api.documents.document-revisions.document-revision-pdf.show", { document: documentUuid, document_revision: documentRevisionUuid })
  const getUrlRes = await axios.get<{ data: { pdf_url: string } }>(apiUrl)
  return getUrlRes.data.data
}

export const syncSigningOrderAction = async (
  documentUuid: Document["uuid"],
  signingOrder: DocumentUser["uuid"][],
): Promise<DocumentUser["uuid"][] | void> => {
  const syncSigningOrderRes = await axios.post<{ data: DocumentUser["uuid"][] }>(route( "api.documents.signing-order.sync", documentUuid),
    {
      signing_order_array: signingOrder,
    })
  return syncSigningOrderRes.data.data
}

export const addTagAction = async (
  documentUuid: Document["uuid"],
  tagUuid: Tag["uuid"],
): Promise<Tag[]|void> => {
  const res = await axios.post<{ data: Tag[]}>(route("api.documents.tags.add", {
    document: documentUuid,
    tag: tagUuid,
  }))
  return res.data.data
}

export const removeTagAction = async (
  documentUuid: Document["uuid"],
  tagUuid: Tag["uuid"],
): Promise<Tag[]|void> => {
  const res = await axios.delete<{ data: Tag[]}>(route("api.documents.tags.remove", {
    document: documentUuid,
    tag: tagUuid,
  }))
  return res.data.data
}
