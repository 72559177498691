<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 64 64"
    viewBox="0 0 64 64"
    fill="currentColor"
  ><path
    d="M2.05,58c0,2.757,2.243,5,5,5h36C45.78,63,48,60.757,48,58v-0.583l3.895,3.895c1.159,1.121,2.668,1.68,4.174,1.68
		c1.528,0,3.051-0.576,4.201-1.726c2.287-2.286,2.3-6.044,0.02-8.385L60,52.592V13c0-2.757-2.243-5-5-5h-7V6c0-2.757-2.22-5-4.95-5
		h-36c-2.757,0-5,2.243-5,5V58z M58.855,59.852c-1.516,1.517-4.015,1.525-5.559,0.034l-10.961-10.96
		c-0.417-0.455-0.393-1.181-0.157-1.685l3.962-3.962c0.598-0.394,1.302-0.373,1.743,0.024l10.982,10.982
		C60.377,55.838,60.373,58.334,58.855,59.852z M26,41c-7.72,0-14-6.28-14-14s6.28-14,14-14s14,6.28,14,14S33.72,41,26,41z
		 M38.064,37.478l5.525,5.525l-1.586,1.586l-5.525-5.525C37.043,38.572,37.572,38.043,38.064,37.478z M55,10c1.654,0,3,1.346,3,3
		v37.592l-8.737-8.736c-0.371-0.337-0.805-0.563-1.263-0.701V38h6v-2h-6v-2h6v-2h-6v-2h6v-2h-6v-2h6v-2h-6v-2h6v-2h-6v-2h6v-2h-6v-6
		H55z M4.05,6c0-1.654,1.346-3,3-3h36C44.704,3,46,4.317,46,6v35.157c-0.334,0.102-0.657,0.262-0.964,0.465l-5.738-5.738
		C41.002,33.34,42,30.285,42,27c0-8.822-7.178-16-16-16s-16,7.178-16,16s7.178,16,16,16c3.285,0,6.34-0.998,8.884-2.702L37.586,43
		H26H9v2h30.586l1.012,1.012c-0.037,0.045-0.077,0.088-0.106,0.139c-0.153,0.271-0.26,0.558-0.34,0.85H9v2h31.163
		c0.143,0.48,0.381,0.93,0.729,1.309L46,55.417V58c0,1.683-1.296,3-2.95,3h-36c-1.654,0-3-1.346-3-3V6z"
  /><path
    d="M26,14c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S33.168,14,26,14z M26,38c-6.065,0-11-4.935-11-11
		s4.935-11,11-11s11,4.935,11,11S32.065,38,26,38z"
  /><path
    d="M24.845,29.431l-3.138-3.138l-1.414,1.414l4,4C24.481,31.896,24.736,32,25,32c0.033,0,0.065-0.002,0.099-0.005
		c0.298-0.029,0.567-0.191,0.733-0.44l6-9l-1.664-1.109L24.845,29.431z"
  /><rect
    width="32"
    height="2"
    x="9"
    y="5"
  /><polygon points="40.999 9 9 9 9 11 26 11 40.999 11" /><rect
    width="32"
    height="2"
    x="9"
    y="51"
  /><rect
    width="32"
    height="2"
    x="9"
    y="55"
  /></svg>
</template>
