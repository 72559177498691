import { debounce, pick, uniq, without, isEqual } from "lodash-es"
import { defineStore } from "pinia"
import { computed, reactive, toRaw, toRefs } from "vue"
import { useI18n } from "vue-i18n"

import { Document, FormErrors, Party, PartyEntityType, CrudContext, Template } from "~/types"
import { changedKeys, scrollTo } from "~/utils"

import {
  createPartyAction,
  fetchAccountPartiesAction,
  fetchAllPartiesAction,
  fetchPartyAction,
  removePartyFromEntityAction,
  updatePartyAction,
  getPartyAction,
  getPartySuggestionsAction,
} from "./partyStoreActions"

import { useSharedStore } from "../sharedStore"
import { useDocumentStore } from "../documentStore"
import { useTemplateStore } from "../templateStore"
import { useNotificationStore } from "../notificationStore"

const partiesContext = [ "document", "template" ]

const emptyParty = Object.freeze<Partial<Party>>(
  {
    scope: "internal_and_external",
  },
)

interface Data {
  parties: Party[]
  isLoadingParties: boolean
  isLoadingAccountParties: boolean
  uuidsOfUpdatingParty: Party["uuid"][]
  partyLastSavedMap: Record<Party["uuid"], number>
  partyErrorsMap: Record<Party["uuid"], FormErrors<Party>>
  partyUuidBeingLoaded: Party["uuid"]
  partyUuidBeingRemovedFromEntity: Party["uuid"]
  accountParties: Party[]
  newParty: Partial<Party> | null
  debounceTimestamp: number
  debounceUuid: Party["uuid"]
  payloadKeys: string[]
  triggerPartyPopoverTab: string
  isLoadingGetPartySuggestions: boolean
  partySuggestions: Partial<Party>[]
}
export const usePartyStore = defineStore(
  "partyStore",
  () => {

    const { t } = useI18n()

    const data = reactive<Data>({
      parties: [],
      isLoadingParties: false,
      isLoadingAccountParties: false,
      uuidsOfUpdatingParty: [],
      partyLastSavedMap: {},
      partyErrorsMap: {},
      partyUuidBeingLoaded: null,
      partyUuidBeingRemovedFromEntity: null,
      accountParties: [],
      newParty: Object.assign({}, emptyParty),
      debounceTimestamp: null,
      debounceUuid: null,
      payloadKeys: [],
      triggerPartyPopoverTab: null,
      isLoadingGetPartySuggestions: false,
      partySuggestions: [],
    })

    const { notify } = useNotificationStore()

    const sharedStore = useSharedStore()
    const documentStore = useDocumentStore()
    const templateStore = useTemplateStore()

    const pendingParties = computed(() => data.parties?.filter((el) => el.scope !== "internal_and_external" && (!el.entity_name || !el.entity_type)))

    const pendingPartiesIncludingExternallyChangeable = computed(() => data.parties?.filter((el) => {
      return !el.entity_name || !el.address || !el.entity_type
    }))

    // mutations
    const setParties = (parties: Party[]) => data.parties = parties
    const setAccountParties = (accountParties: Party[]) => data.accountParties = accountParties
    const pushParty = (party: Party) => data.parties = [ ...toRaw(data.parties || []), party ]
    const pushOrUpdateParty = (party: Partial<Party>) => {
      const localIndexOfParty = data.parties.findIndex(({ uuid }) => uuid === party.uuid)

      const partiesCopy = [ ...toRaw(data.parties) ]

      if (localIndexOfParty !== -1) {
        partiesCopy[localIndexOfParty] = {
          ...partiesCopy[localIndexOfParty],
          ...party,
        }

        data.parties = partiesCopy

        return
      }

      data.parties = [
        ...partiesCopy,
        party,
      ] as Party[] // TODO: change this to real types
    }

    const addUpdatingParty = (uuid: Party["uuid"]) => data.uuidsOfUpdatingParty = uniq([ ...toRaw(data.uuidsOfUpdatingParty), uuid ])

    const removeUpdatingParty = (uuid: Party["uuid"]) => data.uuidsOfUpdatingParty = without([ ...toRaw(data.uuidsOfUpdatingParty) ], uuid)

    const removePartyFromStore = (partyUuidToRemove: Party["uuid"]) => {
      const indexOfPartyToRemove = data.parties.findIndex((party) => party.uuid === partyUuidToRemove)

      if (indexOfPartyToRemove !== -1) {
        data.parties.splice(indexOfPartyToRemove, 1)
      }
    }

    const setPartyLastSaved = (uuid: Party["uuid"]) => {
      data.partyLastSavedMap = {
        ...toRaw(data.partyLastSavedMap || {}),
        [uuid]: Date.now(),
      }
    }

    const setPartyErrors = (uuid: Party["uuid"], partyErrors: FormErrors<Party>) => {
      data.partyErrorsMap = {
        ...toRaw(data.partyErrorsMap || {}),
        [uuid]: partyErrors,
      }
    }

    const setNewParty = (party: Partial<Party>) => data.newParty = party

    const updateNewParty = (payload: Partial<Party>) => {
      data.newParty = {
        ...toRaw(data.newParty || {}),
        ...payload,
      }
    }

    const setTriggerPartyPopoverTab = (tab: string) => data.triggerPartyPopoverTab = tab

    // api actions
    const fetchAllParties = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
    ): Promise<Party[] | void> => {
      if (!partiesContext.includes(context)) {
        console.error("context should be one of", partiesContext, " but given", context)
        return
      }

      try {
        data.isLoadingParties = true

        const parties = await fetchAllPartiesAction(context, entityUuid) || []

        setParties(parties)

        return parties
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingParties = false
      }
    }

    const fetchAccountParties = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
    ): Promise<Party[] | void> => {
      if (!partiesContext.includes(context)) {
        console.error("context should be one of", partiesContext, " but given", context)
        return
      }

      try {
        data.isLoadingAccountParties = true

        const accountParties = await fetchAccountPartiesAction(context, entityUuid) || []

        setAccountParties(accountParties)

        return accountParties
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingAccountParties = false
      }
    }

    const getParty = async (documentUuid: Document["uuid"], partyUuid: Party["uuid"]): Promise<Party | void> => {
      try {
        data.partyUuidBeingLoaded = partyUuid
        const party = await getPartyAction(documentUuid, partyUuid)
        if (party) {
          pushOrUpdateParty(party)
        }
        return party
      } catch (err) {
        console.error(err)
      } finally {
        data.partyUuidBeingLoaded = null
      }
    }

    // party CRUD
    const createParty = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      payload: Partial<Party>,
    ): Promise<Party | void> => {
      const createdParty = await createPartyAction(context, entityUuid, payload)

      if (createdParty) pushParty(createdParty)

      return createdParty
    }

    const fetchParty = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      partyUuid: Party["uuid"],
    ): Promise<Party> => {
      const party = await fetchPartyAction(context, entityUuid, partyUuid)

      if (party) pushOrUpdateParty(party)

      return party
    }

    const updateParty = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      party: Partial<Party>,
      originalParty: Partial<Party>,
      timestamp: number,
      payloadKeys: string[],
    ): Promise<Party | void> => {
      try {
        const payload = pick(party, payloadKeys)

        const updatedParty = await updatePartyAction(context, entityUuid, payload, originalParty.uuid)

        if (updatedParty) {
          if (!(data.debounceTimestamp > timestamp)) pushOrUpdateParty(updatedParty)
          setPartyLastSaved(party.uuid)
          setPartyErrors(party.uuid, {})
        }

        return updatedParty
      } catch (err) {
        const isBackendError = !!err.response?.data?.errors

        if (isBackendError) {
          setPartyErrors(party.uuid, err.response.data.errors)
        }
        else {
          notify({
            title: t("partyForm.errors.update"),
            message: err.response?.data?.message || err.message,
            type: "error",
          })
        }
      } finally {
        removeUpdatingParty(party.uuid)
      }
    }

    const removePartyFromEntity = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      partyUuidToRemove: Party["uuid"],
    ) : Promise<Party[] | void> => {
      try {
        data.partyUuidBeingRemovedFromEntity = partyUuidToRemove

        const removeRes = await removePartyFromEntityAction(
          context,
          entityUuid,
          partyUuidToRemove,
        )

        if (removeRes === 200) removePartyFromStore(partyUuidToRemove)

        return data.parties
      } catch (err) {
        console.error(err)

        notify({
          title: t("parties.errors.remove"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        data.partyUuidBeingRemovedFromEntity = null
      }
    }


    // ui actions
    const jumpToParty = (
      partyRefUuid: Party["ref_uuid"],
    ) => {
      const editorElements = document.querySelectorAll<HTMLDivElement>("[data-party-button=\"" + partyRefUuid + "\"]")
      const editorElement: HTMLDivElement = editorElements[0]?.closest("[data-node-view-wrapper]")

      const mainContentScrollContainer = sharedStore.crudContext === CrudContext.document ? documentStore.mainContentScrollContainer : templateStore.mainContentScrollContainer
      if (editorElement) scrollTo(mainContentScrollContainer, editorElement, () => editorElements[0]?.click())

      const sidebarElement = document.getElementById("editPartyButton_" + partyRefUuid)

      sidebarElement?.classList.add("animate-pulse")
      setTimeout(() => { sidebarElement?.classList.remove("animate-pulse") }, 4000)

      if (!editorElements.length && sidebarElement) {
        sidebarElement.focus()
        setTimeout(() => sidebarElement.click())
      }

    }

    const debouncedUpdateParty = debounce(updateParty, 1000)

    const updateLocalParty = (
      party: Partial<Party>,
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
    ) => {
      if (!party) return
      const originalParty = { ...toRaw(data.parties.find((p) => p.uuid === party.uuid)) }
      let rawParty = { ...toRaw(party) }
      const payloadKeys = changedKeys(originalParty, rawParty)

      if (payloadKeys.includes("account_party_uuid") && !rawParty.account_party_uuid && rawParty.entity_type) {
        payloadKeys.push("entity_type")
      }

      if (payloadKeys.includes("entity_type")) {
        const allowedEntityType = [ PartyEntityType.tbd ].includes(party.entity_type) ? null : party.entity_type
        rawParty = { ...rawParty, entity_type: allowedEntityType }
      }

      const timestamp = Date.now()
      if (data.debounceUuid !== party.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
        debouncedUpdateParty?.flush()
        data.debounceUuid = party.uuid
        data.payloadKeys = payloadKeys
      }
      data.debounceTimestamp = timestamp

      pushOrUpdateParty(party)
      addUpdatingParty(party.uuid)
      debouncedUpdateParty(
        context,
        entityUuid,
        rawParty,
        originalParty,
        timestamp,
        payloadKeys,
      )
    }

    const getPartySuggestions = async (
      query: string,
    ) => {
      data.isLoadingGetPartySuggestions = true
      try {
        const res = await getPartySuggestionsAction(query)
        if (res) {
          data.partySuggestions = res
        }
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingGetPartySuggestions = false
      }
    }

    return {
      ...toRefs(data),
      pendingParties,
      pendingPartiesIncludingExternallyChangeable,

      // mutations
      setParties,
      setAccountParties,
      pushParty,
      pushOrUpdateParty,
      setPartyErrors,
      setPartyLastSaved,
      setNewParty,
      updateNewParty,
      removePartyFromEntity,
      setTriggerPartyPopoverTab,

      // api actions
      fetchAllParties,
      fetchAccountParties,
      removePartyFromEntityAction,
      getParty,

      // party CRUD
      createParty,
      fetchParty,
      updateParty,

      // ui actions
      jumpToParty,
      updateLocalParty,
      getPartySuggestions,
    }
  })
