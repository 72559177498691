import { defineStore } from "pinia"
import { nextTick, reactive, toRefs } from "vue"

import { Comment } from "~/types"

import { getHtmlFromProposalContainer, lookup } from "~/utils/helpers"
import { useDocumentStore } from "../documentStore"
import { JSONContent } from "@tiptap/core"

interface Data {
  prosemirrorDataUuid: Comment["prosemirror_data_uuid"]
  showProposalModal: boolean
  originalText: any
  proposedText: any
  proposedEditorContent: any
  resolveProposal: Comment
}

export const useProposalStore = defineStore("proposalStore", () => {
  const data = reactive<Data>({
    prosemirrorDataUuid: null,
    showProposalModal: false,
    originalText: "",
    proposedText: "",
    proposedEditorContent: null,
    resolveProposal: null,
  })

  const documentStore = useDocumentStore()
  const {
    getMainEditor,
  } = documentStore

  // mutations
  const setProsemirrorDataUuid = (prosemirrorDataUuid: string) => data.prosemirrorDataUuid = prosemirrorDataUuid
  const setShowProposalModal = (showProposalModal: boolean) => data.showProposalModal = showProposalModal
  const setOriginalText = (originalText: string) => data.originalText = originalText
  const setProposedText = (proposedText: string) => data.proposedText = proposedText
  const setProposedEditorContent = (proposedEditorContent: JSONContent) => data.proposedEditorContent = proposedEditorContent
  const setResolveProposal = (resolveProposal: Comment) => data.resolveProposal = resolveProposal

  const startNewProposal = (prosemirrorDataUuid: Comment["prosemirror_data_uuid"]) => {
    resetProposalStore()

    setProsemirrorDataUuid(prosemirrorDataUuid)
    const editorContent: JSONContent = getMainEditor()?.getJSON()
    const proposedContent = lookup(editorContent, "uuid", prosemirrorDataUuid)

    const originalHtmlFromEditor = getHtmlFromProposalContainer(`#editorContainer [data-uuid="${prosemirrorDataUuid}"]`)
    setOriginalText(originalHtmlFromEditor)
    setProposedText(originalHtmlFromEditor)

    setProposedEditorContent(
      {
        type: "doc",
        content: [
          proposedContent,
        ],
      },
    )

    setShowProposalModal(true)
  }

  const reviewProposal = async (resolveProposal: Comment) => {
    resetProposalStore()

    setResolveProposal(resolveProposal)
    setProsemirrorDataUuid(resolveProposal.prosemirror_data_uuid)

    const originalHtmlFromEditor = getHtmlFromProposalContainer(`#editorContainer [data-uuid="${resolveProposal.prosemirror_data_uuid}"]`)
    setOriginalText(originalHtmlFromEditor)

    let proseMirrorData = resolveProposal.prosemirror_data as JSONContent

    if (Array.isArray(proseMirrorData)) {
      proseMirrorData = {
        type: "doc",
        content: [
          {
            attrs: {
              uuid: resolveProposal.prosemirror_data_uuid,
            },
            type: "paragraph",
            content: proseMirrorData,
          },
        ],
      }
    }

    setProposedEditorContent(proseMirrorData)

    setShowProposalModal(true)

    await nextTick()

    const proposedHtmlFromEditor = getHtmlFromProposalContainer(`#proposalContainer [data-uuid="${resolveProposal.prosemirror_data_uuid}"]`)
    setProposedText(proposedHtmlFromEditor)
  }

  const resetProposalStore = () => {
    setProsemirrorDataUuid(null)
    setOriginalText("")
    setProposedText("")
    setProposedEditorContent(null)
    setResolveProposal(null)
  }


  return {
    ...toRefs(data),

    // mutations
    setUuid: setProsemirrorDataUuid,
    setShowProposalModal,
    setOriginalText,
    setProposedText,
    setProposedEditorContent,
    setResolveProposal,

    // ui actions
    startNewProposal,
    reviewProposal,
    resetProposalStore,
  }
})
