import { computed } from "vue"
import { DocumentStage } from "~/types"

export const useStages = (stage = null, checkpointPasses = null, useAll = false) => {

  // Calculate stages and their status
  const stages = computed(() => {
    const stageArray = []
    stageArray.push({
      name: DocumentStage.draft,
      colors: {
        hex: "#06B6D4",
        inner: "bg-cyan-500",
        outer: "bg-cyan-500 bg-opacity-25",
      },
      status:
      stage?.value === DocumentStage.approved_draft ||
      stage?.value === DocumentStage.review ||
      stage?.value === DocumentStage.approved_review ||
      stage?.value === DocumentStage.signing ||
      stage?.value === DocumentStage.signed
        ? "complete"
        : "incomplete",
    })
    const reviewCheckpoints = checkpointPasses?.value?.filter(
      (el) => el.checkpoint_document_stage === DocumentStage.review,
    )
    if (useAll || reviewCheckpoints?.length) {
      stageArray.push({
        name: DocumentStage.approved_draft,
        colors: {
          hex: "#06B6D4",
          inner: "bg-cyan-500",
          outer: "bg-cyan-500 bg-opacity-25",
        },
        status: useAll ? "incomplete" : reviewCheckpoints.some(
          (el) => el.status === "ready" || el.status === "upcoming",
        )
          ? "incomplete"
          : reviewCheckpoints.some((el) => el.status === "active")
            ? "active"
            : "complete",
      })
    }

    stageArray.push({
      name: DocumentStage.review,
      colors: {
        hex: "#6366F1",
        inner: "bg-indigo-500",
        outer: "bg-indigo-500 bg-opacity-25",
      },
      status:
        stage?.value === DocumentStage.signing ||
        stage?.value === DocumentStage.signed
          ? "complete"
          : "incomplete",
    })
    const signingCheckpoints = checkpointPasses?.value?.filter(
      (el) => el.checkpoint_document_stage === DocumentStage.signing,
    )
    if (useAll || signingCheckpoints?.length) {
      stageArray.push({
        name: DocumentStage.approved_review,
        colors: {
          hex: "#6366F1",
          inner: "bg-indigo-500",
          outer: "bg-indigo-500 bg-opacity-25",
        },
        status: useAll ? "incomplete" : signingCheckpoints.some(
          (el) => el.status === "ready" || el.status === "upcoming",
        )
          ? "incomplete"
          : signingCheckpoints.some((el) => el.status === "active")
            ? "active"
            : "complete",
      })
    }
    stageArray.push(
      {
        name: DocumentStage.signing,
        colors: {
          hex: "#EAB308",
          inner: "bg-yellow-500",
          outer: "bg-yellow-500 bg-opacity-25",
        },
        status: stage?.value === DocumentStage.signed ? "complete" : "incomplete",
      },
      {
        name: DocumentStage.signed,
        colors: {
          hex: "#22C55E",
          inner: "bg-green-500",
          outer: "bg-green-500 bg-opacity-25",
        },
        status: stage?.value === DocumentStage.signed ? "complete" : "incomplete",
      },
    )
    return stageArray
  })

  return {
    stages,
  }
}

export default useStages
