<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  ><path
    fill="#EEE"
    d="M0 13h36v10H0z"
  /><path
    fill="#ED2939"
    d="M32 5H4a4 4 0 0 0-4 4v4h36V9a4 4 0 0 0-4-4zM4 31h28a4 4 0 0 0 4-4v-4H0v4a4 4 0 0 0 4 4z"
  /></svg>
</template>
