<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.5 21"
  >
    <path
      d="M15.75,12.75v-2.62c0-1.86-1.51-3.38-3.38-3.38h-1.5c-.62,0-1.12-.5-1.12-1.12v-1.5c0-1.86-1.51-3.38-3.38-3.38h-1.88m2.25,0H1.88C1.25,.75,.75,1.25,.75,1.88V19.12c0,.62,.5,1.12,1.12,1.12H14.62c.62,0,1.12-.5,1.12-1.12V9.75C15.75,4.78,11.72,.75,6.75,.75Z"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      fill="currentColor"
      d="M7.87,10.88l1.31-1.31-.64-.64c-.13-.13-.36-.13-.49,0l-.82,.82c-.14,.13-.14,.36,0,.49"
    />
    <polygon
      fill="currentColor"
      points="9.55 9.94 8.24 11.25 12.11 15.12 13.41 15.12 13.41 13.81 9.55 9.94"
    />
    <path
      fill="currentColor"
      d="M4.4,14.39c.22-.07,.45,.05,.52,.28,.07,.22-.06,.45-.28,.52-.64,.2-1.08,.58-1.22,.94-.04,.1-.05,.19-.03,.26,0,.06,.04,.1,.1,.15,.24,.17,.74,.22,1.59,.01,.51-.12,1.04-.29,1.52-.44,.49-.15,.91-.28,1.21-.34,.55-.1,1.09-.17,1.53-.15,.57,.03,1.01,.22,1.16,.65,.1,.28,.07,.53,.05,.76-.01,.08-.02,.15-.02,.2,0,0,.09,0,.51-.04,.49-.04,.99-.23,1.49-.42,.26-.1,.51-.19,.8-.28,.22-.06,.45,.06,.52,.28,.06,.22-.06,.45-.28,.52-.23,.07-.48,.17-.74,.27-.56,.21-1.12,.43-1.73,.48-1.11,.09-1.37-.23-1.41-.73-.01-.12,0-.23,.01-.36,.01-.14,.03-.3,0-.4-.01-.05-.18-.07-.41-.09-.37-.02-.85,.05-1.34,.14-.24,.05-.65,.17-1.11,.31-.49,.15-1.03,.32-1.57,.45-1.12,.27-1.86,.15-2.28-.15-.24-.17-.38-.4-.43-.64-.05-.23-.02-.48,.08-.73,.22-.56,.85-1.15,1.75-1.43h0Z"
    />
  </svg>
</template>