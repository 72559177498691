<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 66 66"
    viewBox="0 0 66 66"
    fill="currentColor"
  ><path
    d="M39.1,24.2c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.5,5.7,5.7,5.7c0,0,0,0,0,0
		C36.6,29.9,39.1,27.4,39.1,24.2z M33.4,28L33.4,28c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8
		C37.2,26.3,35.5,28,33.4,28z"
  /><path
    d="M48.9,62.8c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-3.1-2.9-5.6-6.6-6c-0.5-2.5-3-4.5-6.1-4.8v-6.4c0.3-0.6,0.7-1.2,1.1-1.9
		c2.7,1.8,5.8,5.4,7,8.3c0.1,0.4,0.5,0.6,0.9,0.6c0,0,0,0,0,0c0.4,0,0.8-0.3,0.9-0.7c0.1-0.2,1.7-5.6,1.9-7.5
		c0.7-5.8-1.4-10.9-5.7-13.4c0.6-2.4,0.9-4.8,0.9-7.2C45.1,9.9,34.4,1.5,34,1.2c-0.3-0.3-0.8-0.3-1.2,0c-0.5,0.3-11.1,8.7-11.1,22.6
		c0,2.4,0.3,4.8,0.9,7.2c-4.2,2.6-6.4,7.7-5.7,13.4c0.3,1.9,1.9,7.3,1.9,7.5c0.1,0.4,0.5,0.7,0.9,0.7c0,0,0,0,0,0
		c0.4,0,0.8-0.2,0.9-0.6c1.2-2.9,4.2-6.5,6.9-8.3c0.4,0.6,0.7,1.3,1,1.9V52c-3.1,0.3-5.6,2.3-6.1,4.8c-3.7,0.4-6.6,2.9-6.6,6
		c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-2.3,2.5-4.1,5.5-4.1c0.5,0,1-0.4,1-1c0-2.1,2.3-3.8,5.2-3.8c0.5,0,1-0.4,1-1v-6.6h5.6v6.6
		c0,0.5,0.4,1,1,1c2.9,0,5.2,1.7,5.2,3.8c0,0.5,0.4,1,1,1C46.4,58.7,48.9,60.6,48.9,62.8z M48,44.2c-0.1,1-0.7,3.2-1.2,4.9
		c-1.7-2.7-4.3-5.5-6.7-7c1.4-2.7,2.7-5.8,3.6-9.1C47,35.2,48.6,39.3,48,44.2z M33.4,3.2c1.2,1.1,4.1,3.9,6.4,8
		c-3.8,2.6-8.9,2.6-12.8,0C29.3,7.1,32.2,4.3,33.4,3.2z M20,49c-0.5-1.7-1.1-3.9-1.2-4.9c-0.6-4.8,1-8.9,4.3-11.2
		c0.9,3.3,2.2,6.4,3.6,9.1C24.3,43.6,21.7,46.3,20,49z M30.1,44.4c-3.5-6.1-6.5-13.6-6.5-20.6c0-4.1,1.1-7.8,2.5-10.8
		c2.2,1.4,4.7,2.2,7.3,2.2s5.1-0.7,7.3-2.2c1.4,3,2.5,6.7,2.5,10.8c0,7.5-3.5,15.3-6.6,20.6H30.1z"
  /><path
    d="M38.7 60.4c-.5 0-1 .4-1 1s.4 1 1 1c1.3 0 2.5.8 2.5 1.7 0 .5.4 1 1 1 .5 0 1-.4 1-1C43.1 62 41.1 60.4 38.7 60.4zM23.7 64c0 .5.4 1 1 1 .5 0 1-.4 1-1 0-.9 1.1-1.7 2.5-1.7.5 0 1-.4 1-1s-.4-1-1-1C25.6 60.4 23.7 62 23.7 64zM29.5 56.2v2.1c0 .5.4 1 1 1 .5 0 1-.4 1-1v-2.1c0-.5-.4-1-1-1C29.9 55.3 29.5 55.7 29.5 56.2zM32.9 51.7v4.6c0 .5.4 1 1 1 .5 0 1-.4 1-1v-4.6c0-.5-.4-1-1-1C33.4 50.7 32.9 51.1 32.9 51.7zM53.8 32.1c.5 0 1-.4 1-1v-.7h.7c.5 0 1-.4 1-1s-.4-1-1-1h-.7v-.7c0-.5-.4-1-1-1-.5 0-1 .4-1 1v.7h-.7c-.5 0-1 .4-1 1s.4 1 1 1h.7v.7C52.9 31.6 53.3 32.1 53.8 32.1zM51.7 21.1v-.7h.7c.5 0 1-.4 1-1s-.4-1-1-1h-.7v-.7c0-.5-.4-1-1-1s-1 .4-1 1v.7h-.7c-.5 0-1 .4-1 1s.4 1 1 1h.7v.7c0 .5.4 1 1 1S51.7 21.6 51.7 21.1zM57.4 13.1v-.7h.7c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-.7V9.9c0-.5-.4-1-1-1s-1 .4-1 1v.7h-.7c-.5 0-1 .4-1 1 0 .5.4 1 1 1h.7v.7c0 .5.4 1 1 1S57.4 13.7 57.4 13.1zM13.8 15.8v-.7h.7c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-.7v-.7c0-.5-.4-1-1-1-.5 0-1 .4-1 1v.7h-.7c-.5 0-1 .4-1 1 0 .5.4 1 1 1h.7v.7c0 .5.4 1 1 1C13.4 16.8 13.8 16.4 13.8 15.8zM16.1 27.2c.5 0 1-.4 1-1v-.7h.7c.5 0 1-.4 1-1 0-.5-.4-1-1-1H17v-.7c0-.5-.4-1-1-1-.5 0-1 .4-1 1v.7h-.7c-.5 0-1 .4-1 1 0 .5.4 1 1 1h.7v.7C15.1 26.7 15.5 27.2 16.1 27.2zM9.6 39.3c.5 0 1-.4 1-1v-.7h.7c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-.7v-.7c0-.5-.4-1-1-1s-1 .4-1 1v.7H7.9c-.5 0-1 .4-1 1 0 .5.4 1 1 1h.7v.7C8.6 38.9 9 39.3 9.6 39.3z"
  /></svg>
</template>
