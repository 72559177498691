import { DashboardFilter, DashboardScope } from "~/types"

export const emptyFilter: DashboardFilter = {
  teams: [],
  tags: [],
  stages: [],
  contract_type: [],
  timeframe: null,
}

export const layouts = {
  [DashboardScope.personal]: {
    default: [
      { "h": 4, "i": "tasks", "w": 3, "x": 0, "y": 0 },
      { "h": 4, "i": "documentsByStage", "w": 3, "x": 0, "y": 4 },
      { "h": 4, "i": "contractValues", "w": 3, "x": 3, "y": 0 },
      { "h": 4, "i": "upcomingWorkload", "w": 3, "x": 3, "y": 4 },
      { "h": 2, "i": "createdDocuments", "w": 3, "x": 0, "y": 8 },
      { "h": 2, "i": "signedDocuments", "w": 3, "x": 3, "y": 8 },
      { "h": 7, "i": "documentList", "w": 6, "x": 0, "y": 18 },
      { "h": 8, "i": "documentCalendar", "w": 6, "x": 0, "y": 10 },
    ],
    large: [
      { "h": 4, "i": "tasks", "w": 3, "x": 0, "y": 0 },
      { "h": 3, "i": "documentsByStage", "w": 3, "x": 0, "y": 12 },
      { "h": 7, "i": "documentList", "w": 6, "x": 3, "y": 12 },
      { "h": 4, "i": "contractValues", "w": 3, "x": 3, "y": 0 },
      { "h": 4, "i": "upcomingWorkload", "w": 3, "x": 6, "y": 0 },
      { "h": 2, "i": "createdDocuments", "w": 3, "x": 0, "y": 15 },
      { "h": 2, "i": "signedDocuments", "w": 3, "x": 0, "y": 17 },
      { "h": 8, "i": "documentCalendar", "w": 9, "x": 0, "y": 4 },
    ],
  },
  [DashboardScope.team]: {
    default: [
      { "h": 4, "i": "documentsByTemplate", "w": 3, "x": 3, "y": 4 },
      { "h": 4, "i": "documentsByStage", "w": 3, "x": 0, "y": 4 },
      { "h": 8, "i": "documentCalendar", "w": 6, "x": 0, "y": 10 },
      { "h": 2, "i": "createdDocuments", "w": 3, "x": 3, "y": 8 },
      { "h": 4, "i": "contractValues", "w": 3, "x": 3, "y": 0 },
      { "h": 2, "i": "signedDocuments", "w": 3, "x": 0, "y": 8 },
      { "h": 4, "i": "upcomingWorkload", "w": 3, "x": 0, "y": 0 },
    ],
    large: [
      { "h": 4, "i": "documentsByTemplate", "w": 3, "x": 0, "y": 8 },
      { "h": 4, "i": "documentsByStage", "w": 3, "x": 0, "y": 4 },
      { "h": 8, "i": "documentCalendar", "w": 8, "x": 3, "y": 4 },
      { "h": 2, "i": "createdDocuments", "w": 3, "x": 8, "y": 0 },
      { "h": 4, "i": "contractValues", "w": 4, "x": 4, "y": 0 },
      { "h": 2, "i": "signedDocuments", "w": 3, "x": 8, "y": 2 },
      { "h": 4, "i": "upcomingWorkload", "w": 4, "x": 0, "y": 0 },
    ],
  },
  [DashboardScope.account]: {
    default: [
      { "x": 0, "y": 0, "w": 3, "h": 2, "i": "createdDocuments" },
      { "x": 0, "y": 2, "w": 3, "h": 2, "i": "signedDocuments" },
      { "x": 0, "y": 4, "w": 3, "h": 4, "i": "documentsByStage" },
      { "x": 0, "y": 8, "w": 3, "h": 4, "i": "documentsByTeam" },
      { "x": 3, "y": 8, "w": 3, "h": 4, "i": "documentsByTemplate" },
      { "x": 0, "y": 12, "w": 6, "h": 8, "i": "documentCalendar" },
      { "x": 3, "y": 0, "w": 3, "h": 4, "i": "upcomingWorkload" },
      { "x": 3, "y": 4, "w": 3, "h": 4, "i": "contractValues" },
    ],
    large: [
      { "x": 0, "y": 0, "w": 3, "h": 2, "i": "createdDocuments" },
      { "x": 0, "y": 2, "w": 3, "h": 2, "i": "signedDocuments" },
      { "x": 3, "y": 0, "w": 3, "h": 4, "i": "upcomingWorkload" },
      { "x": 6, "y": 0, "w": 3, "h": 4, "i": "contractValues" },
      { "x": 0, "y": 4, "w": 3, "h": 4, "i": "documentsByStage" },
      { "x": 3, "y": 4, "w": 3, "h": 4, "i": "documentsByTeam" },
      { "x": 6, "y": 4, "w": 3, "h": 4, "i": "documentsByTemplate" },
      { "x": 0, "y": 8, "w": 9, "h": 8, "i": "documentCalendar" },
    ],
  },
}

const commonWidgets = [
  "documentsByStage",
  "documentsByTeam",
  "documentsByTemplate",
  "documentCalendar",
  "upcomingWorkload",
  "contractValues",
]

export const widgets = {
  personal: [
    "tasks",
    "documentList",
    ...commonWidgets,
  ],
  account: [
    "createdDocuments",
    "signedDocuments",
    ...commonWidgets,
  ],
}
