import { defineStore } from "pinia"
import { reactive, toRefs, toRaw } from "vue"
import { uniq, pick, debounce, isEqual } from "lodash-es"
import { JSONContent } from "@tiptap/vue-3"
import { Editor as TipTapEditor } from "@tiptap/core"

// internal
import { Template, FormErrors, TemplateTab, Tag, Party, DynamicField, MainContentTab, GlobalTemplate, Team } from "~/types"
import { useNotificationStore } from "../notificationStore"
import { changedKeys } from "~/utils"

import {
  updateTemplateAction,
  fetchEditorContentAction,
  fetchHtmlContentAction,
  duplicateTemplateAction,
  addTagAction,
  removeTagAction,
  fetchGlobalTemplatesAction,
  getGlobalTemplateAction,
  createTemplateFromGlobalTemplateAction,
} from "./templateStoreActions"
import { useI18n } from "vue-i18n"
import { useEditorStore } from "../editorStore"

interface Data {
  mainEditor: TipTapEditor,
  currentTemplate: Template,
  activeTabKey: TemplateTab
  editorContentTabs: any[],
  activeEditorContentTab: any,
  mainContentScrollContainer: any,
  keysOfIsUpdatingTemplateField: (keyof Template)[]
  keysOfLastSavedTemplateField: (keyof Template)[]
  templateLastSaved: number
  templateErrors: FormErrors<Template>
  isUpdatingTemplate: boolean
  isLoadingHtmlContent: boolean
  prosemirrorData: JSONContent
  lastSaved: number
  htmlContent: string
  isLoadingSaveTemplateContent: boolean
  isLoadingEditorContent: boolean
  debounceTimestamp: number
  debounceUuid: Template["uuid"]
  payloadKeys: (keyof Template)[]
  uuidOfIsLoadingDuplicateTemplate: Template["uuid"]
  tags: Tag[]
  allTags: Tag[]
  allTeams: Team[]
  isVisiblePreview: boolean
  previewContent: string
  isLoadingPreviewContent: boolean
  mainContentTab: MainContentTab
  globalTemplates: GlobalTemplate[]
  isVisibleSidebar: boolean
}

export const useTemplateStore = defineStore(
  "templateStore",
  () => {
    const data = reactive<Data>(
      {
        mainEditor: null,
        currentTemplate: null,
        activeTabKey: TemplateTab.documentContent,
        editorContentTabs: [],
        activeEditorContentTab: null,
        mainContentScrollContainer: null,
        keysOfIsUpdatingTemplateField: [],
        keysOfLastSavedTemplateField: [],
        templateLastSaved: null,
        templateErrors: null,
        isUpdatingTemplate: false,
        isLoadingHtmlContent: false,
        prosemirrorData: null,
        lastSaved: null,
        htmlContent: null,
        isLoadingSaveTemplateContent: false,
        isLoadingEditorContent: false,
        debounceTimestamp: null,
        debounceUuid: null,
        payloadKeys: [],
        uuidOfIsLoadingDuplicateTemplate: null,
        tags: [],
        allTags: [],
        allTeams: [],
        isVisiblePreview: false,
        previewContent: null,
        isLoadingPreviewContent: false,
        mainContentTab: MainContentTab.documentContent,
        globalTemplates: [],
        isVisibleSidebar: false,
      },
    )

    const { t } = useI18n()

    const { notify } = useNotificationStore()

    const editorStore = useEditorStore()

    const getMainContentScrollContainer = () => data.mainContentScrollContainer
    const getTemplate = () => data.currentTemplate
    const getMainEditor = () => data.mainEditor

    // mutations
    const setTemplate = (template: Template) => data.currentTemplate = template
    const setProsemirrorData = (prosemirrorData: JSONContent) => data.prosemirrorData = prosemirrorData
    const setHtmlContent = (htmlContent: string) => data.htmlContent = htmlContent
    const setIsVisiblePreview = (isVisiblePreview: boolean) => data.isVisiblePreview = isVisiblePreview
    const setActiveTabKey = (activeTabKey: TemplateTab, skipSetVisibleSidebar = false) => {
      // Set isVisibleSidebar if needed
      if (!skipSetVisibleSidebar) setIsVisibleSidebar(true)
      data.activeTabKey = activeTabKey
    }
    const setEditorContentTabs = (tabs: any[]) => data.editorContentTabs = tabs
    const setActiveEditorContentTab = (activeEditorContentTab) => data.activeEditorContentTab = activeEditorContentTab
    const setTags = (tags: Tag[]) => data.tags = tags
    const setAllTags = (allTags: Tag[]) => data.allTags = allTags
    const setAllTeams = (allTeams: Team[]) => data.allTeams = allTeams

    const addKeysOfIsUpdatingTemplateField = (keys: (keyof Template)[]) => data.keysOfIsUpdatingTemplateField = uniq([ ...toRaw(data.keysOfIsUpdatingTemplateField), ...keys ])
    const addKeysOfLastSavedTemplateField = (keys: (keyof Template)[]) => data.keysOfLastSavedTemplateField = uniq([ ...toRaw(data.keysOfLastSavedTemplateField), ...keys ])
    const resetKeysOfIsUpdatingTemplateFields = () => data.keysOfIsUpdatingTemplateField = []
    const resetKeysOfLastSavedTemplateField = () => data.keysOfLastSavedTemplateField = []
    const addUpdatingTemplate = () => data.isUpdatingTemplate = true
    const removeUpdatingTemplate = () => data.isUpdatingTemplate = false
    const setLastSaved = (ts: number) => data.lastSaved = ts
    const setTemplateLastSaved = (ts: number) => data.templateLastSaved = ts
    const setTemplateErrors = (templateErrors: FormErrors<Template>) => data.templateErrors = templateErrors
    const setIsVisibleSidebar = (isVisibleSidebar: boolean) => data.isVisibleSidebar = isVisibleSidebar
    const setMainEditor = (editor: TipTapEditor) => data.mainEditor = editor
    const setMainContentTab = (mainContentTab: MainContentTab) => data.mainContentTab = mainContentTab
    const setMainContentScrollContainer = (mainContentScrollContainer: HTMLDivElement) => data.mainContentScrollContainer = mainContentScrollContainer

    const pushOrUpdateGlobalTemplate = (template: Partial<GlobalTemplate>) => {
      const localIndexOfTemplate = data.globalTemplates.findIndex(({ uuid }) => uuid === template.uuid)

      const templatesCopy = [ ...toRaw(data.globalTemplates) ]

      if (localIndexOfTemplate !== -1) {
        templatesCopy[localIndexOfTemplate] = {
          ...templatesCopy[localIndexOfTemplate],
          ...template,
        }

        data.globalTemplates = templatesCopy
        return
      }

      data.globalTemplates = [
        ...templatesCopy,
        template,
      ] as GlobalTemplate[]
    }

    const updateStoreTemplate = (template: Partial<Template>) => {
      const templateCopy = toRaw(data.currentTemplate)
      const updatedTemplate = {
        ...templateCopy,
        ...template,
      }
      setTemplate(updatedTemplate)
    }

    // template CRUD

    const updateTemplate = async (
      template: Partial<Template>,
      payloadKeys: (keyof Template)[],
      timestamp: number,
    ): Promise<Template | void> => {
      try {
        const payload = pick(template, payloadKeys)
        const updatedTemplate = await updateTemplateAction(template.uuid, payload)
        const onlyTeamUuidsChanged = payloadKeys.length === 1 && payloadKeys[0] === "team_uuids"

        if (updatedTemplate) {
          if (!(data.debounceTimestamp > timestamp) && !onlyTeamUuidsChanged) updateStoreTemplate(updatedTemplate)
          setTemplateLastSaved(Date.now())
          addKeysOfLastSavedTemplateField(payloadKeys)
          setTimeout(resetKeysOfLastSavedTemplateField, 3000)
          setTemplateErrors({})
        }

        return updatedTemplate
      } catch (err) {
        const isBackendError = !!err.response?.data?.errors

        if (isBackendError) {
          setTemplateErrors(err.response.data.errors)
        }

        notify({
          title: t("templates.errors.update"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        removeUpdatingTemplate()
        resetKeysOfIsUpdatingTemplateFields()
      }
    }


    const saveTemplateEditorContent = async (
      templateUuid: Template["uuid"],
    ): Promise<Template | void> => {
      data.isLoadingSaveTemplateContent = true

      const normalizedProsemirrorData = JSON.parse(JSON.stringify(data.prosemirrorData).normalize("NFC")) as JSONContent

      try {
        const payload = {
          prosemirror_data: normalizedProsemirrorData,
        }

        const template = await updateTemplateAction(templateUuid, payload)

        if (!template) return

        setLastSaved(Date.now())
        editorStore.setProsemirrorDataOnServer(normalizedProsemirrorData)

        return template
      } catch (err) {
        if (err.message !== "canceled") {
          notify({
            title: t("templates.errors.saveContent"),
            message: err.response?.data?.message || err.message,
            type: "error",
          })
        }
      } finally {
        data.isLoadingSaveTemplateContent = false
      }
    }

    // Function to load most recent editor (ProseMirror) content
    const fetchEditorContent = async (
      templateUuid: Template["uuid"],
      afterInit = false,
    ) => {
      data.isLoadingEditorContent = true

      try {
        const res = await fetchEditorContentAction(templateUuid)

        if (res) {
          setProsemirrorData(res)
          editorStore.setProsemirrorDataOnServer(res)
          if (!afterInit) return
          data.mainEditor?.commands.setContent(res, true)
        }
      } catch (err) {
        notify({
          title: t("templates.errors.loadContent"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        data.isLoadingEditorContent = false
      }
    }

    // Function to load most recent HTML content
    const fetchHtmlContent = async (
      templateUuid: Template["uuid"],
    ): Promise<string | void> => {
      data.isLoadingHtmlContent = true
      try {
        const res = await fetchHtmlContentAction(templateUuid)
        if (res) {
          setHtmlContent(res)
        }
        return res
      } catch (err) {
        notify({
          title: t("templates.errors.loadHtmlContent"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        data.isLoadingHtmlContent = false
      }
    }

    const fetchGlobalTemplates = async (): Promise<GlobalTemplate[] | void> => {
      try {
        const globalTemplates = await fetchGlobalTemplatesAction()

        if (globalTemplates) {
          data.globalTemplates = globalTemplates
          return globalTemplates
        }
      } catch (err) {
        notify({
          title: t("templates.errors.fetchGlobalTemplates"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    }

    const getGlobalTemplate = async (uuid: GlobalTemplate["uuid"]): Promise<GlobalTemplate | void> => {
      try {
        const globalTemplate = await getGlobalTemplateAction(uuid)
        if (globalTemplate) {
          pushOrUpdateGlobalTemplate(globalTemplate)
          return globalTemplate
        }
      } catch (err) {
        notify({
          title: t("templates.errors.fetchGlobalTemplate"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    }

    const createTemplateFromGlobalTemplate = async (uuid: GlobalTemplate["uuid"], accountPartyUuid: Party["uuid"]): Promise<GlobalTemplate["uuid"] | void> => {
      try {
        const newUuid = await createTemplateFromGlobalTemplateAction(uuid, accountPartyUuid)
        if (newUuid) return newUuid
      } catch (err) {
        notify({
          title: t("templates.errors.createTemplateFromGlobalTemplate"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    }

    // ui actions

    const debouncedUpdateTemplate = debounce(updateTemplate, 1000)

    const updateLocalTemplate = (
      template: Template | Partial<Template>,
    ) => {
      if (!template) return
      const originalParty = { ...toRaw(data.currentTemplate) }
      const rawTemplate = { ...toRaw(template) }
      const payloadKeys = changedKeys(originalParty, rawTemplate) as (keyof Template)[]
      updateStoreTemplate(toRaw(template))

      const timestamp = Date.now()
      if (data.debounceUuid !== template.uuid || !isEqual(payloadKeys, data.payloadKeys)) {
        debouncedUpdateTemplate?.flush()
        data.debounceUuid = template.uuid
        data.payloadKeys = payloadKeys
      }
      data.debounceTimestamp = timestamp

      addUpdatingTemplate()
      addKeysOfIsUpdatingTemplateField(payloadKeys)
      debouncedUpdateTemplate(
        template,
        payloadKeys,
        timestamp,
      )
    }

    const duplicateTemplate = async (templateUuid: Template["uuid"]): Promise<Template["uuid"] | void> => {
      if (!templateUuid) {
        throw new Error("No template uuid provided")
      }
      data.uuidOfIsLoadingDuplicateTemplate = templateUuid
      try {
        const duplicateTemplateRes = await duplicateTemplateAction(templateUuid)
        if (duplicateTemplateRes) return duplicateTemplateRes
      } catch (err) {
        notify({
          title: t("templates.duplicateTemplateError"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        data.uuidOfIsLoadingDuplicateTemplate = null
      }
    }

    const checkForReferencesInProsemirrorData = (
      referenceType: "party" | "dynamicField",
      referenceRefUuid: Party["ref_uuid"] | DynamicField["ref_uuid"],
      content: JSONContent["content"],
    ) => {
      const nodeFoundInTemplate = content.some(
        (node) => {
          if (node.type === "party" && referenceType === "party") {
            return node.attrs.refUuid === referenceRefUuid
          } else if (node.type === "dynamicField" && referenceType === "dynamicField") {
            return node.attrs.refUuid === referenceRefUuid
          } else if (node.content) {
            return checkForReferencesInProsemirrorData(referenceType, referenceRefUuid, node.content)
          }
        },
      )

      return !!nodeFoundInTemplate
    }

    const checkForReferences = (
      referenceType: "party" | "dynamicField",
      referenceRefUuid: Party["ref_uuid"] | DynamicField["ref_uuid"],
    ) => checkForReferencesInProsemirrorData(referenceType, referenceRefUuid, data.prosemirrorData.content)

    const showPreview = async (templateUuid: Template["uuid"]) => {
      data.isLoadingPreviewContent = true
      try {
        const previewContentRes = await fetchHtmlContent(templateUuid)
        if (previewContentRes) {
          data.previewContent = previewContentRes
          setIsVisiblePreview(true)
        }
      }
      finally {
        data.isLoadingPreviewContent = false
      }
    }

    // tags
    const addTag = async (templateUuid: Template["uuid"], tagUuid: Tag["uuid"]) => {
      try {
        const tags = await addTagAction(templateUuid, tagUuid)
        if (tags) {
          setTags(tags)
        }
      } catch (err) {
        notify({
          title: t("documents.errors.addTag"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    }

    const removeTag = async (templateUuid: Template["uuid"], tagUuid: Tag["uuid"]) => {
      try {
        const tags = await removeTagAction(templateUuid, tagUuid)
        if (tags) {
          setTags(tags)
        }
      } catch (err) {
        notify({
          title: t("documents.errors.removeTag"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    }

    return {
      ...toRefs(data),

      // mutations
      setTemplate,
      setProsemirrorData,
      setHtmlContent,
      setIsVisiblePreview,
      setActiveTabKey,
      setEditorContentTabs,
      setActiveEditorContentTab,
      setMainEditor,
      updateStoreTemplate,
      setTemplateErrors,
      setLastSaved,
      setTemplateLastSaved,
      setMainContentScrollContainer,
      setMainContentTab,
      setTags,
      setAllTags,
      setAllTeams,
      setIsVisibleSidebar,

      // getters
      getTemplate,
      getMainEditor,
      getMainContentScrollContainer,

      // template CRUD
      updateTemplate,
      saveTemplateEditorContent,
      fetchGlobalTemplates,
      getGlobalTemplate,
      createTemplateFromGlobalTemplate,

      // template content actions
      fetchEditorContent,
      fetchHtmlContent,

      // ui actions
      updateLocalTemplate,
      duplicateTemplate,
      checkForReferences,
      showPreview,

      // tags
      addTag,
      removeTag,
    }

  })
