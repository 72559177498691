import { defineStore } from "pinia"
import { computed, reactive, toRaw, toRefs } from "vue"

import { useI18n } from "vue-i18n"
import { useNotificationStore } from "../notificationStore"

import { Attachment, CrudContext, Document, Template } from "~/types"
import { createAttachmentAction, fetchAttachmentsAction, getAttachmentUrlAction, updateAttachmentAction, removeAttachmentAction } from "./attachmentStoreActions"

interface Data {
  attachments: Attachment[]
  isLoadingSaveAttachmentCount?: number
  backendErrors:Partial<Record<keyof Attachment, string[]>>
  uuidOfIsRemovingAttachment: Attachment["uuid"] | null
  uuidOfIsLoadingGetAttachmentUrl?: Attachment["uuid"] | null
  isVisibleAttachments: boolean
  uuidOfIsUpdatingAttachment?: Attachment["uuid"] | null
}

export const useAttachmentStore = defineStore("attachmentStore", () => {

  const { t } = useI18n()
  const { notify } = useNotificationStore()

  const data = reactive<Data>({
    attachments: null,
    isLoadingSaveAttachmentCount: 0,
    backendErrors: null,
    uuidOfIsRemovingAttachment: null,
    uuidOfIsLoadingGetAttachmentUrl: null,
    isVisibleAttachments: false,
    uuidOfIsUpdatingAttachment: null,
  })

  // computed
  const visibleAttachments = computed(() => data.attachments?.filter((attachment) => !attachment.deleted_at))
  const deletedAttachments = computed(() => data.attachments?.filter((attachment) => attachment.deleted_at))

  // mutations
  const setAttachments = (attachments: Attachment[]) => data.attachments = attachments
  const setIsVisibleAttachments = (isVisibleAttachments: boolean) => data.isVisibleAttachments = isVisibleAttachments
  const pushAttachment = (attachment: Attachment) => data.attachments.push(attachment)
  const pushOrUpdateAttachment = (attachment: Partial<Attachment>) => {
    const localIndexOfAttachment = data.attachments.findIndex(({ uuid }) => uuid === attachment.uuid)
    const attachmentsCopy = [ ...toRaw(data.attachments) ]
    if (localIndexOfAttachment !== -1) {
      attachmentsCopy[localIndexOfAttachment] = {
        ...attachmentsCopy[localIndexOfAttachment],
        ...attachment,
      }
      data.attachments = attachmentsCopy

      return
    }
  }
  const markAttachmentAsDeleted = (attachmentUuid: Attachment["uuid"]) => {
    const attachment = data.attachments.find((attachment) => attachment.uuid === attachmentUuid)
    const changedAttachment: Attachment = { ...toRaw(attachment), deleted_at: (new Date()).toString() }
    pushOrUpdateAttachment(changedAttachment)
  }

  // upload and store
  const uploadAttachment = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    file_uuid: string,
    file_name: string,
  ): Promise<void> => {
    if (!file_uuid || !file_name) return
    const attachmentUuid = file_uuid
    const attachmentFileName =  file_name
    data.isLoadingSaveAttachmentCount++
    const payload = {
      uuid: attachmentUuid,
      filename: attachmentFileName,
    }
    try {
      const attachment = await createAttachmentAction(crudContext, entityUuid, payload)
      data.backendErrors = null
      if (attachment) pushAttachment(attachment)
    } catch (err) {
      data.backendErrors = err.response?.data?.errors
      notify({
        title: t("documents.attachmentUploadError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingSaveAttachmentCount--
    }
  }

  const fetchAttachments = async (crudContext: CrudContext, entityUuid: Document["uuid"] | Template["uuid"]): Promise<void> => {

    try {
      const res = await fetchAttachmentsAction(crudContext, entityUuid)
      if (res) setAttachments(res)
    } catch (err) {
      notify({
        title: t("documents.attachmentFetchError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    }
  }

  const updateAttachment = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    attachmentUuid: Attachment["uuid"],
    payload: Partial<Attachment>,
  ): Promise<void> => {
    data.uuidOfIsUpdatingAttachment = attachmentUuid
    try {
      const res = await updateAttachmentAction(crudContext, entityUuid, attachmentUuid, payload)
      if (res) pushOrUpdateAttachment(res)
    } catch (err) {
      notify({
        title: t("documents.attachmentUpdateError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsUpdatingAttachment = null
    }
  }


  const removeAttachment = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    attachmentUuid: Attachment["uuid"],
  ): Promise<void> => {
    data.uuidOfIsRemovingAttachment = attachmentUuid
    try {
      const res = removeAttachmentAction(crudContext, entityUuid, attachmentUuid)
      if (res) {
        markAttachmentAsDeleted(attachmentUuid)
      }
    } catch (err) {
      notify({
        title: t("documents.attachmentDeleteError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsRemovingAttachment = null
    }
  }

  // ui action
  const getAttachmentUrl = async (
    crudContext: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    attachmentUuid: Attachment["uuid"],
  ): Promise<string> => {
    try {
      const res = await getAttachmentUrlAction(crudContext, entityUuid, attachmentUuid)
      if (res) return res.url
    } catch (err) {
      notify({
        title: t("documents.attachmentShowError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidOfIsLoadingGetAttachmentUrl = null
    }
  }

  return {
    ...toRefs(data),
    visibleAttachments,
    deletedAttachments,

    // mutations
    setAttachments,
    pushAttachment,
    markAttachmentAsDeleted,
    setIsVisibleAttachments,

    // crud
    fetchAttachments,
    updateAttachment,
    removeAttachment,
    uploadAttachment,
    getAttachmentUrl,
  }
})
