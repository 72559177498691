import { defineStore } from "pinia"

import { computed, reactive, toRefs } from "vue"
import { AiAnalysis, AiAnalysisStatus, Document } from "~/types"
import { useNotificationStore } from "../notificationStore"

import {
  createAiAnalysisAction,
  getAiAnalysisAction,
} from "./aiStoreActions"
import { useI18n } from "vue-i18n"

interface Data {
  analysis: AiAnalysis
  isLoadingCreateAnalysis: boolean
  isLoadingGetAnalysis: boolean
  showAiAnalysisReviewModal: boolean
}

export const useAiStore = defineStore("aiStore", () => {

  const { t } = useI18n()

  const data = reactive<Data>(
    {
      analysis: null,
      isLoadingCreateAnalysis: false,
      isLoadingGetAnalysis: false,
      showAiAnalysisReviewModal: false,
    },
  )

  const { notify } = useNotificationStore()

  const isActiveAiAnalysis = computed(() => {
    return [
      AiAnalysisStatus.pending,
      AiAnalysisStatus.executing,
      AiAnalysisStatus.created,
      AiAnalysisStatus.review,
    ].includes(data.analysis?.status)
  })

  const setShowAiAnalysisReviewModal = (show: boolean) => data.showAiAnalysisReviewModal = show

  const setAnalysis = (analysis:AiAnalysis) => {
    data.analysis = analysis
  }

  const createAiAnalysis = async (
    documentUuid: Document["uuid"],
  ) => {
    try {
      data.isLoadingCreateAnalysis = true
      const analysis = await createAiAnalysisAction(documentUuid)

      if (analysis) {
        data.analysis = analysis
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      data.isLoadingCreateAnalysis = false
    }
  }

  const getAiAnalysis = async (
    documentUuid: Document["uuid"],
    aiAnalysisUuid: AiAnalysis["uuid"],
  ) => {
    if (!documentUuid || !aiAnalysisUuid) return
    data.isLoadingGetAnalysis = true
    try {
      const res = await getAiAnalysisAction(documentUuid, aiAnalysisUuid)
      if (res) setAnalysis(res)
    } catch (err) {
      notify({
        title: t("aiAnalysis.errors.getAiAnalysis"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingGetAnalysis = false
    }
  }

  return {
    ...toRefs(data),
    isActiveAiAnalysis,

    // mutations
    setAnalysis,
    setShowAiAnalysisReviewModal,

    // actions
    createAiAnalysis,
    getAiAnalysis,
  }
})
