import axios from "axios"

import { Document, DynamicField, Template, CrudContext } from "~/types"

export const fetchDynamicFieldsAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
): Promise<DynamicField[] | void> => {
  const routeString = `api.${context}s.dynamic-fields.index`
  const url = route(routeString, entityUuid)

  try {
    const fetchDynamicFieldsRes = await axios.get<{ data: DynamicField[] }>(url)

    return fetchDynamicFieldsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

export const getDynamicFieldAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  dynamicFieldUuid: DynamicField["uuid"],
): Promise<DynamicField | void> => {
  const routeString = `api.${context}s.dynamic-fields.show`
  const url = route(routeString, {
    [context]: entityUuid,
    dynamic_field: dynamicFieldUuid,
  })

  try {
    const getDynamicFieldRes = await axios.get<{ data: DynamicField }>(url)

    return getDynamicFieldRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// dynamicField CRUD
export const createDynamicFieldAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  dynamicFieldToCreate: Partial<DynamicField>,
): Promise<DynamicField | void> => {
  const apiUrl = route(`api.${context}s.dynamic-fields.store`, entityUuid)

  const createDynamicFieldRes = await axios.post<{ data: DynamicField }>(apiUrl, dynamicFieldToCreate)

  return createDynamicFieldRes.data.data
}

export const updateDynamicFieldAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  payload: Partial<DynamicField>,
  dynamicFieldUuid: DynamicField["uuid"],
): Promise<DynamicField | void> => {
  const apiUrl = `api.${context}s.dynamic-fields.update`

  const routeData = {
    dynamic_field: dynamicFieldUuid,
    [context]: entityUuid,
  }

  const url = route(apiUrl, routeData)

  const updateDynamicFieldRes = await axios.patch<{data: DynamicField}>(url, payload)

  return updateDynamicFieldRes.data.data
}

export const removeDynamicFieldAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  dynamicFieldUuidToRemove: DynamicField["uuid"],
): Promise<number | void> => {
  const apiUrl = `api.${context}s.dynamic-fields.destroy`

  const payload = context === CrudContext.document
    ? { document: entityUuid, dynamic_field: dynamicFieldUuidToRemove }
    : { template: entityUuid, dynamic_field: dynamicFieldUuidToRemove }

  const removeDynamicFieldRes = await axios.delete<{ data: DynamicField }>(route(apiUrl, payload))

  return removeDynamicFieldRes.status
}

export const syncDynamicFieldOrderAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
  order: DynamicField["uuid"][],
): Promise<DynamicField["uuid"][] | void> => {
  const syncOrderRes = await axios.post<{ data: DynamicField["uuid"][] }>(route( `api.${context}s.dynamic-field-order.sync`, entityUuid),
    {
      dynamic_field_order_array: order,
    })
  return syncOrderRes.data.data
}
