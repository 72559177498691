import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"

import { Template, Document, CrudContext, Image } from "~/types"

import { createImageAction, readImageAction, fetchImagesAction } from "./imageStoreActions"
import { ImageAttrs } from "~/editor/extensions/resizableImage/resizableImage"

interface Data {
  images: Record<string, string>
  imageIndex: Image[]
  entityUuid: Template["uuid"] | Document["uuid"]
  context: CrudContext | ""
  uuidsOfImagesBeingLoaded: string[]
  uuidsOfImagesBeingUploaded: string[]
  isLoadingFetchImageIndex: boolean
  imageIndexFetchPromise: Promise<Image[]>
  imageIndexFetchAbortController: AbortController
}

export const useImageStore = defineStore(
  "imageStore",
  () => {
    const data = reactive<Data>(
      {
        images: {},
        imageIndex: [],
        entityUuid: null,
        context: "",
        uuidsOfImagesBeingLoaded: [],
        uuidsOfImagesBeingUploaded: [],
        isLoadingFetchImageIndex: false,
        imageIndexFetchPromise: null,
        imageIndexFetchAbortController: null,
      },
    )

    // mutations
    const setLocalImage = (uuid: string, dataUrl: string) => {
      if (!uuid || !dataUrl) return

      data.images[uuid] = dataUrl
    }

    const setEntityUuidAndContext = (uuid: Template["uuid"], context: CrudContext) => {
      if (!checkContext(context)) {
        console.error("context value not valid", { context })

        return
      }

      data.entityUuid = uuid
      data.context = context
    }

    const addUploadingImage = (uuid: string) => {
      if (!uuid) return

      const copyOfUuidsOfImagesBeingUploaded = [ ...data.uuidsOfImagesBeingUploaded ]

      copyOfUuidsOfImagesBeingUploaded.push(uuid)

      data.uuidsOfImagesBeingUploaded = copyOfUuidsOfImagesBeingUploaded

      return copyOfUuidsOfImagesBeingUploaded
    }

    const removeUploadingImage = (uuid: string) => {
      if (!uuid) return

      const copyOfUuidsOfImagesBeingUploaded = [ ...data.uuidsOfImagesBeingUploaded ]

      const index = copyOfUuidsOfImagesBeingUploaded.indexOf(uuid)

      if (index > -1) copyOfUuidsOfImagesBeingUploaded.splice(index, 1)

      data.uuidsOfImagesBeingUploaded = copyOfUuidsOfImagesBeingUploaded

      return copyOfUuidsOfImagesBeingUploaded
    }

    // checks
    const checkContext = (context?: CrudContext) => [ CrudContext.document, CrudContext.template ].includes(context || data.context as CrudContext)

    // actions
    const createImage = async (
      uuid: string,
    ) => {
      if (!checkContext()) {
        console.error(
          "invalid context",
          {
            context: data.context,
          },
        )

        return
      }

      try {
        const createImageRes = await createImageAction(
          uuid,
          data.entityUuid,
          data.context as CrudContext,
        )

        setLocalImage(uuid, createImageRes.image_data)

        return createImageRes
      } catch (err) {
        console.error(err, "\n\n imageStore: createImage", { uuid })
      }
    }

    const getImageIfNeeded = async (
      refUuid: string,
      uuid: string,
    ) => {
      if (!refUuid || !uuid) {
        console.error("invalid data", { refUuid, uuid })
        return
      }

      if (data.images[uuid]) return data.images[uuid]

      // Wait for the image index to be fetched if it is currently being fetched
      if (data.imageIndexFetchPromise) await data.imageIndexFetchPromise
      // Fetch the image index if it is not found
      else if (!data.imageIndex.length) {
        data.isLoadingFetchImageIndex = true
        data.imageIndexFetchAbortController = new AbortController()
        data.imageIndexFetchPromise = fetchImagesAction(data.entityUuid, data.context as CrudContext)
        const imageIndex = await data.imageIndexFetchPromise
        data.isLoadingFetchImageIndex = false
        data.imageIndexFetchAbortController = null
        data.imageIndex = imageIndex
      }
      // Fetch most recent image index based on the document / template, if the vapor uuid is not found in the stored variant
      const imageIndexEntry = data.imageIndex.find((image) => image.ref_uuid === refUuid)
      // The vapor uuid is NOT the prosemirror uuid, this can be confusing, therefore we use the term "vapor uuid"
      const vaporUuid = imageIndexEntry?.uuid

      if (!vaporUuid) {
        console.error("no image vapor uuid found", { refUuid, uuid })
        return
      }

      try {
        if (data.uuidsOfImagesBeingLoaded.includes(`${refUuid}_${uuid}`)) return

        if (!checkContext()) {
          console.error(
            "invalid context",
            {
              context: data.context,
            },
          )

          return
        }

        const copyOfUuidsOfImagesBeingLoaded = [ ...data.uuidsOfImagesBeingLoaded ]

        copyOfUuidsOfImagesBeingLoaded.push(`${refUuid}_${uuid}`)

        data.uuidsOfImagesBeingLoaded = copyOfUuidsOfImagesBeingLoaded

        const res = await readImageAction(vaporUuid, data.entityUuid, data.context as CrudContext)

        setLocalImage(uuid, res.image_data)

        return res
      } catch (err) {
        console.error(err, "\n\n imageStore: createImage", { uuid })
      } finally {
        const copyOfUuidsOfImagesBeingLoaded = [ ...data.uuidsOfImagesBeingLoaded ]

        const index = copyOfUuidsOfImagesBeingLoaded.indexOf(`${refUuid}_${uuid}`)

        if (index > -1) copyOfUuidsOfImagesBeingLoaded.splice(index, 1)

      }
    }

    type ImageDetails = Pick<ImageAttrs, "refUuid" | "uuid">

    // Method to check multiple images at once to prevent multiple fetches
    const getImagesIfNeeded = async (
      imageDetails: ImageDetails[],
    ) => {
      // Check if all images are found in the local index
      const missingImages = imageDetails.filter((imageDetail) => !data.imageIndex.find((index) => index.ref_uuid === imageDetail.refUuid))
      // If not all images are found in the local index, fetch the index
      if (missingImages.length) {
        data.isLoadingFetchImageIndex = true
        data.imageIndexFetchAbortController = new AbortController()
        data.imageIndexFetchPromise = fetchImagesAction(data.entityUuid, data.context as CrudContext)
        const imageIndex = await data.imageIndexFetchPromise
        data.isLoadingFetchImageIndex = false
        data.imageIndexFetchAbortController = null
        data.imageIndex = imageIndex
      }
      // Now, we can iterate
      imageDetails.forEach(({ refUuid, uuid }) => getImageIfNeeded(refUuid, uuid))
    }


    return {
      ...toRefs(data),

      // mutations
      setLocalImage,
      setEntityUuidAndContext,
      addUploadingImage,
      removeUploadingImage,

      // actions
      createImage,
      getImageIfNeeded,
      getImagesIfNeeded,
    }
  },
)
