import axios from "axios"
import { Bookmark } from "~/types"

export const fetchBookmarksAction = async (
  listName: string,
): Promise<Bookmark[] | void> => {
  const res = await axios.get(route("api.bookmarks.index"), {
    params: {
      list_name: listName,
    },
  })
  return res.data.data
}


export const createBookmarkAction = async (
  payload: Partial<Bookmark>,
): Promise<Bookmark | void> => {
  const res = await axios.post(route("api.bookmarks.store"), payload)
  return res.data.data
}


export const deleteBookmarkAction = async (
  uuid: Bookmark["uuid"],
): Promise<Bookmark | void> => {
  const res = await axios.delete(route("api.bookmarks.destroy", uuid))
  return res.data.data
}
