import axios from "axios"
import { JSONContent } from "@tiptap/core"

import { GlobalTemplate, Party, Tag, Template } from "~/types"

export const fetchGlobalTemplatesAction = async (): Promise<GlobalTemplate[] | void> => {
  const globalTemplatesRes = await axios.get<{ data: GlobalTemplate[] }>(route("api.template-init.global-templates.index"))
  return globalTemplatesRes.data.data
}

export const getGlobalTemplateAction = async (uuid: GlobalTemplate["uuid"]): Promise<GlobalTemplate | void> => {
  const globalTemplateRes = await axios.get<{ data: GlobalTemplate }>(
    route("api.template-init.global-templates.show", uuid),
  )

  return globalTemplateRes.data.data
}

export const createTemplateFromGlobalTemplateAction = async (uuid: GlobalTemplate["uuid"], accountPartyUuid: Party["uuid"]): Promise<GlobalTemplate["uuid"] | void> => {
  const apiRoute = route("api.template-init.create-template-from-global-template")
  const createTemplateFromGlobalTemplateRes = await axios.post<{ data: GlobalTemplate["uuid"] }>(apiRoute, {
    global_template_uuid: uuid,
    account_party_uuid: accountPartyUuid,
  })
  const newUuid = createTemplateFromGlobalTemplateRes.data.data
  if (newUuid) return newUuid
}

export const updateTemplateAction = async (
  uuid: Template["uuid"],
  payload: Partial<Template>,
): Promise<Template | void> => {
  const updateTemplateRes = await axios.patch<{ data: Template }>(route("api.templates.update", uuid), payload)
  return updateTemplateRes.data.data
}

export const fetchEditorContentAction = async (
  uuid: Template["uuid"],
): Promise<JSONContent | void> => {
  const fetchEditorContentRes = await axios.get<{ data: { uuid: number, prosemirror_data: JSONContent} }>(route( "api.templates.show", uuid))
  return fetchEditorContentRes.data.data.prosemirror_data
}

export const fetchHtmlContentAction = async (
  uuid: Template["uuid"],
): Promise<string | void> => {
  const fetchHtmlContentRes = await axios.get<{ data: { uuid: number, html: string} }>(route( "api.templates.template-html.show", uuid))
  return fetchHtmlContentRes.data.data.html
}

export const duplicateTemplateAction = async (templateUuid: Template["uuid"]): Promise<Template["uuid"] | void> => {
  const apiRoute = route("api.template-init.create-template-from-template", { template_uuid: templateUuid })
  const duplicateTemplateRes = await axios.post<{ data: Template["uuid"] }>(apiRoute)
  const newUuid = duplicateTemplateRes.data.data
  if (newUuid) return newUuid
}

export const addTagAction = async (
  templateUuid: Template["uuid"],
  tagUuid: Tag["uuid"],
): Promise<Tag[]|void> => {
  const res = await axios.post<{ data: Tag[]}>(route("api.templates.tags.add", {
    template: templateUuid,
    tag: tagUuid,
  }))
  return res.data.data
}

export const removeTagAction = async (
  templateUuid: Template["uuid"],
  tagUuid: Tag["uuid"],
): Promise<Tag[]|void> => {
  const res = await axios.delete<{ data: Tag[]}>(route("api.templates.tags.remove", {
    template: templateUuid,
    tag: tagUuid,
  }))
  return res.data.data
}
