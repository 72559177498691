// external
import { defineStore } from "pinia"
import { reactive, toRefs, toRaw } from "vue"
import { cloneDeep } from "lodash-es"
import i18nInstance from "~/utils/i18n"

// internal
import { DashboardGridItem, Team, Tag, DashboardWidget, Dashboard, DashboardScope, AccountUser, CustomWidget, SelectOption, DashboardTab, DashboardDefaultKey } from "~/types"
import { layouts, emptyFilter } from "./dashboardSettings"
import { createCustomWidgetAction, createDashboardAction, deleteCustomWidgetAction, deleteDashboardAction, getCustomWidgetAction, updateCustomWidgetAction, updateDashboardAction } from "./dashboardStoreActions"
import { isArrayEqual } from "~/utils"

interface Data {
  mau: AccountUser
  isEditMode: boolean
  allowedWidgets: string[]
  allWidgets: DashboardWidget[]
  widgetsToAdd: string[]
  teamOptions: Team[]
  tagOptions: SelectOption[]
  dashboards: Dashboard[]
  activeDashboardUuid: Dashboard["uuid"]
  isLoadingDashboards: boolean
  isSwitchingDashboard: boolean
  isLoadingCustomWidgets: boolean
  previousLayout: DashboardGridItem[]
  showMenu: boolean
  draggedWidgetKey: DashboardWidget["key"]
  latestDragEvent: string
  activeTabKey: string
  dropKey: string
  hasDocuments: boolean
}

const { t } = i18nInstance.global

export const useDashboardStore = defineStore("dashboardStore", () => {
  const data = reactive<Data>(
    {
      mau: null,
      isEditMode: false,
      allowedWidgets: null,
      allWidgets: null,
      widgetsToAdd: [],
      tagOptions: [],
      teamOptions: [],
      dashboards: [],
      activeDashboardUuid: null,
      isLoadingDashboards: false,
      isSwitchingDashboard: false,
      isLoadingCustomWidgets: false,
      previousLayout: [],
      showMenu: false,
      draggedWidgetKey: null,
      latestDragEvent: null,
      activeTabKey: DashboardTab.general,
      dropKey: "drop", // ID of temporary dragNdrop placeholder element
      hasDocuments: false,
    },
  )
  const setMau = (mau: AccountUser) => data.mau = mau
  const setDashboards = (dashboards: Dashboard[]) => {
    if (dashboards?.length) {
      data.dashboards = dashboards

      if (data.mau?.dashboard_uuid) {
        const defaultDashboard = dashboards.find((dashboard) => dashboard.uuid === data.mau?.dashboard_uuid)

        if (defaultDashboard) {
          data.activeDashboardUuid = defaultDashboard.uuid
        }
      }

      if (!data.activeDashboardUuid) {
        const personalDashboards = data.dashboards.filter((dashboard) => dashboard.scope === DashboardScope.personal)

        if (personalDashboards.length) {
          data.activeDashboardUuid = personalDashboards[0].uuid
        }
      }
    }
  }
  const setActiveDashboardUuid = (activeDashboardUuid: Dashboard["uuid"]) => {
    if (activeDashboardUuid !== data.activeDashboardUuid) {
      data.isSwitchingDashboard = true
      data.activeDashboardUuid = activeDashboardUuid
      //localStorage.setItem(lastOpenedStorageKey, activeDashboardUuid.toString())

      // set latest version of empty filter if necessary
      if (data.dashboards.find((dashboard) => dashboard.uuid === activeDashboardUuid).filter === null) {
        data.dashboards.find((dashboard) => dashboard.uuid === activeDashboardUuid).filter = emptyFilter
      }
      setTimeout(() => {
        data.isSwitchingDashboard = false
      }, 300)
    }
  }
  const setTeamOptions = (teamOptions: Team[]) => data.teamOptions = teamOptions
  const setTagOptions = (tags: Tag[]) => data.tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      color: tag.color,
      value: tag.uuid,
    }
  })

  const setLayout = (layout: DashboardGridItem[]) => {
    const activeDashboard = data.dashboards.find((dashboard) => dashboard.uuid === data.activeDashboardUuid)
    activeDashboard.layout = layout
  }
  const setAllWidgets = (allWidgets: DashboardWidget[]) => data.allWidgets = allWidgets
  const setHasDocuments = (hasDocuments: boolean) => data.hasDocuments = hasDocuments
  const toggleEditMode = (tabKey = DashboardTab.widgets) => {
    data.activeTabKey = tabKey
    const activeDashboard = data.dashboards.find((dashboard) => dashboard.uuid === data.activeDashboardUuid)

    if (!data.isEditMode) {
      // store previous layout for later comparision
      data.previousLayout = activeDashboard?.layout ? cloneDeep(toRaw(activeDashboard.layout.map((entry) => toRaw(entry)))) : []
    } else {
      // make sure no drop placeholder is left in layout
      if (activeDashboard.layout?.length) {
        const tmpLayout = JSON.parse(JSON.stringify(activeDashboard.layout)).filter((item) => {
          return item.i !== data.dropKey
        })
        setLayout(tmpLayout)

        // remove DOM leftovers
        document.querySelectorAll(".vgl-item--placeholder").forEach((el) => el.remove())
      }

      // compare previous layout with potentially adapted layout
      if (!isArrayEqual(data.previousLayout, activeDashboard.layout)) {
        createOrUpdateDashboard(activeDashboard)
      }
    }

    data.isEditMode = !data.isEditMode
    data.showMenu = data.isEditMode
  }

  const cleanupPredefinedDashboards = () => {
    const personalDashboards = data.dashboards.filter((dashboard) => dashboard.scope === DashboardScope.personal)
    const accountDashboards = data.dashboards.filter((dashboard) => dashboard.scope === DashboardScope.account)

    if (personalDashboards.length > 1) {
      const predefinedPersonalDashboardIndex = data.dashboards.findIndex((dashboard) => dashboard.uuid === DashboardDefaultKey.personal)
      if (predefinedPersonalDashboardIndex !== -1) {
        data.dashboards.splice(predefinedPersonalDashboardIndex, 1)
      }
    }

    if (accountDashboards.length > 1) {
      const predefinedAccountDashboardIndex = data.dashboards.findIndex((dashboard) => dashboard.uuid === DashboardDefaultKey.account)
      if (predefinedAccountDashboardIndex !== -1) {
        data.dashboards.splice(predefinedAccountDashboardIndex, 1)
      }
    }
  }

  const preparePredefinedDashboards = () => {
    const predefinedPersonalDashboard = {
      uuid: DashboardDefaultKey.personal,
      key: DashboardDefaultKey.personal,
      layout: getDefaultLayout(DashboardScope.personal),
      filter: {
        ...emptyFilter,
      },
      dashboardable_uuid: data.mau.uuid,
      scope: DashboardScope.personal,
    }
    const predefinedAccountDashboard = {
      uuid: DashboardDefaultKey.account,
      key: DashboardDefaultKey.account,
      layout: getDefaultLayout(DashboardScope.account),
      filter: {
        ...emptyFilter,
      },
      dashboardable_uuid: data.mau.account_uuid,
      scope: DashboardScope.account,
    }

    // temporary account dashboard
    if (!data.dashboards.find((dashboard) => dashboard.scope === DashboardScope.account) && data.mau.roles[0]?.name === "account-owner") {
      data.dashboards.unshift(predefinedAccountDashboard)
    }
    // temporary personal dashboard
    if (!data.dashboards.find((dashboard) => dashboard.scope === DashboardScope.personal)) {
      data.dashboards.unshift(predefinedPersonalDashboard)
    }

    const activeDashboardStillExists = !!data.dashboards.find((dashboard) => dashboard.uuid === data.activeDashboardUuid)
    setActiveDashboardUuid(activeDashboardStillExists ? data.activeDashboardUuid : data.dashboards[0].uuid)
  }

  const getDefaultLayout = (scope: DashboardScope) => {
    if (window.innerWidth > 1371) {
      return layouts[scope].large
    }
    return layouts[scope].default
  }

  const restoreDefaultLayout = () => {
    const activeDashboard = data.dashboards.find((dashboard) => dashboard.uuid === data.activeDashboardUuid)
    setLayout(getDefaultLayout(activeDashboard.scope))
    createOrUpdateDashboard(activeDashboard)
  }

  const resetFilter = () => {
    const activeDashboard = data.dashboards.find((dashboard) => dashboard.uuid === data.activeDashboardUuid)
    activeDashboard.filter = { ...emptyFilter }
  }

  const pushOrUpdateDashboard = (dashboard: Dashboard) => {
    const localIndexOfDashboard = data.dashboards.findIndex(({ uuid }) => uuid === dashboard.uuid)

    if (localIndexOfDashboard !== -1) {
      const updatedDashboard = {
        ...data.dashboards[localIndexOfDashboard],
        ...dashboard,
      }
      data.dashboards[localIndexOfDashboard] = updatedDashboard
    } else {
      data.dashboards.push(dashboard)
    }
  }

  const removeDashboard = (dashboardUuid: Dashboard["uuid"], cleanup = true) => {
    const localIndexOfDashboard = data.dashboards.findIndex((dashboard) => dashboard.uuid === dashboardUuid)
    if (localIndexOfDashboard !== -1) {
      data.dashboards.splice(localIndexOfDashboard, 1)
    }
    if (cleanup) {
      preparePredefinedDashboards()

      let newActiveDashboardUuid = data.dashboards[0].uuid
      if (data.mau?.dashboard_uuid) {
        const defaultDashboard = data.dashboards.find((dashboard) => dashboard.uuid === data.mau?.dashboard_uuid)
        if (defaultDashboard) {
          newActiveDashboardUuid = defaultDashboard.uuid
        }
      }
      setActiveDashboardUuid(newActiveDashboardUuid)
    }
  }

  const createDashboard = async (
    dashboard: Partial<Dashboard>,
  ) => {
    data.isLoadingDashboards = true
    try {
      const createdDashboard = await createDashboardAction(dashboard)
      if (createdDashboard) {
        pushOrUpdateDashboard(createdDashboard)
        cleanupPredefinedDashboards()
      }
      return createdDashboard
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingDashboards = false
    }
  }

  const updateDashboard = async (
    dashboard: Partial<Dashboard>,
    force = false,
  ) => {
    if (!data.isSwitchingDashboard || force) {
      data.isLoadingDashboards = true
      try {
        const updatedDashboard = await updateDashboardAction(dashboard)
        if (updatedDashboard) {
          pushOrUpdateDashboard(updatedDashboard)
          cleanupPredefinedDashboards()
        }
        return updatedDashboard
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingDashboards = false
      }
    }
  }

  const createOrUpdateDashboard = async (
    dashboard: Partial<Dashboard>,
    force = false,
  ) => {
    const payload = {
      ...dashboard,
    }

    let newDashboard
    if (!payload.hasOwnProperty("uuid") || payload.uuid === null || payload.uuid === "personal" || payload.uuid === "account") {
      let oldDashboardUuid = null
      if (!payload.name) {
        payload.name = t("dashboard.tabs." + payload.key)
      }
      if (payload.hasOwnProperty("uuid")) {
        oldDashboardUuid = payload.uuid
        delete payload.uuid
      }
      if (payload.hasOwnProperty("key")) {
        delete payload.key
      }
      newDashboard = await createDashboard(payload)

      if (oldDashboardUuid !== null) {
        removeDashboard(oldDashboardUuid, false)
      }
    } else {
      newDashboard = await updateDashboard(payload, force)
    }
    if (newDashboard?.uuid) {
      setActiveDashboardUuid(newDashboard.uuid)
      return newDashboard
    }
  }

  const deleteDashboard = async (
    uuid: Dashboard["uuid"],
  ) => {
    data.isLoadingDashboards = true
    try {
      await deleteDashboardAction(uuid)
      removeDashboard(uuid)
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingDashboards = false
    }
  }

  const createCustomWidget = async (
    customWidget: Partial<CustomWidget>,
  ) => {
    data.isLoadingCustomWidgets = true
    try {
      const createdWidget = await createCustomWidgetAction(customWidget)
      return createdWidget
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingCustomWidgets = false
    }
  }

  const getCustomWidget = async (
    customWidgetUuid: CustomWidget["uuid"],
  ) => {
    try {
      const createdWidget = await getCustomWidgetAction(customWidgetUuid)
      return createdWidget
    } catch (err) {
      console.error(err)
    }
  }

  const updateCustomWidget = async (
    customWidget: Partial<CustomWidget>,
  ) => {
    data.isLoadingCustomWidgets = true
    try {
      const updatedWidget = await updateCustomWidgetAction(customWidget)
      return updatedWidget
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingCustomWidgets = false
    }
  }

  const deleteCustomWidget = async (
    customWidgetUuid: CustomWidget["uuid"],
  ) => {
    data.isLoadingCustomWidgets = true
    try {
      await deleteCustomWidgetAction(customWidgetUuid)
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingCustomWidgets = false
    }
  }

  return {
    ...toRefs(data),

    // mutations
    setMau,
    setActiveDashboardUuid,
    setDashboards,
    preparePredefinedDashboards,
    restoreDefaultLayout,
    setLayout,
    toggleEditMode,
    setAllWidgets,
    setTeamOptions,
    setTagOptions,
    resetFilter,
    cleanupPredefinedDashboards,
    setHasDocuments,

    // crud
    createDashboard,
    updateDashboard,
    deleteDashboard,
    createOrUpdateDashboard,

    // custom widgets
    getCustomWidget,
    createCustomWidget,
    updateCustomWidget,
    deleteCustomWidget,

    // ui actions
  }
})
