import { defineStore } from "pinia"
import { reactive, toRaw, toRefs, computed } from "vue"
import { useI18n } from "vue-i18n"

import { Template, Document, Checkpoint, CrudContext, CheckpointStep, CheckpointStepApprover, AccountUser, DocumentStage, CheckpointPass, CheckpointStepPolicy } from "~/types"
import { useNotificationStore } from "../notificationStore"
import { useDocumentStore } from "../documentStore"

import {
  createCheckpointAction,
  createCheckpointStepAction,
  createCheckpointStepApproverAction,
  fetchCheckpointPassesAction,
  fetchCheckpointsAction,
  removeCheckpointAction,
  removeCheckpointStepAction,
  removeCheckpointStepApproverAction,
  updateCheckpointStepAction,
} from "./checkpointStoreActions"

interface Data {
  checkpoints: Checkpoint[]
  checkpointPasses: CheckpointPass[]
  activeCheckpoint: Checkpoint | null
  entityUuid: Template["uuid"] | Document["uuid"]
  context: CrudContext | ""
  isLoadingCheckpoints: boolean
  isLoadingCheckpointPasses: boolean
  isCreatingCheckpoint: boolean
  isCreatingCheckpointStep: boolean
  isCreatingCheckpointStepApprover: boolean
  updatingCheckpointUuid: Checkpoint["uuid"]
  updatingCheckpointStepUuid: CheckpointStep["uuid"]
  removingCheckpointUuid: Checkpoint["uuid"]
  removingCheckpointStepUuid: CheckpointStep["uuid"]
  removingCheckpointStepApproverUuid: AccountUser["uuid"]
}

export const useCheckpointStore = defineStore(
  "checkpointStore",
  () => {
    const data = reactive<Data>(
      {
        checkpoints: [],
        checkpointPasses: [],
        activeCheckpoint: null,
        entityUuid: null,
        context: "",
        isLoadingCheckpoints: false,
        isLoadingCheckpointPasses: false,
        isCreatingCheckpoint: false,
        isCreatingCheckpointStep: false,
        isCreatingCheckpointStepApprover: false,
        updatingCheckpointUuid: null,
        updatingCheckpointStepUuid: null,
        removingCheckpointUuid: null,
        removingCheckpointStepUuid: null,
        removingCheckpointStepApproverUuid: null,
      },
    )

    const { t } = useI18n()
    const { notify } = useNotificationStore()

    const documentStore = useDocumentStore()

    // computed
    const readyCheckpoints = computed<CheckpointPass[]>(() => data.checkpointPasses.filter((el) => el.status === "ready"))

    // mutations
    const setCheckpoints = (checkpoints: Checkpoint[]) => {
      data.checkpoints = checkpoints
    }

    const getCheckpointByUuid = (checkpointUuid: Checkpoint["uuid"]):Checkpoint => {
      return data.checkpoints.find((checkpoint:Checkpoint) => checkpointUuid === checkpoint.uuid)
    }

    const getCheckpointStepByUuid = (checkpointUuid: Checkpoint["uuid"], checkpointStepUuid: CheckpointStep["uuid"]):CheckpointStep => {
      const checkpoint = getCheckpointByUuid(checkpointUuid)
      return checkpoint.checkpoint_steps.find((step: CheckpointStep) => step.uuid === checkpointStepUuid)
    }

    const setActiveCheckpoint = (checkpointUuid: Checkpoint["uuid"]) => {
      data.activeCheckpoint = getCheckpointByUuid(checkpointUuid)
    }

    const setCheckpointPasses = (checkpointPasses: CheckpointPass[]) => {
      data.checkpointPasses = checkpointPasses
    }

    const pushCheckpoint = (checkpoint: Checkpoint) => data.checkpoints = [ ...toRaw(data.checkpoints || []), checkpoint ]

    const pushCheckpointStep = (checkpointUuid: Checkpoint["uuid"], checkpointStep:CheckpointStep) => {
      const checkpoint:Checkpoint = getCheckpointByUuid(checkpointUuid)
      checkpoint.checkpoint_steps.push(checkpointStep)
    }

    const pushCheckpointStepApprover = (checkpointUuid: Checkpoint["uuid"], checkpointStepUuid: CheckpointStep["uuid"], checkpointStepApprover: CheckpointStepApprover) => {
      const checkpointStep = getCheckpointStepByUuid(checkpointUuid, checkpointStepUuid)
      if (checkpointStep) {
        checkpointStep.checkpoint_step_approvers.push(checkpointStepApprover)
      }
    }

    const updateCheckpointStepInStore = (checkpointUuid: Checkpoint["uuid"], updatedCheckpointStep: Partial<CheckpointStep>) => {
      const checkpoint = getCheckpointByUuid(checkpointUuid)

      const indexOfCheckpointStepToUpdate = checkpoint.checkpoint_steps.findIndex((checkpointStep) => checkpointStep.uuid === updatedCheckpointStep.uuid)

      if (indexOfCheckpointStepToUpdate !== -1) {
        checkpoint.checkpoint_steps[indexOfCheckpointStepToUpdate] = {
          ...checkpoint.checkpoint_steps[indexOfCheckpointStepToUpdate],
          ...updatedCheckpointStep,
        }
      }
    }

    const removeCheckpointFromStore = (checkpointUuid: Checkpoint["uuid"]) => {
      const indexOfCheckpointToRemove = data.checkpoints.findIndex((checkpoint) => checkpoint.uuid === checkpointUuid)

      if (indexOfCheckpointToRemove !== -1) {
        data.checkpoints.splice(indexOfCheckpointToRemove, 1)
      }
    }

    const removeCheckpointStepFromStore = (checkpointUuid: Checkpoint["uuid"], checkpointStepUuid: CheckpointStep["uuid"]) => {
      const checkpoint = getCheckpointByUuid(checkpointUuid)

      const indexOfCheckpointStepToRemove = checkpoint.checkpoint_steps.findIndex((checkpointStep) => checkpointStep.uuid === checkpointStepUuid)

      if (indexOfCheckpointStepToRemove !== -1) {
        checkpoint.checkpoint_steps.splice(indexOfCheckpointStepToRemove, 1)
      }
    }

    const removeCheckpointStepApproverFromStore = (checkpointUuid: Checkpoint["uuid"], checkpointStepUuid: CheckpointStep["uuid"], checkpointStepApproverUuid: CheckpointStepApprover["uuid"]) => {
      const checkpointStep = getCheckpointStepByUuid(checkpointUuid, checkpointStepUuid)

      const indexOfCheckpointStepApproverToRemove = checkpointStep.checkpoint_step_approvers.findIndex((checkpointStepApprover) => checkpointStepApprover.uuid === checkpointStepApproverUuid)

      if (indexOfCheckpointStepApproverToRemove !== -1) {
        checkpointStep.checkpoint_step_approvers.splice(indexOfCheckpointStepApproverToRemove, 1)
      }
    }

    const fetchCheckpoints = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
    ): Promise<Checkpoint[] | void> => {

      try {
        data.isLoadingCheckpoints = true

        const checkpoints = await fetchCheckpointsAction(context, entityUuid)

        setCheckpoints(checkpoints)

        return checkpoints
      } catch (err) {
        console.error(err)
      } finally {
        data.isLoadingCheckpoints = false
      }
    }

    // checkpoint CRUD
    const createCheckpoint = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      stage: DocumentStage,
    ): Promise<Checkpoint | void> => {
      try {
        data.isCreatingCheckpoint = true
        const createdCheckpoint = await createCheckpointAction(context, entityUuid, stage)

        if (createdCheckpoint) {
          pushCheckpoint(createdCheckpoint)
          setActiveCheckpoint(createdCheckpoint.uuid)
          createCheckpointStep(context, entityUuid, createdCheckpoint.uuid)
        }

        return createdCheckpoint
      } catch (err) {
        console.error(err)
      } finally {
        data.isCreatingCheckpoint = false
      }
    }

    const removeCheckpoint = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
    ): Promise<Checkpoint | void> => {
      try {
        data.removingCheckpointUuid = checkpointUuid
        const removeRes = await removeCheckpointAction(context, entityUuid, checkpointUuid)
        if (removeRes === 200) removeCheckpointFromStore(checkpointUuid)
      } catch (err) {
        console.error(err)
      } finally {
        data.removingCheckpointUuid = null
      }
    }

    // checkpointStep CRUD
    const createCheckpointStep = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
    ): Promise<CheckpointStep | void> => {
      try {
        data.isCreatingCheckpointStep = true

        const checkpoint = getCheckpointByUuid(checkpointUuid)
        const checkpointStepsIdx = checkpoint.checkpoint_steps.length - 1
        const lastCheckpointStep = checkpoint.checkpoint_steps[checkpointStepsIdx]
        const lastOrder = lastCheckpointStep?.order
        const newOrder = lastOrder > 0 ? lastOrder + 1 : 1
        const payload = {
          policy: "all",
          order: newOrder,
        }

        const createdCheckpointStep = await createCheckpointStepAction(context, entityUuid, checkpointUuid, payload)

        if (createdCheckpointStep) {
          pushCheckpointStep(checkpointUuid, createdCheckpointStep)
        }

        return createdCheckpointStep
      } catch (err) {
        console.error(err)
      } finally {
        data.isCreatingCheckpointStep = false
      }
    }

    const updateCheckpointStep = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
      checkpointStepUuid: CheckpointStep["uuid"],
      payload: Partial<CheckpointStep>,
    ): Promise<Checkpoint | void> => {
      try {
        data.updatingCheckpointUuid = checkpointUuid
        data.updatingCheckpointStepUuid = checkpointStepUuid
        const updatedCheckpointStep = await updateCheckpointStepAction(context, entityUuid, checkpointUuid, checkpointStepUuid, payload)
        if (updatedCheckpointStep) {
          updateCheckpointStepInStore(checkpointUuid, updatedCheckpointStep)
        }
      } catch (err) {
        console.error(err)
      } finally {
        data.updatingCheckpointUuid = null
        data.updatingCheckpointStepUuid = null
      }
    }

    const removeCheckpointStep = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
      checkpointStepUuid: CheckpointStep["uuid"],
    ): Promise<Checkpoint | void> => {
      try {
        data.removingCheckpointStepUuid = checkpointStepUuid
        const removeRes = await removeCheckpointStepAction(context, entityUuid, checkpointUuid, checkpointStepUuid)
        if (removeRes === 200) removeCheckpointStepFromStore(checkpointUuid, checkpointStepUuid)
      } catch (err) {
        console.error(err)
      } finally {
        data.removingCheckpointStepUuid = null
      }
    }

    // checkpointStepApprover CRUD
    const createCheckpointStepApprover = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
      checkpointStepUuid: CheckpointStep["uuid"],
      accountUserUuid: AccountUser["uuid"],
    ): Promise<CheckpointStepApprover | void> => {
      try {
        data.isCreatingCheckpointStepApprover = true

        const createdCheckpointStepApprover = await createCheckpointStepApproverAction(context, entityUuid, checkpointUuid, checkpointStepUuid, accountUserUuid)

        if (createdCheckpointStepApprover) {
          pushCheckpointStepApprover(checkpointUuid, checkpointStepUuid, createdCheckpointStepApprover)
        }

        return createdCheckpointStepApprover
      } catch (err) {
        console.error(err)
      } finally {
        data.isCreatingCheckpointStepApprover = false
      }
    }

    const removeCheckpointStepApprover = async (
      context: CrudContext,
      entityUuid: Document["uuid"] | Template["uuid"],
      checkpointUuid: Checkpoint["uuid"],
      checkpointStepUuid: CheckpointStep["uuid"],
      checkpointStepApproverUuid: CheckpointStepApprover["uuid"],
    ): Promise<Checkpoint | void> => {
      try {
        data.removingCheckpointStepApproverUuid = checkpointStepApproverUuid
        const removeRes = await removeCheckpointStepApproverAction(context, entityUuid, checkpointUuid, checkpointStepUuid, checkpointStepApproverUuid)
        if (removeRes === 200) {
          removeCheckpointStepApproverFromStore(checkpointUuid, checkpointStepUuid, checkpointStepApproverUuid)
          // If the selected policy is "two" and there is only one approver left, change the policy to "all"
          const checkpointStep = getCheckpointStepByUuid(checkpointUuid, checkpointStepUuid)
          if (checkpointStep.policy === "two" && checkpointStep.checkpoint_step_approvers.length === 1) {
            const copyOfCheckpointStep = { ...checkpointStep }
            copyOfCheckpointStep.policy = CheckpointStepPolicy.all
            await updateCheckpointStep(context, entityUuid, checkpointUuid, checkpointStepUuid, copyOfCheckpointStep)
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        data.removingCheckpointStepApproverUuid = null
      }
    }

    // Function to fetch checkpoint passes
    const fetchCheckpointPasses = async (
      documentUuid: Document["uuid"],
    ) => {

      data.isLoadingCheckpointPasses = true

      try {
        const checkpointPasses = await fetchCheckpointPassesAction(documentUuid)

        if (checkpointPasses) {
          setCheckpointPasses(checkpointPasses)
        }
      } catch (err) {
        notify({
          title: t("checkpoint.errors.fetchCheckpointPasses"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      } finally {
        data.isLoadingCheckpointPasses = false
        documentStore.setHasChangedCheckpoint(false)
      }
    }


    return {
      ...toRefs(data),
      readyCheckpoints,

      // mutations
      setCheckpoints,
      setActiveCheckpoint,
      setCheckpointPasses,

      // actions
      fetchCheckpoints,
      createCheckpoint,
      removeCheckpoint,

      createCheckpointStep,
      updateCheckpointStep,
      removeCheckpointStep,

      createCheckpointStepApprover,
      removeCheckpointStepApprover,

      fetchCheckpointPasses,
    }
  },
)
