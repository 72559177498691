<template>
  <svg
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2165.6 712.4"
    enable-background="new 0 0 2165.6 712.4"
    xml:space="preserve"
  >
    <g id="Layer_x0020_1">
      <g id="_105553311802592">
        <path
          fill="#E31F25"
          d="M500.3,267.8C498.7,272.4,502.3,264.1,500.3,267.8z"
        />
        <path
          fill="#E31F25"
          d="M496.6,268.6c-0.6,1.3-3.4,7.6-1.7,4.6C495.6,271.8,498.4,265.6,496.6,268.6z"
        />
        <path
          fill="#E31F25"
          d="M483.9,309.6c1-2.4,1.6-4.2,1.3-5.1C484.4,306.6,482.8,309.6,483.9,309.6z"
        />
        <path
          fill="#E31F25"
          d="M517.4,244.9c-0.7,1.6-1.8,3.7-1.7,4.6C516.7,248.6,517.3,246.6,517.4,244.9z"
        />
        <path
          fill="#E31F25"
          d="M506.3,254.2c-0.8,1.6-0.6,2.2-1.2,3.5c-1-1.5-3,6.4-2.4,7.1c1.3-1.6,2.9-4.8,4.3-7.2
			C507.1,255.2,507.4,254.1,506.3,254.2z"
        />
        <path
          fill="#E31F25"
          d="M516,245.8C513,251.6,517.9,241.9,516,245.8z"
        />
        <path
          fill="#E31F25"
          d="M516.8,235.6c-0.8,2-1.2,1.9-2,3.8C514.5,242.2,517.3,236,516.8,235.6z"
        />
        <path
          fill="#E31F25"
          d="M508.9,253.4c1.1-3.1,3.1-4.6,3.3-7.3c-1.6,2.8-0.9,0.7-0.6-1.2c-1,1.7,0.4-1.4,0.1-1.8
			c-0.3,0.5-2.3,3-1.9,4.2c0.3-0.6,0.7-0.9,0.2,0.4l-0.2-0.4l-1.4,3.1C510.4,249.6,508,254.1,508.9,253.4z"
        />
        <path
          fill="#E31F25"
          d="M513.3,248.4C511.7,253,515.4,244.7,513.3,248.4z"
        />
        <path
          fill="#E31F25"
          d="M483.6,298c-1.5,2.7-3,8.5-1.1,5.4C481.7,302.9,483.9,299,483.6,298z"
        />
        <path
          fill="#E31F25"
          d="M519.5,237.1C520,237,520,235.8,519.5,237.1z"
        />
        <path
          fill="#E31F25"
          d="M520.3,236.9C518.7,241.4,522.4,233.2,520.3,236.9z"
        />
        <path
          fill="#E31F25"
          d="M517.8,235.8C516.5,240.8,519.7,232.9,517.8,235.8z"
        />
        <path
          fill="#E31F25"
          d="M519.5,237.1C517.4,239.2,516.2,245.4,519.5,237.1z"
        />
        <path
          fill="#E31F25"
          d="M519.5,235.2c2-4.9,1.6-1.9,1,0.2c1.6-4.7,3.9-6.5,3.6-8.6c-1.3,2.6-2.3,5.2-2.5,2.8
			c-0.4,0.7-0.9,2-1.1,1.7c0.4-0.8,0.9-2.1,1.1-1.7l1.4-5.2c-2.4,3.6-2.5,5.7-3.9,10C519.9,233.1,519.3,234.9,519.5,235.2
			L519.5,235.2z"
        />
        <path
          fill="#E31F25"
          d="M509.4,256.3C507.8,260.9,511.5,252.7,509.4,256.3z"
        />
        <path
          fill="#E31F25"
          d="M769.3,60.1c0.9-1.7-2.7-1.1-2.4-0.1C768.1,59.5,768.5,60.1,769.3,60.1z"
        />
        <path
          fill="#E31F25"
          d="M766.9,60C765,60.5,767.6,62.2,766.9,60z"
        />
        <path
          fill="#E31F25"
          d="M750.7,79.5C747.2,80.8,753,78.7,750.7,79.5z"
        />
        <path
          fill="#E31F25"
          d="M750.8,80.7C748.6,82.5,753.1,80.1,750.8,80.7z"
        />
        <path
          fill="#E31F25"
          d="M744.2,62.5C746.4,61.8,739.7,63.9,744.2,62.5z"
        />
        <path
          fill="#E31F25"
          d="M743.7,64.5C740.2,65.8,746.2,63.6,743.7,64.5z"
        />
        <path
          fill="#E31F25"
          d="M745,83.6C742.9,86.1,747,83.4,745,83.6z"
        />
        <path
          fill="#E31F25"
          d="M744.1,86.4C741.9,88.1,746.4,85.8,744.1,86.4z"
        />
        <path
          fill="#E31F25"
          d="M741,62.4C738.7,64.2,743.5,61.8,741,62.4z"
        />
        <path
          fill="#E31F25"
          d="M740.4,68.5C738.1,70.3,742.8,68,740.4,68.5z"
        />
        <path
          fill="#E31F25"
          d="M740.5,86.4C738.2,88.2,742.8,85.9,740.5,86.4z"
        />
        <path
          fill="#E31F25"
          d="M737.9,68.6C735.6,70.4,740.3,68,737.9,68.6z"
        />
        <path
          fill="#E31F25"
          d="M738.9,83.7C735.5,84.9,741.3,82.9,738.9,83.7z"
        />
        <path
          fill="#E31F25"
          d="M736.1,63.1C733.7,64.9,738.5,62.5,736.1,63.1z"
        />
        <path
          fill="#E31F25"
          d="M736.9,70.8C737.7,70,736.8,70.2,736.9,70.8z"
        />
        <path
          fill="#E31F25"
          d="M552,212.1c-1,1.7-1.4,0.6-2.8,4C548.9,219.6,552.1,213.4,552,212.1z"
        />
        <path
          fill="#E31F25"
          d="M575.8,155.9c1.2-1.9,1.9-3.4,1.8-4.4c-2.7,6.8-11.9,18-11.4,22.2c-3.2,3.8-9.1,15.6-14.2,25.9
			c10.7-20.8,21.5-35,30.1-52.2C579.1,151.5,577.9,154.7,575.8,155.9L575.8,155.9z"
        />
        <path
          fill="#E31F25"
          d="M582.8,144.8c-1,1.4-0.9,2-0.7,2.7l1-1.8L582.8,144.8z"
        />
        <path
          fill="#E31F25"
          d="M553.3,205.7c-0.1,0.1-0.1,0.2-0.3,0.5C550.9,209.9,552.7,206.8,553.3,205.7z"
        />
        <path
          fill="#E31F25"
          d="M554.8,202c0.9-0.7,3.2-5,2.7-6.2C556.8,197.7,554.5,200.9,554.8,202z"
        />
        <path
          fill="#E31F25"
          d="M547.9,219.4c0.3,0.1,0.4,0.8,0.8-0.2C548.5,219.1,548.4,218.5,547.9,219.4z"
        />
        <path
          fill="#E31F25"
          d="M560.5,200.7c-0.1-1.2,0.6-0.8,1.2-1.5c-0.2-0.8,1.1-2.8,0.4-3.2C561,197.8,558.5,203.4,560.5,200.7
			L560.5,200.7z"
        />
        <path
          fill="#E31F25"
          d="M551,201.6c-2,4.2-2.9,4-3.2,7.2C548.7,206.6,551.2,203,551,201.6z"
        />
        <path
          fill="#E31F25"
          d="M547.8,196.3c-1.5,3.4-6.9,12.8-5.7,12.1C543.6,205,549,195.6,547.8,196.3z"
        />
        <path
          fill="#E31F25"
          d="M551.9,195.2c-0.2-0.1-0.4-0.8-0.8,0.1C551.3,195.5,551.4,196.2,551.9,195.2z"
        />
        <path
          fill="#E31F25"
          d="M581,151.5C579.1,155.5,583.3,148.5,581,151.5z"
        />
        <path
          fill="#E31F25"
          d="M585,159.9C588.6,155.6,586.2,155.7,585,159.9z"
        />
        <path
          fill="#E31F25"
          d="M599.1,136.6C597.1,140.2,601.5,134,599.1,136.6z"
        />
        <path
          fill="#E31F25"
          d="M597.9,135.5c-1.5,1.9-2.3,3.5-1.8,4.5C597.6,138.1,597.2,137.1,597.9,135.5z"
        />
        <path
          fill="#E31F25"
          d="M584.7,159.1c-0.3-0.6-0.5-1.6-1.5,0.4C583.4,160.3,584,160,584.7,159.1z"
        />
        <path
          fill="#E31F25"
          d="M603.4,129.9C600,134.1,605.7,127,603.4,129.9z"
        />
        <path
          fill="#E31F25"
          d="M601,132.5c-0.2-0.2-0.3-0.9-0.9-0.1C600.4,132.6,600.5,133.3,601,132.5z"
        />
        <path
          fill="#E31F25"
          d="M565.3,184.6l-5.6,10c-0.3,1.2,0.2,1.7-0.4,3.2c1.2,1.7,1-3.3,2.9-5.7c0.7,2.4,1.6-2,2.6-4.4
			C563,190,565.7,185.1,565.3,184.6L565.3,184.6z"
        />
        <path
          fill="#E31F25"
          d="M608.8,121.6c2.3-2.9,1.8-2.1,3.9-5.5c0.1,0.7-0.6,2.5,0.5,1.4c0.3-1.4,0-2.5,1.1-4.2l4.3-4
			c-2.5,0.2,1.6-2.9,0.9-4.7c-6.8,9.9-8.8,12.2-14.7,22C605.5,124.9,611.9,120.4,608.8,121.6L608.8,121.6z"
        />
        <path
          fill="#E31F25"
          d="M557.6,200.2c0.1-2.7,1.1,1.6,1.6-2.4C559.6,193.2,555.2,203.5,557.6,200.2z"
        />
        <path
          fill="#E31F25"
          d="M615.6,89C613.5,92.7,618,86.4,615.6,89z"
        />
        <path
          fill="#E31F25"
          d="M573.9,155.5c4.5-7.9,13.1-21.2,16.2-28C586.4,133.4,579,145.8,573.9,155.5z"
        />
        <path
          fill="#E31F25"
          d="M580.8,146.2c-0.2,2.5-2.9,3.3-2.3,5.3c1.5-3.2,1.9-2,3-3.6C580.6,148,582.4,143.8,580.8,146.2z"
        />
        <path
          fill="#E31F25"
          d="M593.6,125.4C591.8,129.6,595.7,123.4,593.6,125.4z"
        />
        <path
          fill="#E31F25"
          d="M624.1,82.6C622,86.2,626.5,80,624.1,82.6z"
        />
        <path
          fill="#E31F25"
          d="M624.8,76.8c2.4-3,6.6-6.5,7.8-9.3C630.3,69.9,626.1,73.5,624.8,76.8z"
        />
        <path
          fill="#E31F25"
          d="M618.3,84.6c-1,1.3-1.3,2.4-1.4,3.3C617.8,86.6,618.2,85.5,618.3,84.6z"
        />
        <path
          fill="#E31F25"
          d="M586.6,143.5C584.7,147.6,588.8,140.6,586.6,143.5z"
        />
        <path
          fill="#E31F25"
          d="M557.3,186.9C555.6,191.2,559.5,183.5,557.3,186.9z"
        />
        <path
          fill="#E31F25"
          d="M591.6,136.5C589.7,140.5,593.9,133.5,591.6,136.5z"
        />
        <path
          fill="#E31F25"
          d="M590.8,129.3c-0.4,2.2-1.3,4.8-3.8,8.4c-0.1-0.7,0.6-2.6-0.5-1.3c0.4,1.4-3.8,5.7-3.4,7.2
			c1-1.3,0.1,0.7,0.5,1.3c4.8-7.8,8.9-15.2,15.6-24.8c-1.8,1.2-5,6.7-7.5,9.9C592.2,128.4,592,127.9,590.8,129.3z"
        />
        <path
          fill="#E31F25"
          d="M585,144C583.6,147.6,587.5,141.6,585,144z"
        />
        <path
          fill="#E31F25"
          d="M569.6,162.2l-1.6,2.7c-0.3-0.4-0.4-1.4-1-0.4c0.5,1.2-1.3,3.8-0.7,4.8
			C567.1,167.2,570.3,162.3,569.6,162.2z"
        />
        <path
          fill="#E31F25"
          d="M560.4,181.3C558.6,185.4,562.7,178.3,560.4,181.3z"
        />
        <path
          fill="#E31F25"
          d="M564.6,174.2C561.4,179.1,566.8,170.9,564.6,174.2z"
        />
        <path
          fill="#E31F25"
          d="M559.7,179.6C557.5,181.6,561.6,178.9,559.7,179.6z"
        />
        <path
          fill="#E31F25"
          d="M573.2,156c-0.9,1.7-2.8,4-1.8,4.4C572.7,158.5,573.4,157,573.2,156z"
        />
        <path
          fill="#E31F25"
          d="M614.3,92.8C614.6,93.3,613.6,94.3,614.3,92.8c-0.5-2.2-1.5,2.5-2.5,2.2c1.1,1-2.4,4-1.7,5.1
			c6.4-8.2,10.6-16.2,14.7-20.6C623,78.5,616.6,90.4,614.3,92.8L614.3,92.8z"
        />
        <path
          fill="#E31F25"
          d="M569.2,159.2c-1.3,1.3-2.2,4.9-0.7,2.7C568.3,161.2,569.3,159.8,569.2,159.2z"
        />
        <path
          fill="#E31F25"
          d="M606.8,102.7C605.6,106.8,604.4,103.9,606.8,102.7z M609.3,97.8c-14.8,20.9-23,31.7-37,58.2
			c7.7-13.4,26.6-45.9,35.4-52.7C606.4,103,609.6,97.9,609.3,97.8z"
        />
        <path
          fill="#E31F25"
          d="M578.1,168.7c0.8-0.6,1.4-0.4,2.9-3.6C581.2,162.9,578.1,167,578.1,168.7z"
        />
        <path
          fill="#E31F25"
          d="M549.1,213.9C547.4,218.2,551.3,210.6,549.1,213.9z"
        />
        <path
          fill="#E31F25"
          d="M687.2,121.8C685.1,124.2,689.6,120.7,687.2,121.8z"
        />
        <path
          fill="#E31F25"
          d="M679.2,133.2C677.4,136.8,681.1,132,679.2,133.2z"
        />
        <path
          fill="#E31F25"
          d="M685.8,92.2C682.3,94.5,688.2,90.6,685.8,92.2z"
        />
        <path
          fill="#E31F25"
          d="M684.2,97.7C686.7,96.3,684.1,96.1,684.2,97.7z"
        />
        <path
          fill="#E31F25"
          d="M692.2,120.9C690.1,123.3,694.5,119.8,692.2,120.9z"
        />
        <path
          fill="#E31F25"
          d="M629.6,101.9c-0.8,1.1-4.5,6.1-2.6,4.3C627.8,105.1,631.6,100.1,629.6,101.9z"
        />
        <path
          fill="#E31F25"
          d="M697.8,116.5C694.7,118.2,697.4,118.6,697.8,116.5z"
        />
        <path
          fill="#E31F25"
          d="M684.2,97.7C681.1,99.6,683.9,99.8,684.2,97.7z"
        />
        <path
          fill="#E31F25"
          d="M697.8,116.5C700.5,115,696.7,114.5,697.8,116.5z"
        />
        <path
          fill="#E31F25"
          d="M578.5,169.6l-0.3-0.8c-2.7,3.8-2.9,4.7-5.3,8.4c1.6,0.8,4.1-6.9,5.3-6.2
			C577.9,170.7,578.2,170.2,578.5,169.6z"
        />
        <path
          fill="#E31F25"
          d="M671.8,91C669.7,94.1,674.2,89.1,671.8,91z"
        />
        <path
          fill="#E31F25"
          d="M669.3,86.1C667.2,89.2,671.7,84.3,669.3,86.1z"
        />
        <path
          fill="#E31F25"
          d="M671.8,81.4C669.7,84.6,674.2,79.6,671.8,81.4z"
        />
        <path
          fill="#E31F25"
          d="M665.9,105.2c0.7-0.6,1.5-1.8,1.8-1.3c0.2,0.7-0.9,1.6-0.9,2.3c3.7-4.2,7.5-8.9,10.4-12.5
			c-3.2,2.2-5.7,5.7-8.3,9.5C669.3,100.9,666.1,103.6,665.9,105.2L665.9,105.2z"
        />
        <path
          fill="#E31F25"
          d="M673.3,94.5C671.2,97.6,675.6,92.6,673.3,94.5z"
        />
        <path
          fill="#E31F25"
          d="M677,105C673.7,108.2,679.3,102.9,677,105z"
        />
        <path
          fill="#E31F25"
          d="M679.7,95.3c-0.9,1.5-3.4,2.7-3.8,4.6C676.3,99.5,680.1,96.9,679.7,95.3z"
        />
        <path
          fill="#E31F25"
          d="M626.8,110.7C621.7,116.4,628.4,108.7,626.8,110.7z"
        />
        <path
          fill="#E31F25"
          d="M673.3,81.7l-0.7,0.1l-1.1,1.3C670.9,85.4,673.8,82.5,673.3,81.7z"
        />
        <path
          fill="#E31F25"
          d="M681.3,96.4l-1.2,0.9l-0.4,1.5C680.7,98.1,681.8,97.3,681.3,96.4L681.3,96.4z"
        />
        <polygon
          fill="#E31F25"
          points="623.5,120.6 623.8,121.6 624.9,120 624.6,119.1 		"
        />
        <path
          fill="#E31F25"
          d="M582.4,168.7c-2.3,3.5-0.1,0.2,0.4-0.6C582.7,168.3,582.6,168.4,582.4,168.7z"
        />
        <path
          fill="#E31F25"
          d="M571.9,185.8C574.8,181.6,572,183.4,571.9,185.8z"
        />
        <polygon
          fill="#E31F25"
          points="579.8,173.1 580.1,174 581.2,172.3 580.8,171.4 		"
        />
        <path
          fill="#E31F25"
          d="M576.4,177.5C578.3,174.7,572.3,183.3,576.4,177.5z"
        />
        <path
          fill="#E31F25"
          d="M580.2,167.4C578.7,171,582.6,165.1,580.2,167.4z"
        />
        <path
          fill="#E31F25"
          d="M571.9,185.8C567.3,192.8,570.2,190.9,571.9,185.8z"
        />
        <path
          fill="#E31F25"
          d="M566.1,197.1C568.1,192.3,564.8,196,566.1,197.1z"
        />
        <path
          fill="#E31F25"
          d="M568.3,185.4c-0.8,1.3-4.3,7.1-2.4,4.8C566.6,189,570.2,183.2,568.3,185.4z"
        />
        <path
          fill="#E31F25"
          d="M584,163.9c0.1,0.3,0.1,0.6-0.3,1.3c-1.5,1.9-3.7,5.6-5.5,10C579.7,172,586.1,163,584,163.9z"
        />
        <path
          fill="#E31F25"
          d="M603.9,133.9C601.9,137.5,606.3,131.4,603.9,133.9z"
        />
        <polygon
          fill="#E31F25"
          points="626.6,110.2 626.3,109.2 625.2,110.7 625.5,111.7 		"
        />
        <path
          fill="#E31F25"
          d="M604.3,132.7c0.5-0.9,1.8,0.4,1.8-1.9l-0.7,0.3c0.8-1.1,2.1-3.1,1-2C605.6,130.3,604.3,131.8,604.3,132.7
			L604.3,132.7z"
        />
        <path
          fill="#E31F25"
          d="M618.8,128C617,132.1,620.8,126.1,618.8,128z"
        />
        <path
          fill="#E31F25"
          d="M603.4,132.5c-5,8.3-9.2,13.7-13.3,20.9c2.2,0.9,6.6-11.9,10.3-12.7c-1-0.9,1.5-3.3,2-3.6
			C602.1,135.9,604,133.7,603.4,132.5L603.4,132.5z"
        />
        <path
          fill="#E31F25"
          d="M589.2,152.9C586.2,157.1,589,155.4,589.2,152.9z"
        />
        <path
          fill="#E31F25"
          d="M585,162.2c1.7,0.4-1.8,4.1-0.7,4.9c1.1-2.6,4.2-7.6,1.6-4.8c1.5-3.2,1.8-2,2.9-3.5
			c-0.3-0.9,1.2-2.9,0.4-3.5L585,162.2L585,162.2z"
        />
        <path
          fill="#E31F25"
          d="M593.9,142.8C594.4,144,595.9,138.8,593.9,142.8z"
        />
        <path
          fill="#E31F25"
          d="M588.7,151.6l5.2-8.8c-1.7,0.2-4.6,6.5-6.4,10.1c0.1,1.8,1.4-0.8,1.7,0
			C590.6,149.8,589.5,151.1,588.7,151.6z"
        />
        <path
          fill="#E31F25"
          d="M683.3,79.7l1.2-1c0.5-0.3,0.5-0.6,0.5-1l-1.4-0.1C683,78.3,683.3,79,683.3,79.7L683.3,79.7z"
        />
        <path
          fill="#E31F25"
          d="M683.6,77.7C686.2,76.2,683.4,76,683.6,77.7z"
        />
        <path
          fill="#E31F25"
          d="M684.3,88C682.1,90.4,686.7,86.7,684.3,88z"
        />
        <path
          fill="#E31F25"
          d="M691.4,78.7C689.1,81.2,693.8,77.4,691.4,78.7z"
        />
        <path
          fill="#E31F25"
          d="M679,85.6C676.8,88.1,681.5,84.3,679,85.6z"
        />
        <path
          fill="#E31F25"
          d="M686,83l-1.3,0.4C683.3,85.5,686,84.5,686,83z"
        />
        <path
          fill="#E31F25"
          d="M686.2,87.1c-0.2-0.3-0.2-1-0.8-0.5C685.6,86.8,685.6,87.5,686.2,87.1z"
        />
        <path
          fill="#E31F25"
          d="M703.4,112.5c-1.5,0-2.6,1-4,2.6c1.5-0.9,5.5-0.5,5.7-3.4c-0.7,0.5-0.7-0.6-0.8-1.2
			C703.4,111.1,703.4,111.8,703.4,112.5L703.4,112.5z"
        />
        <path
          fill="#E31F25"
          d="M688.7,129.1l1.7-1.3C690.1,127.2,688.6,128.4,688.7,129.1z"
        />
        <path
          fill="#E31F25"
          d="M705.2,111.7l0.5-0.4C705.5,110.8,705.1,111.4,705.2,111.7z"
        />
        <path
          fill="#E31F25"
          d="M681.2,82.6c-1.3,1.3-2.3,1.8-3.1,1.9C677.5,86.8,681.5,83.4,681.2,82.6z"
        />
        <path
          fill="#E31F25"
          d="M656,78.6c-0.2-0.3-0.2-1-0.8-0.4C655.4,78.5,655.4,79.2,656,78.6z"
        />
        <path
          fill="#E31F25"
          d="M655.3,87.7C650.8,91.7,657.4,85.8,655.3,87.7z"
        />
        <path
          fill="#E31F25"
          d="M625.9,99.6c0.7-0.9,4-5.5,2.1-3.6C627.2,97,623.9,101.6,625.9,99.6z"
        />
        <path
          fill="#E31F25"
          d="M688.1,53.6C685.2,57.4,690.4,53.2,688.1,53.6z"
        />
        <path
          fill="#E31F25"
          d="M674.8,98C669.2,102.7,677.8,95.4,674.8,98z"
        />
        <path
          fill="#E31F25"
          d="M684.3,70.5C682,73,686.8,69.2,684.3,70.5z"
        />
        <path
          fill="#E31F25"
          d="M683.1,75.1C680.9,77.6,685.6,73.8,683.1,75.1z"
        />
        <path
          fill="#E31F25"
          d="M680.3,74.9C676.7,77.3,682.8,73.3,680.3,74.9z"
        />
        <path
          fill="#E31F25"
          d="M566.5,183.3l-0.2-0.4C565.8,184,566.2,183.8,566.5,183.3z"
        />
        <path
          fill="#E31F25"
          d="M689.3,78.5l-3,2.4C686.2,82,689.7,79.2,689.3,78.5z"
        />
        <path
          fill="#E31F25"
          d="M680.7,90.3l-0.1-0.5C679.9,90.4,680.4,90.6,680.7,90.3z"
        />
        <path
          fill="#E31F25"
          d="M681.4,87.3c1.5-1.2,0.7-1,0.4-1.5C681.3,86.2,681.5,86.8,681.4,87.3z"
        />
        <path
          fill="#E31F25"
          d="M698.1,59.4l0.6-0.5C698.5,58.4,698.1,59.1,698.1,59.4z"
        />
        <path
          fill="#E31F25"
          d="M693.2,95.4c0.1,0.5,0.8-0.2,1.2-0.4C694.3,94.5,693.6,95.2,693.2,95.4z"
        />
        <path
          fill="#E31F25"
          d="M679.5,94.3c0.7-0.8,2.1-3.7,1.2-4c-0.4,2.2-2.4,1-2.8,2.9C678.1,94.1,678.6,94.6,679.5,94.3L679.5,94.3z"
        />
        <path
          fill="#E31F25"
          d="M563.8,161.1c-0.2-0.4-0.8,0.8-1.2,1.4C562.8,162.9,563.4,161.7,563.8,161.1z"
        />
        <path
          fill="#E31F25"
          d="M529.8,222.6c-0.2,1.2-1.2,2.9-1.1,3.8C529.8,224.5,530.3,223.2,529.8,222.6z"
        />
        <path
          fill="#E31F25"
          d="M670.3,80.7l-0.2-1l-2.9,3.2C667.5,83.8,668.9,82.3,670.3,80.7z"
        />
        <path
          fill="#E31F25"
          d="M700.4,76.9C698.3,77.1,702.2,77.5,700.4,76.9z"
        />
        <path
          fill="#E31F25"
          d="M567.1,182.4C569.4,179.2,566.6,181,567.1,182.4z"
        />
        <path
          fill="#E31F25"
          d="M638,61.5l-0.7,0.2C636.4,63,637.4,62.2,638,61.5z"
        />
        <path
          fill="#E31F25"
          d="M610.6,109.5c-0.2-1.1-2,2.6-3,3.5c0.2,0.4,0.8-0.6,1.3-1.1l0.2,0.5C609.9,111.3,610.7,110.2,610.6,109.5
			L610.6,109.5z"
        />
        <path
          fill="#E31F25"
          d="M558.5,172.3c0.2,0.4,0.8-0.8,1.2-1.4C559.5,170.5,559,171.7,558.5,172.3z"
        />
        <path
          fill="#E31F25"
          d="M652.8,95.5c1.5,0,4.9-4.8,3-5.8c-0.9,1.2-1.1,0.6-1.8,1.4C655,91.8,654.4,93.2,652.8,95.5L652.8,95.5z"
        />
        <path
          fill="#E31F25"
          d="M655.8,89.7c1-0.4,1.6-1,2.3-2.5C658,84.8,655.8,88.9,655.8,89.7z"
        />
        <path
          fill="#E31F25"
          d="M667.7,65.7c0,1.2,2.8-2.2,1.6-2.5C668.5,64.1,667.6,65,667.7,65.7z"
        />
        <path
          fill="#E31F25"
          d="M596,134.8C592.6,139.1,598.4,131.9,596,134.8z"
        />
        <path
          fill="#E31F25"
          d="M610.9,125.5C608.4,128.3,611.2,127.1,610.9,125.5z"
        />
        <path
          fill="#E31F25"
          d="M621.5,90.2C619.5,93.8,623.9,87.6,621.5,90.2z"
        />
        <path
          fill="#E31F25"
          d="M530.4,227.8C528.6,232.1,532.5,224.4,530.4,227.8z"
        />
        <path
          fill="#E31F25"
          d="M568.8,175.8C566.9,179.8,571.1,172.8,568.8,175.8z"
        />
        <path
          fill="#E31F25"
          d="M569.5,171C567.6,175,571.8,168,569.5,171z"
        />
        <path
          fill="#E31F25"
          d="M571.1,168.4C569.2,172.3,573.3,165.4,571.1,168.4z"
        />
        <path
          fill="#E31F25"
          d="M572.6,165.7C570.8,169.7,574.9,162.7,572.6,165.7z"
        />
        <path
          fill="#E31F25"
          d="M538.9,217.8c-0.6,1.1-1.4,1.5-1.5,2.9C538.1,219.6,538.9,219.3,538.9,217.8z"
        />
        <path
          fill="#E31F25"
          d="M536.3,238.5c1.7-3.2,2.6-3.9,3.2-7.1C538.6,233.6,536.1,237.2,536.3,238.5z"
        />
        <path
          fill="#E31F25"
          d="M593.3,138.8C591.3,142.7,595.5,136,593.3,138.8z"
        />
        <path
          fill="#E31F25"
          d="M527.6,230.1c0.3-0.5,0.7-0.8,0.2,0.4c-0.7,1.3-1.8,3.8-0.8,2.3c1.5-3.3,4.5-7.9,4-9.6
			C529.8,226.8,527.8,227.5,527.6,230.1z"
        />
        <path
          fill="#E31F25"
          d="M535,213.2C532.9,215.5,536.8,212.2,535,213.2z"
        />
        <path
          fill="#E31F25"
          d="M537.2,228.3C535.5,232.5,539.3,224.9,537.2,228.3z"
        />
        <path
          fill="#E31F25"
          d="M534.2,205.4c0.2,0.4-0.5,1.5-0.8,2.4C533.9,209.2,536.2,201.9,534.2,205.4z"
        />
        <path
          fill="#E31F25"
          d="M539.8,199C538.1,203.4,542,195.6,539.8,199z"
        />
        <path
          fill="#E31F25"
          d="M603.8,116.4c0.8-1,4.1-5.7,2.1-3.6C605.2,113.7,601.8,118.4,603.8,116.4z"
        />
        <path
          fill="#E31F25"
          d="M537.6,202.5C533.7,209.3,539.5,199.4,537.6,202.5z"
        />
        <path
          fill="#E31F25"
          d="M611.6,104.8C609.5,108.5,614,102.3,611.6,104.8z"
        />
        <path
          fill="#E31F25"
          d="M629.6,79.8c-1,1.1-1.4,2.1-1.5,3C629.1,81.6,629.5,80.7,629.6,79.8z"
        />
        <path
          fill="#E31F25"
          d="M632.6,76.4C630.4,79.6,635.2,74.3,632.6,76.4z"
        />
        <path
          fill="#E31F25"
          d="M576.3,159.5C574.4,163.5,578.6,156.5,576.3,159.5z"
        />
        <path
          fill="#E31F25"
          d="M620.4,91.8l-1.1,1.6l-0.3,1.7C619.9,93.8,621,92.4,620.4,91.8L620.4,91.8z"
        />
        <path
          fill="#E31F25"
          d="M533.4,207.8l0.1-1.8c-1.5,1.3-2.6,5.5-3.1,7.7C532.1,210.8,532,209,533.4,207.8L533.4,207.8z"
        />
        <path
          fill="#E31F25"
          d="M528.1,226.9c-0.3,0.6-0.6,1.1-0.7,0.6l-1,2C526.1,232,528.6,227.3,528.1,226.9L528.1,226.9z"
        />
        <path
          fill="#E31F25"
          d="M528.3,217.2C530.4,213.5,525.2,222.7,528.3,217.2z"
        />
        <path
          fill="#E31F25"
          d="M528.2,215c-2.1,3.5-3,5.4-2.9,8.1l1.7-2.6C525.7,220.1,528.3,216.4,528.2,215z"
        />
        <path
          fill="#E31F25"
          d="M703.5,109.4c-0.7-1.1,2.7-1.7,2-2.8C704.4,107.2,701.4,110.1,703.5,109.4z"
        />
        <path
          fill="#E31F25"
          d="M659.9,120.9C662.2,118.7,656.5,124.1,659.9,120.9z"
        />
        <path
          fill="#E31F25"
          d="M636.8,97.3C634.6,100.4,639.3,95.3,636.8,97.3z"
        />
        <path
          fill="#E31F25"
          d="M658.8,149.4C656.7,149.7,658.4,152.2,658.8,149.4z"
        />
        <path
          fill="#E31F25"
          d="M658.1,105C656,108,660.4,103.1,658.1,105z"
        />
        <path
          fill="#E31F25"
          d="M656.5,151.3C654.6,154.2,658.8,149.5,656.5,151.3z"
        />
        <path
          fill="#E31F25"
          d="M667.5,140.5c0.2,0.3,0.3,1,0.8,0.4C668,140.6,668,139.9,667.5,140.5z"
        />
        <path
          fill="#E31F25"
          d="M670.8,137.4C667.6,140.5,673,135.3,670.8,137.4z"
        />
        <path
          fill="#E31F25"
          d="M659.9,148.2c0.2,0.3,0.3,1,0.8,0.4C660.5,148.4,660.4,147.7,659.9,148.2z"
        />
        <path
          fill="#E31F25"
          d="M664.2,140.9c-1.2,2.1,1.3,0.4,0,2.6c-1.5,1.7-2,1.3-1.5-0.3c-0.7,1.1-2.9,2.5-2.5,3.4
			c1.8-2.5,2-1.3,1.5,0.3c1.1-1.4,1.5-1.2,2.3-1.9c-0.1-0.3,0.3-1,0.5-0.6l-0.5,0.6l0.1,0.5c0.8-0.9,2.1-2,2-2.8
			C663.4,145.9,666.9,138,664.2,140.9L664.2,140.9z"
        />
        <path
          fill="#E31F25"
          d="M658.8,149.4C659.5,148.3,658.6,148.9,658.8,149.4z"
        />
        <path
          fill="#E31F25"
          d="M640.3,139.1c2.6-3.2,5.9-6.2,9.2-11.3C645.8,132.7,642.2,133.6,640.3,139.1z"
        />
        <polygon
          fill="#E31F25"
          points="641.5,127.7 641.2,126.8 640.1,128 640.4,129 		"
        />
        <path
          fill="#E31F25"
          d="M641,99C642.8,97.5,635.7,103.6,641,99z"
        />
        <path
          fill="#E31F25"
          d="M655.6,150.4c0.2,0.5-0.6,1.6,0.3,1C656.4,150.8,656.3,149.8,655.6,150.4z"
        />
        <path
          fill="#E31F25"
          d="M640,95.1C641.8,93.5,634.6,99.7,640,95.1z"
        />
        <path
          fill="#E31F25"
          d="M655,121.2l0.6-0.6C655.3,120.1,654.9,120.9,655,121.2z"
        />
        <path
          fill="#E31F25"
          d="M652.5,122.6c0.8,1.8-1.4,1.5-2.2,2.5c-0.2,2.3,0.3,1.2,0.8,3c0.7-2.2,2.4-4.6,3.9-6.9
			C653.4,123.7,653.4,121.9,652.5,122.6L652.5,122.6z"
        />
        <path
          fill="#E31F25"
          d="M647.7,131.7C645.7,134.7,650.1,129.9,647.7,131.7z"
        />
        <path
          fill="#E31F25"
          d="M649.2,137.1C647.6,139.9,651.7,136,649.2,137.1z"
        />
        <path
          fill="#E31F25"
          d="M671.5,134.7c-0.8,0.9-4.4,4.7-2.6,3.5C669.8,137.3,673.4,133.5,671.5,134.7z"
        />
        <path
          fill="#E31F25"
          d="M693.5,97c0.2,0.3,0.2,1,0.8,0.6C694,97.3,694,96.5,693.5,97z"
        />
        <path
          fill="#E31F25"
          d="M697.4,104.1C695.3,104.3,699.1,104.7,697.4,104.1z"
        />
        <path
          fill="#E31F25"
          d="M691.2,109.1c-0.6,2,2.2,0,1.8-0.8l-0.7-0.1L691.2,109.1L691.2,109.1z"
        />
        <path
          fill="#E31F25"
          d="M689.4,96.6C691.1,95.6,684.1,99.6,689.4,96.6z"
        />
        <path
          fill="#E31F25"
          d="M704,97.6c-2.7,1.8-4,3.7-4.8,5.7C701.9,101.5,702.4,99.4,704,97.6L704,97.6z"
        />
        <path
          fill="#E31F25"
          d="M630.8,86.8l0.6-0.7l-0.1-0.5C630.7,86.1,630.7,86.4,630.8,86.8L630.8,86.8z"
        />
        <path
          fill="#E31F25"
          d="M719.2,79.3c0.8-0.2,2.3-0.7,1.1-0.3C719.8,79.1,719.4,79.2,719.2,79.3z"
        />
        <path
          fill="#E31F25"
          d="M675.7,132.6C673.7,135.5,678,130.8,675.7,132.6z"
        />
        <path
          fill="#E31F25"
          d="M672.2,111.1c-1.8,1.8-3.3,3.7-3.6,5.3c1.5-1.6,3.2-3.3,3.7-4.8L672.2,111.1z"
        />
        <path
          fill="#E31F25"
          d="M678.5,99.3C676.7,101.6,681,98.8,678.5,99.3z"
        />
        <path
          fill="#E31F25"
          d="M686.5,98.9C683.1,101,686.7,101.4,686.5,98.9z"
        />
        <path
          fill="#E31F25"
          d="M672.2,132c2.6-2.4,6.3-6.9,5.5-8.1C677.2,126.5,671.9,129.6,672.2,132z"
        />
        <path
          fill="#E31F25"
          d="M684.7,103.3l1.2-0.4c0-0.3,0.4-0.9,0.6-0.5l-0.6,0.5l0.1,0.5c1.3-0.9,1.8-1.9,1.4-3
			c-1.1,1.2-1.6,0.9-2.4,1.3C685.3,102.4,685.1,102.9,684.7,103.3L684.7,103.3z"
        />
        <path
          fill="#E31F25"
          d="M673.2,136.1C675.4,134,669.9,139.2,673.2,136.1z"
        />
        <path
          fill="#E31F25"
          d="M680.8,104C680.1,105,681,104.6,680.8,104z"
        />
        <path
          fill="#E31F25"
          d="M682,106.1c1.4-0.9,2.2-1.8,2.8-2.8l-0.1-0.5C683.2,103.9,681.8,105,682,106.1z"
        />
        <path
          fill="#E31F25"
          d="M681.2,121.4c1-1.5,4.1-2.4,3.1-3.9C682.9,118.7,681,119.8,681.2,121.4z"
        />
        <path
          fill="#E31F25"
          d="M680.8,104C681.6,103.1,680.6,103.5,680.8,104z"
        />
        <path
          fill="#E31F25"
          d="M656.9,128.6l-1.7,1.8C654.8,132.8,658.1,129.2,656.9,128.6z"
        />
        <path
          fill="#E31F25"
          d="M664.2,103.9l0.7-0.1c-0.2-0.8,1.5-2,1.4-2.9c-0.8,0.7-1.2,0.5-2.3,2L664.2,103.9L664.2,103.9z"
        />
        <path
          fill="#E31F25"
          d="M635.2,113.1c1-0.4,1.5-1,1.6-2.4l-1.9,1.4L635.2,113.1z"
        />
        <path
          fill="#E31F25"
          d="M660.7,112.9c2.6-3.1,4.3-3.4,5.5-6.7C664.6,108.4,661.2,110.9,660.7,112.9z"
        />
        <path
          fill="#E31F25"
          d="M660.4,99.9C658.3,102.9,662.8,98,660.4,99.9z"
        />
        <path
          fill="#E31F25"
          d="M661.4,94.9C659.7,97.8,663.9,93.8,661.4,94.9z"
        />
        <path
          fill="#E31F25"
          d="M663.3,87.8l-0.3,1.6c1.2-3.1,3.6-3.7,3.7-6c-3.1,3.9-8.9,8.3-8.1,11.5C661.8,92.3,659.6,90.6,663.3,87.8z
			"
        />
        <path
          fill="#E31F25"
          d="M662.8,121.4C662.1,122.6,663,122,662.8,121.4z"
        />
        <path
          fill="#E31F25"
          d="M666.5,98.8C663.4,100.6,664.9,102.9,666.5,98.8z"
        />
        <path
          fill="#E31F25"
          d="M661.6,101.8c0.9-0.2,2.2-1.7,2.1-3C662.9,99.8,661.6,100.9,661.6,101.8z"
        />
        <path
          fill="#E31F25"
          d="M662.8,121.4C664.9,118.9,662.2,119.3,662.8,121.4z"
        />
        <path
          fill="#E31F25"
          d="M663.4,112.5c1.2-0.9,6.6-6.9,9.3-11C671.3,103.6,663.9,110,663.4,112.5z"
        />
        <path
          fill="#E31F25"
          d="M665.9,111C661.6,114.8,668,109.2,665.9,111z"
        />
        <path
          fill="#E31F25"
          d="M667,138.5C663.7,141.6,669.2,136.4,667,138.5z"
        />
        <path
          fill="#E31F25"
          d="M670.9,117.6C668.9,120.5,673.2,115.7,670.9,117.6z"
        />
        <path
          fill="#E31F25"
          d="M668.9,135.1c-1.3,0.3-0.9,1.5-2.3,1.8C666.1,139.3,669.3,135.8,668.9,135.1z"
        />
        <path
          fill="#E31F25"
          d="M654.4,98.9c1.4-1.1,2.4-1.8,3.3-4.3C656,94.7,655.3,96.4,654.4,98.9z"
        />
        <path
          fill="#E31F25"
          d="M662,97.5l0.2,1l0.7-0.1c-0.2-0.7,0.9-1.5,0.9-2.2C663.1,96.9,662.9,96.3,662,97.5z"
        />
        <path
          fill="#E31F25"
          d="M669.2,107.3c0.2,0.3,0.2,1,0.8,0.4C669.8,107.4,669.8,106.7,669.2,107.3z"
        />
        <path
          fill="#E31F25"
          d="M667.2,116.5c1.3-1.3,2-2.4,2-3.5C668.2,114.3,666.3,115.6,667.2,116.5z"
        />
        <path
          fill="#E31F25"
          d="M668,111.2C666.2,114.8,670,109.9,668,111.2z"
        />
        <path
          fill="#E31F25"
          d="M659.1,109c-0.8-0.9,2.7-2.6,2-3.5c-0.7-0.3-2,0.9-3.2,1.6C658.3,107.5,657.9,110.4,659.1,109z"
        />
        <path
          fill="#E31F25"
          d="M646.3,102C647.1,100.8,646.1,101.5,646.3,102z"
        />
        <path
          fill="#E31F25"
          d="M644.4,100.3C642.3,103.5,646.8,98.4,644.4,100.3z"
        />
        <path
          fill="#E31F25"
          d="M643.9,90c2-3.5,3.1-0.7,3.7-3.4c-0.7,0.7-0.8-0.1-1-0.9c0.8-0.8,2.2-2.6,1-1.8
			C645.4,86.2,643.7,88.3,643.9,90L643.9,90z"
        />
        <path
          fill="#E31F25"
          d="M656.7,108.4c-1.8,2.6-2.5,2.4-2.3,5.1C655.7,111.7,656.9,109.9,656.7,108.4z"
        />
        <path
          fill="#E31F25"
          d="M641.9,87.9c-2.1,1.5-8.8,8-9.2,10.9c1.7-1.4,6.8-6.3,9.4-10.4L641.9,87.9z"
        />
        <path
          fill="#E31F25"
          d="M643.6,105.7c-1,0.2-2.3,1.7-2.2,3C642.3,107.7,643.6,106.6,643.6,105.7z"
        />
        <path
          fill="#E31F25"
          d="M643.6,85.9c0.1,0.7-1,1.6-1.6,2.5C642.3,89.9,646.2,83.8,643.6,85.9z"
        />
        <path
          fill="#E31F25"
          d="M643.2,90.2C635.5,97.7,642,94,643.2,90.2z"
        />
        <path
          fill="#E31F25"
          d="M640.9,106.8c1.4-1.5,2.8-3,2.6-4.2C642.1,104.1,640.7,105.6,640.9,106.8z"
        />
        <path
          fill="#E31F25"
          d="M644.3,129.8C642.6,132.5,646.8,128.6,644.3,129.8z"
        />
        <path
          fill="#E31F25"
          d="M655.9,93.4c-0.7,0.8-1.7,1.7-1.6,2.4l0.7-0.1C655.7,94.8,656.3,94,655.9,93.4L655.9,93.4z"
        />
        <path
          fill="#E31F25"
          d="M649.4,96.7c0.8-0.3,1.7-1,3-2.7C652.9,91.6,649.1,96,649.4,96.7z"
        />
        <path
          fill="#E31F25"
          d="M652.4,99.8C650.3,102.9,654.8,97.9,652.4,99.8z"
        />
        <path
          fill="#E31F25"
          d="M652,95.2c-1,1.1-1.4,2-1.5,2.9C651.5,97,651.9,96.1,652,95.2z"
        />
        <path
          fill="#E31F25"
          d="M644.3,103l4-4.5l-0.2,2.1c2-1.6,0.4-2.5,1.2-3.9C647.7,99.3,644.1,100.2,644.3,103z"
        />
        <polygon
          fill="#E31F25"
          points="649.3,124.3 649.6,125.2 650.7,124 650.4,123 		"
        />
        <path
          fill="#E31F25"
          d="M646.3,102C644.8,104.5,647.7,104,646.3,102z"
        />
        <path
          fill="#E31F25"
          d="M719.2,79.3c0.2-0.1,0.6-0.2,1.1-0.3C721.5,78.6,720,79.1,719.2,79.3z M699.2,103.2
			c0.8-1.9,2.1-3.9,4.8-5.7C702.4,99.4,701.9,101.5,699.2,103.2z M697.4,104.1C699.1,104.7,695.3,104.3,697.4,104.1z M693.5,97
			c0.6-0.4,0.6,0.3,0.8,0.6C693.7,98,693.7,97.3,693.5,97z M691.2,109.1l1.1-0.9l0.7,0.1C693.4,109.1,690.6,111.1,691.2,109.1
			L691.2,109.1z M689.4,96.6C684.1,99.6,691.1,95.6,689.4,96.6z M686.1,103.5l-0.1-0.5l-1.2,0.4c-0.6,1-1.4,1.9-2.8,2.8
			c-0.2-1.2,1.2-2.2,2.7-3.3l0.1,0.5c0.4-0.4,0.6-0.9,0.4-1.5c0.8-0.4,1.2-0.1,2.4-1.3C687.8,101.6,687.4,102.6,686.1,103.5
			L686.1,103.5z M681.2,121.4c-0.2-1.5,1.6-2.7,3.1-3.9C685.3,119,682.3,119.8,681.2,121.4z M680.8,104
			C681,104.6,680.1,105,680.8,104C680.6,103.5,681.6,103.1,680.8,104z M686.5,98.9C686.7,101.4,683.1,101,686.5,98.9z M678.5,99.3
			C681,98.8,676.7,101.6,678.5,99.3z M672.2,132c-0.4-2.4,5-5.5,5.5-8.1C678.6,125.1,674.9,129.6,672.2,132z M673.2,136.1
			C669.9,139.2,675.4,134,673.2,136.1z M675.7,132.6C678,130.8,673.7,135.5,675.7,132.6z M669,138.2c-1.9,1.2,1.8-2.6,2.6-3.5
			C673.4,133.5,669.8,137.3,669,138.2z M670.8,137.4C673,135.3,667.6,140.5,670.8,137.4z M667.5,140.5c0.5-0.5,0.6,0.2,0.8,0.4
			C667.8,141.5,667.7,140.7,667.5,140.5z M664,145.6l-0.1-0.5c-0.8,0.7-1.2,0.4-2.3,1.9c0.6-1.6,0.3-2.8-1.5-0.3
			c-0.4-1,1.8-2.3,2.5-3.4c-0.6,1.6,0,2,1.5,0.3c1.4-2.2-1.1-0.5,0-2.6c2.7-2.9-0.8,5,1.8,1.8C666.1,143.6,664.8,144.7,664,145.6
			L664,145.6z M659.9,148.2c0.5-0.6,0.6,0.2,0.8,0.4C660.1,149.2,660.1,148.5,659.9,148.2z M659.9,120.9
			C656.5,124.1,662.2,118.7,659.9,120.9z M658.8,149.4C658.4,152.2,656.7,149.7,658.8,149.4C658.6,148.9,659.5,148.3,658.8,149.4z
			 M658.1,105C660.4,103.1,656,108,658.1,105z M656.5,151.3C658.8,149.5,654.6,154.2,656.5,151.3z M655.6,150.4
			c0.7-0.6,0.8,0.4,0.3,1C655,152,655.8,150.9,655.6,150.4z M655.2,130.5l1.7-1.8C658.1,129.2,654.8,132.8,655.2,130.5z M651,128.1
			c-0.5-1.8-1-0.7-0.8-3c0.8-0.9,3-0.7,2.2-2.5c0.9-0.8,0.9,1,2.5-1.4c-0.1-0.3,0.3-1.1,0.6-0.6l-0.6,0.6
			C653.4,123.5,651.7,125.9,651,128.1L651,128.1z M649.2,137.1C651.7,136,647.6,139.9,649.2,137.1z M647.7,131.7
			C650.1,129.9,645.7,134.7,647.7,131.7z M640.3,139.1c1.9-5.4,5.5-6.4,9.2-11.3C646.2,132.9,642.9,135.9,640.3,139.1z M641.2,126.8
			l0.3,0.9l-1.1,1.3l-0.3-1L641.2,126.8z M641,99C635.7,103.6,642.8,97.5,641,99z M640,95.1C634.6,99.7,641.8,93.5,640,95.1z
			 M636.8,97.3C639.3,95.3,634.6,100.4,636.8,97.3z M635.2,113.1l-0.3-0.9l1.9-1.4C636.7,112.1,636.1,112.8,635.2,113.1z
			 M643.6,105.7c0,0.9-1.3,2-2.2,3C641.3,107.4,642.7,105.9,643.6,105.7z M643.2,90.2C642,94,635.5,97.7,643.2,90.2z M643.5,102.6
			c0.2,1.2-1.2,2.7-2.6,4.2C640.7,105.6,642.1,104.1,643.5,102.6z M632.6,98.8c0.5-2.8,7.2-9.3,9.2-10.9l0.1,0.5
			c0.7-0.8,1.7-1.8,1.6-2.5c2.6-2.1-1.4,4-1.6,2.5C639.5,92.5,634.3,97.4,632.6,98.8z M647.7,84c1.2-0.8-0.2,1-1,1.8
			c0.1,0.8,0.3,1.5,1,0.9c-0.6,2.7-1.7-0.2-3.7,3.4C643.7,88.3,645.4,86.2,647.7,84L647.7,84z M644.4,100.3
			C646.8,98.4,642.3,103.5,644.4,100.3z M644.3,129.8C646.8,128.6,642.6,132.5,644.3,129.8z M646.3,102
			C646.1,101.5,647.1,100.8,646.3,102C647.7,104,644.8,104.5,646.3,102z M652.4,94c-1.3,1.6-2.2,2.4-3,2.7c-0.8,1.4,0.8,2.3-1.2,3.9
			l0.2-2.1l-4,4.5c-0.3-2.8,3.4-3.7,5.1-6.2C649.1,96,652.9,91.6,652.4,94L652.4,94z M650.7,124l-1.1,1.2l-0.3-1l1.1-1.3L650.7,124z
			 M650.5,98.1c0.1-0.9,0.5-1.8,1.5-2.9C651.9,96.1,651.5,97,650.5,98.1z M652.4,99.8C654.8,97.9,650.3,102.9,652.4,99.8z
			 M655.9,93.4c0.4,0.6-0.2,1.4-0.9,2.3l-0.7,0.1C654.3,95.1,655.3,94.2,655.9,93.4L655.9,93.4z M656.7,108.4c0.1,1.5-1,3.3-2.3,5.1
			C654.2,110.8,654.9,111,656.7,108.4z M657.7,94.6c-0.9,2.5-1.9,3.2-3.3,4.3C655.3,96.4,656,94.7,657.7,94.6z M666.6,83.4
			c-0.1,2.3-2.4,2.8-3.7,6l0.3-1.6c-3.7,2.8-1.5,4.5-4.8,7.2C657.7,91.8,663.5,87.4,666.6,83.4z M662.8,121.4
			C663,122,662.1,122.6,662.8,121.4C662.2,119.3,664.9,118.9,662.8,121.4z M666.5,98.8C664.9,102.9,663.4,100.6,666.5,98.8z
			 M666.3,100.9c0.1,0.9-1.6,2.1-1.4,2.9l-0.7,0.1l-0.2-1C665.1,101.4,665.5,101.6,666.3,100.9L666.3,100.9z M666.2,106.2
			c-1.2,3.3-2.8,3.6-5.5,6.7C661.2,110.9,664.6,108.4,666.2,106.2z M661.1,105.5c0.7,0.9-2.7,2.6-2,3.5c-1.2,1.4-0.7-1.5-1.2-1.9
			C659,106.4,660.3,105.2,661.1,105.5z M660.4,99.9C662.8,98,658.3,102.9,660.4,99.9z M661.4,94.9C663.9,93.8,659.7,97.8,661.4,94.9
			z M663.7,98.8c0.1,1.3-1.2,2.8-2.1,3C661.6,100.9,662.9,99.8,663.7,98.8z M662.9,98.4l-0.7,0.1l-0.2-1c0.9-1.2,1.1-0.6,1.8-1.4
			C663.8,96.8,662.8,97.7,662.9,98.4z M663.4,112.5c0.5-2.5,7.9-8.9,9.3-11C670,105.5,664.6,111.6,663.4,112.5z M669.2,107.3
			c0.5-0.6,0.6,0.2,0.8,0.4C669.5,108.3,669.4,107.6,669.2,107.3z M668,111.2C670,109.9,666.2,114.8,668,111.2z M669.2,113.1
			c0.1,1-0.7,2.2-2,3.5C666.3,115.6,668.2,114.3,669.2,113.1z M666.6,136.9c1.3-0.3,1-1.5,2.3-1.8
			C669.3,135.8,666.1,139.3,666.6,136.9z M665.9,111C668,109.2,661.6,114.8,665.9,111z M667,138.5
			C669.2,136.4,663.7,141.6,667,138.5z M670.9,117.6C673.2,115.7,668.9,120.5,670.9,117.6z M668.5,116.3c0.3-1.6,1.9-3.4,3.6-5.3
			l0.1,0.5C671.7,113.1,670,114.7,668.5,116.3z M674.8,98C677.8,95.4,669.2,102.7,674.8,98z M669,103.1c2.6-3.8,5.2-7.3,8.3-9.5
			c-3,3.6-6.8,8.3-10.4,12.5c0-0.7,1-1.6,0.9-2.3c-0.2-0.5-1.1,0.7-1.8,1.3C666.1,103.6,669.3,100.9,669,103.1L669,103.1z
			 M669.3,86.1C671.7,84.3,667.2,89.2,669.3,86.1z M671.8,91C674.2,89.1,669.7,94.1,671.8,91z M671.8,81.4
			C674.2,79.6,669.7,84.6,671.8,81.4z M673.3,81.7c0.4,0.8-2.4,3.6-1.8,1.4l1.1-1.3L673.3,81.7z M673.3,94.5
			C675.6,92.6,671.2,97.6,673.3,94.5z M677,105C679.3,102.9,673.7,108.2,677,105z M679.7,95.3c0.4,1.6-3.4,4.2-3.8,4.6
			C676.3,98,678.8,96.8,679.7,95.3z M680.1,97.4l1.2-0.9c0.5,0.9-0.6,1.7-1.5,2.4L680.1,97.4L680.1,97.4z M684.2,97.7
			C684.1,96.1,686.7,96.3,684.2,97.7C683.9,99.8,681.1,99.6,684.2,97.7z M685.8,92.2C688.2,90.6,682.3,94.5,685.8,92.2z
			 M679.2,133.2C681.1,132,677.4,136.8,679.2,133.2z M687.2,121.8C689.6,120.7,685.1,124.2,687.2,121.8z M692.2,120.9
			C694.5,119.8,690.1,123.3,692.2,120.9z M697.8,116.5C696.7,114.5,700.5,115,697.8,116.5C697.4,118.6,694.7,118.2,697.8,116.5z
			 M705.6,106.6c0.6,1.1-2.7,1.7-2,2.8C701.4,110.1,704.4,107.2,705.6,106.6z M627,106.2c-1.9,1.8,1.8-3.2,2.6-4.3
			C631.6,100.1,627.8,105.1,627,106.2z M626.8,110.7C628.4,108.7,621.7,116.4,626.8,110.7z M623.8,121.6l-0.3-1l1.1-1.5l0.3,0.9
			L623.8,121.6L623.8,121.6z M618.8,128C620.8,126.1,617,132.1,618.8,128z M626.3,109.2l0.3,1l-1.1,1.5l-0.3-1L626.3,109.2z
			 M605.4,131.1l0.7-0.3c0,2.3-1.3,1-1.8,1.9c0-0.9,1.2-2.4,2.1-3.6C607.5,128,606.2,130.1,605.4,131.1L605.4,131.1z M603.9,133.9
			C606.3,131.4,601.9,137.5,603.9,133.9z M600.5,140.7c-3.7,0.8-8.2,13.6-10.3,12.7c4.1-7.2,8.2-12.6,13.3-20.9
			c0.5,1.2-1.3,3.4-0.9,4.6C602,137.4,599.5,139.8,600.5,140.7L600.5,140.7z M589.2,152.9C589,155.4,586.2,157.1,589.2,152.9
			c-0.3-0.9-1.6,1.8-1.7,0c1.8-3.5,4.8-9.9,6.4-10.1c2-4,0.6,1.1,0,0l-5.2,8.8C589.5,151.1,590.6,149.8,589.2,152.9z M588.7,158.7
			c-1,1.5-1.4,0.3-2.9,3.5c2.6-2.7-0.5,2.2-1.6,4.8c-1.1-0.8,2.4-4.4,0.7-4.9l4.1-7C589.9,155.8,588.4,157.8,588.7,158.7
			L588.7,158.7z M582.4,168.7c0.2-0.3,0.3-0.5,0.4-0.6C582.3,168.9,580.1,172.2,582.4,168.7z M580.1,174l-0.3-0.9l1-1.8l0.3,0.9
			L580.1,174z M583.6,165.3c0.5-0.7,0.4-1,0.3-1.3c2.1-0.9-4.2,8.1-5.9,11.4C579.9,170.9,582.1,167.2,583.6,165.3z M576.4,177.5
			C572.3,183.3,578.3,174.7,576.4,177.5z M571.9,185.8C570.2,190.9,567.3,192.8,571.9,185.8C572,183.4,574.8,181.6,571.9,185.8z
			 M565.9,190.3c-1.9,2.2,1.7-3.5,2.4-4.8C570.2,183.2,566.6,189,565.9,190.3z M566.1,197.1C564.8,196,568.1,192.3,566.1,197.1z
			 M580.2,167.4C582.6,165.1,578.7,171,580.2,167.4z M578.1,168.7c0-1.7,3.1-5.9,2.9-3.6C579.6,168.4,578.9,168.2,578.1,168.7
			l0.3,0.8c-0.3,0.6-0.6,1.1-0.4,1.3c-1.3-0.8-3.7,6.9-5.3,6.2C575.2,173.5,575.4,172.5,578.1,168.7z M584.7,159.1
			c-0.7,0.9-1.3,1.2-1.5,0.4C584.2,157.4,584.4,158.4,584.7,159.1z M585,159.9C586.2,155.7,588.6,155.6,585,159.9z M597.9,135.5
			c-0.8,1.6-0.3,2.6-1.8,4.5C595.6,139.1,596.4,137.5,597.9,135.5z M599.1,136.6C601.5,134,597.1,140.2,599.1,136.6z M601,132.5
			c-0.5,0.7-0.6,0-0.9-0.1C600.7,131.6,600.8,132.4,601,132.5z M603.4,129.9C605.7,127,600,134.1,603.4,129.9z M619.5,104.6
			c0.7,1.8-3.4,4.9-0.9,4.7l-4.3,4c-1.1,1.7-0.7,2.8-1.1,4.2c-1.1,1.1-0.3-0.7-0.5-1.4c-2.1,3.3-1.6,2.6-3.9,5.5
			c3.1-1.2-3.3,3.3-4,5C610.7,116.8,612.6,114.5,619.5,104.6L619.5,104.6z M562.2,192.1c-1.9,2.3-1.7,7.3-2.9,5.7
			c-0.6,4.1-1.5-0.2-1.6,2.4c-2.4,3.2,2-7,1.6-2.4c0.6-1.4,0.2-2,0.4-3.2l5.6-10c0.3,0.4-2.3,5.4-0.5,3.1
			C563.8,190.1,562.9,194.5,562.2,192.1L562.2,192.1z M561.7,199.3c-0.5,0.7-1.2,0.2-1.2,1.5c-2,2.7,0.5-2.9,1.6-4.7
			C562.7,196.5,561.4,198.5,561.7,199.3L561.7,199.3z M554.8,202c-0.3-1.1,2-4.3,2.7-6.2C558,196.9,555.6,201.3,554.8,202z
			 M553,206.2c0.2-0.3,0.2-0.4,0.3-0.5C552.7,206.8,550.9,209.9,553,206.2z M566.2,173.7c-0.5-4.3,8.7-15.4,11.4-22.2
			c0.2,1-0.5,2.5-1.8,4.4c2-1.2,3.2-4.4,6.3-8.4c-0.1-0.7-0.2-1.3,0.7-2.7l0.3,0.9l-1,1.8c-8.6,17.2-19.3,31.4-30.1,52.2
			C557.1,189.3,562.9,177.5,566.2,173.7L566.2,173.7z M549.2,216.1c1.4-3.4,1.8-2.3,2.8-4C552.1,213.4,548.9,219.6,549.2,216.1z
			 M547.9,219.4c0.5-0.9,0.6-0.3,0.8-0.2C548.3,220.2,548.2,219.5,547.9,219.4z M549.1,213.9C551.3,210.6,547.4,218.2,549.1,213.9z
			 M551,201.6c0.2,1.4-2.3,4.9-3.2,7.2C548.1,205.6,549,205.8,551,201.6z M542.1,208.4c-1.2,0.8,4.2-8.7,5.7-12.1
			C549,195.6,543.6,205,542.1,208.4z M551.9,195.2c-0.5,0.9-0.6,0.2-0.8,0.1C551.6,194.5,551.7,195.1,551.9,195.2z M557.3,186.9
			C559.5,183.5,555.6,191.2,557.3,186.9z M559.7,179.6C561.6,178.9,557.5,181.6,559.7,179.6z M560.4,181.3
			C562.7,178.3,558.6,185.4,560.4,181.3z M564.6,174.2C566.8,170.9,561.4,179.1,564.6,174.2z M566.9,164.5c0.6-1,0.8,0.1,1,0.4
			l1.6-2.7c0.7,0.1-2.5,4.9-3.3,7.1C565.7,168.3,567.4,165.7,566.9,164.5z M569.2,159.2c0.1,0.7-0.9,2.1-0.7,2.7
			C567,164.1,568,160.5,569.2,159.2z M573.2,156c0.2,1-0.5,2.5-1.8,4.4C570.4,160,572.4,157.6,573.2,156z M609.3,97.8
			c0.3,0.2-2.9,5.2-1.5,5.5c-8.9,6.8-27.7,39.3-35.4,52.7C586.3,129.5,594.6,118.7,609.3,97.8z M611.8,95.1c1,0.2,2-4.5,2.5-2.2
			c2.3-2.4,8.7-14.3,10.5-13.3c-4.1,4.4-8.3,12.4-14.7,20.6C609.3,99,612.8,96,611.8,95.1L611.8,95.1z M615.6,89
			C618,86.4,613.5,92.7,615.6,89z M618.3,84.6c-0.1,0.9-0.4,2-1.4,3.3C616.9,87,617.3,85.9,618.3,84.6z M624.1,82.6
			C626.5,80,622,86.2,624.1,82.6z M632.6,67.6c-1.2,2.7-5.4,6.2-7.8,9.3C626.1,73.5,630.3,69.9,632.6,67.6z M593.6,125.4
			C595.7,123.4,591.8,129.6,593.6,125.4z M573.9,155.5c5-9.8,12.5-22.2,16.2-28C587,134.4,578.4,147.7,573.9,155.5z M581.4,147.9
			c-1.1,1.5-1.4,0.3-3,3.6c-0.6-2.1,2.2-2.8,2.3-5.3C582.4,143.8,580.6,148,581.4,147.9z M599.3,120c-6.7,9.6-10.8,17-15.6,24.8
			c-0.4-0.6,0.5-2.6-0.5-1.3c-0.4-1.4,3.8-5.7,3.4-7.2c1-1.2,0.4,0.7,0.5,1.3c2.4-3.5,3.3-6.2,3.8-8.4c1.3-1.4,1.4-1,1,0.6
			C594.3,126.7,597.5,121.3,599.3,120z M591.6,136.5C593.9,133.5,589.7,140.5,591.6,136.5z M586.6,143.5
			C588.8,140.6,584.7,147.6,586.6,143.5z M585,144C587.5,141.6,583.6,147.6,585,144z M581,151.5C583.3,148.5,579.1,155.5,581,151.5z
			 M576.3,159.5C578.6,156.5,574.4,163.5,576.3,159.5z M572.6,165.7C574.9,162.7,570.8,169.7,572.6,165.7z M571.1,168.4
			C573.3,165.4,569.2,172.3,571.1,168.4z M569.5,171C571.8,168,567.6,175,569.5,171z M568.8,175.8
			C571.1,172.8,566.9,179.8,568.8,175.8z M536.3,238.5c-0.2-1.3,2.3-4.9,3.2-7.1C538.9,234.5,538,235.2,536.3,238.5z M535,213.2
			C536.8,212.2,532.9,215.5,535,213.2z M537.2,228.3C539.3,224.9,535.5,232.5,537.2,228.3z M538.9,217.8c0,1.4-0.9,1.8-1.5,2.9
			C537.5,219.3,538.3,218.9,538.9,217.8z M527,232.8c-1,1.5,0.1-1,0.8-2.3c0.5-1.2,0.1-0.9-0.2-0.4c0.2-2.6,2.2-3.3,3.5-6.8
			C531.5,224.9,528.5,229.5,527,232.8z M530.4,227.8C532.5,224.4,528.6,232.1,530.4,227.8z M485.2,304.6c0.3,0.9-0.3,2.7-1.3,5.1
			C482.8,309.6,484.4,306.6,485.2,304.6z M483.6,298c0.3,1-1.9,4.9-1.1,5.4C480.6,306.5,482.1,300.7,483.6,298z M496.6,268.6
			c1.8-2.9-1,3.2-1.7,4.6C493.2,276.1,496,269.9,496.6,268.6z M500.3,267.8C502.3,264.1,498.7,272.4,500.3,267.8z M505.1,257.7
			c0.6-1.3,0.4-1.9,1.2-3.5c1.1-0.1,0.8,1,0.7,3.4c-1.5,2.4-3,5.6-4.3,7.2C502.2,264.1,504.2,256.2,505.1,257.7z M509.8,247.3
			c-0.4-1.1,1.5-3.7,1.9-4.2c0.3,0.4-1.1,3.5-0.1,1.8c-0.3,1.8-1,4,0.6,1.2c-0.1,2.7-2.2,4.2-3.3,7.3c-0.8,0.7,1.5-3.8-0.5-3
			L509.8,247.3z M513.3,248.4C515.4,244.7,511.7,253,513.3,248.4z M516.8,235.6c0.5,0.3-2.3,6.5-2,3.8
			C515.6,237.5,516,237.7,516.8,235.6z M516,245.8C517.9,241.9,513,251.6,516,245.8z M517.4,244.9c-0.1,1.7-0.8,3.7-1.7,4.6
			C515.7,248.6,516.8,246.5,517.4,244.9z M509.4,256.3C511.5,252.7,507.8,260.9,509.4,256.3z M519.5,237.1
			C520,235.8,520,237,519.5,237.1C516.2,245.4,517.4,239.2,519.5,237.1z M517.8,235.8C519.7,232.9,516.5,240.8,517.8,235.8z
			 M520.3,236.9C522.4,233.2,518.7,241.4,520.3,236.9z M523,224.5l-1.4,5.2c0.2,2.3,1.2-0.2,2.5-2.8c0.3,2.1-2,3.9-3.6,8.6
			c0.6-2.1,0.9-5.1-1-0.2c-0.3-0.3,0.4-2.1-0.4-0.8C520.6,230.2,520.7,228.1,523,224.5L523,224.5z M527.4,227.5
			c0.1,0.5,0.4,0,0.7-0.6c0.6,0.4-2,5.1-1.7,2.5L527.4,227.5L527.4,227.5z M365.6,402.3C363.2,397.4,364.4,395.2,365.6,402.3z
			 M528.2,215c0.1,1.4-2.5,5.1-1.2,5.6l-1.7,2.6C525.2,220.4,526.1,218.5,528.2,215z M528.3,217.2
			C525.2,222.7,530.4,213.5,528.3,217.2z M533.6,206l-0.1,1.8c0.3-0.9,1-2,0.8-2.4c2-3.5-0.3,3.8-0.8,2.4c-1.5,1.2-1.3,3-3,5.9
			C531,211.5,532.1,207.3,533.6,206L533.6,206z M537.6,202.5C539.5,199.4,533.7,209.3,537.6,202.5z M539.8,199
			C542,195.6,538.1,203.4,539.8,199z M605.9,112.7c2-2-1.3,2.6-2.1,3.6C601.8,118.4,605.2,113.7,605.9,112.7z M611.6,104.8
			C614,102.3,609.5,108.5,611.6,104.8z M619.3,93.4l1.1-1.6c0.6,0.6-0.5,2-1.4,3.3L619.3,93.4L619.3,93.4z M621.5,90.2
			C623.9,87.6,619.5,93.8,621.5,90.2z M629.6,79.8c-0.1,0.9-0.5,1.9-1.5,3C628.2,81.9,628.6,80.9,629.6,79.8z M632.6,76.4
			C635.2,74.3,630.4,79.6,632.6,76.4z M593.3,138.8C595.5,136,591.3,142.7,593.3,138.8z M596,134.8
			C598.4,131.9,592.6,139.1,596,134.8z M628,96.1c1.9-1.9-1.3,2.6-2.1,3.6C623.9,101.6,627.2,97,628,96.1z M655.3,87.7
			C657.4,85.8,650.8,91.7,655.3,87.7z M656,78.6c-0.6,0.6-0.6-0.1-0.8-0.4C655.7,77.6,655.8,78.4,656,78.6z M688.1,53.6
			C690.4,53.2,685.2,57.4,688.1,53.6z M681.2,82.6c0.3,0.8-3.7,4.2-3.1,1.9C678.9,84.4,679.8,83.9,681.2,82.6z M680.3,74.9
			C682.8,73.3,676.7,77.3,680.3,74.9z M683.1,75.1C685.6,73.8,680.9,77.6,683.1,75.1z M684.3,70.5C686.8,69.2,682,73,684.3,70.5z
			 M683.6,77.7C683.4,76,686.2,76.2,683.6,77.7l1.4,0.1c0,0.3,0,0.7-0.5,1l-1.2,1C683.3,79,683,78.3,683.6,77.7L683.6,77.7z
			 M679,85.6C681.5,84.3,676.8,88.1,679,85.6z M684.8,83.4L686,83C686,84.5,683.3,85.5,684.8,83.4z M691.4,78.7
			C693.8,77.4,689.1,81.2,691.4,78.7z M684.3,88C686.7,86.7,682.1,90.4,684.3,88z M686.2,87.1c-0.6,0.5-0.6-0.2-0.8-0.5
			C686,86.1,686,86.8,686.2,87.1z M753.5,61.9c-2.5,3.3-14.5,9.2-16.5,8.9c-2.7,0.6-5.4,3.8-5.8,3.5l0.3-2.5
			c-8.3,1.2-24.2,15.8-29.5,17.2c0.4,1.3-3.3,4.2-1.7,1.4c-1.2,2-2.8,3.8-5.5,5.7l-0.2-1.1c-0.5,0.2-1.1,0.9-1.2,0.4
			c0.5-0.2,1.1-0.9,1.2-0.4c3.1-3,8.2-7.1,12.9-12.8c-3.5,3.7-10.7,9.7-14.4,11.6c2.3-2.7,6.5-5.2,8.5-8c-4.8,2.7-19,17.1-23.2,18
			c5.5-4.6,13.4-10.1,14.1-13.7c1.5,0.1,4.7-4.1,6.3-5.7c-1-1.4-2.5,2.4-3.7,1.7c-1.5-0.6-6.2,6.2-7.8,5c1.3-2.1,2.2-1,2.8-2.9
			c-2.3,0.3-6.7,6.8-9.5,7c2.7-2.8,7.9-6.3,11.5-10.4c-3,1.3-3,1-4.2-0.8c-3.5,3-3.7,3.3-7,5.6l0.1,0.5c0.9,0.3-0.6,3.2-1.2,4
			c-0.9,0.3-1.3-0.2-1.6-1.1c0.4-1.8,2.4-0.7,2.8-2.9c-0.3,0.3-0.8,0.1-0.1-0.5c5.2-6,13.5-10.7,19-17.7c-2.7,4.1,3.3-1.8,3.6-2.3
			c-0.7,0.4-1.7,0.3-0.8-0.6c0.9-0.7,2.2-2.1,2.4-1.4c-1.5,1.2-0.7,1-0.4,1.5c2-1.1,0.5-2.4,1.4-3.6c-5.5,4.1-12,10.3-16.6,12.8
			c0.4,0.7-3.1,3.5-3,2.4l3-2.4c-1.8,0.6,0.8-3.3-1.6-1.1c-0.8,0.9,0.4,0.5,0.3,1.6c-2.7,0.5-2.3,4.8-6.2,6.8
			c0.3,0.6,1.1,0.3-0.4,1.5c0.1-0.5-0.2-1,0.4-1.5l1.6-2.4c0.5-1.9-2.2-0.8-0.5-2.6l8.9-7.1c1.5-2-11,8.2-3.9,1.3l-0.2-1
			c-0.3,0.5-2,3.2-2.4,1.9c0.7-0.5,1-1,1.1-1.5c-0.9,0.4-3,3.1-3.1,1.9c3.3-3.7,7.3-5.6,12.4-10.6c-2.7-0.9-7.4,6.8-8.7,5.1
			c1.2-1.2,6.5-4.7,4.7-4.4c-1.4,0.7-4.5,4.7-4.4,2.3c4.6-3.9,6-5.1,10.7-9.2c0-0.3,0.4-1,0.6-0.5c3-3.5,4.1-1.9,5.8-5.9
			c1.6,1,3.9-2.5,5.4-4.3l-0.8-0.6c-9.1,9-20.6,17.7-33.6,28.2c-0.2-2.7-0.9,0-2.1-0.1c-0.1,1.4,1.3-0.2,1.5,0.6l-4.8,3.9
			c-1.4,1.7-2.8,3.2-3.1,2.2l2.9-3.2c7.5-9.6-0.9,0.2-4.3,2.9c13.4-15.9-8.7,9.8-8.9,8.5c0-1.4,3.2-3.4,2.5-4.7l-1.3,0.8
			c-0.7,1.5-1.3,2.2-2.3,2.5c2,1-1.4,5.8-3,5.8c1.6-2.4,2.2-3.8,1.1-4.4c0.7-0.8,1-0.3,1.8-1.4c0-0.8,2.3-4.9,2.3-2.5
			c2.2-4.8,5.9-6.7,7.6-11c3.1-1.2,5.5-7.2,10.1-9.7c-0.1-2.4-3.1,0.8-4.2,0.4c3.7-2.4,6-6.2,8.1-5.9c0,2.5-3.2,1.7-2.8,4
			c5.5-5.1,12.4-8.9,14.5-12.8l1.2-1c0.1-0.8,1-1.7,1-2.5c-4.2,3.9-5.4,2-10.4,8.4c1,0.7,2.3-3.3,2.7-0.9c-0.5,2-3.9,3.5-3.4,0.9
			c-8.4,5.2-18.5,17.7-24.6,22.9c3.3-3.7,6.7-7.3,9.2-10.8c0.3-2.9,4.5-5,1.3-0.8c5.7-8,13.6-11.7,19.7-19.4c-1.2,2.7,3.1-1.1,3.7-3
			c-8.9,5.6-13.1,11.6-20.8,15.3c0.2,0,0.4,0.1,0.4,0.6l-1.8,1.5c1.2,0.3-1.7,3.7-1.6,2.5c0-0.7,0.8-1.6,1.6-2.5l-0.1-0.5
			c-4.9,6.3-9.4,11-12.1,14c0.3-3.9,6.1-6,6.6-9.3c-4.9,4.6-7.5,8.6-11.9,11.9c3.4-3.9,7.5-7.9,9.1-11.4c-6.4,6.9-11.8,12.1-16.2,16
			c6.3-8.3,13.7-13.6,17.9-21.7c4.7-2.9,9.4-9.6,15.5-12.8c-0.3-0.9,0.5-1.7,0.2-2.6C664,59.4,654,70.3,642.5,84.6
			c-1,0.4-1.8,0.2-3.4,1.8l-2.1,3.6c0.5,0.5-3,4.3-2.9,3.3c-1.1,1.1-1.1,2-2.2,3.1c-0.2-0.7-0.3-1.4-1-0.8
			c-4.9,9.9-14.1,19.7-19.5,29.2l-0.5,0.8c0.3,1.6-2.5,2.8,0,0c-0.3-1.4-3.3,5.2-3.6,3.7c1.4-1.9,0.6-1.3,0.3-1.7
			c3.7-7.5,9.6-13.2,13.3-18.6c0,0.7-1,1.8-0.8,2.5c2.7-8.1,11.3-16,15.2-23c-0.4,2.5,3.9-2.7,3.7-3.4c-3.5,3.2-5.2,3.3-7.2,4.8
			c-1.8,2.5-9.6,12.6-11.4,14.6c0.9-3.3,6.6-8.6,7.5-11.9l-25.1,35.2c-0.8,2.3,1.2,0.3-0.5,3.4c-1.2-4.3-8.2,11.2-9.6,10.5
			c2.1-4.2,6.5-8.4,7.5-11.6c-1.2,1.4-1.4,0.9-1-0.6c-7,9.6-15,23.5-18.8,31.6c4.7-6.7,7.5-13.4,12.1-19.8c-3.2,4.8-0.5,2.3-2.1,5.8
			c-2.5,3.2-6.7,11.4-11.4,19.3l0.2-1.8c-1.8,2.1-3.8,6.4-3.3,7c-2.5,3.3-2.8,5.1-5.7,9.7c0.3,2.8,1.6-3.2,1.9-1.8
			c0.5,0.8-1.8,3.3-2.4,4.8v-2.2c-1,1.3-2.8,5.7-3.3,4.9c2.7-5.2-1.3,2.3-1-0.4l1.7-2.2l-0.3-0.8c-0.3,0.5-0.7,0.6-0.2-0.4l0.2,0.4
			l0.5-0.9c-0.4-1.4,2.3-3.2,0,0c0.8-0.2,4.9-7.1,5.2-8.8c-2.7,0.7-10.2,18.9-12.3,17.4c0.8-1.5,1.9-3.6,2.2-3.2l2.2-3.1
			c-0.3-0.5-0.6-1.1,0-2.2c1.6-1.1,4.3-8,2.6-2.2c2.3-3.8,4.6-7.6,3.8-7.9c1.1-1.2,2.1-1.2,2.8-4c0.7-5.9,8.5-10.2,9.6-17.7
			c3.3-3.3,7.2-9.7,10-16.6c2.8,0,4.9-10.4,8.6-11.4c-0.9,1.3-2.2,2.8-2.1,3.6c1.1-1.9,3.9-4.6,3.8-6c-3.5,4.4,4.4-9.8,3.9-5.5
			c0.9-0.5,1-1,1.7-2.4l-0.2-0.5c-0.5,0.5-1.1,1.6-1.3,1.1c0.9-0.9,2.7-4.6,3-3.5c0.1,0.7-0.7,1.8-1.5,2.8c2.5-1,3.9-9.7,3.3-4.7
			c10.2-16.7,15.2-25.5,26.3-34.8c-3.8,4-5.1,4.9-6.9,9c2.5-2.8,5-5.7,6.3-5.7c1.6-3.5,3.1-5.4,6.3-8.3c0-3-4.5,5.1-5.2,3.8
			c4.2-6.8,8.7-8.1,10.5-12.9c-3.4,3-3.5,4.1-5.5,5.4c0.7-2.2,4.5-5.2,6.5-7.8c-17.2,15.5-36.1,40.8-50.1,62.5
			c-0.4-2.6-2.1,1.4-3.6,1.7c0.7-1.9,3.8-5.6,5.2-6.7c-1.3,1.1-2.4,4.5-0.8,2.5c0.2-1.7,5.4-6,6-9.2c-2,1.3-3.2,5.5-4.2,4.6
			c1.9-2.3,2.8-4.2,3.1-5.7c2.6-1.2,7.2-11.5,14.7-20.6c1.4-0.1,0.8,0.8,1.8,0.8c2.9-5.1,9.5-14,12.8-16.4c-0.1-1-1.4-0.2-0.4-1.5
			c1.2-2.4,9.3-8.7,7.6-9.7c-4.8,5.2-13.3,15.8-17.7,19c6.5-7.9,10.5-10.4,13-16.3c-0.7,0.7-1.6,1.5-0.7,0.2l0.7-0.2
			c0,1.8,6.7-5.8,5-4.9c-2.1,2.7-3.2,3-4,2.5c1.3-1.2,1.9-2.2,1.6-3c-2.1,1-5,5.7-6.2,6.3c0.2,0.3-0.1,0.7-0.5,1.2
			c0.5,0.7,1.4,0.6,0.7,2.5c-1,0.2-3.3,1.7-2.5-0.4c-3.2,3.3-8.7,7.6-10.1,12.4c-1.2-2-4,7-6.1,9.2c-1.8,1.5,0.6-3.8-1.7-0.3
			c-0.7,2.9-2.1,3-1.8,4.6c-3.1,2.2-6.1,10.3-9.8,13.1c-8.2,13.3-19.2,25.3-23.5,36.6c2.1-2.8,5.8-11.4,6.7-9.8
			c-0.9,1.9-14.8,22.2-12,21c-8.3,11.5-15.8,26.2-23.6,40.4c4.3-11.8,14.3-25.3,19.2-35.9c-6.8,11.1-14.7,25.5-20.3,35.6
			c8.7-20.2,18.9-32.6,30-53.6c0.3-0.6,3.9-5.2,2.3-5.3c-10.3,18.7-16,26.2-22.8,38.6c0.5-0.6,1-1.8,1.2-1.4c-0.5,0.6-1,1.8-1.2,1.4
			c-0.9,3.4-0.7,0-2.3,3.2c0.7,1.5-3.3,7-4.6,10.4c1.4-4.9-3.7,5.2-3.6,6.4c0.8-1.8,0.6,1.7,0.2,2.7c-4.1,7.8-6.5,13-8.6,16.1
			c1.1-4.5,6.9-12.8,8-17.4c-2.9,0.9-5.1,12.6-7.3,12.8c3.8-10.1,9.1-17.1,10.8-23.7c-2,3.8-4.2,8.6-7.1,13.2
			c1.5-3.1,3.4-6.6,2.9-8.1c-1.7,3.4-1.6,5.6-3,5.9c0.3,1.7-0.8,2.1-0.8,4.6l-1.3,1.2c-1.2,5.6-9.1,12.2-11.1,21.1
			c0.8-1.8,1.1-1.6,1.4-1.2c-1.2,3-1.4,1.2-2.3,3.1c0.4,0.6,0,1.9-1.1,3.8c-0.1-0.8,0.9-2.6,1.1-3.8l-0.2-0.4c-1.5,0.9-5,10.7-6,9.6
			c2.2-4.3,3.7-6.2,4.8-10.2c-1.5,2.3-2.3,2-3.7,4.3c2.8-4.7,3-6.1,6-11.8l-0.1,1.8c1.6-1.6,1.8-4.6,3.2-5.5c0,2-4.3,7.7-3.4,9.1
			c5.8-11.8,11.8-20,15.7-31.8c-4.1,8.1-7.5,12.7-10.2,14.8c2.2-3.6,2.3-3.8,3.1-7.7c-8.7,13.4-13.5,25.4-21.1,38.8
			c-1.4,1.2-0.1-0.7-0.1-2.2c-2.5,4.4-3.3,7.9-5.5,10.7c4.5-11.4,9.2-18.4,11.2-27.3l-1,2c-0.9,0-1.8,3.3-1.1,2.3
			c-2.9,6.1-8.4,19.8-6.5,12.3c-5.1,10.8-8.2,18.2-14.1,29.3c0.8-2.2,3.1-5.8,2-6c-15.3,32.9-29.9,67.5-44.5,105
			c-4.5,11.4-9,23.1-13.5,35.2c-1.9,5.4-3.8,11.1-5.7,16.5c-10.6,30.1-20.9,61.1-30.7,92.5c-1,3.2-1.9,6.2-2.9,9.4
			c-1.6-4.5-3.1-8.9-4.5-13.1c-6-18-11.3-37.5-15.4-48.7c-3.5-9.5-6.8-17.1-10-26.5c-1.4-4.2-2.9-8.8-4.4-13.6
			c-4.8-15.2-10-32.4-12.9-44.9c-0.7-1.4-1.3-3.4-1.9-5.5c-3.5-11.6-7-31.5-12.2-44.4c-0.6,1.8-1.2,4.5-2.6,1.7
			c0.3,12.2-8.7,5.9-7.9,22c-0.5-2.8-0.8-5.2-1.8-8.8c-1.3,0.1,2.1,7.8,1,8.2c-3.1,2.8-4.6,3.2-4.2,13c-0.6-2-1.2-2.2-1.6-1.2
			c-0.1-1.8-1.1-4.6-1.9-5.6c1.6,5.9-0.9,1.5,0.5,7.3c-1.4-5.6-1.7,0.8-3.5-3.6c0.8,3.6,1,6.5,1.4,9.4c-1-0.7-2-3.2-3.3-8.6
			c-0.6,2.9-0.6,2.9,0,7.9l-2.6-1.6c0.4,2.6-0.2,4.1,0,7.9c-3.4-6.6-3.3,3.9-6.7-2.9c-0.5,5.6-2.9,12.7-5.4,9.7c0.6,4.4-1,2.3,0,7.9
			c-1.8-5.6-2.4-3.5-2.5-0.1c-0.9-1.7-1.8-4.1-2.5-3.3c-0.5,4.2,0.9,11.6-1.4,12.9c-0.8-3.8-1.4-4.1-2.2-6.9
			c-0.7,16.4,4.9,41.7,13.6,69.8l13.2,41.5c0.7,2.2,1.3,4.6,2,6.8c7.9,26.6,15.8,55.3,24.5,83.3c1.2,3.7,2.2,7.3,3.4,10.9l8.3,23.1
			c6.8,18.4,9.9,33.1,22.8,59.2c8,11.6,17.2,28.1,37.1,11.8c20.4-25.9,12.2-21.4,17.1-29.1c4.6-11.5,7.5-20.5,10.2-29
			c17.3-57.5,36.5-111.2,56.2-161.2c7.5-18.9,15.1-37.1,22.7-55c13-30.4,26-59.5,38.9-86.8c11.2-21.6,24.3-54.1,37.3-74.9
			c8-13.1,16.7-30.4,24.3-41.4c1.2-1.5,2.1-1.9,3.6-4.4c0.8-3.8,4.8-6,6.7-10.7c1.5-4.3,8.5-10,9.4-15.9c3.1-2.8,5.5-6.5,8.3-10.5
			c-0.5,0.6-1.3,1.3-0.2-0.3c0.5-0.4,0.7-0.4,0.7-0.3c1.2-1.8,2.5-3.5,3.9-5.4l-0.3,1.6c1.2-2.1,6.3-7,3.3-5.5
			c6.2-7.4,12.6-16.8,20.2-23.8c-1.5-1.9,3.6-5.1,0.9-1.7c2.3-0.6,1.9-4,3.2-3.1l1-1.4c-0.1-0.7,1.4-1.9,1.7-1.3l-1.7,1.3
			c0.5,1,2.9-2.3,3.5-1.6c-0.2-0.7-0.4-1.4,0.2-2c0.5,2.2,3.5-4.9,3-1.1c2-1.1,0.3-2.7,2.5-3.8l-0.2,2c2.1-0.7,0-2.1,1.3-2.9
			c1.3,0,1-1.3,2.3-1.2c3.3-4.5,8.3-7.4,9.4-11.2c0.8,0.2,0.3,0.7,0.2,1.6l7.7-6.3c-1.9,0.3-3.9,2.9-5.7,3.6c0.2-0.4-2-1.3,0.2-2.8
			c-0.5-0.1-3.7,1.7-3.9,3.2c0.9-0.9,1.1-0.4,1.3,0.2c-1.7,0.5-3.7,1.5-6.3,3.9c0.2,0.6,0.2,1.6,0.8,1.2c-0.1-0.3,0.3-0.9,0.5-0.4
			l-0.5,0.4c-0.2,2.9-4.3,2.5-5.7,3.4c1.4-1.5,2.5-2.6,4-2.6c0-0.7,0-1.4,0.9-2c-0.4-0.2-0.8-0.4-0.2-1.1l7.2-5.8
			c-0.8-1.4-4.8,2.5-1.5-1.8c-2.3,1.4-2.9,3.1-3.7,4.8c-1-3.3-4.1,3.8-7,3.8c-0.8,5-4.3,5.5-8.1,8.3c-0.4-1.4,3.5-2.3,3.1-3.7
			c-0.7,0.4-0.8-0.6-0.2-1l-1.2,0.4c0.5,1.4-1.6,2.5-2.5,3.8c0.1-0.4,1-3.1-0.4-2.1c-3.2,3-2.3,3.3-4,6.1c-1.1,0.7-0.7-1.5-1.6-1.2
			c-2.4,2.4-2.3,1.5-2.5,3.9c-2.1,2.5-2.4,2.6-1.3,0.2c-2.4,2.4-2.1,3.6-2.3,4.5c-2.8,2.4-3.9,3-5.2,3.3c4.4-6.5,12-13.7,14.9-16.6
			l-2.6,0.2c2.5-1.7,7-6.5,8.8-8.2c3.1-3.7,7-5.3,10.5-9.7c-0.2-1.4,2.5-3.4,3.3-3.3c-0.2-0.5,0-1,0.4-1.5c1.9-2.9,4.4-4.5,6.8-5.5
			c0.8-4.4,4.2-4.7,6.2-5.3c4.8-1.7,9.2-5.5,14.6-8.9c9.1-5,19-12,27.8-12.5C761.5,59.2,756.4,62.4,753.5,61.9L753.5,61.9z"
        />
        <path
          fill="#E31F25"
          d="M735.5,69.7c-2,0.3-1.4,0.7-3.6,1.8C732,72.5,735.7,70.6,735.5,69.7z"
        />
        <path
          fill="#E31F25"
          d="M734.6,88.2C736.9,87.4,731.1,89.4,734.6,88.2z"
        />
        <path
          fill="#E31F25"
          d="M727.7,67.8c1-0.6,5.5-2.4,3.5-2.3C730.3,66,725.8,67.9,727.7,67.8z"
        />
        <path
          fill="#E31F25"
          d="M731.4,71.8l0.6-0.3C731.8,70.9,731.3,71.5,731.4,71.8z"
        />
        <path
          fill="#E31F25"
          d="M727.3,64.5c0.8-0.3,4.4-2.1,2.4-1.7C728.8,63.1,725.3,64.9,727.3,64.5z"
        />
        <path
          fill="#E31F25"
          d="M727.8,58.5C731.1,57.6,721.9,60.1,727.8,58.5z"
        />
        <path
          fill="#E31F25"
          d="M728.4,68.6C730.9,67.7,724.8,69.9,728.4,68.6z"
        />
        <path
          fill="#E31F25"
          d="M731.1,89.9C728.9,91.7,733.4,89.4,731.1,89.9z"
        />
        <path
          fill="#E31F25"
          d="M730.7,91.8C728.5,93.6,733.1,91.3,730.7,91.8z"
        />
        <polygon
          fill="#E31F25"
          points="727,72.2 728.2,71.6 728.1,70.5 726.9,71.1 		"
        />
        <path
          fill="#E31F25"
          d="M718.8,68.7c3-1.5,4.7-1.2,6.6-3.8C723.4,66.2,719.7,66.9,718.8,68.7z"
        />
        <path
          fill="#E31F25"
          d="M726.8,90.2C727.7,91.6,729.8,88.2,726.8,90.2z"
        />
        <path
          fill="#E31F25"
          d="M728.2,91.3C723,92.7,729.9,90.9,728.2,91.3z"
        />
        <path
          fill="#E31F25"
          d="M723.7,61.1C721.3,62.9,726.1,60.6,723.7,61.1z"
        />
        <path
          fill="#E31F25"
          d="M727.3,93.5C723.8,94.7,729.6,92.7,727.3,93.5z"
        />
        <path
          fill="#E31F25"
          d="M726.8,90.2C725.6,88.8,725.3,92.9,726.8,90.2z"
        />
        <path
          fill="#E31F25"
          d="M719.3,48.2C722,47.5,719.2,46.5,719.3,48.2z"
        />
        <path
          fill="#E31F25"
          d="M721.3,55.4c-0.1,0-0.1,0-0.3,0.1c-0.5,0.2-0.7,0.3-0.9,0.3C720.4,55.7,720.9,55.5,721.3,55.4z"
        />
        <path
          fill="#E31F25"
          d="M726.5,97.3C724.4,99.1,728.9,96.9,726.5,97.3z"
        />
        <path
          fill="#E31F25"
          d="M721.1,61.2C718.7,63.1,723.6,60.7,721.1,61.2z"
        />
        <path
          fill="#E31F25"
          d="M724.6,87.3c-0.8,0.4-4.8,2.2-2.8,2C722.6,88.8,726.5,87.2,724.6,87.3z"
        />
        <path
          fill="#E31F25"
          d="M719.3,48.2C718.4,49,719.5,48.8,719.3,48.2z"
        />
        <path
          fill="#E31F25"
          d="M721.9,72.4C719.7,74.2,724.4,71.9,721.9,72.4z"
        />
        <path
          fill="#E31F25"
          d="M724.7,93C722.5,94.7,727.1,92.5,724.7,93z"
        />
        <path
          fill="#E31F25"
          d="M719.3,58C720.2,57.3,719.2,57.4,719.3,58z"
        />
        <path
          fill="#E31F25"
          d="M724.6,91.9C722.5,91.2,720.3,94.7,724.6,91.9z"
        />
        <path
          fill="#E31F25"
          d="M719.3,58C716.7,58.8,719.4,59.7,719.3,58z"
        />
        <path
          fill="#E31F25"
          d="M711.3,62c1.5-2.4,7.3-4.3,6.5-5C715.9,58.3,711,59.3,711.3,62z"
        />
        <path
          fill="#E31F25"
          d="M719,60.5c-0.9,0.5-2.3,0.9-2.4,1.7C718.2,61.6,718.7,62.4,719,60.5z"
        />
        <path
          fill="#E31F25"
          d="M719,65.2C721.2,64.5,714.4,66.6,719,65.2z"
        />
        <path
          fill="#E31F25"
          d="M722.5,90.1C720.4,92.5,724.5,89.9,722.5,90.1z"
        />
        <path
          fill="#E31F25"
          d="M719.5,73.6c-2,0.7-8.1,4.5-7,5C714.3,76.2,720.7,74.1,719.5,73.6z"
        />
        <path
          fill="#E31F25"
          d="M723.1,99c-0.8,0.4-4.7,2.1-2.8,1.9C721.2,100.5,725,98.9,723.1,99z"
        />
        <path
          fill="#E31F25"
          d="M716.3,50.3c-0.2-0.3-0.2-1-0.8-0.8C715.7,49.9,715.7,50.6,716.3,50.3z"
        />
        <path
          fill="#E31F25"
          d="M718.2,64.4c-0.2-0.4-0.2-1.1-0.8-0.8C717.7,63.9,717.7,64.6,718.2,64.4z"
        />
        <path
          fill="#E31F25"
          d="M722.5,94.7C718.5,95.2,720.1,97.8,722.5,94.7z"
        />
        <path
          fill="#E31F25"
          d="M711.6,27.1C707.7,28.6,714.3,26.1,711.6,27.1z"
        />
        <path
          fill="#E31F25"
          d="M714.2,45.5C711.9,47.4,716.8,44.9,714.2,45.5z"
        />
        <path
          fill="#E31F25"
          d="M718.1,72.5C712.7,74,719.9,72.1,718.1,72.5z"
        />
        <path
          fill="#E31F25"
          d="M721.8,98.5C719.6,100.2,724.2,98,721.8,98.5z"
        />
        <path
          fill="#E31F25"
          d="M714,48.5C711.6,50.4,716.6,47.9,714,48.5z"
        />
        <path
          fill="#E31F25"
          d="M705.7,76.8c-1,3-4,2.4-6.7,7.3c4.6-2.8,13.8-12.7,18.7-14.2C713,68.5,708.9,77.7,705.7,76.8z"
        />
        <path
          fill="#E31F25"
          d="M713.6,50.4C713.4,53,715.5,49.7,713.6,50.4z"
        />
        <path
          fill="#E31F25"
          d="M715.7,65C713.5,64.7,717.5,66.1,715.7,65z"
        />
        <path
          fill="#E31F25"
          d="M719.9,93.6C717.7,95.4,722.3,93.2,719.9,93.6z"
        />
        <path
          fill="#E31F25"
          d="M710.6,34C708.1,35.9,713.2,33.4,710.6,34z"
        />
        <path
          fill="#E31F25"
          d="M715.4,66.9C711.7,68.4,717.8,66,715.4,66.9z"
        />
        <path
          fill="#E31F25"
          d="M711.8,52C709.5,54.3,714.3,51,711.8,52z"
        />
        <path
          fill="#E31F25"
          d="M712.2,68c0.9-0.8,2.2-1.6,2.3-2.3C713.6,65.3,712.2,66.6,712.2,68z"
        />
        <path
          fill="#E31F25"
          d="M717.6,95C715.5,97.4,719.9,93.8,717.6,95z"
        />
        <path
          fill="#E31F25"
          d="M710.3,50.8c-1.1,1-6,4.4-4.1,3.9C707.3,53.7,712.2,50.3,710.3,50.8z"
        />
        <path
          fill="#E31F25"
          d="M711.7,64.2C709.4,66.7,714.1,62.9,711.7,64.2z"
        />
        <path
          fill="#E31F25"
          d="M716.8,98.1c-1.2,1.1-6,4.4-4.4,4.2C713.6,101.1,718.4,97.8,716.8,98.1z"
        />
        <path
          fill="#E31F25"
          d="M706.3,33.5C704,36.1,708.9,32.1,706.3,33.5z"
        />
        <path
          fill="#E31F25"
          d="M709.4,53.9l-2.5,1.4C706.2,57.6,709.8,54.7,709.4,53.9z"
        />
        <path
          fill="#E31F25"
          d="M711.6,67.9c-1.5,0.7-6.5,3.8-3.9,4.4C708.1,70.3,711.9,69.9,711.6,67.9z"
        />
        <path
          fill="#E31F25"
          d="M711.2,70C709,72.5,713.6,68.7,711.2,70z"
        />
        <path
          fill="#E31F25"
          d="M712.2,76.4C710,78.9,714.6,75.2,712.2,76.4z"
        />
        <path
          fill="#E31F25"
          d="M712.8,80.2C710.6,82.6,715.2,78.9,712.8,80.2z"
        />
        <path
          fill="#E31F25"
          d="M704.5,39.2C707.8,37.2,704.9,37,704.5,39.2z"
        />
        <path
          fill="#E31F25"
          d="M709.7,64c-2.5,1.6-0.3,0.2,0.4-0.2C709.9,63.8,709.9,63.9,709.7,64z"
        />
        <path
          fill="#E31F25"
          d="M710,63.8C710.3,63.6,710.2,63.6,710,63.8z"
        />
        <path
          fill="#E31F25"
          d="M714.9,97.8C715.6,96.8,714.7,97.2,714.9,97.8z"
        />
        <path
          fill="#E31F25"
          d="M704,27.5l-0.1-0.5c-4.7,3.2-9.9,7.6-16.9,13.5c0.6-1.3,0.1-1-0.3-1.6c-3.8,2.9-2.8,5.4-6.7,6.6
			c-0.7,0.9-1.1,1.7-0.9,2.6c3.3-2.6,5.3-4.9,7.5-6C691.4,37.2,696.6,33.8,704,27.5L704,27.5z"
        />
        <path
          fill="#E31F25"
          d="M704.4,30.2c-2.5,2.4-6.7,4.6-8,7C700,34.6,701.7,34.7,704.4,30.2z"
        />
        <path
          fill="#E31F25"
          d="M699.5,38.4c-0.7,3.6,5.2-2.2,5.7-3.4C703.3,34.8,700.2,39.9,699.5,38.4z"
        />
        <path
          fill="#E31F25"
          d="M714.9,97.8l-1.7,1.3C713.2,100.3,715.6,99.3,714.9,97.8z"
        />
        <path
          fill="#E31F25"
          d="M708.7,66.6C710.9,65.2,704.2,69.3,708.7,66.6z"
        />
        <path
          fill="#E31F25"
          d="M714.3,102.5C712.2,104.9,716.6,101.3,714.3,102.5z"
        />
        <path
          fill="#E31F25"
          d="M702.5,40.2c-0.7,2.1,2.3-0.1,1.9-0.9l-0.7,0L702.5,40.2z"
        />
        <path
          fill="#E31F25"
          d="M700.9,42.7c-2.5,1.8-1.7,2.7-3.6,3.5l0.3,1.6c1.2-0.4,5.7-4.3,7.2-6.4C703.4,42,701.4,43.7,700.9,42.7z"
        />
        <path
          fill="#E31F25"
          d="M706.5,65.3c0.6-0.4,0.6,0.3,0.8,0.6C709.6,64.1,707,63,706.5,65.3z"
        />
        <path
          fill="#E31F25"
          d="M712.1,92.2C710,94.7,714.4,91,712.1,92.2z"
        />
        <path
          fill="#E31F25"
          d="M707,60.1c-2.3,2.3-6.5,4.4-7.6,6.7c0.7-0.5,1.4-1,1.3,0.1c-1.3,1.5-4.8,2.8-4,4.4
			c3.1-3.1,4.8-3.1,6.4-6.3c-0.7,0.5-1.7,1.5-1.9,0.9C702.1,63.2,707.9,61.7,707,60.1L707,60.1z"
        />
        <path
          fill="#E31F25"
          d="M709.1,72.9c-0.2-0.3-0.2-1-0.8-0.6C708.5,72.6,708.5,73.4,709.1,72.9z"
        />
        <path
          fill="#E31F25"
          d="M712.6,99.6l0.6-0.5C713,98.7,712.6,99.3,712.6,99.6z"
        />
        <path
          fill="#E31F25"
          d="M701.1,35.8C703.8,34.3,701,34.1,701.1,35.8z"
        />
        <path
          fill="#E31F25"
          d="M707.3,65.9C704.4,67.6,708.3,67.9,707.3,65.9z"
        />
        <path
          fill="#E31F25"
          d="M712.6,99.6C711.9,100.6,712.8,100.2,712.6,99.6z"
        />
        <path
          fill="#E31F25"
          d="M706.2,63.2C705.2,62.1,703.1,66.3,706.2,63.2z"
        />
        <path
          fill="#E31F25"
          d="M708.2,79.7C704.7,82,710.5,78.1,708.2,79.7z"
        />
        <path
          fill="#E31F25"
          d="M701.1,35.8C700.3,36.9,701.3,36.4,701.1,35.8z"
        />
        <path
          fill="#E31F25"
          d="M701.4,41.7C702.2,40.6,701.2,41.1,701.4,41.7z"
        />
        <path
          fill="#E31F25"
          d="M703.5,58.7C704.8,59.8,705.1,55.5,703.5,58.7z"
        />
        <path
          fill="#E31F25"
          d="M706.6,74.3C704.4,76.8,709.1,73,706.6,74.3z"
        />
        <path
          fill="#E31F25"
          d="M709.9,94.6C707.8,97,712.3,93.4,709.9,94.6z"
        />
        <path
          fill="#E31F25"
          d="M701.4,41.7c-0.5,0.3-0.5,0.7-0.5,1C701.4,42.4,701.4,42,701.4,41.7z"
        />
        <path
          fill="#E31F25"
          d="M703.9,61.4C701.7,63.9,706.3,60.1,703.9,61.4z"
        />
        <path
          fill="#E31F25"
          d="M705.1,73.1C705.4,75.5,707.5,71.3,705.1,73.1z"
        />
        <path
          fill="#E31F25"
          d="M700,62.2c1.5-1.1,2.9-2.2,3.5-3.4l-0.1-0.6C701.8,59.5,699.8,60.7,700,62.2z"
        />
        <path
          fill="#E31F25"
          d="M696.5,24.5C691.7,27.5,698.8,23.1,696.5,24.5z"
        />
        <path
          fill="#E31F25"
          d="M701.9,57.6C699.6,60.1,704.4,56.3,701.9,57.6z"
        />
        <path
          fill="#E31F25"
          d="M704.9,75.7C701.4,78,707.3,74.1,704.9,75.7z"
        />
        <path
          fill="#E31F25"
          d="M706.5,85.3l0.1,0.5C707.2,85.2,706.8,85,706.5,85.3z"
        />
        <path
          fill="#E31F25"
          d="M698.8,43.1C696.5,45.7,701.4,41.8,698.8,43.1z"
        />
        <path
          fill="#E31F25"
          d="M706.6,85.8C705.8,86.8,706.7,86.4,706.6,85.8z"
        />
        <path
          fill="#E31F25"
          d="M700.4,76.9C702.2,77.5,698.3,77.1,700.4,76.9z M694.5,84.7l3.5-2.8c-0.2-3.5,4.1-3.7,6.1-7.3
			c-0.2-1.7-2-0.2-3.8,1.2c-0.2-0.6,2.1-3.5,0.2-2.6c-0.1,1-0.5,0.4-1.2,0.9c1,3.1-4.5,6.1-7.5,10.8c2.8-1.8,3.7-3.3,5.3-3.7
			C697.8,82.3,692.1,85.2,694.5,84.7z"
        />
        <path
          fill="#E31F25"
          d="M704.8,83C702.6,83.3,706.5,83.7,704.8,83z"
        />
        <path
          fill="#E31F25"
          d="M704.8,98.7C706.3,99.3,708.4,95.2,704.8,98.7z"
        />
        <path
          fill="#E31F25"
          d="M699.6,60C700.4,59,699.4,59.4,699.6,60z"
        />
        <path
          fill="#E31F25"
          d="M693.6,68.5l-3.9,4.9c3-2.7,10.7-7.9,10.6-9.1C698.2,65.3,694,70.7,693.6,68.5L693.6,68.5z"
        />
        <path
          fill="#E31F25"
          d="M708.4,112.1C705.1,114.3,710.7,110.7,708.4,112.1z"
        />
        <path
          fill="#E31F25"
          d="M693.3,27C689.5,29.6,695.9,25.3,693.3,27z"
        />
        <path
          fill="#E31F25"
          d="M695.3,38.7c0.6-0.5,0.6-1.6-0.2-1C695.3,38.2,694.4,39.2,695.3,38.7z"
        />
        <path
          fill="#E31F25"
          d="M697.3,46.2C696.9,44.7,694.8,48.7,697.3,46.2z"
        />
        <path
          fill="#E31F25"
          d="M697.5,47.8C694.9,49.3,697.7,49.5,697.5,47.8z"
        />
        <path
          fill="#E31F25"
          d="M699.6,60C696.3,62,699.2,62.2,699.6,60z"
        />
        <path
          fill="#E31F25"
          d="M697.4,55.2C695.1,57.7,699.9,53.8,697.4,55.2z"
        />
        <path
          fill="#E31F25"
          d="M698.7,58.9l-0.6,0.5C698.3,59.9,698.8,59.2,698.7,58.9z"
        />
        <path
          fill="#E31F25"
          d="M691.6,45.9c2-0.7,2-2.1,3.8-2.4C696,41.1,691.5,45.1,691.6,45.9z"
        />
        <path
          fill="#E31F25"
          d="M698.1,63.1C696,66.2,700.2,62.2,698.1,63.1z"
        />
        <path
          fill="#E31F25"
          d="M703.7,103.2C706.8,102.4,704.6,99.6,703.7,103.2z"
        />
        <path
          fill="#E31F25"
          d="M693.6,72.1c2.4-2.8,4.7-3.2,5.2-5.4C696.8,68.5,693.5,70.2,693.6,72.1z"
        />
        <path
          fill="#E31F25"
          d="M692.6,51.2C694.2,51.7,696.4,47.4,692.6,51.2z"
        />
        <path
          fill="#E31F25"
          d="M696.5,61.9C692.9,64.3,699,60.3,696.5,61.9z"
        />
        <path
          fill="#E31F25"
          d="M705.6,114.4C702.3,116.5,707.9,112.9,705.6,114.4z"
        />
        <path
          fill="#E31F25"
          d="M692.1,40.7C689.8,43.3,694.7,39.3,692.1,40.7z"
        />
        <path
          fill="#E31F25"
          d="M695.4,67.1C693.1,69.6,697.8,65.8,695.4,67.1z"
        />
        <path
          fill="#E31F25"
          d="M697.6,76.1c-1,1.2-3.6,2.1-3.4,3.4C696.2,77.3,697.5,78.4,697.6,76.1z"
        />
        <path
          fill="#E31F25"
          d="M699,84l-0.6,0.5C698.7,85,699.1,84.3,699,84z"
        />
        <path
          fill="#E31F25"
          d="M696.3,72.3l-2.5,1.4C693.2,75.9,696.7,73.1,696.3,72.3z"
        />
        <path
          fill="#E31F25"
          d="M701.4,104.5C699.2,106.9,703.7,103.3,701.4,104.5z"
        />
        <path
          fill="#E31F25"
          d="M689.1,43C687.1,44.2,688.2,43.6,689.1,43z"
        />
        <path
          fill="#E31F25"
          d="M689.9,42.5c-0.2,0.1-0.4,0.2-0.7,0.4c0.2-0.1,0.3-0.2,0.5-0.3C689.8,42.6,689.8,42.6,689.9,42.5
			L689.9,42.5z"
        />
        <path
          fill="#E31F25"
          d="M693.6,64.9C691.4,67.4,696.1,63.5,693.6,64.9z"
        />
        <path
          fill="#E31F25"
          d="M689.4,45.3C691.9,43.6,685.7,47.8,689.4,45.3z"
        />
        <path
          fill="#E31F25"
          d="M691.2,59C685.7,62.2,693,57.9,691.2,59z"
        />
        <path
          fill="#E31F25"
          d="M691.4,60C693.6,58.7,686.7,62.8,691.4,60z"
        />
        <path
          fill="#E31F25"
          d="M694.8,86.2C695.6,85.2,694.6,85.7,694.8,86.2z"
        />
        <path
          fill="#E31F25"
          d="M686.7,42C685.8,43.1,686.8,42.6,686.7,42z"
        />
        <path
          fill="#E31F25"
          d="M686.9,47.3C689.5,45.6,683.2,49.8,686.9,47.3z"
        />
        <path
          fill="#E31F25"
          d="M685.9,45.7C683.6,48.2,688.5,44.3,685.9,45.7z"
        />
        <path
          fill="#E31F25"
          d="M691.6,76.1C693.2,72.9,689.3,77,691.6,76.1z"
        />
        <path
          fill="#E31F25"
          d="M692.3,80.3c-0.9,0.7-4.8,3.7-2.8,2.9C690.4,82.5,694.4,79.6,692.3,80.3z"
        />
        <path
          fill="#E31F25"
          d="M691.9,81.9C689.8,84.3,694.4,80.6,691.9,81.9z"
        />
        <path
          fill="#E31F25"
          d="M687.7,62.9C685.5,65.4,690.2,61.6,687.7,62.9z"
        />
        <path
          fill="#E31F25"
          d="M690.4,87.9c0.5,0,3.1-1,2.2-2.4C691.8,86.3,690.4,87.1,690.4,87.9z"
        />
        <path
          fill="#E31F25"
          d="M696.8,111.2C694.7,113.6,699.1,110,696.8,111.2z"
        />
        <path
          fill="#E31F25"
          d="M695.8,109.6C694.1,111.8,698.3,109.1,695.8,109.6z"
        />
        <path
          fill="#E31F25"
          d="M683.4,47.6C681.1,50.3,686,46.3,683.4,47.6z"
        />
        <path
          fill="#E31F25"
          d="M687.9,74.8C689.3,75.9,689.4,71.6,687.9,74.8z"
        />
        <path
          fill="#E31F25"
          d="M685.7,63.3C680.2,66.6,687.5,62.3,685.7,63.3z"
        />
        <path
          fill="#E31F25"
          d="M687.7,73.8C688.5,72.8,687.5,73.2,687.7,73.8z"
        />
        <path
          fill="#E31F25"
          d="M682.3,63.7C686.3,61.9,683.9,60.5,682.3,63.7z"
        />
        <path
          fill="#E31F25"
          d="M680.1,45.5C681,44.4,679.9,44.9,680.1,45.5z"
        />
        <path
          fill="#E31F25"
          d="M681.4,51.7C679,54.3,683.9,50.4,681.4,51.7z"
        />
        <path
          fill="#E31F25"
          d="M693.6,111.3c-2.3,1.3-4,4.1-5.1,4.1C689.3,117.1,694.3,111.9,693.6,111.3z"
        />
        <path
          fill="#E31F25"
          d="M683.8,67.9C680.1,70.3,686.3,66.3,683.8,67.9z"
        />
        <path
          fill="#E31F25"
          d="M685.9,78.8C683.7,81.4,688.4,77.6,685.9,78.8z"
        />
        <path
          fill="#E31F25"
          d="M677.2,52.7c1-1.4,2.4-0.1,2.5-2c-0.8,0.5-0.5-0.3-0.2-1C678.1,50.6,677.2,51.6,677.2,52.7L677.2,52.7z"
        />
        <path
          fill="#E31F25"
          d="M679.8,72.3c-0.7-1.1,2.9-1.9,2.2-3C680.7,70,677.5,73.1,679.8,72.3z"
        />
        <path
          fill="#E31F25"
          d="M678.5,66.1C681,64.4,674.8,68.6,678.5,66.1z"
        />
        <path
          fill="#E31F25"
          d="M686,102.9l0.6-0.5C686.3,102,685.9,102.7,686,102.9z"
        />
        <path
          fill="#E31F25"
          d="M673.5,55.6C671.2,58.2,676.1,54.3,673.5,55.6z"
        />
        <path
          fill="#E31F25"
          d="M676.4,69.6C674.1,72.1,678.9,68.3,676.4,69.6z"
        />
        <path
          fill="#E31F25"
          d="M676.9,72.2C674.7,74.7,679.4,70.9,676.9,72.2z"
        />
        <path
          fill="#E31F25"
          d="M688.5,131.1C686.5,133.4,690.8,130,688.5,131.1z"
        />
        <path
          fill="#E31F25"
          d="M672.3,56.6c-3.6,1-6.4,7.3-10.9,10.3C662.1,69.2,671.3,58.4,672.3,56.6z"
        />
        <path
          fill="#E31F25"
          d="M675,69.5C670.7,71.5,672.6,73.5,675,69.5z"
        />
        <path
          fill="#E31F25"
          d="M675.1,73.7C672.9,76.2,677.6,72.3,675.1,73.7z"
        />
        <path
          fill="#E31F25"
          d="M687.7,130.5C687,131.4,687.9,131,687.7,130.5z"
        />
        <path
          fill="#E31F25"
          d="M669.5,50.4C667.1,53,672.1,49,669.5,50.4z"
        />
        <path
          fill="#E31F25"
          d="M674.8,72.1c-1,1.2-2.5,2.3-2.8,3.5C673.5,74.4,675,73.3,674.8,72.1z"
        />
        <path
          fill="#E31F25"
          d="M669.4,53.5C672,51.8,665.6,56.1,669.4,53.5z"
        />
        <path
          fill="#E31F25"
          d="M669.7,61.7c-0.5,0.3-0.5,0.7-0.5,1C669.8,62.4,669.7,62.1,669.7,61.7z"
        />
        <path
          fill="#E31F25"
          d="M662.5,42.1C660.2,45.4,665,40.1,662.5,42.1z"
        />
        <path
          fill="#E31F25"
          d="M663.3,45.7C661,48.9,665.8,43.7,663.3,45.7z"
        />
        <path
          fill="#E31F25"
          d="M670,79.6l0.2,1C670.9,79.7,670.6,79,670,79.6z"
        />
        <path
          fill="#E31F25"
          d="M659,46.6C661.4,45.9,660.4,42.7,659,46.6z"
        />
        <path
          fill="#E31F25"
          d="M660.5,53.2l0.7-0.1l1.2-1.3c0-1.4-1.1,0-2,1L660.5,53.2L660.5,53.2z"
        />
        <path
          fill="#E31F25"
          d="M666.4,79.2C664.2,82.4,668.8,77.3,666.4,79.2z"
        />
        <path
          fill="#E31F25"
          d="M659,46.6C658.2,47.9,659.2,47.2,659,46.6z"
        />
        <path
          fill="#E31F25"
          d="M658.6,48.3C656.4,51.5,661.1,46.3,658.6,48.3z"
        />
        <path
          fill="#E31F25"
          d="M657.8,51.1l-0.3,2.2l2.4-2.7C660,48.9,658.5,51.1,657.8,51.1L657.8,51.1z"
        />
        <path
          fill="#E31F25"
          d="M654.1,41.8C656.7,40.7,656.4,37,654.1,41.8z"
        />
        <path
          fill="#E31F25"
          d="M659.9,53.9C657.5,54.6,658.5,57.8,659.9,53.9z"
        />
        <path
          fill="#E31F25"
          d="M679.1,141.6C677.2,144.5,681.3,139.9,679.1,141.6z"
        />
        <path
          fill="#E31F25"
          d="M654.3,52.4c0.8-0.8,4.4-4.7,2.3-3.2C655.8,50.1,652.2,54,654.3,52.4z"
        />
        <path
          fill="#E31F25"
          d="M659.4,61.5C655.8,64.9,661.8,59.1,659.4,61.5z"
        />
        <path
          fill="#E31F25"
          d="M660.9,84l0.4,1.5c1.9-1.8,2.2-3.2,2.5-4.7l-1.8,1.4C662.2,83.1,661.8,83.7,660.9,84L660.9,84z"
        />
        <path
          fill="#E31F25"
          d="M654.1,41.8C653.3,43.1,654.3,42.4,654.1,41.8z"
        />
        <path
          fill="#E31F25"
          d="M653.5,45.7C654.9,46.4,655,41.9,653.5,45.7z"
        />
        <path
          fill="#E31F25"
          d="M654.3,46.1C648.3,51.8,653.2,49.8,654.3,46.1z"
        />
        <path
          fill="#E31F25"
          d="M656.1,56.9c0.2,0.2,0.2,1,0.8,0.3C656.7,57,656.7,56.2,656.1,56.9z"
        />
        <path
          fill="#E31F25"
          d="M652.9,46.4l0.6-0.7C653.2,45.3,652.8,46.1,652.9,46.4z"
        />
        <path
          fill="#E31F25"
          d="M654.4,53C652.2,56.2,656.9,50.9,654.4,53z"
        />
        <path
          fill="#E31F25"
          d="M652.9,46.4l-2.5,2.2C649.7,51,653.3,47,652.9,46.4z"
        />
        <path
          fill="#E31F25"
          d="M656.4,64.7C654.2,67.9,658.9,62.8,656.4,64.7z"
        />
        <path
          fill="#E31F25"
          d="M673.7,146.9C671.8,149.8,676,145.2,673.7,146.9z"
        />
        <path
          fill="#E31F25"
          d="M650.9,57.5C648.7,60.6,653.4,55.4,650.9,57.5z"
        />
        <path
          fill="#E31F25"
          d="M649.6,61.4C651.3,61.5,653.3,56.6,649.6,61.4z"
        />
        <path
          fill="#E31F25"
          d="M648,48.5C650.5,46.1,644.3,52.1,648,48.5z"
        />
        <path
          fill="#E31F25"
          d="M650,72.4c0.8-0.8,4.3-4.7,2.2-3.1C651.5,70.1,648,74,650,72.4z"
        />
        <path
          fill="#E31F25"
          d="M646.7,52.6C649.2,50.2,643,56.1,646.7,52.6z"
        />
        <path
          fill="#E31F25"
          d="M670.3,152c-1.2,1.6-1.4,0.4-2.8,2.4C667.2,156.8,670.6,152.8,670.3,152z"
        />
        <path
          fill="#E31F25"
          d="M649.2,68.9c-0.2-0.2-0.3-1-0.9-0.3C648.6,68.8,648.6,69.6,649.2,68.9z"
        />
        <path
          fill="#E31F25"
          d="M644.5,53.1l-0.4-1.5l-1.8,2l0.3,1C643.3,53.8,643.5,54.3,644.5,53.1z"
        />
        <path
          fill="#E31F25"
          d="M640.3,45.6C638,48.9,642.9,43.5,640.3,45.6z"
        />
        <path
          fill="#E31F25"
          d="M640.2,48.3C642.7,45.9,636.4,51.9,640.2,48.3z"
        />
        <path
          fill="#E31F25"
          d="M646.1,71.7C643.9,74.9,648.5,69.7,646.1,71.7z"
        />
        <path
          fill="#E31F25"
          d="M640.2,51.5C637.9,54.7,642.7,49.4,640.2,51.5z"
        />
        <path
          fill="#E31F25"
          d="M642.6,61C640.3,64.2,645.1,58.9,642.6,61z"
        />
        <path
          fill="#E31F25"
          d="M663.9,145.1l0.5-0.6C664.2,144.1,663.8,144.8,663.9,145.1z"
        />
        <path
          fill="#E31F25"
          d="M638.4,47.7C633.6,52,640.7,45.6,638.4,47.7z"
        />
        <path
          fill="#E31F25"
          d="M637.8,78.4c2.2-1.2,5.4-4.2,7-8.5C640.6,74.4,640.4,74.7,637.8,78.4z"
        />
        <path
          fill="#E31F25"
          d="M645.5,75.6C643.3,78.7,648,73.5,645.5,75.6z"
        />
        <path
          fill="#E31F25"
          d="M646.4,75.9l-1.8,2C644.1,80.2,647.6,76.4,646.4,75.9z"
        />
        <path
          fill="#E31F25"
          d="M639.5,54.8C641.3,53.2,633.8,59.8,639.5,54.8z"
        />
        <path
          fill="#E31F25"
          d="M638.9,65l0.3,1c0.7,1.2,3.8-5,1.5-3C640.7,64.1,639.9,63.7,638.9,65z"
        />
        <path
          fill="#E31F25"
          d="M635.3,51.1c-1.8,2.2-8.1,8.3-6.7,8C630.5,56.9,636.8,50.8,635.3,51.1z"
        />
        <path
          fill="#E31F25"
          d="M636,53.6C633.7,56.8,638.6,51.5,636,53.6z"
        />
        <path
          fill="#E31F25"
          d="M641.9,76.3C636.4,81.1,643.7,74.8,641.9,76.3z"
        />
        <path
          fill="#E31F25"
          d="M640.7,83.4c-0.2-0.2-0.3-0.9-0.8-0.3C640.1,83.3,640.1,84,640.7,83.4z"
        />
        <path
          fill="#E31F25"
          d="M638.3,80.3C635.9,81,637,84.1,638.3,80.3z"
        />
        <path
          fill="#E31F25"
          d="M638.8,82.3C635.2,85.8,639.1,85,638.8,82.3z"
        />
        <path
          fill="#E31F25"
          d="M637.1,78.5c-3.1,3.4-4.7,5.3-5.8,7.1l0.1,0.5c2-3,5-4.2,5.4-6C635.5,81,637.3,79.1,637.1,78.5L637.1,78.5
			z"
        />
        <path
          fill="#E31F25"
          d="M637.1,90C636.6,88.6,634.8,93.2,637.1,90z"
        />
        <path
          fill="#E31F25"
          d="M628.5,67C630.7,65,623.7,71.3,628.5,67z"
        />
        <path
          fill="#E31F25"
          d="M634.1,93.2C634.9,92,633.9,92.7,634.1,93.2z"
        />
        <path
          fill="#E31F25"
          d="M623.9,63.6C623.6,63.9,623.5,64.1,623.9,63.6z"
        />
        <path
          fill="#E31F25"
          d="M624.9,62.7c-0.5,0.4-0.7,0.7-0.9,0.9C624.7,62.9,626.4,61.2,624.9,62.7z"
        />
        <path
          fill="#E31F25"
          d="M628.8,85.2c1-0.3,2.4-1.8,2.3-3.1C630.2,83.1,628.8,84.3,628.8,85.2z"
        />
        <path
          fill="#E31F25"
          d="M631.2,85.6l-0.1-0.5L627,90l0.3,1c0.5-0.4,1.8-1.3,1.7-2.2l1.8-2C630.7,86.4,630.7,86.1,631.2,85.6
			L631.2,85.6z"
        />
        <path
          fill="#E31F25"
          d="M631.4,86.1l-0.6,0.7C631,87.2,631.5,86.4,631.4,86.1z"
        />
        <path
          fill="#E31F25"
          d="M625.4,70.4C623.2,73.7,628,68.3,625.4,70.4z"
        />
        <path
          fill="#E31F25"
          d="M628.4,92.1c1-1.4,1.4-0.8,2.5-2.2l0.3-1.6C630.4,89.4,627.9,91.1,628.4,92.1z"
        />
        <path
          fill="#E31F25"
          d="M622.3,68.1C620.2,71.6,624.9,65.8,622.3,68.1z"
        />
        <path
          fill="#E31F25"
          d="M624.2,91.2c1.1-1.8,3.8-4.6,3.8-5.8C626.8,85.5,625,89.3,624.2,91.2z"
        />
        <path
          fill="#E31F25"
          d="M621.2,66.9C615.9,73,623,65,621.2,66.9z"
        />
        <path
          fill="#E31F25"
          d="M620.8,70.9c-0.4,0.4-2.6,1.9-2.3,3.2C619.4,72.2,620.8,72.9,620.8,70.9z"
        />
        <path
          fill="#E31F25"
          d="M619.9,73.5C617.8,77.3,622.3,70.9,619.9,73.5z"
        />
        <path
          fill="#E31F25"
          d="M617.9,72.2c-2.2,1.1-6.5,8.4-9.2,12.9l0.2,0.5C611,80.9,615.1,77.7,617.9,72.2z"
        />
        <path
          fill="#E31F25"
          d="M616.6,77.9C616,78.6,615.7,78.9,616.6,77.9z"
        />
        <path
          fill="#E31F25"
          d="M617.8,76.5C618,76.2,618,76.2,617.8,76.5z"
        />
        <path
          fill="#E31F25"
          d="M616.6,77.9c0.4-0.5,0.9-1,1.2-1.4c-0.1,0.1-0.1,0.1-0.2,0.2C617.1,77.3,616.8,77.7,616.6,77.9L616.6,77.9
			z"
        />
        <path
          fill="#E31F25"
          d="M619.9,100.6c1,0.4,4.2-4,3.9-5.5c-3.7,2.8-8.7,12.3-14.1,19.1C612.6,112.8,617.1,105.9,619.9,100.6z"
        />
        <path
          fill="#E31F25"
          d="M620.6,86.8c-1.6,1.8-2,3-1.3,3.8C620.9,88.8,620.1,88.1,620.6,86.8z"
        />
        <path
          fill="#E31F25"
          d="M613.6,71.5C611.5,75.2,616.1,68.8,613.6,71.5z"
        />
        <path
          fill="#E31F25"
          d="M620.1,96.2C618.1,99.9,622.5,93.7,620.1,96.2z"
        />
        <path
          fill="#E31F25"
          d="M613.4,84.6C612,88.7,615.7,82.5,613.4,84.6z"
        />
        <path
          fill="#E31F25"
          d="M613.9,86C611.8,89.7,616.3,83.4,613.9,86z"
        />
        <path
          fill="#E31F25"
          d="M616,93.2c-0.8,1-4.1,5.6-2.1,3.6C614.7,95.8,618,91.1,616,93.2z"
        />
        <path
          fill="#E31F25"
          d="M617.9,99.3C615.8,103,620.3,96.8,617.9,99.3z"
        />
        <path
          fill="#E31F25"
          d="M612.2,88.4C613.9,86.4,606.9,94.4,612.2,88.4z"
        />
        <path
          fill="#E31F25"
          d="M614.3,92.8C613.6,94.3,614.6,93.3,614.3,92.8z"
        />
        <path
          fill="#E31F25"
          d="M614.8,96.9C609.5,102.9,616.5,95,614.8,96.9z"
        />
        <path
          fill="#E31F25"
          d="M605.8,75.6C603.7,79.4,608.3,73,605.8,75.6z"
        />
        <path
          fill="#E31F25"
          d="M604.3,92c1.9-2.5,2.2-4.2,4.4-6.9C607.2,86.1,603.4,91.5,604.3,92z"
        />
        <path
          fill="#E31F25"
          d="M617,114.8C618.7,112.8,611.9,120.6,617,114.8z"
        />
        <path
          fill="#E31F25"
          d="M606.8,89.8C604.8,93.5,609.3,87.1,606.8,89.8z"
        />
        <path
          fill="#E31F25"
          d="M613.7,119.5C616,116.6,610.3,123.6,613.7,119.5z"
        />
        <path
          fill="#E31F25"
          d="M605.3,95.3C603.2,99,607.7,92.7,605.3,95.3z"
        />
        <path
          fill="#E31F25"
          d="M606.8,102.7C604.4,103.9,605.6,106.8,606.8,102.7z"
        />
        <path
          fill="#E31F25"
          d="M604.2,99.6C602.1,103.3,606.6,97,604.2,99.6z"
        />
        <path
          fill="#E31F25"
          d="M603.3,99.5C601.2,103.2,605.7,96.8,603.3,99.5z"
        />
        <path
          fill="#E31F25"
          d="M611.4,124.7C612.1,123.3,611.2,124.3,611.4,124.7z"
        />
        <path
          fill="#E31F25"
          d="M598.2,86.2C600.4,83.7,593.7,91.6,598.2,86.2z"
        />
        <path
          fill="#E31F25"
          d="M609.1,117.7C608.8,113.8,607.1,119.1,609.1,117.7z"
        />
        <path
          fill="#E31F25"
          d="M599.3,102.4C604.5,94.2,597.8,101.9,599.3,102.4z"
        />
        <path
          fill="#E31F25"
          d="M607.4,114.8C606.7,116.2,607.6,115.2,607.4,114.8z"
        />
        <path
          fill="#E31F25"
          d="M601.3,100.9C597.8,105.3,603.7,98,601.3,100.9z"
        />
        <path
          fill="#E31F25"
          d="M609.3,125.7C607.3,129.3,611.7,123.2,609.3,125.7z"
        />
        <path
          fill="#E31F25"
          d="M601.3,103.6c-1.3,2.1-6.7,8.8-5,7.7C597.7,109.2,603,102.5,601.3,103.6z"
        />
        <path
          fill="#E31F25"
          d="M605.9,120.2C608.4,117.4,605.6,118.7,605.9,120.2z"
        />
        <path
          fill="#E31F25"
          d="M606.4,121.6c0-0.7,1-1.8,0.8-2.5l-1.3,1.1l-0.6,0.8C605.5,121.7,605.7,122.5,606.4,121.6z"
        />
        <path
          fill="#E31F25"
          d="M599.3,102.4C599.8,103.7,601.5,98.6,599.3,102.4z"
        />
        <path
          fill="#E31F25"
          d="M598.4,99.6c-0.7,0.1-15.2,22.3-15.5,21.9c-0.3-0.7,5.7-7.3,2.5-5c-0.9,0.8-3.6,6.8-3.8,6.3l0.5,1.3
			C586.8,115.5,593.4,108.4,598.4,99.6L598.4,99.6z"
        />
        <path
          fill="#E31F25"
          d="M600.2,127.6c0.1,2.9,3.5-1.7,4.4-4.1l-0.2-0.5c-1.3,0.6-0.9,1.7-2.8,3.9
			C601.5,125.9,600.9,126.7,600.2,127.6L600.2,127.6z"
        />
        <path
          fill="#E31F25"
          d="M596.6,104.9C596.7,104.8,596.7,104.8,596.6,104.9z"
        />
        <path
          fill="#E31F25"
          d="M596.1,105.5c-2.6,3.2-0.1,0.1,0.5-0.6C596.5,105,596.4,105.2,596.1,105.5z"
        />
        <path
          fill="#E31F25"
          d="M595.8,107.2C593.8,111,598.3,104.6,595.8,107.2z"
        />
        <path
          fill="#E31F25"
          d="M595.4,113.3c-0.2-0.2-0.3-0.9-0.9-0.1C594.7,113.4,594.8,114.1,595.4,113.3z"
        />
        <path
          fill="#E31F25"
          d="M598.4,127.4C595,131.6,600.8,124.4,598.4,127.4z"
        />
        <path
          fill="#E31F25"
          d="M589.8,104.3C587.7,108,592.2,101.6,589.8,104.3z"
        />
        <polygon
          fill="#E31F25"
          points="593.1,116.5 594.2,114.9 593.9,114 592.8,115.6 		"
        />
        <path
          fill="#E31F25"
          d="M589.5,120.9c-7.5,11.4-1.1,3.2,1.7-2.4C590.4,119.5,590.1,119.3,589.5,120.9z"
        />
        <path
          fill="#E31F25"
          d="M585.2,106c-0.2-0.2-0.3-0.9-0.9-0.1C584.5,106,584.6,106.8,585.2,106z"
        />
        <path
          fill="#E31F25"
          d="M587.5,117.6c-0.7,1-1.3,2.1-0.8,2.6c-6.9,11-1.6,4.7,1.1-1.7C587.1,119.4,587.3,118.5,587.5,117.6
			L587.5,117.6z"
        />
        <path
          fill="#E31F25"
          d="M580.2,108.8c-2,3.8-8.3,13.3-7.4,13.3C574.8,118.3,581,108.9,580.2,108.8z"
        />
        <path
          fill="#E31F25"
          d="M584.1,129.6C582.2,133.7,586.4,126.6,584.1,129.6z"
        />
        <path
          fill="#E31F25"
          d="M576.3,134.1c3-5.6,4-5.1,5.9-9.9c-0.9,0.2-2.4,2.5-3.3,2.7C579.6,128,575.9,133.7,576.3,134.1
			L576.3,134.1z"
        />
        <path
          fill="#E31F25"
          d="M582.1,126.5C584.1,123.6,577.9,132.5,582.1,126.5z"
        />
        <path
          fill="#E31F25"
          d="M580.6,131.9C578.7,135.9,582.9,128.8,580.6,131.9z"
        />
        <path
          fill="#E31F25"
          d="M579.5,131C577.6,135.1,581.8,127.9,579.5,131z"
        />
        <path
          fill="#E31F25"
          d="M576.8,135.5C574.9,139.6,579.1,132.4,576.8,135.5z"
        />
        <path
          fill="#E31F25"
          d="M568.8,151.2c2.6-3.4,14.4-24.8,2.5-4.9c-0.6,1-1,1.6-1.2,1.4C568.9,149.3,568.4,150.6,568.8,151.2
			L568.8,151.2z"
        />
        <path
          fill="#E31F25"
          d="M571.5,123.5c-0.7,1.6-3,4.2-2.5,5C570.6,125.4,571.5,125.8,571.5,123.5z"
        />
        <path
          fill="#E31F25"
          d="M568.5,148.1c1.1-8.2,5.1-5.4,6.6-12.7c-2.2,2-4.8,6-7.8,11.8C567.6,147.8,567.7,149.2,568.5,148.1
			L568.5,148.1z"
        />
        <path
          fill="#E31F25"
          d="M579,164.3C582.5,159.2,579.6,161,579,164.3z"
        />
        <path
          fill="#E31F25"
          d="M570,147.7C574.2,141.4,571.2,143.4,570,147.7z"
        />
        <path
          fill="#E31F25"
          d="M549.7,161.9c3-6.7,13.8-22.8,16.9-30.2C561.4,141.5,552.5,153.8,549.7,161.9z"
        />
        <path
          fill="#E31F25"
          d="M578.5,163C576.6,167,580.8,160.1,578.5,163z"
        />
        <path
          fill="#E31F25"
          d="M576.9,165.6c-0.5,0.7-0.8,1.2-1.1,1.6C576.6,166.2,578.1,164,576.9,165.6z"
        />
        <path
          fill="#E31F25"
          d="M575.9,167.2C575.8,167.2,575.8,167.2,575.9,167.2C575.8,167.2,575.8,167.2,575.9,167.2z"
        />
        <path
          fill="#E31F25"
          d="M575.8,167.3C575.3,168,575.5,167.8,575.8,167.3z"
        />
        <path
          fill="#E31F25"
          d="M554.9,156.7c3-5.5,10.5-17,10.6-18.7C561.4,145.7,556.9,150.3,554.9,156.7z"
        />
        <path
          fill="#E31F25"
          d="M575.7,164.8C573.9,168.8,578,161.8,575.7,164.8z"
        />
        <path
          fill="#E31F25"
          d="M576.1,165.6c-1.1,2-5.7,9.1-4,7.5C573.2,171.2,577.8,164.1,576.1,165.6z"
        />
        <path
          fill="#E31F25"
          d="M567.7,153C565.8,157.1,570,150,567.7,153z"
        />
        <path
          fill="#E31F25"
          d="M568.1,153.9C570.3,150.5,564.8,158.9,568.1,153.9z"
        />
        <path
          fill="#E31F25"
          d="M565.7,150c-1.5,3.4-1.6,1.5-2.5,2.8C560.6,160.1,566.9,149,565.7,150z"
        />
        <path
          fill="#E31F25"
          d="M566.2,151.3C564.3,155.4,568.5,148.2,566.2,151.3z"
        />
        <path
          fill="#E31F25"
          d="M573.8,171C572,174.9,576.1,168,573.8,171z"
        />
        <path
          fill="#E31F25"
          d="M564.4,158c0.8-1.4,2-3.2,2-4C565.4,154.6,564.1,156.7,564.4,158z"
        />
        <path
          fill="#E31F25"
          d="M560.6,152.9c-0.3,2.7,3.2-3.6,2.8-4.1C562.2,151,561.3,152.2,560.6,152.9z"
        />
        <path
          fill="#E31F25"
          d="M565.1,153.1c-1.7,3-2.6,3.1-4.5,6.8c2.2-4.9-1.5,0.1-1.8,2.3c0.6-1.1,1-1.5,1.2-1.4
			c0,1.3-2.5,4.3-2.3,5.4C559.3,164,563.5,157,565.1,153.1L565.1,153.1z"
        />
        <path
          fill="#E31F25"
          d="M563.8,161.1c-0.4,0.6-1,1.8-1.2,1.4C563,161.9,563.6,160.7,563.8,161.1c0.8-1.9,2.7-2.5,2.7-4.5
			c-1.3,0.5-3.5,4.3-5.7,8.2C561,165.8,563.6,163.5,563.8,161.1z"
        />
        <path
          fill="#E31F25"
          d="M563,158.9C561.1,163,565.3,155.9,563,158.9z"
        />
        <path
          fill="#E31F25"
          d="M569.6,180.2C566.4,185,571.8,176.9,569.6,180.2z"
        />
        <path
          fill="#E31F25"
          d="M554.5,164.7c2.1-3.6,4-7.1,5-10C557.1,159.2,553.8,163.5,554.5,164.7z"
        />
        <path
          fill="#E31F25"
          d="M559.4,159.1C557.5,163.2,561.8,156,559.4,159.1z"
        />
        <path
          fill="#E31F25"
          d="M560.8,164.7C559.6,164.5,558.2,170,560.8,164.7z"
        />
        <path
          fill="#E31F25"
          d="M560.6,166.5C562.9,163.1,557.3,171.5,560.6,166.5z"
        />
        <path
          fill="#E31F25"
          d="M553.6,158.1c-0.7,1.1-3.9,6.6-2,4.1C552.4,161.1,555.6,155.6,553.6,158.1z"
        />
        <path
          fill="#E31F25"
          d="M558,168.8c-0.8,1.3-4.4,7.2-2.5,4.9C556.3,172.4,559.9,166.6,558,168.8z"
        />
        <path
          fill="#E31F25"
          d="M554.3,173c2-3,2.6-5.1,2.7-6.7C556.3,168.3,553.5,171.9,554.3,173z"
        />
        <path
          fill="#E31F25"
          d="M555.2,166.4C553.3,170.4,557.5,163.3,555.2,166.4z"
        />
        <path
          fill="#E31F25"
          d="M557.4,171.9C559.1,169.6,552.4,179,557.4,171.9z"
        />
        <path
          fill="#E31F25"
          d="M553.1,165.6C551.1,169.7,555.4,162.5,553.1,165.6z"
        />
        <path
          fill="#E31F25"
          d="M562.2,187.8C559.9,191,562.6,189.2,562.2,187.8z"
        />
        <path
          fill="#E31F25"
          d="M553.2,170.4C553.9,168.8,552.9,170,553.2,170.4z"
        />
        <path
          fill="#E31F25"
          d="M553.2,170.4C549.8,176.1,552.6,173.7,553.2,170.4z"
        />
        <path
          fill="#E31F25"
          d="M550.1,171.7c-1.9,3.7-5,8.3-5.3,11.2C547.8,178.3,547.8,178,550.1,171.7z"
        />
        <path
          fill="#E31F25"
          d="M551.3,178.8C551.9,179.8,553.2,174.3,551.3,178.8z"
        />
        <path
          fill="#E31F25"
          d="M552.8,180.3C551.1,184.6,555,176.9,552.8,180.3z"
        />
        <path
          fill="#E31F25"
          d="M553.2,181.2C555.3,177.5,550.1,186.6,553.2,181.2z"
        />
        <path
          fill="#E31F25"
          d="M551.4,176.9c-0.8,1.6-2,3.5-1.8,4.3l1.7-2.5L551.4,176.9z"
        />
        <path
          fill="#E31F25"
          d="M541.9,176.2c1.3-2.1,2.5-3.9,4.2-7.5c0.4-0.9,0.4-1.5,0.2-1.8l-8,15.8c-1.9,3.1-2.6,6.3-0.3,1.4
			L541.9,176.2L541.9,176.2z"
        />
        <path
          fill="#E31F25"
          d="M549.2,176C547.4,180.3,551.3,172.6,549.2,176z"
        />
        <path
          fill="#E31F25"
          d="M549,177.8C545.1,184.5,550.9,174.6,549,177.8z"
        />
        <path
          fill="#E31F25"
          d="M549.1,182.2C547.5,183.1,546.1,189,549.1,182.2z"
        />
        <path
          fill="#E31F25"
          d="M539,186.7c0.9-1.9,5.1-9.5,3.3-7.3C541.4,181.3,537.3,188.8,539,186.7z"
        />
        <path
          fill="#E31F25"
          d="M534.2,190.7C531.1,196.2,536.4,187,534.2,190.7z"
        />
        <path
          fill="#E31F25"
          d="M534.7,193.8C532.9,198.1,536.8,190.3,534.7,193.8z"
        />
        <path
          fill="#E31F25"
          d="M533.8,193.9C535.4,191.3,529,201.8,533.8,193.9z"
        />
        <path
          fill="#E31F25"
          d="M535.4,197.6C537.6,193.9,532.3,203.1,535.4,197.6z"
        />
        <path
          fill="#E31F25"
          d="M529.7,199.6c0.5-0.3,3.2-4.6,3-6c-1.3,2.9-2.2,3.7-3.2,5.6C520.6,215.7,526.8,207,529.7,199.6
			L529.7,199.6z"
        />
        <path
          fill="#E31F25"
          d="M533.6,195.7c-1.8,4-7.7,14.4-6.8,14.3C529,204.5,534,197.8,533.6,195.7z"
        />
        <path
          fill="#E31F25"
          d="M531.9,204.5C534,200.8,528.8,210,531.9,204.5z"
        />
        <path
          fill="#E31F25"
          d="M528.5,207.4C525.4,213,530.6,203.7,528.5,207.4z"
        />
        <path
          fill="#E31F25"
          d="M528.5,211.4C530.6,207.7,525.3,216.9,528.5,211.4z"
        />
        <path
          fill="#E31F25"
          d="M530.5,215.9C528.3,219.6,531,217.3,530.5,215.9z"
        />
        <path
          fill="#E31F25"
          d="M525.1,210.4C523.3,214.7,527.3,206.9,525.1,210.4z"
        />
        <path
          fill="#E31F25"
          d="M525.1,212.5C523.7,217.4,527.1,209.9,525.1,212.5z"
        />
        <path
          fill="#E31F25"
          d="M523,212.1c-7,12.6-15.1,29-19.2,41.1C511.2,236.8,516.4,225.9,523,212.1z"
        />
        <path
          fill="#E31F25"
          d="M521.3,224.9C523.4,221,518.3,230.5,521.3,224.9z"
        />
        <path
          fill="#E31F25"
          d="M519.5,227.1C517.9,231.7,521.5,223.4,519.5,227.1z"
        />
        <path
          fill="#E31F25"
          d="M521.7,229.7c-0.2-0.4-0.7,0.9-1.1,1.7C520.7,231.7,521.3,230.4,521.7,229.7z"
        />
        <path
          fill="#E31F25"
          d="M516.7,227.5C514.6,229.8,516,232.2,516.7,227.5z"
        />
        <path
          fill="#E31F25"
          d="M509.8,247.3l0.2,0.4C510.5,246.4,510,246.7,509.8,247.3z"
        />
        <path
          fill="#2B2B2A"
          d="M201,440.7c0-9.7-1.8-18.6-5.5-26.9c-3.6-8.3-8.6-15.5-15-21.6c-6.3-6.1-13.8-10.9-22.3-14.3
			c-8.5-3.5-17.7-5.2-27.5-5.2c-8.1,0-15.9,1.7-23.5,5.2c-7.5,3.5-14.2,8.2-20,14.2c-5.8,6-10.7,13.2-14.5,21.5
			c-3.8,8.3-6.1,17.3-7,26.9L201,440.7L201,440.7z M147,619.1c-18.4,0-35.7-3.2-51.9-9.7c-16.1-6.5-30.3-15.3-42.4-26.4
			c-12.1-11.2-21.7-24.2-28.7-39.2c-7-14.9-10.5-31-10.5-48.1c0-20.4,3.2-39.3,9.6-56.7c6.4-17.4,15.5-32.4,27.1-45.1
			c11.7-12.7,25.7-22.6,42.2-29.7c16.5-7.1,34.8-10.7,54.9-10.7c17.9,0,34.3,2.9,49.2,8.8s27.7,13.7,38.3,23.6
			c10.7,9.9,18.9,21.3,24.9,34.3c5.9,13,8.9,26.6,8.9,40.8H65.5c0,17.1,2.4,32.9,7.1,47.6c4.7,14.6,11.3,27.2,19.8,37.8
			c8.5,10.6,18.6,18.9,30.5,24.9c11.8,6,24.8,9,38.8,9c11.8,0,23.2-1.8,34-5.3c10.8-3.6,20.6-8.2,29.4-14
			c8.8-5.8,16.3-12.4,22.5-19.9c6.2-7.5,10.5-15.2,13.1-23.1l15.5,6.4c-3.9,13.1-10.2,25.4-18.9,36.9c-8.7,11.5-18.9,21.5-30.5,30
			c-11.6,8.5-24.3,15.3-38.1,20.3C174.9,616.7,161,619.1,147,619.1L147,619.1z"
        />
        <path
          fill="#2B2B2A"
          d="M603.3,358.9h53.8V407c5.2-7.1,10.9-13.9,17-20.4c6.1-6.5,12.4-12.2,19-17.1c6.6-4.9,13.4-8.8,20.4-11.6
			c7-2.8,14.2-4.3,21.4-4.3c5.9,0,11.4,0.8,16.5,2.5c5.1,1.7,9.5,3.9,13.3,6.7c3.8,2.8,6.8,6,9,9.7c2.2,3.7,3.3,7.6,3.3,11.8
			c0,8.2-2.8,14.4-8.2,18.6c-5.5,4.2-13.2,6.3-23,6.3c-6.1,0-11-0.6-14.6-1.8c-3.6-1.2-6.9-2.5-9.9-4c-3-1.5-6-2.8-9-4
			c-3-1.2-6.8-1.8-11.4-1.8c-3.5,0-7.2,1-10.8,3c-3.6,2-7.3,4.8-10.9,8.3c-3.6,3.6-7.3,7.8-11,12.6c-3.7,4.8-7.5,10.1-11.2,15.8
			v176.5h-53.8L603.3,358.9L603.3,358.9z"
        />
        <path
          fill="#2B2B2A"
          d="M929.1,600.4c10.7,0,20.4-2.6,29.3-7.7c8.9-5.1,16.6-12,23.1-20.6c6.5-8.6,11.6-18.7,15.1-30.2
			c3.6-11.5,5.3-23.7,5.3-36.5c0-18.5-1.7-35.8-5.1-51.8c-3.4-16.1-8.9-30.1-16.5-42.1c-7.6-12-17.5-21.5-29.7-28.3
			c-12.2-6.9-27.2-10.3-44.9-10.3c-9.1,0-17.9,2.4-26.4,7.4c-8.5,4.9-15.9,11.6-22.5,20.2c-6.5,8.5-11.7,18.5-15.6,29.9
			s-5.8,23.5-5.8,36.3c0,18.5,2.1,35.9,6.3,52.2c4.2,16.3,10.3,30.5,18.3,42.6c7.9,12.1,17.8,21.7,29.4,28.7
			C901.2,596.9,914.4,600.4,929.1,600.4L929.1,600.4z M919,619.1c-20.8,0-40.1-3.4-57.7-10.2c-17.7-6.8-32.9-16.1-45.7-27.8
			c-12.8-11.7-22.8-25.4-30-41.2c-7.2-15.7-10.8-32.5-10.8-50.4c0-18.8,3.6-36.5,10.8-53c7.2-16.5,17.2-30.9,30-43.2
			c12.8-12.3,28-22,45.7-29.1c17.7-7.1,36.9-10.7,57.7-10.7c20.8,0,40,3.2,57.5,9.7c17.5,6.5,32.7,15.4,45.6,26.7
			c12.8,11.3,22.9,24.6,30.1,39.9c7.2,15.3,10.8,31.6,10.8,49c0,19.3-3.6,37.4-10.8,54.4c-7.2,17-17.2,31.9-30.1,44.6
			c-12.9,12.7-28,22.8-45.6,30.1C958.9,615.5,939.8,619.1,919,619.1L919,619.1z"
        />
        <path
          fill="#2B2B2A"
          d="M1240.5,581.2c-7.3,11.7-16.9,21-28.9,27.8c-12,6.8-26.8,10.2-44.4,10.2c-9.1,0-17-1.4-23.7-4.3
			c-6.7-2.9-12.2-6.9-16.6-12.1s-7.7-11.5-9.8-18.7c-2.1-7.3-3.2-15.3-3.2-24.2V380.2h-47.2v-12.6c15.2,0,27.8-2.6,37.6-7.7
			c9.8-5.1,17.8-12.1,24.1-20.9c6.2-8.8,11.2-18.9,14.7-30.5c3.6-11.5,6.7-23.8,9.4-36.8h15.5v87.1h61.2v21.4H1168v179.6
			c0,3.8,0.8,7.3,2.3,10.7c1.5,3.3,3.6,6.2,6.2,8.7c2.6,2.4,5.7,4.4,9.4,5.8s7.5,2.1,11.6,2.1c6.1,0,11.6-1.4,16.6-4.2
			c5-2.8,9.3-6.2,13.1-10.3L1240.5,581.2L1240.5,581.2z"
        />
        <path
          fill="#2B2B2A"
          d="M1273.3,358.9h53.8V407c5.2-7.1,10.9-13.9,17-20.4c6.1-6.5,12.4-12.2,19-17.1c6.6-4.9,13.4-8.8,20.4-11.6
			c7-2.8,14.2-4.3,21.4-4.3c5.9,0,11.4,0.8,16.5,2.5c5.1,1.7,9.5,3.9,13.3,6.7c3.8,2.8,6.8,6,9,9.7c2.2,3.7,3.3,7.6,3.3,11.8
			c0,8.2-2.8,14.4-8.2,18.6c-5.5,4.2-13.2,6.3-23,6.3c-6.1,0-10.9-0.6-14.6-1.8c-3.6-1.2-6.9-2.5-9.9-4c-3-1.5-6-2.8-9-4
			c-3.1-1.2-6.8-1.8-11.4-1.8c-3.6,0-7.2,1-10.8,3c-3.6,2-7.3,4.8-10.9,8.3c-3.6,3.6-7.3,7.8-11,12.6c-3.7,4.8-7.4,10.1-11.2,15.8
			v176.5h-53.8L1273.3,358.9L1273.3,358.9z"
        />
        <path
          fill="#2B2B2A"
          d="M1535.9,358.9v181.3c0,6.3,1.6,12.3,4.7,17.9c3.1,5.6,7.4,10.5,12.8,14.6c5.4,4.1,11.8,7.4,19,9.9
			c7.3,2.4,15.1,3.7,23.3,3.7c5.4,0,11.2-1.1,17.3-3.2c6.1-2.1,12-5.1,17.9-8.9s11.3-8.2,16.5-13.2c5.2-5,9.4-10.3,12.8-16v-186h54
			v254.8h-54V566c-4.7,7.3-10.7,14.2-17.9,20.6c-7.2,6.5-15,12.2-23.3,17c-8.4,4.8-17,8.6-25.9,11.4c-8.9,2.8-17.3,4.2-25.2,4.2
			c-11.8,0-23-2.1-33.5-6.3c-10.5-4.2-19.6-9.8-27.4-17c-7.8-7.1-13.9-15.5-18.4-25c-4.5-9.6-6.7-19.8-6.7-30.7V358.9H1535.9
			L1535.9,358.9z"
        />
        <path
          fill="#2B2B2A"
          d="M1855,601.6c7.4,0,14.3-1.1,20.6-3.2c6.2-2.1,11.6-4.9,16.1-8.4c4.5-3.5,8-7.5,10.5-12.1
			c2.5-4.6,3.8-9.2,3.8-14c0-7.8-2.1-14.5-6.2-20.3c-4.1-5.8-9.6-11-16.5-15.7c-6.9-4.7-14.7-8.9-23.5-12.7
			c-8.8-3.8-17.7-7.7-26.8-11.7c-9.1-4-18-8.4-26.8-13.1c-8.8-4.7-16.6-10-23.5-16.1s-12.4-13.1-16.5-21.1c-4.2-8-6.2-17.4-6.2-28.3
			c0-10.3,2.3-19.8,7-28.5s11.1-16.2,19.3-22.5c8.2-6.3,18-11.3,29.4-14.8c11.4-3.6,23.9-5.3,37.4-5.3c9,0,17.4,0.6,25.4,1.8
			c7.9,1.2,15.7,3.5,23.2,6.9c7.5,3.4,15.1,8.2,22.6,14.3c7.5,6.2,15.4,14.2,23.5,24.2l-19.8,16.6c-10.3-15.8-21.9-27.6-34.6-35.3
			c-12.8-7.8-26.4-11.6-41-11.6c-7.6,0-14.6,0.9-21.1,2.7s-11.9,4.3-16.5,7.3c-4.6,3.1-8.1,6.7-10.7,10.8c-2.5,4.1-3.8,8.5-3.8,13
			c0,7.6,2.3,14.2,6.8,19.9c4.6,5.7,10.6,10.8,18,15.4c7.4,4.6,15.9,8.8,25.4,12.7c9.5,3.9,19.2,7.8,29.2,11.9
			c10,4,19.7,8.3,29.2,12.9c9.5,4.6,17.9,9.9,25.4,16c7.4,6.1,13.4,13.1,18,21.1s6.9,17.3,6.9,27.9c0,10.8-2.2,20.8-6.5,30.2
			c-4.3,9.4-10.8,17.6-19.4,24.4c-8.6,6.9-19.5,12.3-32.6,16.2s-28.4,5.9-45.8,5.9c-7.9,0-16.1-0.6-24.5-1.8
			c-8.4-1.2-16.8-3.5-25.4-6.9c-8.5-3.4-17.1-8.2-25.6-14.3c-8.6-6.2-16.9-14.2-25-24.2l22.1-18.8c6.6,8.7,12.9,16.1,19,22.2
			c6.1,6.1,12.2,11.1,18.4,14.9c6.2,3.9,12.6,6.7,19.3,8.5C1840,600.7,1847.2,601.6,1855,601.6L1855,601.6z"
        />
        <path
          fill="#2B2B2A"
          d="M2139.5,581.2c-7.3,11.7-16.9,21-28.9,27.8c-12,6.8-26.8,10.2-44.4,10.2c-9.1,0-17.1-1.4-23.7-4.3
			c-6.7-2.9-12.2-6.9-16.6-12.1c-4.4-5.2-7.6-11.5-9.8-18.7c-2.1-7.3-3.2-15.3-3.2-24.2V380.2h-47.2v-12.6c15.2,0,27.8-2.6,37.6-7.7
			s17.8-12.1,24.1-20.9s11.2-18.9,14.7-30.5c3.6-11.5,6.7-23.8,9.4-36.8h15.5v87.1h61.1v21.4h-61.1v179.6c0,3.8,0.8,7.3,2.3,10.7
			c1.5,3.3,3.6,6.2,6.2,8.7c2.6,2.4,5.8,4.4,9.4,5.8s7.5,2.1,11.6,2.1c6.1,0,11.6-1.4,16.6-4.2c5-2.8,9.4-6.2,13.1-10.3
			L2139.5,581.2L2139.5,581.2z"
        />
      </g>
    </g>
  </svg>
</template>
