<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 183.7 35.1"
  ><path
    fill="#FFF"
    d="M179.1 0h-38.2c-2.5 0-4.6 2-4.6 4.4v26.3c0 2.4 2 4.4 4.6 4.4h38.2c2.5 0 4.6-2 4.6-4.4V4.4c0-2.4-2.1-4.4-4.6-4.4z"
  /><path
    fill="#003168"
    d="M166.8 5.2H154.5c-.6 0-1 .5-1 1v22.6c0 .6.5 1 1 1h11.6c11.5 0 12.4-9 12.4-11.5v-1.9c-.4-9.8-8.5-11.2-11.7-11.2zm-1.9 18h-3.6V12h3.8s5.3-.4 5.3 5.4c0 5.9-5.5 5.8-5.5 5.8z"
  /><path
    fill="#F04E23"
    d="M150.6 11V6.2c0-.6-.5-1-1-1h-5.8c-.6 0-1 .5-1 1v7.9l7.8-3.1z"
  /><path
    fill="#003168"
    d="M142.8 17v11.9c0 .6.5 1 1 1h5.8c.6 0 1-.5 1-1v-15l-7.8 3.1z"
  /><path
    fill="#003168"
    d="M179.1 1.3c1.8 0 3.3 1.4 3.3 3.1v26.3c0 1.7-1.5 3.1-3.3 3.1h-38.2c-1.8 0-3.3-1.4-3.3-3.1V4.4c0-1.7 1.5-3.1 3.3-3.1h38.2m0-1.3h-38.2c-2.5 0-4.6 2-4.6 4.4v26.3c0 2.4 2 4.4 4.6 4.4h38.2c2.5 0 4.6-2 4.6-4.4V4.4c0-2.4-2.1-4.4-4.6-4.4z"
  /><g><g fill="#003168"><path d="M0 5.2h6.1l9.2 19.3 9.1-19.3h6.1v24.6h-4.6V11.3L24.2 15l-6.9 14.9h-4.1l-7-14.8-1.6-3.8v18.6H0V5.2zM34.7 17.5c0-7.1 5.4-12.7 12.6-12.7 7.3 0 12.6 5.7 12.6 12.7 0 7-5.4 12.7-12.6 12.7-7.2 0-12.6-5.7-12.6-12.7zm20.3 0c0-4.5-3.3-8.4-7.8-8.4s-7.7 3.8-7.7 8.4c0 4.5 3.2 8.3 7.7 8.3 4.6 0 7.8-3.8 7.8-8.3zM64 29.9V5.2h10.4c5.6 0 9 3.1 9 7 0 3.7-2.9 5.1-4.3 5.1v.3c1.5.1 4.5 1.7 4.5 5.1 0 4.2-3.4 7.2-9.4 7.2H64zm4.8-20.8v6.5h5.7c2.6 0 4-1.5 4-3.2 0-1.7-1.4-3.3-4-3.3h-5.7zm0 10.3V26h5.3c3.4 0 4.6-1.6 4.6-3.5 0-1.7-1.5-3.2-4.2-3.2h-5.7zM88.9 29.9V5.2h4.7v24.6h-4.7zM99.8 29.9V5.2h4.7v24.6h-4.7zM110.8 5.2h4.7v20.7h12.4v4h-17.1V5.2z" /></g></g></svg>
</template>
