<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36 36"
  >
    <rect
      x="23"
      y="5.3"
      fill="currentColor"
      width="4.2"
      height="1.1"
    />
    <rect
      x="19.7"
      y="7.4"
      fill="currentColor"
      width="7.6"
      height="1.1"
    />
    <rect
      x="8.2"
      y="11.6"
      fill="currentColor"
      width="18.9"
      height="1.1"
    />
    <rect
      x="8.2"
      y="13.7"
      fill="currentColor"
      width="15.4"
      height="1.1"
    />
    <rect
      x="8.2"
      y="15.9"
      fill="currentColor"
      width="9.7"
      height="1.1"
    />
    <rect
      x="8.2"
      y="18"
      fill="currentColor"
      width="9.2"
      height="1.1"
    />
    <path
      fill="currentColor"
      d="M28.2,23.9v9.8h-21V3.5h21V23.9c0,0,0.3,0,0.6,0c0.3,0,0.5,0,0.5,0l0-21c0-0.3-0.3-0.6-0.6-0.6H6.6
	c-0.3,0-0.6,0.3-0.6,0.6v31.3c0,0.3,0.3,0.6,0.6,0.6h22.1c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6V23.9"
    />
    <rect
      x="8.3"
      y="23.2"
      fill="currentColor"
      width="15.4"
      height="1.1"
    />
    <rect
      x="8.3"
      y="25.3"
      fill="currentColor"
      width="9.7"
      height="1.1"
    />
    <rect
      x="8.3"
      y="27.5"
      fill="currentColor"
      width="9.2"
      height="1.1"
    />
  </svg>
</template>