// external
import { defineStore } from "pinia"
import { reactive, toRefs } from "vue"

// internal
import { Account, AccountColorSettings } from "~/types"


interface Data {
  account: Account
  contractTypes: string[]
  companySize: string
  selectedUsecaseIds: string[]
  partyName: string
  partyAddress: string
  industry: string
  subIndustry: string
  logoUuid: string
  logoBlob: Blob
  logoImageUrl: string
  ciColors: AccountColorSettings
  showBrandingOptions: boolean
  currentStepIndex: number
  steps: string[]
}

export const useOnboardingStore = defineStore("onboardingStore", () => {

  const data = reactive<Data>(
    {
      account: null,
      contractTypes: [],
      companySize: null,
      selectedUsecaseIds: [ "compliance_governance" ],
      partyName: null,
      partyAddress: null,
      industry: null,
      subIndustry: null,
      logoUuid: null,
      logoBlob: null,
      logoImageUrl: null,
      ciColors: null,
      showBrandingOptions: false,
      currentStepIndex: 0,
      steps: [
        "intro",
        "party",
        "company",
        "usecases",
        "recommendations",
      ],
    },
  )

  const setAccount = (account:Account) => {
    data.account = account
    data.ciColors = account.ci_colors
    data.logoImageUrl = account.ci_logo_url
    if (!data.partyName) {
      data.partyName = account.name
    }
  }

  return {
    ...toRefs(data),
    setAccount,
  }
})
