// internal
import { CalendarDaysIcon, EllipsisHorizontalCircleIcon, ForwardIcon } from "@heroicons/vue/24/outline"
import { BuildingOffice2Icon, QuestionMarkCircleIcon, UserCircleIcon } from "@heroicons/vue/24/solid"
import { AesIcon, AnnouncementIcon, BookOpenIcon, DashboardIcon, DocumentTypeContractIcon, DocumentTypeFormIcon, DocumentTypeOtherIcon, DocumentTypeProposalIcon, LaunchIcon, QesIcon, SecurityIcon, SesIcon, StarIcon, UserGroupIcon } from "~/icons"
import { ConditionOperator, ContractDurationType, DocumentType, MultiFieldType, NotificationType, PartyEntityType, SignatureSecurityLevel } from "~/types/enums"
import { CurrencyOption } from "~/types/misc"

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

export const documentUserRoles = [
  {
    id: 1,
    type: "read-only",
  },
  {
    id: 2,
    type: "collaborator",
  },
  {
    id: 3,
    type: "owner",
  },
]

export const contractTypes = [
  "data_processing_agreement",
  "deed_of_trust",
  "employment_contract",
  "investment_agreement",
  "letter_of_intent",
  "license_agreement",
  "loan_agreement",
  "non_disclosure_agreement",
  "partnership_agreement",
  "privacy_policy",
  "purchase_agreement",
  "rental_agreement",
  "service_agreement",
  "terms_and_conditions",
]

export const expressionLanguageStringMultiFieldTypes = [
  MultiFieldType.text,
  MultiFieldType.email,
  MultiFieldType.currency,
  MultiFieldType.currency_duration,
  MultiFieldType.duration,
  MultiFieldType.date,
]

export const expressionLanguageStringOperators = [
  ConditionOperator.CONTAINS,
  ConditionOperator.NOT_CONTAINS,
  ConditionOperator.STARTS_WITH,
  ConditionOperator.ENDS_WITH,
]

export const contractDurationTypes = [
  {
    type: ContractDurationType.fixed_term,
    icon: CalendarDaysIcon,
  },
  {
    type: ContractDurationType.open_ended,
    icon: ForwardIcon,
  },
  {
    type: ContractDurationType.no_term,
    icon: EllipsisHorizontalCircleIcon,
  },
]

export const documentTypes = [
  {
    type: DocumentType.contract,
    icon: DocumentTypeContractIcon,
  },
  {
    type: DocumentType.quote,
    icon: DocumentTypeProposalIcon,
  },
  {
    type: DocumentType.form,
    icon: DocumentTypeFormIcon,
  },
  {
    type: DocumentType.other,
    icon: DocumentTypeOtherIcon,
  },
]

export const signatureSecurityLevels = [
  { key: SignatureSecurityLevel.SES, name: "Simple (SES)", icon: SesIcon },
  { key: SignatureSecurityLevel.AES, name: "Advanced (AES)", icon: AesIcon },
  { key: SignatureSecurityLevel.QES, name: "Qualified (QES)", icon: QesIcon },
]

export const currencyOptions: CurrencyOption[] = [
  { code: "EUR", name: "Euro", symbol: "€" },
  { code: "USD", name: "US Dollar", symbol: "$" },
  { code: "GBP", name: "British Pound", symbol: "£" },
  { code: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { code: "AED", name: "UAE Dirham", symbol: "د.إ" },
  { code: "ARS", name: "Argentine Peso", symbol: "$" },
  { code: "AUD", name: "Australian Dollar", symbol: "$" },
  { code: "BGN", name: "Bulgarian Lev", symbol: "лв" },
  { code: "BRL", name: "Brazilian Real", symbol: "R$" },
  { code: "CAD", name: "Canadian Dollar", symbol: "$" },
  { code: "CLP", name: "Chilean Peso", symbol: "$" },
  { code: "CNY", name: "Chinese Yuan", symbol: "¥" },
  { code: "COP", name: "Colombian Peso", symbol: "$" },
  { code: "CZK", name: "Czech Koruna", symbol: "Kč" },
  { code: "DKK", name: "Danish Krone", symbol: "kr" },
  { code: "DZD", name: "Algerian Dinar", symbol: "د.ج" },
  { code: "EGP", name: "Egyptian Pound", symbol: "£" },
  { code: "GHS", name: "Ghanaian Cedi", symbol: "GH₵" },
  { code: "HKD", name: "Hong Kong Dollar", symbol: "$" },
  { code: "HRK", name: "Croatian Kuna", symbol: "kn" },
  { code: "HUF", name: "Hungarian Forint", symbol: "Ft" },
  { code: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
  { code: "ILS", name: "Israeli Shekel", symbol: "₪" },
  { code: "INR", name: "Indian Rupee", symbol: "₹" },
  { code: "JPY", name: "Japanese Yen", symbol: "¥" },
  { code: "KES", name: "Kenyan Shilling", symbol: "KSh" },
  { code: "KRW", name: "South Korean Won", symbol: "₩" },
  { code: "MAD", name: "Moroccan Dirham", symbol: "د.م." },
  { code: "MAD", name: "Moroccan Dirham", symbol: "د.م." },
  { code: "MXN", name: "Mexican Peso", symbol: "$" },
  { code: "MYR", name: "Malaysian Ringgit", symbol: "RM" },
  { code: "NGN", name: "Nigerian Naira", symbol: "₦" },
  { code: "NOK", name: "Norwegian Krone", symbol: "kr" },
  { code: "NZD", name: "New Zealand Dollar", symbol: "$" },
  { code: "PEN", name: "Peruvian Sol", symbol: "S/." },
  { code: "PHP", name: "Philippine Peso", symbol: "₱" },
  { code: "PLN", name: "Polish Zloty", symbol: "zł" },
  { code: "RON", name: "Romanian Leu", symbol: "lei" },
  { code: "RUB", name: "Russian Ruble", symbol: "₽" },
  { code: "RWF", name: "Rwandan Franc", symbol: "FRw" },
  { code: "SAR", name: "Saudi Riyal", symbol: "﷼" },
  { code: "SEK", name: "Swedish Krona", symbol: "kr" },
  { code: "SGD", name: "Singapore Dollar", symbol: "$" },
  { code: "THB", name: "Thai Baht", symbol: "฿" },
  { code: "TND", name: "Tunisian Dinar", symbol: "د.ت" },
  { code: "TRY", name: "Turkish Lira", symbol: "₺" },
  { code: "TWD", name: "Taiwan New Dollar", symbol: "NT$" },
  { code: "TZS", name: "Tanzanian Shilling", symbol: "TSh" },
  { code: "UGX", name: "Ugandan Shilling", symbol: "USh" },
  { code: "VND", name: "Vietnamese Dong", symbol: "₫" },
  { code: "ZAR", name: "South African Rand", symbol: "R" },
  { code: "ZMW", name: "Zambian Kwacha", symbol: "ZK" },
]

export const conditionOperators = [
  { key: ConditionOperator.EQUAL },
  { key: ConditionOperator.NOT_EQUAL },
  { key: ConditionOperator.GREATER_THAN },
  { key: ConditionOperator.GREATER_THAN_OR_EQUAL },
  { key: ConditionOperator.OR },
  { key: ConditionOperator.LESS_THAN },
  { key: ConditionOperator.LESS_THAN_OR_EQUAL },
]

export const teamIcons = {
  StarIcon: StarIcon,
  UserGroupIcon: UserGroupIcon,
  SecurityIcon: SecurityIcon,
  AnnouncementIcon: AnnouncementIcon,
  LaunchIcon: LaunchIcon,
  DashboardIcon: DashboardIcon,
  BookOpenIcon: BookOpenIcon,
}

export const entityTypeOptions = [
  {
    icon: BuildingOffice2Icon,
    title: "partyForm.businessEntityLabel",
    description: "partyForm.businessEntityDescription",
    value: PartyEntityType.business,
  },
  {
    icon: UserCircleIcon,
    title: "partyForm.personLabel",
    description: "partyForm.personDescription",
    value: PartyEntityType.person,
  },
  //{
  //  icon: HomeIcon,
  //  title: "partyForm.accountPartyLabel",
  //  description: "partyForm.accountPartyDescription",
  //  value: PartyEntityType.accountParty,
  //},
  {
    icon: QuestionMarkCircleIcon,
    title: "partyForm.tbdLabel",
    description: "partyForm.tbdDescription",
    value: PartyEntityType.tbd,
  },
]

export interface Locale {
  label: string
  value: string
  icon: string
}

export const localeOptions: Locale[] = [
  {
    label: "English",
    value: "en",
    icon: "🇺🇸",
  },
  {
    label: "Deutsch",
    value: "de",
    icon: "🇩🇪",
  },
]

export const IS_CYPRESS_ENV = import.meta.env.MODE === "cypress"

export const IS_DEV_ENV = import.meta.env.MODE === "development"

export const phonePlaceholdersByCountry = {
  "US": "+1 212 555 1234",
  "AT": "+43 660 1234567",
  "BE": "+32 470 12 34 56",
  "BG": "+359 48 123 456",
  "CH": "+41 79 123 45 67",
  "CY": "+357 97 123456",
  "CZ": "+420 601 123 456",
  "DE": "+49 170 1234567",
  "DK": "+45 20 12 34 56",
  "EE": "+372 5123 456",
  "ES": "+34 612 34 56 78",
  "FI": "+358 40 1234567",
  "FR": "+33 1 70 18 98 56",
  "GB": "+44 20 7123 4567",
  "GR": "+30 691 234 5678",
  "HR": "+385 91 123 4567",
  "HU": "+36 20 123 4567",
  "IE": "+353 87 123 4567",
  "IS": "+354 821 2345",
  "IT": "+39 312 345 6789",
  "LT": "+370 612 34 567",
  "LU": "+352 621 123 456",
  "LV": "+371 21 234 567",
  "MT": "+356 7912 3456",
  "NL": "+31 6 12345678",
  "NO": "+47 912 34 567",
  "PL": "+48 512 345 678",
  "PT": "+351 912 345 678",
  "RO": "+40 712 345 678",
  "SE": "+46 70 123 45 67",
  "SI": "+386 31 234 567",
  "SK": "+421 912 345 678",
  "SM": "+378 66 66 1234",
  "VA": "+379 1 12 34 56",
  "MC": "+377 6 12 34 56 78",
  "AD": "+376 312 345",
  "ME": "+382 67 123 456",
  "AL": "+355 67 123 4567",
  "MK": "+389 70 123 456",
  "BA": "+387 61 234 567",
  "XK": "+383 44 123 456",
  "RS": "+381 60 1234567",
  "MD": "+373 6 123 4567",
  "BY": "+375 29 123 45 67",
  "UA": "+380 50 123 4567",
  "RU": "+7 912 345 67 89",
  "CA": "+1 416 555 1234",
  "BR": "+55 21 91234 5678",
  "AU": "+61 2 8123 4567",
  "IN": "+91 98765 43210",
  "CN": "+86 10 1234 5678",
  "JP": "+81 3 1234 5678",
  "ZA": "+27 10 123 4567",
  "NG": "+234 812 345 6789",
  "MX": "+52 55 1234 5678",
}

export const stageUiHintMap = {
  draft: "document_created",
  approved_draft: "review_ready",
  review: "review_active",
  approved_review: "signing_ready",
  signing: "signing_active",
  signed: "signed",
}

export const documentUserInvitationStatuses = [
  NotificationType.DOCUMENT_INVITED,
  NotificationType.DOCUMENT_MOVED_TO_SIGNING_PHASE,
  NotificationType.DOCUMENT_INVITED_CC,
  NotificationType.SIGNATURE_REQUIRED,
]

export const LevelNumberingStyleMap = [
  "decimal",
  "lower-alpha",
  "lower-roman",
]

export const DelimiterStyleMap = [
  "dot",
  "parenthesis",
  "dot",
]


export const industries = [
  {
    key: "education",
    entries: [
      "education-management",
      "e-learning",
      "higher-education",
      "primary-secondary-education",
      "research",
    ],
  },
  {
    key: "construction",
    entries: [
      "building-materials",
      "civil-engineering",
      "construction",
    ],
  },
  {
    key: "design",
    entries: [
      "architecture-planning",
      "design",
      "graphic-design",
    ],
  },
  {
    key: "corporate-services",
    entries: [
      "accounting",
      "business-supplies-equipment",
      "environmental-services",
      "events-services",
      "executive-office",
      "facilities-services",
      "human-resources",
      "information-services",
      "management-consulting",
      "outsourcing-offshoring",
      "professional-training-coaching",
      "security-investigations",
      "staffing-recruiting",
    ],
  },
  {
    key: "retail",
    entries: [
      "retail",
      "supermarkets",
      "wholesale",
    ],
  },
  {
    key: "energy-and-mining",
    entries: [
      "mining-metals",
      "oil-energy",
      "utilities",
    ],
  },
  {
    key: "manufacturing",
    entries: [
      "automotive",
      "aviation-aerospace",
      "chemicals",
      "defense-space",
      "electrical-electronic-manufacturing",
      "food-production",
      "glass-ceramics-concrete",
      "industrial-automation",
      "machinery",
      "mechanical-industrial-engineering",
      "packaging-containers",
      "paper-forest-products",
      "plastics",
      "railroad-manufacture",
      "renewables-environment",
      "shipbuilding",
      "textiles",
    ],
  },
  {
    key: "finance",
    entries: [
      "banking",
      "capital-markets",
      "financial-services",
      "insurance",
      "investment-banking",
      "investment-management",
      "venture-capital-private-equity",
    ],
  },
  {
    key: "recreation-and-travel",
    entries: [
      "airlines-aviation",
      "gambling-casinos",
      "hospitality",
      "leisure-travel-tourism",
      "restaurants",
      "recreational-facilities-services",
      "sports",
    ],
  },
  {
    key: "arts",
    entries: [
      "arts-crafts",
      "fine-art",
      "performing-arts",
      "photography",
    ],
  },
  {
    key: "healthcare",
    entries: [
      "biotechnology",
      "hospital-health-care",
      "medical-device",
      "medical-practice",
      "mental-health-care",
      "pharmaceuticals",
      "veterinary",
    ],
  },
  {
    key: "hardware-and-networking",
    entries: [
      "computer-hardware",
      "computer-networking",
      "nanotechnology",
      "semiconductors",
      "telecommunications",
      "wireless",
    ],
  },
  {
    key: "real-estate",
    entries: [
      "commercial-real-estate",
      "real-estate",
    ],
  },
  {
    key: "legal",
    entries: [
      "alternative-dispute-resolution",
      "law-practice",
      "legal-services",
    ],
  },
  {
    key: "consumer-goods",
    entries: [
      "apparel-fashion",
      "consumer-electronics",
      "consumer-goods",
      "consumer-services",
      "cosmetics",
      "food-beverages",
      "furniture",
      "luxury-goods-jewelry",
      "sporting-goods",
      "tobacco",
      "wine-and-spirits",
    ],
  },
  {
    key: "agriculture",
    entries: [
      "dairy",
      "farming",
      "fishery",
      "ranching",
    ],
  },
  {
    key: "media-and-communications",
    entries: [
      "market-research",
      "marketing-advertising",
      "newspapers",
      "online-media",
      "printing",
      "public-relations-communications",
      "publishing",
      "translation-localization",
      "writing-editing",
    ],
  },
  {
    key: "nonprofit",
    entries: [
      "civic-social-organization",
      "fundraising",
      "individual-family-services",
      "international-trade-development",
      "libraries",
      "museums-institutions",
      "non-profit-organization-management",
      "philanthropy",
      "program-development",
      "religious-institutions",
      "think-tanks",
    ],
  },
  {
    key: "software-and-it-services",
    entries: [
      "computer-network-security",
      "computer-software",
      "information-technology-services",
      "internet",
    ],
  },
  {
    key: "transportation-and-logistics",
    entries: [
      "import-export",
      "logistics-supply-chain",
      "maritime",
      "package-freight-delivery",
      "transportation-trucking-railroad",
      "warehousing",
    ],
  },
  {
    key: "entertainment",
    entries: [
      "animation",
      "broadcast-media",
      "computer-games",
      "entertainment",
      "media-production",
      "mobile-games",
      "motion-pictures-film",
      "music",
    ],
  },
  {
    key: "wellness-and-fitness",
    entries: [
      "alternative-medicine",
      "health-wellness-fitness",
    ],
  },
  {
    key: "public-safety",
    entries: [
      "law-enforcement",
      "military",
      "public-safety",
    ],
  },
  {
    key: "public-administration",
    entries: [
      "government-administration",
      "government-relations",
      "international-affairs",
      "judiciary",
      "legislative-office",
      "political-organization",
      "public-policy",
    ],
  },
]

export const DEFAULT_CHART_COLORS = [ "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#84cc16", "#c026d3", "#7f1d1d", "#2dd4bf" ]
