<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
  ><path
    fill="currentColor"
    d="M128 48v80H40V56a8 8 0 0 1 8-8Zm87.4 80H128v103.6a7.7 7.7 0 0 0 2.5-.4c13.4-4.4 77.8-29.5 84.9-103.2Z"
    opacity=".2"
  /><path
    fill="currentColor"
    d="M224 114.7V56a16 16 0 0 0-16-16H48a16 16 0 0 0-16 16v58.7a127.4 127.4 0 0 0 .7 14v.2c7.5 78.4 76 105.2 90.3 109.9a16 16 0 0 0 10 0c14.3-4.7 82.8-31.5 90.3-109.9v-.2a127.4 127.4 0 0 0 .7-14Zm-16 0c0 1.8-.1 3.5-.1 5.3H136V56h72ZM48 56h72v64H48.1c-.1-1.8-.1-3.5-.1-5.3Zm1.8 80H120v84.6c-19.7-8.4-61.2-31.9-70.2-84.6Zm86.2 84.6V136h70.2c-9 52.7-50.5 76.2-70.2 84.6Z"
  /></svg>
</template>