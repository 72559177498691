import { defineStore } from "pinia"
import { reactive, toRefs, toRaw, computed } from "vue"
import { Editor as TipTapEditor } from "@tiptap/core"
import { uniq, pick, debounce, some, isEqual, find, filter } from "lodash-es"
import { JSONContent } from "@tiptap/vue-3"
import { useI18n } from "vue-i18n"
import { Instance } from "tippy.js"
import { InformationCircleIcon, VariableIcon } from "@heroicons/vue/24/outline"

import { Document, FormErrors, DocumentTab, DocumentUser, AccountUser, DocumentRevision, Activity, WorkingCopy, DocumentVariable, DocumentEditorSession, Tag, Comment, Attachment, Party, DocumentContentType, DynamicField, Condition, MetadataValue, MainContentTab, SignatureBlock, SigningPhase, CrudContext, ExtendedTippyProps, DocumentStage, Team, DocumentVisit, DocumentOrigin, DocumentWorkflowState, Signatory } from "~/types"
import { changedKeys, getSha256Hash, getUserRepresentation, readPdfFile, stageUiHintMap } from "~/utils"
import { useTippies } from "~/composables"

import {
  updateDocumentAction,
  fetchDocumentAction,
  fetchRevisionsAction,
  fetchActivitiesAction,
  getDocumentEditorSessionAction,
  createDocumentEditorSessionAction,
  destroyDocumentEditorSessionAction,
  updateDocumentEditorSessionAction,
  fetchHtmlContentAction,
  fetchPdfUrlAction,
  fetchEditorContentAction,
  createWorkingCopyAction,
  publishWorkingCopyAction,
  generateAiIntroAction,
  fetchDocumentVariablesAction,
  getPdfDownloadAction,
  syncSigningOrderAction,
  getWorkingCopyAction,
  addTagAction,
  removeTagAction,
  fetchDocumentSigningPhasesAction,
  fetchDocumentWorkflowStateAction,
} from "./documentStoreActions"
import { usePartyStore } from "../partyStore"
import { useMetadataStore } from "../metadataStore"
import { useConditionStore } from "../conditionStore"
import { useDynamicFieldStore } from "../dynamicFieldStore"
import { useUserStore } from "../userStore"
import { useSignatureStore } from "../signatureStore"
import { useEditorStore } from "../editorStore"
import { useSharedStore } from "../sharedStore"
import { useNotificationStore } from "../notificationStore"
import { useConfirmationStore } from "../confirmationStore"

interface Data {
  currentDocument: Document,
  attachments: Attachment[]
  documentEditorSession: DocumentEditorSession
  activeTabKey: DocumentTab,
  editorContentTabs: any[],
  activeEditorContentTab: any,
  activeDynamicContentTab: any,
  activeActivityTab: any,
  mainContentScrollContainer: any,
  mainContentPdfViewer: any,
  mainEditor: TipTapEditor,
  mdu: DocumentUser,
  mau: AccountUser,
  isUpdatingDocument: boolean
  isLoadingRevisions: boolean
  isLoadingActivities: boolean
  isLoadingLockedState: boolean
  isLoadingEditorContent: boolean
  isCreatingEditorSession: boolean
  isLoadingHtmlContent: boolean
  isLoadingPdfUrl: boolean
  isChangingStage: boolean
  hasChangedStage: boolean
  hasChangedCheckpoint: boolean
  isLoadingSaveDocumentContent: boolean
  isLoadingWorkingCopy: boolean
  isLoadingGenerateAiIntro: boolean
  isSyncingSigningOrder: boolean
  isLoadingDocument: boolean
  isLoadingDocumentVariables: boolean
  isUpdatingTags: boolean
  revisions: DocumentRevision[]
  activities: Activity[]
  documentLastSaved: number
  documentErrors: FormErrors<Document>
  documentVariables: DocumentVariable[]
  keysOfIsUpdatingDocumentField: (keyof Document)[]
  keysOfLastSavedDocumentField: (keyof Document)[]
  prosemirrorData: JSONContent
  lastSaved: number
  htmlContent: string
  pdfUrl: string
  activeWorkingCopy: WorkingCopy
  debounceTimestamp: number
  debounceUuid: Document["uuid"]
  isLoadingPdfDownload: Record<DocumentRevision["uuid"], boolean>
  keepAliveDocumentEditorSessionTimerId: NodeJS.Timeout
  documentEditorSessionUuidBeingLoaded: DocumentEditorSession["uuid"]
  workingCopyUuidBeingLoaded: WorkingCopy["uuid"],
  tags: Tag[]
  allTags: Tag[]
  allTeams: Team[]
  isActiveEditMode: boolean
  isLoadingEditorSession: boolean
  saveDocumentContentKeyListener: (e: KeyboardEvent) => void
  editModeTimer: NodeJS.Timeout
  editModeTimerHelper: number
  documentEditorSessionObjectSignature: string
  isLoadingDestroyDocumentEditorSession: boolean
  isVisiblePreview: boolean
  previewContent: string
  isLoadingPreviewContent: boolean
  mainContentTab: MainContentTab
  isLoadingRefreshStage: boolean
  isLoadingRefreshSigningPhase: boolean
  stageTippy: Instance<ExtendedTippyProps>[]
  isVisibleSidebar: boolean
  editorSessionTimeoutInSeconds: number
  editorSessionTimeoutInterval: NodeJS.Timeout
  documentVisits: DocumentVisit[]
  signedPdfSha256: string
  documentWorkflowState: Record<DocumentWorkflowState, boolean>
  isLoadingRefreshDocumentWorkflowState: boolean
  isPdfImportModalOpen: boolean
  showPlgModal: boolean
}

export const useDocumentStore = defineStore("documentStore", () => {
  const data = reactive<Data>({
    currentDocument: null,
    attachments: null,
    documentEditorSession: null,
    activeTabKey: DocumentTab.general,
    editorContentTabs: [],
    activeEditorContentTab: null,
    activeDynamicContentTab: null,
    activeActivityTab: null,
    mainContentScrollContainer: null,
    mainContentPdfViewer: null,
    mainEditor: null,
    mdu: null,
    mau: null,
    revisions: null,
    activities: null,
    isUpdatingDocument: false,
    isLoadingRevisions: false,
    isLoadingActivities: false,
    isLoadingLockedState: false,
    isLoadingEditorContent: false,
    isCreatingEditorSession: false,
    isLoadingHtmlContent: false,
    isLoadingPdfUrl: false,
    isChangingStage: false,
    hasChangedStage: false,
    hasChangedCheckpoint: false,
    isLoadingSaveDocumentContent: false,
    isLoadingWorkingCopy: false,
    isLoadingGenerateAiIntro: false,
    isSyncingSigningOrder: false,
    isLoadingDocument: false,
    isLoadingDocumentVariables: false,
    isUpdatingTags: false,
    documentLastSaved: null,
    documentErrors: null,
    documentVariables: null,
    keysOfIsUpdatingDocumentField: [],
    keysOfLastSavedDocumentField: [],
    prosemirrorData: null,
    lastSaved: null,
    htmlContent: null,
    pdfUrl: null,
    activeWorkingCopy: null,
    debounceTimestamp: null,
    debounceUuid: null,
    isLoadingPdfDownload: {},
    keepAliveDocumentEditorSessionTimerId: null,
    documentEditorSessionUuidBeingLoaded: null,
    workingCopyUuidBeingLoaded: null,
    tags: [],
    allTags: [],
    allTeams: [],
    isActiveEditMode: false,
    isLoadingEditorSession: false,
    saveDocumentContentKeyListener: () => null,
    editModeTimer: null,
    editModeTimerHelper: 0,
    documentEditorSessionObjectSignature: null,
    isLoadingDestroyDocumentEditorSession: false,
    isVisiblePreview: false,
    previewContent: null,
    isLoadingPreviewContent: false,
    mainContentTab: MainContentTab.documentContent,
    isLoadingRefreshStage: false,
    isLoadingRefreshSigningPhase: false,
    stageTippy: null,
    isVisibleSidebar: false,
    editorSessionTimeoutInSeconds: null,
    editorSessionTimeoutInterval: null,
    documentVisits: [],
    signedPdfSha256: null,
    documentWorkflowState: null,
    isLoadingRefreshDocumentWorkflowState: false,
    isPdfImportModalOpen: false,
    showPlgModal: false,
  })

  const isLockedDocument = computed<boolean>(() => !!(data.currentDocument?.is_locked))

  const { t } = useI18n()

  const { notify } = useNotificationStore()

  const confirmationStore = useConfirmationStore()
  const { setShowConfirmModal, setConfirmOptions } = confirmationStore

  const userStore = useUserStore()

  const conditionStore = useConditionStore()

  const metadataStore = useMetadataStore()

  const dynamicFieldStore = useDynamicFieldStore()

  const partyStore = usePartyStore()

  const signatureStore = useSignatureStore()

  const editorStore = useEditorStore()

  const sharedStore = useSharedStore()

  const { showStageTippy } = useTippies(CrudContext.document)

  const getMainContentScrollContainer = () => data.mainContentScrollContainer
  const getMainEditor = () => data.mainEditor
  const getDocument = () => data.currentDocument
  const getMdu = () => data.mdu

  // computed

  const unplacedSignatureBlock = computed<SignatureBlock>(() => {
    if (data.isLoadingEditorContent) return null
    if (data.currentDocument?.content_type === DocumentContentType.pdf && signatureStore.unplacedSignatureBlocks?.length) return signatureStore.unplacedSignatureBlocks?.[0]
    else if (data.currentDocument?.content_type === DocumentContentType.prosemirror_data && data.prosemirrorData) {
      const signatureBlocksFiltered = signatureStore.signatureBlocks?.filter((el: SignatureBlock) => !el.deleted_at)
      const signatureBlockNotPlacedInEditor = find(signatureBlocksFiltered, (sb) => {
        const check = !some(editorStore.refUuidsOfSignaturesInEditor, (el) => el === sb.ref_uuid)
        return check
      },
      )
      if (signatureBlockNotPlacedInEditor) return signatureBlockNotPlacedInEditor
    }
    return null
  })

  const documentSigningCriteriaMet = computed<boolean>(() => {
    return data.documentWorkflowState?.[DocumentWorkflowState.mdu_can_start_signing]
  })

  // mutations
  const setDocument = (document: Document) => {
    data.currentDocument = document
    setRevisions(null)
    // If certain tabs are active, we need to immediately fetch revisions
    if (data.activeTabKey === DocumentTab.activity || data.activeTabKey === DocumentTab.signatures) {
      fetchRevisions(document.uuid)
    }
  }
  const setRevisions = (revisions: DocumentRevision[]) => data.revisions = revisions
  const setDocumentVariables = (documentVariables: DocumentVariable[]) => data.documentVariables = documentVariables
  const setProsemirrorData = (prosemirrorData: JSONContent) => data.prosemirrorData = prosemirrorData
  const setActiveTabKey = (activeTabKey: DocumentTab, skipSetVisibleSidebar = false) => {
    // Set isVisibleSidebar if needed
    if (!skipSetVisibleSidebar) setIsVisibleSidebar(true)
    // Fetch activities and revisions only when needed
    // to prevent unnecessary load
    if (activeTabKey === DocumentTab.activity || activeTabKey === DocumentTab.signatures) {
      fetchActivities(data.currentDocument.uuid)
      fetchRevisions(data.currentDocument.uuid)
    }
    // Fetch calendarData only when needed
    // to prevent unnecessary load
    if (activeTabKey === DocumentTab.dates) {
      metadataStore.fetchCalendarData(data.currentDocument.uuid)
    }
    data.activeTabKey = activeTabKey
  }
  const setEditorSessionTimeoutInSeconds = (editorSessionTimeoutInSeconds: number) => data.editorSessionTimeoutInSeconds = editorSessionTimeoutInSeconds
  const setHasChangedStage = (hasChangedStage: boolean) => data.hasChangedStage = hasChangedStage
  const setHasChangedCheckpoint = (hasChangedCheckpoint: boolean) => data.hasChangedCheckpoint = hasChangedCheckpoint
  const setIsVisiblePreview = (isVisiblePreview: boolean) => data.isVisiblePreview = isVisiblePreview
  const setEditorContentTabs = (tabs: any[]) => data.editorContentTabs = tabs
  const setActiveEditorContentTab = (activeEditorContentTab) => data.activeEditorContentTab = activeEditorContentTab
  const setActiveDynamicContentTab = (activeDynamicContentTab) => data.activeDynamicContentTab = activeDynamicContentTab
  const setActiveActivityTab = (activeActivityTab) => data.activeActivityTab = activeActivityTab
  const setMdu = (mdu: DocumentUser) => data.mdu = mdu
  const setMau = (mau: AccountUser) => data.mau = mau
  const setMainEditor = (editor: TipTapEditor) => data.mainEditor = editor
  const setWorkingCopy = (workingCopy: WorkingCopy) => data.activeWorkingCopy = workingCopy
  const setIsVisibleSidebar = (isVisibleSidebar: boolean) => data.isVisibleSidebar = isVisibleSidebar
  const setDocumentEditorSession = (editorSession: DocumentEditorSession) => {
    data.documentEditorSession = editorSession
    data.documentEditorSessionObjectSignature = editorSession?.object_signature
  }
  const setMainContentTab = (mainContentTab: MainContentTab) => data.mainContentTab = mainContentTab
  const setMainContentScrollContainer = (mainContentScrollContainer: HTMLDivElement) => data.mainContentScrollContainer = mainContentScrollContainer
  const setMainContentPdfViewer = (mainContentPdfViewer: HTMLDivElement) => data.mainContentPdfViewer = mainContentPdfViewer
  const setTags = (tags: Tag[]) => data.tags = tags
  const setAllTags = (allTags: Tag[]) => data.allTags = allTags
  const setAllTeams = (allTeams: Team[]) => data.allTeams = allTeams
  const setIsActiveEditMode = (state: boolean) => data.isActiveEditMode = state
  const setIsLoadingEditorSession = (state: boolean) => data.isLoadingEditorSession = state
  const setDocumentVisits = (documentVisits: DocumentVisit[]) => data.documentVisits = documentVisits
  const setDocumentWorkflowState = (documentWorkflowState: Record<DocumentWorkflowState, boolean>) => data.documentWorkflowState = documentWorkflowState
  const setIsPdfImportModalOpen = (state: boolean) => data.isPdfImportModalOpen = state

  const updateStoreDocument = (document: Partial<Document>) => {
    const documentCopy = toRaw(data.currentDocument)
    const updatedDocument = {
      ...documentCopy,
      ...document,
    }
    setDocument(updatedDocument)
  }

  const addKeysOfIsUpdatingDocumentField = (keys: (keyof Document)[]) => data.keysOfIsUpdatingDocumentField = uniq([ ...toRaw(data.keysOfIsUpdatingDocumentField), ...keys ])
  const addKeysOfLastSavedDocumentField = (keys: (keyof Document)[]) => data.keysOfLastSavedDocumentField = uniq([ ...toRaw(data.keysOfLastSavedDocumentField), ...keys ])
  const resetKeysOfIsUpdatingDocumentFields = () => data.keysOfIsUpdatingDocumentField = []
  const resetKeysOfLastSavedDocumentField = () => data.keysOfLastSavedDocumentField = []
  const addUpdatingDocument = () => data.isUpdatingDocument = true
  const removeUpdatingDocument = () => data.isUpdatingDocument = false
  const setLastSaved = (ts: number) => data.lastSaved = ts
  const setPdfUrl = (pdfUrl: string) => data.pdfUrl = pdfUrl
  const setHtmlContent = (htmlContent: string) => data.htmlContent = htmlContent
  const setDocumentLastSaved = (ts: number) => data.documentLastSaved = ts
  const setDocumentErrors = (documentErrors: FormErrors<Document>) => data.documentErrors = documentErrors
  const setStageTippy = (stageTippy: Instance<ExtendedTippyProps>[]) => data.stageTippy = stageTippy

  // api actions

  const fetchDocument = async (
    documentUuid: Document["uuid"],
  ): Promise<Document | void> => {
    try {
      data.isLoadingDocument = true
      const updatedDocument = await fetchDocumentAction(documentUuid)

      if (updatedDocument) {
        data.currentDocument = updatedDocument
      }

      return updatedDocument
    } catch (err) {
      notify({
        title: t("documents.errors.fetch"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingDocument = false
    }
  }

  // document CRUD

  const updateDocument = async (
    document: Partial<Document>,
    payloadKeys: (keyof Document)[],
    timestamp: number,
  ): Promise<Document | void> => {
    try {
      const payload = pick(document, payloadKeys)
      const updatedDocument = await updateDocumentAction(document.uuid, payload)
      const onlyTeamUuidsChanged = payloadKeys.length === 1 && payloadKeys[0] === "team_uuids"

      if (updatedDocument) {
        if (!(data.debounceTimestamp > timestamp)) {
          if (onlyTeamUuidsChanged) {
            data.currentDocument.teams = [ ...updatedDocument.teams ]
          } else {
            updateStoreDocument(updatedDocument)
          }
        }
        setDocumentLastSaved(Date.now())
        addKeysOfLastSavedDocumentField(payloadKeys)
        setTimeout(resetKeysOfLastSavedDocumentField, 3000)
        setDocumentErrors({})
      }

      return updatedDocument
    } catch (err) {
      const isBackendError = !!err.response?.data?.errors

      if (isBackendError) {
        setDocumentErrors(err.response.data.errors)
      }
      else {
        notify({
          title: t("documents.errors.update"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    } finally {
      removeUpdatingDocument()
      resetKeysOfIsUpdatingDocumentFields()
    }
  }

  // Function to change the stage of the document
  const changeStage = async (
    documentUuid: Document["uuid"],
    stage: Document["stage"],
  ): Promise<Document> => {
    data.isChangingStage = true
    try {
      const updatedDocument = await updateDocumentAction(documentUuid, { stage: stage })
      if (updatedDocument) {
        setDocument(updatedDocument)
        setHasChangedStage(true)
      }
    } catch (err) {
      notify(
        {
          title: t("documents.errors.changeStage"),
          message: err.response?.data?.message || err.message,
          type: "error",
        },
      )
    } finally {
      data.isChangingStage = false
      return data.currentDocument
    }
  }

  const getDocumentLockState = async (
    documentUuid: Document["uuid"],
  ): Promise<Document | void> => {
    try {
      data.isLoadingLockedState = true
      const updatedDocument = await fetchDocumentAction(documentUuid)

      if (!data.currentDocument) console.warn("No current document found")

      if (updatedDocument) {
        data.currentDocument.is_locked = updatedDocument.is_locked
      }

      return updatedDocument
    } catch (err) {
      notify({
        title: t("documents.errors.fetchLockState"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingLockedState = false
    }
  }

  const fetchRevisions = async (
    documentUuid: Document["uuid"],
  ): Promise<DocumentRevision[] | void> => {

    data.isLoadingRevisions = true

    try {
      const revisions = await fetchRevisionsAction(documentUuid)

      if (revisions && revisions.length) {
        setRevisions(revisions.reverse())
      }

      return data.revisions
    } catch (err) {
      notify({
        title: t("documents.errors.fetchRevisions"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingRevisions = false
    }
  }

  const fetchActivities = async (
    documentUuid: Document["uuid"],
  ) => {
    data.isLoadingActivities = true

    try {
      const activities = await fetchActivitiesAction(documentUuid)

      if (activities && activities.length) {
        data.activities = activities.slice().reverse()
      }

      return data.activities
    } catch (err) {
      notify({
        title: t("documents.errors.fetchActivities"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingActivities = false
    }
  }

  const getDocumentEditorSession = async (documentUuid: Document["uuid"], documentEditorSessionUuid: DocumentEditorSession["uuid"]): Promise<DocumentEditorSession | void> => {
    try {
      data.documentEditorSessionUuidBeingLoaded = documentEditorSessionUuid
      const documentEditorSession = await getDocumentEditorSessionAction(documentUuid, documentEditorSessionUuid)

      if (documentEditorSession) {
        const validTill = new Date(documentEditorSession.valid_till)
        const currentTimestamp = new Date()
        setDocumentEditorSession(validTill > currentTimestamp ? documentEditorSession : null)
      }
      return documentEditorSession
    } catch (err) {
      console.error(err)
    } finally {
      data.documentEditorSessionUuidBeingLoaded = null
    }
  }

  const createDocumentEditorSession = async (
    documentUuid: Document["uuid"],
    commentUuid: Comment["uuid"] = null,
    restoringSession = false,
  ): Promise<DocumentEditorSession | void> => {
    try {
      data.isCreatingEditorSession = true
      const documentEditorSession = await createDocumentEditorSessionAction(documentUuid, commentUuid)

      if (documentEditorSession) {
        setDocumentEditorSession(documentEditorSession)

        if (!restoringSession) {
        // Ensure that the displayed editor content is 100% the same as on the server
          setProsemirrorData(documentEditorSession.prosemirror_data)
          // Check if data.prosemirrorData is equal to JSON.stringify(data.mainEditor.getJSON())
          if (data.mainEditor && !isEqual(data.prosemirrorData, data.mainEditor.getJSON())) {
            data.mainEditor.commands.setContent(data.prosemirrorData)
          }
        } else {
          // When we are restoring the session, we have to eliminate our unsaved changes only if there have been updates on the server side
          if (!isEqual(documentEditorSession.prosemirror_data, editorStore.prosemirrorDataOnServer)) {
            setProsemirrorData(documentEditorSession.prosemirror_data)
            // Check if data.prosemirrorData is equal to JSON.stringify(data.mainEditor.getJSON())
            if (data.mainEditor && !isEqual(data.prosemirrorData, data.mainEditor.getJSON())) {
              data.mainEditor.commands.setContent(data.prosemirrorData)
            }
          }
        }

        editorStore.setProsemirrorDataOnServer(documentEditorSession.prosemirror_data)
      }

      return data.documentEditorSession
    } catch (err) {
      if (err.code === "ERR_NETWORK") return
      // To do: handle error when another editor session blocks the request
      notify(
        {
          title: t("documents.errors.createEditorSession"),
          message: err.response?.data?.message || err.message,
          type: "error",
        },
      )
    } finally {
      data.isCreatingEditorSession = false
    }
  }

  const updateDocumentEditorSession = async (
    documentUuid: Document["uuid"],
    documentEditorSessionUuid: DocumentEditorSession["uuid"],
    objectSignature: string,
  ): Promise<DocumentEditorSession | void> => {
    try {
      const documentEditorSession = await updateDocumentEditorSessionAction(documentUuid, documentEditorSessionUuid, { object_signature: objectSignature })
      if (documentEditorSession) {
        setDocumentEditorSession({ ...toRaw(data.documentEditorSession), ...documentEditorSession })
      } else {
        setDocumentEditorSession(null)
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") return
      setDocumentEditorSession(null)
      setIsActiveEditMode(false)

      notify(
        {
          title: t("documents.errors.updateEditorSession"),
          message: err.response?.data?.message || err.message,
          type: "error",
        },
      )
    }
  }

  const destroyDocumentEditorSession = async (
    documentUuid: Document["uuid"],
    documentEditorSessionUuid: DocumentEditorSession["uuid"],
  ): Promise<boolean | void> => {
    data.isLoadingDestroyDocumentEditorSession = true
    try {
      const res = await destroyDocumentEditorSessionAction(documentUuid, documentEditorSessionUuid)
      setDocumentEditorSession(null)
      setIsActiveEditMode(false)
      return !!(res)
    } catch (err) {
      notify({
        title: t("documents.errors.destroyEditorSession"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingDestroyDocumentEditorSession = false
    }
  }

  const saveDocumentEditorContent = async (
    documentUuid: Document["uuid"],
    documentEditorSessionUuid: DocumentEditorSession["uuid"],
  ): Promise<DocumentEditorSession | void> => {
    if (!data.documentEditorSession?.uuid) return

    data.isLoadingSaveDocumentContent = true

    const normalizedProsemirrorData = JSON.parse(JSON.stringify(data.prosemirrorData || {}).normalize("NFC")) as JSONContent

    try {
      const payload = {
        object_signature: data.documentEditorSessionObjectSignature,
        prosemirror_data: normalizedProsemirrorData,
      }

      const documentEditorSession = await updateDocumentEditorSessionAction(documentUuid, documentEditorSessionUuid, payload)

      if (documentEditorSession) {
        setLastSaved(Date.now())
        editorStore.setProsemirrorDataOnServer(normalizedProsemirrorData)
        fetchDocumentVariables()
      }

      return documentEditorSession
    } catch (err) {
      if (err.message !== "canceled") {
        notify({
          title: t("documents.errors.saveEditorContent"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }
    } finally {
      data.isLoadingSaveDocumentContent = false
    }
  }

  // Function to load most recent editor (ProseMirror) content
  const fetchEditorContent = async (
    documentUuid: Document["uuid"],
    afterInit = false,
  ) => {
    data.isLoadingEditorContent = true

    try {
      const res = await fetchEditorContentAction(documentUuid)

      if (res) {
        setProsemirrorData(res)
        editorStore.setProsemirrorDataOnServer(res)

        // pdfContent and htmlContent reset is necessary when going back from "signing" to "review"
        setPdfUrl(null)
        setHtmlContent(null)

        if (!afterInit) return
        data.mainEditor?.commands.setContent(res)
      }
    } catch (err) {
      notify({
        title: t("documents.errors.fetchEditorContent"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingEditorContent = false
    }
  }

  // Function to load most recent HTML content
  const fetchHtmlContent = async (
    documentUuid: Document["uuid"],
  ): Promise<string | void> => {
    data.isLoadingHtmlContent = true
    try {
      const res = await fetchHtmlContentAction(documentUuid)
      if (res) {
        setHtmlContent(res)
      }
      return res
    } catch (err) {
      notify({
        title: t("documents.errors.fetchHtmlContent"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingHtmlContent = false
    }
  }

  // Function to load most recent PDF content
  const fetchPdfUrl = async (
    documentUuid: Document["uuid"],
  ): Promise<string | void> => {

    data.isLoadingPdfUrl = true

    try {
      const res = await fetchPdfUrlAction(documentUuid)
      if (res && res !== data.pdfUrl) {
        setPdfUrl(res)
        if (data.currentDocument?.origin === DocumentOrigin.pdf) {
          try {
            const signedPdfBlob = await readPdfFile(res)
            if (!signedPdfBlob) return
            data.signedPdfSha256 = await getSha256Hash(signedPdfBlob)
          } catch (err) {
            notify({
              title: t("documents.errors.readPdfFile"),
              message: err.response?.data?.message || err.message,
              type: "error",
            })
          }
        }
      }
      return res
    } catch (err) {
      notify({
        title: t("documents.errors.fetchPdfUrl"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingPdfUrl = false
    }
  }

  // Function to get a working copy
  const getWorkingCopy = async (documentUuid: Document["uuid"], workingCopyUuid: WorkingCopy["uuid"]): Promise<WorkingCopy | void> => {
    try {
      data.workingCopyUuidBeingLoaded = workingCopyUuid
      const workingCopy = await getWorkingCopyAction(documentUuid, workingCopyUuid)
      if (workingCopy) {
        setWorkingCopy(workingCopy)
      }
      return workingCopy
    } catch (err) {
      console.error(err)
    } finally {
      data.workingCopyUuidBeingLoaded = null
    }
  }

  // Function to create a working copy
  const createWorkingCopy = async (
    documentUuid: Document["uuid"],
  ): Promise<WorkingCopy | void> => {
    data.isLoadingWorkingCopy = true

    try {
      const res = await createWorkingCopyAction(documentUuid)
      if (res) {
        data.activeWorkingCopy = res
        return res
      }
    } catch (err) {
      notify({
        title: t("documents.errors.createWorkingCopy"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingWorkingCopy = false
    }
  }

  // Function to publish a working copy
  const publishWorkingCopy = async (
    documentUuid: Document["uuid"],
    workingCopyUuid: WorkingCopy["uuid"],
  ): Promise<WorkingCopy | void> => {
    data.isLoadingWorkingCopy = true
    try {
      const res = await publishWorkingCopyAction(documentUuid, workingCopyUuid)
      if (res) {
        data.activeWorkingCopy = null
      }
    } catch (err) {
      notify({
        title: t("documents.errors.publishWorkingCopy"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingWorkingCopy = false
    }
  }

  // ui actions

  const debouncedUpdateDocument = debounce(updateDocument, 1000)

  const updateLocalDocument = (
    document: Document | Partial<Document>,
  ) => {
    if (!document) return

    const changedKeysOfDocument = changedKeys(data.currentDocument, document) as (keyof Document)[]

    updateStoreDocument(document)

    const timestamp = Date.now()
    if (data.debounceUuid !== document.uuid) {
      debouncedUpdateDocument?.flush()
      data.debounceUuid = document.uuid
    }
    data.debounceTimestamp = timestamp


    addUpdatingDocument()
    addKeysOfIsUpdatingDocumentField(changedKeysOfDocument)
    debouncedUpdateDocument(
      document,
      changedKeysOfDocument,
      timestamp,
    )
  }

  const generateAiIntro = async (documentUuid: Document["uuid"]) => {
    data.isLoadingGenerateAiIntro = true
    try {
      const res = await generateAiIntroAction(documentUuid)
      if (res) {
        if (res.text) updateLocalDocument({ ...toRaw(data.currentDocument), intro: res.text })
        if (res.error) console.error(res.error)
      }
    } catch (err) {
      notify({
        title: t("documents.errors.generateAiIntro"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingGenerateAiIntro = false
    }
  }

  const fetchDocumentVariables = async (): Promise<DocumentVariable[] | void> => {
    try {
      if (!data.currentDocument?.uuid) return
      data.isLoadingDocumentVariables = true
      const documentVariables = await fetchDocumentVariablesAction(data.currentDocument.uuid)

      if (documentVariables) {
        setDocumentVariables(documentVariables)
      }

      return documentVariables
    } catch (err) {
      notify({
        title: t("documents.errors.fetchDocumentVariables"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingDocumentVariables = false
    }
  }

  const getPdfDownload = async (documentUuid: Document["uuid"], documentRevisionUuid: DocumentRevision["uuid"] = null): Promise<string | void> => {
    let currentRevisionUuid = documentRevisionUuid
    if (!documentRevisionUuid) {
      const revisions = await fetchRevisions(documentUuid)
      if (revisions && revisions.length) {
        currentRevisionUuid = revisions[0].uuid
      }
    }
    if (!currentRevisionUuid) return
    data.isLoadingPdfDownload[currentRevisionUuid] = true
    try {
      const getUrlRes = await getPdfDownloadAction(documentUuid, currentRevisionUuid)
      if (!getUrlRes) return
      const pdfUrl = getUrlRes.pdf_url
      // location.href = pdfUrl
      window.open(
        pdfUrl,
        "_blank", // <- This is what makes it open in a new window.
        "noopener,noreferrer",
      )
      return pdfUrl
    } catch (err) {
      notify({
        title: t("documents.errors.getPdfDownload"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingPdfDownload[currentRevisionUuid] = false
    }
  }

  const getHTML = (documentRevisionUuid: DocumentRevision["uuid"]) => {
    if (!documentRevisionUuid || !import.meta.env.DEV) return

    window.open(
      `/admin/document-revision/${documentRevisionUuid}/html`,
      "_blank",
      "noopener,noreferrer",
    )
  }

  const toggleEditMode = async (state: boolean, callback: () => Promise<boolean | void>) => {
    if (data.currentDocument?.content_type === DocumentContentType.pdf) return
    if (state === data.isActiveEditMode) return
    setIsLoadingEditorSession(true)
    if (state && !data.documentEditorSession?.uuid) {
      const createDocumentEditorSessionRes = await createDocumentEditorSession(data.currentDocument?.uuid)
      if (createDocumentEditorSessionRes) {

        const validTill = new Date(createDocumentEditorSessionRes.valid_till)
        const currentTimestamp = new Date()
        setIsActiveEditMode(validTill > currentTimestamp && createDocumentEditorSessionRes.object_signature === data.documentEditorSessionObjectSignature)

        setDocumentEditorSessionInterval()

        setActiveTabKey(DocumentTab.documentContent)

        data.saveDocumentContentKeyListener = (e: KeyboardEvent) => {
          if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            callback()
          }
        }

        window.document.addEventListener("keydown", data.saveDocumentContentKeyListener.bind(this))

      }
      setIsLoadingEditorSession(false)
    } else if (!state && data.documentEditorSession?.uuid && data.documentEditorSession?.document_user_uuid === data.mdu?.uuid && editorStore.isDirty) {
      confirmDocumentEditorContentIsDirtyState(async () => {
        const handleSaveDocumentEditorContentRes = await callback()
        if (handleSaveDocumentEditorContentRes) handleCancelEditMode()
      }, async () => {
        handleCancelEditMode()
        fetchEditorContent(data.currentDocument?.uuid, true)
      })
    } else {
      handleCancelEditMode()
    }

  }

  const handleCancelEditMode = async () => {
    window.document.removeEventListener("keydown", data.saveDocumentContentKeyListener)
    if (data.currentDocument?.uuid && data.documentEditorSession?.uuid && data.documentEditorSession?.document_user_uuid === data.mdu?.uuid) {
      setIsLoadingEditorSession(true)
      await destroyDocumentEditorSession(data.currentDocument?.uuid, data.documentEditorSession.uuid)
    }
    if (data.keepAliveDocumentEditorSessionTimerId) clearInterval(data.keepAliveDocumentEditorSessionTimerId)
    setActiveTabKey(DocumentTab.general)
    setIsActiveEditMode(false)
    setIsLoadingEditorSession(false)
    resetEditModeTimer()
  }

  const setDocumentEditorSessionInterval = () => {
    data.keepAliveDocumentEditorSessionTimerId = setInterval(() => {
      if (data.isLoadingDestroyDocumentEditorSession) return
      const isValid = Date.parse(data.documentEditorSession?.valid_till) > Date.now()
      if (data.documentEditorSession && isValid) {
        updateDocumentEditorSession(data.currentDocument.uuid, data.documentEditorSession.uuid, data.documentEditorSessionObjectSignature)
      }
      else if (data.documentEditorSession && !isValid) {
        createDocumentEditorSession(data.currentDocument.uuid, null, true)
      }
      else {
        clearInterval(this)
      }
    }, 10000)
  }

  const confirmDocumentEditorContentIsDirtyState = (callback: () => void, secondaryCallback: () => void) => {
    if (!editorStore.isDirty) return
    setConfirmOptions({
      title: t("common.unsavedChanges"),
      description: t("documents.saveChangesPrompt"),
      buttonText: t("common.saveChanges"),
      secondaryButtonText: t("common.discardChanges"),
      callback: callback,
      secondaryCallback: secondaryCallback,
      cancelCallback: () => {
        setIsLoadingEditorSession(false)
      },
    })
    setShowConfirmModal(true)
  }

  const removeIsDirtyHandler = () => {
    editorStore.setIsDirty(false)
    return true
  }

  const startEditModeTimer = () => {
    data.lastSaved = Date.now()
    data.editModeTimer = setInterval(() => {
      data.editModeTimerHelper = Date.now()
    }, 1000)
  }

  const resetEditModeTimer = () => {
    data.lastSaved = null
    clearInterval(data.editModeTimer)
    data.editModeTimerHelper = null
  }

  const editModeTimerCount = computed(() => {
    if (!data.lastSaved || !data.editModeTimerHelper) return null
    return Math.floor((data.editModeTimerHelper - data.lastSaved) / 1000)
  })

  const handleSaveDocumentEditorContent = async (): Promise<boolean | void> => {
    if (!data.mainEditor) return
    if (!data.isLoadingEditorContent && (data.mainEditor.isEditable || data.isLoadingEditorSession)) {
      data.isLoadingSaveDocumentContent = true
      const res = await saveDocumentEditorContent(data.currentDocument?.uuid, data.documentEditorSession?.uuid)
      if (!res) return false
      removeIsDirtyHandler()
      resetEditModeTimer()
      return true
    }
  }

  const syncSigningOrder = async (documentUuid: Document["uuid"], signingOrder: DocumentUser["uuid"][]) => {
    data.isSyncingSigningOrder = true
    try {
      await syncSigningOrderAction(documentUuid, signingOrder)
    } catch (err) {
      notify({
        title: t("documents.errors.syncSigningOrder"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isSyncingSigningOrder = false
    }
  }

  const missingDocumentVariablesBackend = computed<DocumentVariable[]>(() => {
    if (!data.documentVariables) {
      return []
    }
    // We filter out document users, as the first and last name is an exception
    // that is not required in order to start the signing phase
    return data.documentVariables.filter((docVar: DocumentVariable) => {
      // documentVariables are only relevant when:
      // 1) they are not a placeholder, because they are duplicates
      // 2) they have no value set
      // 3) they have a counter > 0, meaning they are used in the document
      // 4) they are not a document user, because they can remain empty (first and last name)
      return !docVar.value && docVar.content_counter > 0 && docVar.class !== "App\\Models\\DocumentUser"
    })
  })

  const missingDocumentVariablesFrontend = computed(() => {
    const missingPartyData = partyStore.pendingPartiesIncludingExternallyChangeable?.map(
      (el) => (
        {
          uuid: el.uuid,
          key: el.name,
          value: buildMissingPartyDataValue(el),
          entityType: "party",
        }
      ),
    )
    //const missingDynamicFields = dynamicFieldStore.emptyDynamicFields?.map(
    //  (el) => (
    //    {
    //      uuid: el.uuid,
    //      key: el.name,
    //      value: "value",
    //      entityType: "dynamicField",
    //    }
    //  ),
    //)
    // dynamicFields are now only triggering a missing data alert when they are mandatory, so this is simplified
    const missingDynamicFields = dynamicFieldStore.pendingDynamicFieldsIncludingExternallyChangeable.map(
      (el) => (
        {
          uuid: el.uuid,
          key: el.name,
          value: "value",
          entityType: "dynamicField",
        }
      ),
    )
    const concatenatedResult = Array.isArray(missingPartyData) ? missingPartyData.concat(missingDynamicFields) : Array.isArray(missingDynamicFields) ? missingDynamicFields : []
    return concatenatedResult
  })

  const missingDataFoundInVariables = computed(() => {
    let returnResult = []
    for (const missingDataEntry of missingDocumentVariablesFrontend.value) {
      const found = missingDocumentVariablesBackend.value.find((missingDocVar) => {
        let entityMatch = false
        if (missingDataEntry.entityType === "party") {
          entityMatch = !!(missingDocVar.class === "App\\Models\\Party")
        }
        // Backend document variables for dynamic fields are not longer used
        // else if (missingDataEntry.entityType === "dynamicField") {
        //  entityMatch = !!(missingDocVar.class === "App\\Models\\DynamicField")
        // }
        if (!entityMatch) return false
        const idMatch = !!(missingDocVar.uuid === missingDataEntry.uuid)
        const computedFrontendValues = missingDataEntry.value?.split(", ")
        const valueMatch = computedFrontendValues.includes(missingDocVar.field)
        return !!(idMatch && valueMatch)
      })
      || (missingDataEntry.entityType === "party" && missingDataEntry.key?.includes("entity_type"))
      //|| (missingDataEntry.entityType === "dynamicField" && dynamicFieldStore.pendingDynamicFieldsIncludingExternallyChangeable?.some((el) => el.uuid === missingDataEntry.uuid))
      // dynamicFields are now only triggering a missing data alert when they are mandatory, so this is simplified
      || (missingDataEntry.entityType === "dynamicField")
      if (found) {
        returnResult = [ ...returnResult, missingDataEntry ]
      }
    }
    return returnResult
  })

  const checkForMissingData = async () => {
    await fetchDocumentVariables()
    return !!(missingDataFoundInVariables.value?.length)
  }

  const checkForReferences = async (referenceType: "party" | "dynamicField", referenceUuid: Party["uuid"] | DynamicField["uuid"]) => {
    await fetchDocumentVariables()
    const referenceClass = referenceType === "party" ? "App\\Models\\Party" : "App\\Models\\DynamicField"
    const found = data.documentVariables?.some((docVar) => docVar.class === referenceClass && docVar.content_counter > 0 && docVar.uuid === referenceUuid)
    return !!(found)
  }

  const showReferenceAlert = async (
    referenceType: "party" | "dynamicField",
    referenceRefUuid: Party["ref_uuid"] | DynamicField["ref_uuid"],
    referenceSource: "content" | "metadataValue" | "condition" = "content",
    referenceUuid: Condition["uuid"] | MetadataValue["uuid"],
  ) => {
    setConfirmOptions({
      title: t(`documentStatusBanner.referenceAlert.${ referenceType }.${ referenceSource }.title`),
      description: t(`documentStatusBanner.referenceAlert.${ referenceType }.${ referenceSource }.description`),
      buttonText: t(`documentStatusBanner.referenceAlert.${ referenceType }.${ referenceSource }.buttonText`),
      callback: () => {
        // Depending on the referenceType, jump to the reference in the editor
        if (referenceType === "party" && referenceSource === "content") {
          partyStore.jumpToParty(referenceRefUuid)
        } else if (referenceType === "dynamicField" && referenceSource === "content") {
          dynamicFieldStore.jumpToDynamicField(referenceRefUuid)
        } else if (referenceType === "dynamicField" && referenceSource === "condition") {
          conditionStore.jumpToCondition(referenceUuid)
        }
        return true
      },
    })
    setShowConfirmModal(true)
  }

  const buildMissingPartyDataValue = (el: Party) => {
    let keys = []
    if (!el.entity_name) keys.push("entity_name")
    if (!el.address) keys.push("address")
    if (!el.entity_type) keys.push("entity_type")
    if (keys?.length && !userStore.users?.some((du) => du.party_uuid === el.uuid)) keys = [ t("documentStatusBanner.assignedUsers"), ...keys ]
    const string = keys.join(", ")
    return string || ""
  }

  const showOptionalButReferencedDynamicFieldsAlert = async (callback: () => Promise<any>) => {
    setConfirmOptions({
      title: t("documentStatusBanner.optionalButReferencedDynamicFields.title"),
      description: t("documentStatusBanner.optionalButReferencedDynamicFields.description"),
      icon: VariableIcon,
      hasActionButtons: true,
      data: dynamicFieldStore.optionalButReferencedDynamicFieldsWithoutValue?.map(
        (el) => {
          const usageString = t("documentStatusBanner.optionalButReferencedDynamicFields.usage", el.countOfIsValueUsedInDocument)
          const returnValue = {
            key: el.name,
            value: usageString,
            callback: () => {
              dynamicFieldStore.jumpToDynamicField(el.ref_uuid, true)
              setShowConfirmModal(false)
              return true
            },
            buttonText: t("documentStatusBanner.optionalButReferencedDynamicFields.jumpToDynamicField"),
          }
          return returnValue
        },
      ),
      buttonText: t("documentStatusBanner.optionalButReferencedDynamicFields.button"),
      callback: callback,
    })
    setShowConfirmModal(true)
  }

  const showMissingDataAlert = async () => {
    setConfirmOptions({
      title: t("documentStatusBanner.missingData.title"),
      description: t("documentStatusBanner.missingData.description"),
      data: missingDataFoundInVariables.value,
      buttonText: t("documentStatusBanner.missingData.button"),
      callback: () => {
        if (dynamicFieldStore.pendingDynamicFieldsIncludingExternallyChangeable?.[0]?.ref_uuid) {
          dynamicFieldStore.jumpToDynamicField(dynamicFieldStore.pendingDynamicFieldsIncludingExternallyChangeable?.[0]?.ref_uuid)
        } else if (dynamicFieldStore.emptyDynamicFields?.[0]?.uuid) {
          // This happens when BE has not informed us that a dynamic field has become mandatory through being used in the content
          dynamicFieldStore.jumpToDynamicField(dynamicFieldStore.emptyDynamicFields?.[0]?.ref_uuid)
        } else if (partyStore.pendingPartiesIncludingExternallyChangeable?.[0]?.uuid) {
          partyStore.jumpToParty(partyStore.pendingPartiesIncludingExternallyChangeable?.[0]?.ref_uuid)
        }
        return true
      },
    })
    setShowConfirmModal(true)
  }

  const showUnplacedSignatureBlockAlert = () => {
    let missingDataSignatureBlocks: SignatureBlock[] = []
    if (data.currentDocument?.content_type === DocumentContentType.pdf && signatureStore.unplacedSignatureBlocks?.length) {
      missingDataSignatureBlocks = signatureStore.unplacedSignatureBlocks
    } else if (data.currentDocument?.content_type === DocumentContentType.prosemirror_data && data.prosemirrorData) {
      const signatureBlocksFiltered = signatureStore.signatureBlocks?.filter((el: SignatureBlock) => !el.deleted_at)
      missingDataSignatureBlocks = filter(signatureBlocksFiltered, (sb) => {
        const check = !some(editorStore.refUuidsOfSignaturesInEditor, (el) => el === sb.ref_uuid)
        return check
      })
    }
    const missingData = missingDataSignatureBlocks?.map(
      (el: SignatureBlock) => (
        {
          key: partyStore.parties.find((p) => p.uuid === el.party_uuid)?.entity_name + (partyStore.parties.find((p) => p.uuid === el.party_uuid)?.entity_name ? " (" + (partyStore.parties.find((p) => p.uuid === el.party_uuid)?.entity_name) + ")" : ""),
          value: signatureStore.signatories.filter((s) => s.party_uuid === el.party_uuid).map((el: Signatory) => getUserRepresentation(el)).join(", "),
        }
      ),
    )
    setConfirmOptions({
      title: t("documentStatusBanner.unplacedSignature.title"),
      description: t("documentStatusBanner.unplacedSignature.description"),
      data: missingData,
      buttonText: t("documentStatusBanner.unplacedSignature.button"),
      callback: () => {
        toggleEditMode(true, handleSaveDocumentEditorContent)
        setActiveTabKey(DocumentTab.documentContent)
        return true
      },
    })
    setShowConfirmModal(true)
  }

  const showDocumentWorkflowStateAlert = () => {
    setConfirmOptions({
      title: t("documentStatusBanner.workflowStateAlert.title"),
      description: t("documentStatusBanner.workflowStateAlert.description"),
      buttonText: t("documentStatusBanner.workflowStateAlert.button"),
      icon: InformationCircleIcon,
      callback: () => {
        // Open discussions tab
        setActiveTabKey(DocumentTab.discussions)
        // Close modal
        setShowConfirmModal(false)
      },
    })
    setShowConfirmModal(true)
  }

  const showPreview = async (documentUuid: Document["uuid"]) => {
    data.isLoadingPreviewContent = true
    try {
      const previewContentRes = await fetchHtmlContent(documentUuid)
      if (previewContentRes) {
        data.previewContent = previewContentRes
        setIsVisiblePreview(true)
      }
    }
    finally {
      data.isLoadingPreviewContent = false
    }
  }

  // Function to refresh the stage of the document after receiving a pusher event
  const refreshStage = async (): Promise<Document | void> => {
    data.isLoadingRefreshStage = true
    try {
      const showDocumentRes = await fetchDocumentAction(data.currentDocument?.uuid)
      if (!showDocumentRes) return
      setDocument(showDocumentRes)
      const newContentType = showDocumentRes.content_type
      // Refresh HTML for document if needed
      let contentRes: string | void
      if (newContentType === "prosemirror_data") {
        contentRes = await fetchEditorContent(data.currentDocument?.uuid, true)
      } else if (newContentType === "html") {
        contentRes = await fetchHtmlContent(data.currentDocument?.uuid)
      } else if (newContentType === "pdf") {
        contentRes = await fetchPdfUrl(data.currentDocument?.uuid)
      }
      const signingPhase = await refreshSigningPhase()
      signatureStore.setActiveSigningPhase(signingPhase)
      if (showDocumentRes?.stage === DocumentStage.signing) setActiveTabKey(DocumentTab.signatures)
      if (signingPhase && contentRes) signatureStore.bindEventsOnSignatureBlocks()
      // Show UI hint if it has not been shown before
      const uiHintType: string = stageUiHintMap[showDocumentRes.stage]
      if (!!data.mdu && !sharedStore.uiHintCollection?.some((el) => el.type === uiHintType)) {
        setTimeout(
          () => {
            const element = window.document.getElementById("stageIndicator_" + showDocumentRes.stage)
            if (!element) return
            setStageTippy(showStageTippy(
              showDocumentRes.stage,
              data.stageTippy,
              sharedStore.uiHintCollection,
              data.currentDocument?.uuid,
            ))
          },
          300,
        )
      }
      return showDocumentRes
    } catch (err) {
      notify({
        title: t("documents.errors.refreshStage"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingRefreshStage = false
      // Set helper (prevents loader flickerin cross-method) to false again
      setHasChangedStage(false)
    }
  }

  const refreshSigningPhase = async (): Promise<SigningPhase | null> => {
    data.isLoadingRefreshSigningPhase = true
    try {
      const getSigningPhasesRes = await fetchDocumentSigningPhasesAction(data.currentDocument?.uuid)
      if (!getSigningPhasesRes) return
      signatureStore.setSigningPhases(getSigningPhasesRes)
      return getSigningPhasesRes.find((el) => el.is_active) || null
    } catch (err) {
      notify({
        title: t("documents.errors.refreshSigningPhase"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingRefreshSigningPhase = false
    }
    return null
  }

  const refreshDocumentWorkflowState = async () => {
    data.isLoadingRefreshDocumentWorkflowState = true
    try {
      const getDocumentWorkflowStateRes = await fetchDocumentWorkflowStateAction(data.currentDocument?.uuid)
      if (!getDocumentWorkflowStateRes) return
      setDocumentWorkflowState(getDocumentWorkflowStateRes)
    } catch (err) {
      notify({
        title: t("documents.errors.refreshDocumentWorkflowState"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingRefreshDocumentWorkflowState = false
    }
  }

  // Update the document content depending on content_type
  const handleUpdateDocumentContent = async (afterInit: boolean) => {
    let res: string | void
    // Re-fetch document content based on content type
    if (data.currentDocument.content_type === "pdf") res = await fetchPdfUrl(data.currentDocument?.uuid)
    else if (data.currentDocument.content_type === "html" || data.currentDocument.stage === DocumentStage.signing || data.currentDocument.stage === DocumentStage.signed) res = await fetchHtmlContent(data.currentDocument?.uuid)
    else if (data.currentDocument.content_type === "prosemirror_data") res = await fetchEditorContent(data.currentDocument?.uuid, afterInit)
    if (res) signatureStore.bindEventsOnSignatureBlocks()
  }

  // tags
  const addTag = async (documentUuid: Document["uuid"], tagUuid: Tag["uuid"]) => {
    data.isUpdatingTags = true
    try {
      const tags = await addTagAction(documentUuid, tagUuid)
      if (tags) {
        setTags(tags)
      }
    } catch (err) {
      notify({
        title: t("documents.errors.addTag"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isUpdatingTags = false
    }
  }

  const removeTag = async (documentUuid: Document["uuid"], tagUuid: Tag["uuid"]) => {
    data.isUpdatingTags = true
    try {
      const tags = await removeTagAction(documentUuid, tagUuid)
      if (tags) {
        setTags(tags)
      }
    } catch (err) {
      notify({
        title: t("documents.errors.removeTag"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isUpdatingTags = false
    }
  }

  const adjustEditorSessionTimeoutCounter = () => {
    if (!data.documentEditorSession) return null
    const validTill = new Date(data.documentEditorSession.valid_till)
    const currentTimestamp = new Date()
    // Return difference in seconds
    const timeoutValue = Math.floor((validTill.getTime() - currentTimestamp.getTime()) / 1000)
    // Return 0 if timeoutValue is negative
    setEditorSessionTimeoutInSeconds(timeoutValue > 0 ? timeoutValue : 0)
  }

  const setEditorSessionTimeoutInterval = () => {
    data.editorSessionTimeoutInterval = setInterval(() => {
      adjustEditorSessionTimeoutCounter()
    }, 1000)
  }

  const clearEditorSessionTimeoutInterval = () => {
    clearInterval(data.editorSessionTimeoutInterval)
  }

  return {
    ...toRefs(data),
    unplacedSignatureBlock,
    isLockedDocument,
    editModeTimerCount,
    documentSigningCriteriaMet,

    // mutations
    setDocument,
    setProsemirrorData,
    setActiveTabKey,
    setEditorContentTabs,
    setActiveEditorContentTab,
    setActiveDynamicContentTab,
    setActiveActivityTab,
    setMdu,
    setMau,
    setMainContentScrollContainer,
    setMainContentPdfViewer,
    setMainEditor,
    updateStoreDocument,
    setDocumentErrors,
    setDocumentLastSaved,
    setLastSaved,
    setWorkingCopy,
    setDocumentEditorSession,
    setTags,
    setAllTags,
    setAllTeams,
    setIsActiveEditMode,
    setIsLoadingEditorSession,
    setHasChangedStage,
    setHasChangedCheckpoint,
    setDocumentVariables,
    setIsVisiblePreview,
    setPdfUrl,
    setHtmlContent,
    setMainContentTab,
    setStageTippy,
    setIsVisibleSidebar,
    setDocumentVisits,
    setDocumentWorkflowState,
    setIsPdfImportModalOpen,

    // getters
    getMainContentScrollContainer,
    getMainEditor,
    getDocument,
    getMdu,

    // api actions
    fetchDocument,

    // document CRUD
    updateDocument,
    getDocumentLockState,
    fetchRevisions,
    fetchActivities,
    fetchDocumentVariables,

    saveDocumentEditorContent,
    changeStage,

    // editor session
    getDocumentEditorSession,
    createDocumentEditorSession,
    updateDocumentEditorSession,
    destroyDocumentEditorSession,

    // working copy
    getWorkingCopy,
    createWorkingCopy,
    publishWorkingCopy,

    // document content actions
    fetchEditorContent,
    fetchHtmlContent,
    fetchPdfUrl,

    // signing order
    syncSigningOrder,

    // ui actions
    updateLocalDocument,
    generateAiIntro,
    getPdfDownload,
    toggleEditMode,
    removeIsDirtyHandler,
    resetEditModeTimer,
    startEditModeTimer,
    checkForReferences,
    showReferenceAlert,
    checkForMissingData,
    showMissingDataAlert,
    showOptionalButReferencedDynamicFieldsAlert,
    showUnplacedSignatureBlockAlert,
    handleSaveDocumentEditorContent,
    showPreview,
    refreshStage,
    refreshSigningPhase,
    refreshDocumentWorkflowState,
    showDocumentWorkflowStateAlert,
    handleUpdateDocumentContent,
    setEditorSessionTimeoutInterval,
    clearEditorSessionTimeoutInterval,
    getHTML,

    // tags
    addTag,
    removeTag,
  }
})
