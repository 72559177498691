<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M11,9h2V7h-2V9 M11,17h2v-6h-2V17z" />
    <path d="M5.9,9h2V7h-2V9 M5.9,17h2v-6h-2V17z" />
    <path d="M16.1,9h2V7h-2V9 M16.1,17h2v-6h-2V17z" />
  </svg>
</template>