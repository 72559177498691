import axios from "axios"

import { Condition, Document, Template, CrudContext } from "~/types"

export const fetchConditionsAction = async (
  context: CrudContext,
  entityUuid: Document["uuid"] | Template["uuid"],
): Promise<Condition[] | void> => {
  const routeString = `api.${context}s.conditions.index`
  const url = route(routeString, entityUuid)

  try {
    const fetchConditionsRes = await axios.get<{ data: Condition[] }>(url)

    return fetchConditionsRes.data.data
  } catch (err) {
    console.error(err)
  }
}

// condition CRUD
export const createConditionAction = async (
  entityUuid: Template["uuid"],
  conditionToCreate: Partial<Condition>,
): Promise<Condition | void> => {
  const apiUrl = route(`api.templates.conditions.store`, entityUuid)

  const createConditionRes = await axios.post<{ data: Condition }>(apiUrl, conditionToCreate)

  return createConditionRes.data.data
}

export const updateConditionAction = async (
  entityUuid: Template["uuid"],
  payload: Partial<Condition>,
  conditionUuid: Condition["uuid"],
): Promise<Condition | void> => {
  const apiUrl = `api.templates.conditions.update`

  const routeData = {
    condition: conditionUuid,
    template: entityUuid,
  }

  const url = route(apiUrl, routeData)

  const updateConditionRes = await axios.patch<{data: Condition}>(url, payload)

  return updateConditionRes.data.data
}

export const removeConditionAction = async (
  entityUuid: Template["uuid"],
  conditionUuidToRemove: Condition["uuid"],
): Promise<Condition | void> => {
  const apiUrl = `api.templates.conditions.destroy`

  const payload = { template: entityUuid, condition: conditionUuidToRemove }

  const removeConditionRes = await axios.delete<{ data: Condition }>(route(apiUrl, payload))

  return removeConditionRes.data.data
}
