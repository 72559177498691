// external
import { router } from "@inertiajs/vue3"
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"

// internal
import { useConfirmationStore, useNotificationStore, useAccountStore } from "~/stores"

export function useEntityActions (entities) {
  if (!entities) {
    console.error("No namespace for entities declared!")
    return
  }

  const confirmationStore = useConfirmationStore()
  const { setShowConfirmModal, setConfirmOptions } = confirmationStore

  const notificationStore = useNotificationStore()
  const { addToasterNotification } = notificationStore

  const accountStore = useAccountStore()
  const { planFeatures, accountUsage } = storeToRefs(accountStore)

  const { t } = useI18n()

  const isSubmitting = ref(false)
  const showAssignTeamModal = ref(false)
  const showManageTeamsModal = ref(false)
  const showManageTagsModal = ref(false)
  const showManageMetadataModal = ref(false)
  const showLimitExceedModal = ref(false)
  const localEntityUuids = ref([])
  const localAll = ref(false)
  const localFilterData = ref(null)

  interface EntityAcitionParams {
    entityUuids?: string[]
    all?: boolean
    total?: number
    filterData?: any
  }

  const confirmEntityDeletion = ({ entityUuids, all = false, total, filterData = null }:EntityAcitionParams = {}) => {
    localEntityUuids.value = entityUuids
    localAll.value = all
    localFilterData.value = filterData

    setConfirmOptions({
      title: t(`${entities}.actions.confirmDelete.title`),
      description: t(`${entities}.actions.confirmDelete.description`, all ? total : entityUuids.length),
      buttonText: t("common.confirm"),
      callback: entityUuids.length === 1 ? deleteEntity : bulkDelete,
    })
    setShowConfirmModal(true)
  }

  const confirmEntityArchiving = ({ entityUuids, all = false, total, filterData = null }:EntityAcitionParams = {}) => {
    localEntityUuids.value = entityUuids
    localAll.value = all
    localFilterData.value = filterData

    setConfirmOptions({
      title: t(`${entities}.actions.confirmArchive.title`),
      description: t(`${entities}.actions.confirmArchive.description`, all ? total : entityUuids.length),
      buttonText: t("common.confirm"),
      callback: entityUuids.length === 1 ? archiveEntity : bulkArchive,
    })
    setShowConfirmModal(true)
  }

  const confirmEntityRestoration = ({ entityUuids, all = false, total, filterData = null }:EntityAcitionParams = {}) => {
    localEntityUuids.value = entityUuids
    localAll.value = all
    localFilterData.value = filterData

    if (entities === "documents" && (accountUsage.value.documents + entityUuids.length) > planFeatures.value?.documents && planFeatures.value?.documents !== 0) {
      showLimitExceedModal.value = true
      return false
    }

    setConfirmOptions({
      title: t(`${entities}.actions.confirmRestore.title`),
      description: t(`${entities}.actions.confirmRestore.description`, all ? total : entityUuids.length),
      buttonText: t("common.confirm"),
      callback: entityUuids.length === 1 ? restoreEntity : bulkRestore,
    })
    setShowConfirmModal(true)
  }

  const restoreEntity = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true

      router.post(route(`${entities}.restore`, localEntityUuids.value[0]),
        {},
        {
          onSuccess: () => {
            addToasterNotification({
              title: t(`${entities}.actions.restoreSuccess.title`, 1),
              message: t(`${entities}.actions.restoreSuccess.description`, 1),
              type: "success",
            })
          },
          onError: () => {
            addToasterNotification({
              title: t("common.errorOccured"),
              message: t(`${entities}.actions.restoreError`, 1),
              type: "error",
            })
          },
          onFinish: () => {
            isSubmitting.value = false
            localEntityUuids.value = []
          },
        })
    }
  }

  const deleteEntity = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true

      router.delete(route(`${entities}.destroy`, localEntityUuids.value[0]),
        {
          onSuccess: () => {
            addToasterNotification({
              title: t(`${entities}.actions.deleteSuccess.title`, 1),
              message: t(`${entities}.actions.deleteSuccess.description`, 1),
              type: "success",
            })
          },
          onError: () => {
            addToasterNotification({
              title: t("common.errorOccured"),
              message: t(`${entities}.actions.deleteError`, 1),
              type: "error",
            })
          },
          onFinish: () => {
            isSubmitting.value = false
            localEntityUuids.value = []
          },
        })
    }
  }

  const bulkDelete = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true
      const count = localEntityUuids.value.length
      router.post(route(`${entities}.bulk-delete`), {
        entity_uuids: localEntityUuids.value,
        all: localAll.value,
        filter_data: localFilterData.value,
      }, {
        onSuccess: () => {
          addToasterNotification({
            title: t(`${entities}.actions.deleteSuccess.title`, count),
            message: t(`${entities}.actions.deleteSuccess.description`, count),
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t(`${entities}.actions.deleteError`, count),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          localEntityUuids.value = []
        },
      })
    }
  }

  const archiveEntity = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true

      router.post(route(`${entities}.archive`, localEntityUuids.value[0]),
        {
          archived_at: new Date().toLocaleString(),
        },
        {
          onSuccess: () => {
            addToasterNotification({
              title: t(`${entities}.actions.archiveSuccess.title`, 1),
              message: t(`${entities}.actions.archiveSuccess.description`, 1),
              type: "success",
            })
          },
          onError: () => {
            addToasterNotification({
              title: t("common.errorOccured"),
              message: t(`${entities}.actions.archiveError`, 1),
              type: "error",
            })
          },
          onFinish: () => {
            isSubmitting.value = false
            localEntityUuids.value = []
          },
        })
    }
  }

  const bulkArchive = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true
      const count = localEntityUuids.value.length
      router.post(route(`${entities}.bulk-archive`), {
        entity_uuids: localEntityUuids.value,
        all: localAll.value,
        filter_data: localFilterData.value,
      }, {
        onSuccess: () => {
          addToasterNotification({
            title: t(`${entities}.actions.archiveSuccess.title`, count),
            message: t(`${entities}.actions.archiveSuccess.description`, count),
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t(`${entities}.actions.archiveError`, count),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          localEntityUuids.value = []
        },
      })
    }
  }

  const bulkRestore = () => {
    if (!isSubmitting.value && localEntityUuids.value.length) {
      isSubmitting.value = true
      const count = localEntityUuids.value.length
      router.post(route(`${entities}.bulk-restore`), {
        entity_uuids: localEntityUuids.value,
        all: localAll.value,
        filter_data: localFilterData.value,
      }, {
        onSuccess: () => {
          addToasterNotification({
            title: t(`${entities}.actions.restoreSuccess.title`, count),
            message: t(`${entities}.actions.restoreSuccess.description`, count),
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t(`${entities}.actions.restoreError`, count),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          localEntityUuids.value = []
        },
      })
    }
  }

  const bulkManageRelations = ({ entityUuids, all, mode, teamUuids = null, tagUuids = null, filterData = null }) => {
    if (!isSubmitting.value && (entityUuids?.length || all)) {
      isSubmitting.value = true
      router.patch(route(`${entities}.bulk-manage-relations`), {
        entity_uuids: entityUuids,
        all: all,
        filter_data: filterData,
        tag_uuids: tagUuids,
        team_uuids: teamUuids,
        mode: mode,
      }, {
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          showManageTeamsModal.value = false
          showManageTagsModal.value = false
        },
      })
    }
  }

  const bulkManageMetadata = ({ entityUuids, all, data, filterData = null }) => {
    if (!isSubmitting.value && (entityUuids.length || all)) {

      isSubmitting.value = true
      router.patch(route(`${entities}.bulk-manage-metadata`), {
        entity_uuids: entityUuids,
        all: all,
        data: data,
        filter_data: filterData,
      }, {
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          showManageMetadataModal.value = false
        },
      })
    }
  }


  return {
    isSubmitting,
    showAssignTeamModal,
    showManageTeamsModal,
    showManageTagsModal,
    showManageMetadataModal,
    showLimitExceedModal,
    localEntityUuids,

    confirmEntityDeletion,
    confirmEntityArchiving,
    confirmEntityRestoration,
    archiveEntity,
    restoreEntity,
    deleteEntity,
    bulkManageRelations,
    bulkManageMetadata,
  }
}
