import tippy, { Tippy, Plugin } from "tippy.js"
import { ExtendedTippyProps } from "~/types"

export const documentUserCustomProp: Plugin<ExtendedTippyProps> = {
  name: "documentUser",
  defaultValue: null,
  fn: () => ({}),
}

export default (tippy as unknown) as Tippy<ExtendedTippyProps>
