import { defineStore } from "pinia"
import { reactive, toRefs, toRaw, nextTick, computed } from "vue"
import { differenceWith, orderBy, sortBy } from "lodash-es"
import { useI18n } from "vue-i18n"

import { Document, Signature, SignatureBlock, SigningPhase, DocumentUser, Party, Template, CrudContext, SignatureSeal, SignatureAesProcess, SignatureQesProcess, PreemptiveSignature, UserSignatureSeal, SignatureSealPayload } from "~/types"
import { DocumentOrigin, DocumentStage, DocumentUserRoleEnum, SignatureBlockStyle, SignatureSecurityLevel } from "~/types/enums"
import {
  fetchAllSignatureBlocksAction,
  fetchAllSignaturesAction,
  createSignatureAction,
  createSignatureBlockAction,
  fetchAllSigningPhasesAction,
  createSignatureProcessAction,
  updateSignatureBlockAction,
  fetchAllUserSignatureSealsAction,
  createUserSignatureSealAction,
  removeUserSignatureSealAction,
  generateSignatureSealAction,
} from "./signatureStoreActions"
import { scrollTo } from "~/utils"
import { useDocumentStore } from "../documentStore"
import { useNotificationStore } from "../notificationStore"
import { useUserStore } from "../userStore"
import { useEditorStore } from "../editorStore"
import { usePdfBrickStore } from "../pdfBrickStore"
import { useSharedStore } from "../sharedStore"
import { useTemplateStore } from "../templateStore"

import {
  removeSignatureBlockAction,
} from "./signatureStoreActions"

const signatureBlocksContext = [ "document", "template" ]

interface Data {
  isActiveSignaturePad: boolean
  activeSignatureBlockUuid: SignatureBlock["uuid"]
  signatures: Signature[]
  preemptiveSignatures: PreemptiveSignature[]
  isLoadingSignatures: boolean
  signatureBlocks: SignatureBlock[]
  isLoadingSignatureBlocks: boolean
  signingPhases: SigningPhase[]
  activeSigningPhase: SigningPhase
  isLoadingSigningPhases: boolean
  signatureBlockUuidBeingRemoved: SignatureBlock["uuid"]
  isLoadingCreateSignatureProcess: boolean
  signatureProcess: SignatureAesProcess | SignatureQesProcess
  signatureBlockPlacedInDocumentSwitch: boolean
  isLoadingUserSignatureSeals: boolean
  userSignatureSeals: UserSignatureSeal[]
  uuidsOfIsRemovingUserSignatureSeal: UserSignatureSeal["uuid"][]
  savingSignatureStatus: null | "success" | "error"
  isLoadingCreateQesSignature: boolean
  uuidsOfIsUpdatingSignatureBlock: SignatureBlock["uuid"][]
}

export const useSignatureStore = defineStore("signatureStore", () => {
  const data = reactive<Data>({
    isActiveSignaturePad: false,
    activeSignatureBlockUuid: null,
    signatures: [],
    preemptiveSignatures: [],
    isLoadingSignatures: false,
    signatureBlocks: [],
    isLoadingSignatureBlocks: false,
    signingPhases: [],
    activeSigningPhase: null,
    isLoadingSigningPhases: false,
    signatureBlockUuidBeingRemoved: null,
    isLoadingCreateSignatureProcess: false,
    signatureProcess: null,
    signatureBlockPlacedInDocumentSwitch: false,
    isLoadingUserSignatureSeals: false,
    userSignatureSeals: [],
    uuidsOfIsRemovingUserSignatureSeal: [],
    savingSignatureStatus: null,
    isLoadingCreateQesSignature: false,
    uuidsOfIsUpdatingSignatureBlock: [],
  })

  const { notify } = useNotificationStore()

  const { t } = useI18n()

  const documentStore = useDocumentStore()

  const sharedStore = useSharedStore()

  const templateStore = useTemplateStore()

  const userStore = useUserStore()

  const editorStore = useEditorStore()

  const pdfBrickStore = usePdfBrickStore()

  // computed

  const unplacedSignatureBlocks = computed<SignatureBlock[]>(() => {
    return differenceWith(data.signatureBlocks?.filter((el) => !el.deleted_at), pdfBrickStore.pdfBricks, (a, b) => b.pdf_brickable_type === "App\\Models\\SignatureBlock" && a.uuid === b.pdf_brickable_uuid)
  })

  // Return all signatories (users with is_signatory=true) who also have a corresponding signature block
  const signatories = computed<DocumentUser[]>(() => {
    const returnValue = []
    const signingUsers = userStore.users?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory))
    if (!signingUsers) return
    const signingUsersOrderedBySigningOrder = orderBy(signingUsers, [ "signing_order" ], [ "asc" ])
    signingUsersOrderedBySigningOrder.forEach((signingUser: DocumentUser) => {
      if (!signingUser.party_uuid) return
      let signatureBlockCount = 1
      const countSignatureBlocks = data.signatureBlocks?.filter(
        (el) => el.party_uuid === signingUser.party_uuid,
      )
      if (countSignatureBlocks?.length > 1) signatureBlockCount = countSignatureBlocks?.length
      returnValue.push({ ...signingUser, signature_block_count: signatureBlockCount })
    })
    return returnValue
  })

  interface SignaturesRequiredByMdu {
    nextSignatureBlockUuid: SignatureBlock["uuid"]
    remainingCount: number
    totalCount: number
    nextRefUuid: SignatureBlock["ref_uuid"]
  }

  // Check if there are still pending signatures for the document users
  const signaturesRequiredByMdu = computed<SignaturesRequiredByMdu>(() => {
    if (!data.activeSigningPhase || !documentStore.mdu || !data.signatureBlocks) return null

    const mduIsSignatory = documentStore.mdu.roles?.includes(DocumentUserRoleEnum.signatory)
    if (!mduIsSignatory) return null

    const mduPartyUuid = documentStore.mdu.party_uuid

    const checkSignatureBlocks = data.signatureBlocks.filter((el) => el.party_uuid === mduPartyUuid && !el.deleted_at)
    if (!checkSignatureBlocks.length) return null
    if (documentStore.currentDocument?.sequential_signing || documentStore.currentDocument?.signature_security_level === SignatureSecurityLevel.QES) {
      const signatoriesByOrder = sortBy(signatories.value, [ "signing_order" ])
      const remainingSignatories = signatoriesByOrder?.filter((el) => {
        const checkSignatureRes = checkAllSignaturesComplete(el.uuid, el.party_uuid)
        return !!checkSignatureRes?.remainingCount
      })
      if (remainingSignatories?.length && remainingSignatories[0].uuid !== documentStore.mdu?.uuid) return null
    }

    let remainingCount = 0
    let nextSignatureBlockUuid = ""
    let nextRefUuid = ""

    for (const signatureBlock of checkSignatureBlocks) {
      const hasSignature = data.signatures?.some(
        (el) =>
          el.signing_phase_uuid === data.activeSigningPhase.uuid &&
        el.created_by_document_user_uuid === documentStore.mdu?.uuid &&
        el.signature_block_uuid === signatureBlock.uuid,
      )

      if (!hasSignature) {
        if (!nextSignatureBlockUuid) {
          nextSignatureBlockUuid = signatureBlock.uuid
          nextRefUuid = signatureBlock.ref_uuid
        }
        remainingCount++
      }
    }

    return nextSignatureBlockUuid
      ? { nextSignatureBlockUuid, remainingCount, totalCount: checkSignatureBlocks?.length, nextRefUuid }
      : null
  })

  // mutations
  const setSignatures = (signatures: Signature[]) => data.signatures = signatures
  const setPreemptiveSignatures = (preemptiveSignatures: PreemptiveSignature[]) => data.preemptiveSignatures = preemptiveSignatures
  const setSignatureBlocks = (signatureBlocks: SignatureBlock[]) => data.signatureBlocks = signatureBlocks
  const setSigningPhases = (signingPhases: SigningPhase[]) => data.signingPhases = signingPhases
  const setActiveSigningPhase = (signingPhase: SigningPhase) => {
    data.activeSigningPhase = signingPhase
    // Resetting signature process too, as this becomes invalid with a new signing phase
    data.signatureProcess = null
  }
  const setIsLoadingCreateQesSignature = (isLoadingCreateQesSignature: boolean) => data.isLoadingCreateQesSignature = isLoadingCreateQesSignature
  const setSignatureProcess = (signatureProcess: SignatureAesProcess | SignatureQesProcess) => data.signatureProcess = signatureProcess
  const setSavingSignatureStatus = (savingSignatureStatus: null | "success" | "error") => data.savingSignatureStatus = savingSignatureStatus
  const setUserSignatureSeals = (userSignatureSeals: UserSignatureSeal[]) => data.userSignatureSeals = userSignatureSeals
  const removeUserSignatureSealFromStore = (userSignatureSealUuidToRemove: UserSignatureSeal["uuid"]) => {
    const indexOfUserSignatureSealToRemove = data.userSignatureSeals.findIndex((userSignatureSeal) => userSignatureSeal.uuid === userSignatureSealUuidToRemove)
    if (indexOfUserSignatureSealToRemove !== -1) {
      const userSignatureSealsCopy = [ ...toRaw(data.userSignatureSeals) ]
      userSignatureSealsCopy.splice(indexOfUserSignatureSealToRemove, 1)
      data.userSignatureSeals = userSignatureSealsCopy
    }
  }
  const pushUserSignatureSeal = (userSignatureSeal: UserSignatureSeal) => data.userSignatureSeals = [ ...toRaw(data.userSignatureSeals || []), userSignatureSeal ]

  const pushSignature = (signature: Signature) => data.signatures = [ ...toRaw(data.signatures || []), signature ]
  const pushSignatureBlock = (signatureBlock: SignatureBlock) => data.signatureBlocks = [ ...toRaw(data.signatureBlocks || []), signatureBlock ]

  const pushOrUpdateSignatureBlock = (sb: Partial<SignatureBlock>) => {
    const localIndexOfSignatureBlocks = data.signatureBlocks.findIndex(({ uuid }) => uuid === sb.uuid)

    const signatureBlocksCopy = [ ...toRaw(data.signatureBlocks) ]

    if (localIndexOfSignatureBlocks !== -1) {
      signatureBlocksCopy[localIndexOfSignatureBlocks] = {
        ...signatureBlocksCopy[localIndexOfSignatureBlocks],
        ...sb,
      }

      data.signatureBlocks = signatureBlocksCopy

      return
    }

    data.signatureBlocks = [
      ...signatureBlocksCopy,
      sb,
    ] as SignatureBlock[]
  }

  const setSignatureBlockIdBeingRemoved = (signatureBlockUuid: SignatureBlock["uuid"]) => data.signatureBlockUuidBeingRemoved = signatureBlockUuid
  const removeSignatureBlockFromStore = (signatureBlockUuidToRemove: SignatureBlock["uuid"]) => {

    const indexOfSignatureBlockToRemove = data.signatureBlocks.findIndex((signatureBlock) => signatureBlock.uuid === signatureBlockUuidToRemove)

    if (indexOfSignatureBlockToRemove !== -1) {

      const copyOfSignatureBlock = { ...toRaw(data.signatureBlocks.find((signatureBlock) => signatureBlock.uuid === signatureBlockUuidToRemove)), deleted_at: Date.now().toString() }

      const signatureBlocksCopy = [ ...toRaw(data.signatureBlocks) ]

      signatureBlocksCopy[indexOfSignatureBlockToRemove] = {
        ...signatureBlocksCopy[indexOfSignatureBlockToRemove],
        ...copyOfSignatureBlock,
      }

      data.signatureBlocks = signatureBlocksCopy
    }

  }

  // api actions
  const fetchAllSignatures = async (documentUuid: Document["uuid"]): Promise<Signature[] | void> => {
    if (!documentUuid) {
      console.error("invalid documentUuid: ", documentUuid)
      return
    }

    data.isLoadingSignatures = true

    try {
      const signatures = await fetchAllSignaturesAction(documentUuid) || []

      setSignatures(signatures)

      return signatures
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingSignatures = false
    }
  }

  const fetchAllSignatureBlocks = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
  ): Promise<SignatureBlock[] | void> => {
    if (!signatureBlocksContext.includes(context)) {
      console.error("context should be one of", signatureBlocksContext, " but given", context)
      return
    }

    data.isLoadingSignatureBlocks = true

    try {
      const signatureBlocks = await fetchAllSignatureBlocksAction(context, entityUuid) || []

      setSignatureBlocks(signatureBlocks)

      return signatureBlocks
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingSignatureBlocks = false
    }
  }

  const fetchAllSigningPhases = async (documentUuid: Document["uuid"]): Promise<SigningPhase[] | void> => {
    if (!documentUuid) {
      console.error("invalid documentUuid: ", documentUuid)
      return
    }

    data.isLoadingSigningPhases = true

    try {
      const signingPhases = await fetchAllSigningPhasesAction(documentUuid) || []

      setSigningPhases(signingPhases)

      return signingPhases
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingSigningPhases = false
    }
  }

  // signature CRUD
  const createSignature = async (
    documentUuid: Document["uuid"],
    signingPhaseUuid: SigningPhase["uuid"],
    payload: Partial<Signature>,
    signatureSecurityLevel: SignatureSecurityLevel,
  ): Promise<Signature | void> => {
    const createdSignature = await createSignatureAction(documentUuid, signingPhaseUuid, payload, signatureSecurityLevel)

    if (createdSignature) pushSignature(createdSignature)

    return createdSignature
  }

  const generateSignatureSeal = async (signatureSealPayload: SignatureSealPayload): Promise<SignatureSeal | void> => {
    if (signatureSealPayload?.isSaved) {
      delete signatureSealPayload.isSaved
      return signatureSealPayload
    }
    try {
      const generateSignatureSealRes = await generateSignatureSealAction(signatureSealPayload)
      return generateSignatureSealRes
    } catch (err) {
      notify({
        title: t("signatures.errors.generateSignatureSeal"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    }
  }

  // signature process CRUD
  const createSignatureProcess = async (
    documentUuid: Document["uuid"],
    signingPhaseUuid: SigningPhase["uuid"],
    signatureSecurityLevel: SignatureSecurityLevel,
    signatureBlockUuid: SignatureBlock["uuid"],
    sealPayload: SignatureSealPayload,
  ): Promise<SignatureAesProcess | SignatureQesProcess | void> => {

    data.isLoadingCreateSignatureProcess = true

    let processedSeal: SignatureSeal

    // Generate seal via backend action if it's a draw or photo type
    if ([ "draw", "photo" ].includes(sealPayload.type)) {
      const generateSignatureSealRes = await generateSignatureSeal(sealPayload)
      if (!generateSignatureSealRes) return
      processedSeal = generateSignatureSealRes
    } else {
      processedSeal = sealPayload
    }

    const payload = {
      signature_block_uuid: signatureBlockUuid,
      signature_seal: processedSeal,
    }
    try {
      const signatureProcessRes = await createSignatureProcessAction(documentUuid, signingPhaseUuid, signatureSecurityLevel, payload)
      if (signatureProcessRes) setSignatureProcess(signatureProcessRes)
      return signatureProcessRes
    } catch (err) {
      notify({
        title: t("signatures.errors.createSignatureProcess"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.isLoadingCreateSignatureProcess = false
    }
  }

  // signature block CRUD
  const createSignatureBlock = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    partyUuid: Party["uuid"],
    style: SignatureBlock["style"],
  ): Promise<SignatureBlock | void> => {
    if (!style) style = SignatureBlockStyle.regular

    const createdSignatureBlock = await createSignatureBlockAction(context, entityUuid, partyUuid, style)

    if (createdSignatureBlock) pushSignatureBlock(createdSignatureBlock)

    return createdSignatureBlock
  }

  const updateSignatureBlock = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    signatureBlock: SignatureBlock,
    style: SignatureBlock["style"],
  ): Promise<SignatureBlock | void> => {
    if (!signatureBlock?.uuid) return
    if (data.uuidsOfIsUpdatingSignatureBlock.includes(signatureBlock?.uuid)) return
    try {
      data.uuidsOfIsUpdatingSignatureBlock.push(signatureBlock.uuid)
      const updatedSignatureBlock = await updateSignatureBlockAction(context, entityUuid, signatureBlock?.uuid, style)

      if (updatedSignatureBlock) {
        pushOrUpdateSignatureBlock(updatedSignatureBlock)
      }

      return updatedSignatureBlock
    } catch (err) {
      console.error(err)
      notify({
        title: t("signatures.errors.updateSignatureBlock"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.uuidsOfIsUpdatingSignatureBlock = data.uuidsOfIsUpdatingSignatureBlock.filter((uuid) => uuid !== signatureBlock?.uuid)
    }
  }

  const removeSignatureBlock = async (
    context: CrudContext,
    entityUuid: Document["uuid"] | Template["uuid"],
    signatureBlockUuidToRemove: SignatureBlock["uuid"],
  ) : Promise<SignatureBlock[] | void> => {
    try {
      data.signatureBlockUuidBeingRemoved = signatureBlockUuidToRemove

      const removeRes = await removeSignatureBlockAction(
        context,
        entityUuid,
        signatureBlockUuidToRemove,
      )

      if (removeRes === 200) removeSignatureBlockFromStore(signatureBlockUuidToRemove)

      return data.signatureBlocks
    } catch (err) {
      console.error(err)

      notify({
        title: t("signatures.errors.removeSignatureBlock"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      data.signatureBlockUuidBeingRemoved = null
    }
  }

  // user signature seals CRUD
  const createUserSignatureSeal = async (userSignatureSeal: Partial<UserSignatureSeal>) => {
    try {
      const createUserSignatureSealRes = await createUserSignatureSealAction(userSignatureSeal)
      if (createUserSignatureSealRes) pushUserSignatureSeal(createUserSignatureSealRes)
      return createUserSignatureSealRes
    } catch (err) {
      console.error(err)
    }
  }

  const removeUserSignatureSeal = async (userSignatureSealUuid: UserSignatureSeal["uuid"]) => {
    try {
      data.uuidsOfIsRemovingUserSignatureSeal.push(userSignatureSealUuid)
      const removeUserSignatureSealRes = await removeUserSignatureSealAction(userSignatureSealUuid)
      if (removeUserSignatureSealRes === 200) removeUserSignatureSealFromStore(userSignatureSealUuid)
      return removeUserSignatureSealRes
    } catch (err) {
      console.error(err)
    } finally {
      data.uuidsOfIsRemovingUserSignatureSeal = data.uuidsOfIsRemovingUserSignatureSeal.filter((uuid) => uuid !== userSignatureSealUuid)
    }
  }

  const fetchAllUserSignatureSeals = async () => {
    data.isLoadingUserSignatureSeals = true
    try {
      const fetchAllUserSignatureSealsRes = await fetchAllUserSignatureSealsAction()
      if (fetchAllUserSignatureSealsRes) setUserSignatureSeals(fetchAllUserSignatureSealsRes)
      return fetchAllUserSignatureSealsRes
    } catch (err) {
      console.error(err)
    } finally {
      data.isLoadingUserSignatureSeals = false
    }
  }

  // ui actions
  const launchSignaturePad = (signatureBlockUuid: SignatureBlock["uuid"] = null): void => {
    data.isActiveSignaturePad = true
    data.activeSignatureBlockUuid = signatureBlockUuid
  }

  const jumpToSignatureBlock = (
    refUuid: SignatureBlock["ref_uuid"],
    documentUserUuid: DocumentUser["uuid"],
    emptyState: boolean,
    clickInSidebar = true,
  ) => {
    let focusElement: HTMLDivElement
    let editorElement = document.getElementById(`signatureBlockNodeRendered_${refUuid}`)
    if (!editorElement) editorElement = document.getElementById(`signatureBlockNode_${refUuid}`)
    if (!editorElement) editorElement = document.querySelector(`#pdfViewerContainer [data-ref-uuid="${refUuid}"]`)
    if (editorElement) {
      const focusElements = editorElement.querySelectorAll<HTMLDivElement>(`[data-signatory-uuid="${documentUserUuid}"]`)
      let focusElement = focusElements[0]
      if (!focusElement) {
        const focusElementsEmpty = editorElement.querySelectorAll<HTMLDivElement>("[data-empty-message]")
        if (!focusElementsEmpty.length) {
          console.warn("No populated or empty signature blocks")
          return
        }
        focusElement = focusElementsEmpty[0].closest("[data-ref-uuid]")
      }
      if (focusElement) {
        const mainContentScrollContainer = sharedStore.crudContext === CrudContext.document ? documentStore.mainContentScrollContainer : templateStore.mainContentScrollContainer
        scrollTo(mainContentScrollContainer, (focusElement as HTMLDivElement), () => {
          focusElement.click()
        })
      }
    } else {
      const pdfViewerElements = document.querySelectorAll("#pdfViewerContainer [data-ref-uuid=\"" + refUuid + "\"]")
      if (pdfViewerElements.length > 0) {
        if (emptyState) {
          focusElement = pdfViewerElements[0].querySelectorAll<HTMLDivElement>("[data-empty-message]")[0]
        } else {
          focusElement = pdfViewerElements[0].querySelectorAll<HTMLDivElement>(`[data-signatory-uuid="${documentUserUuid}"]`)[0]
        }
        focusElement?.focus()
      } else {
        const sidebarElement = document.getElementById("draggableSignatureBlock_" + refUuid) || document.getElementById("sidebarSignatureBlock_" + refUuid)
        if (!sidebarElement) return
        if (emptyState) {
          focusElement = sidebarElement.querySelectorAll<HTMLDivElement>("[data-empty-message]")[0]
        } else {
          focusElement = sidebarElement.querySelectorAll<HTMLDivElement>(`[data-signatory-uuid="${documentUserUuid}"]`)[0]
        }
        focusElement?.focus()
        if (!clickInSidebar) {
          scrollTo(sharedStore.sidebarScrollContainer, sidebarElement, () => null)
          sidebarElement.classList.add("hovering")
          const animatedCursor = document.createElement("div")
          animatedCursor.classList.add("transition-all", "drop-shadow-xl", "text-black", "absolute", "top-4", "-left-6")
          const svg = `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" viewBox="0 0 256 256"><g fill="currentColor"><path d="M208 116v36a80 80 0 0 1-80 80c-44.18 0-55.81-24-93.32-90a20 20 0 0 1 34.64-20L88 152V44a20 20 0 0 1 40 0v56a20 20 0 0 1 40 0v16a20 20 0 0 1 40 0Z" fill="#FFFFFF"/><path d="M188 88a27.86 27.86 0 0 0-13.35 3.39A28 28 0 0 0 136 74.7V44a28 28 0 0 0-56 0v80l-3.82-6.13A28 28 0 0 0 27.73 146l4.67 8.23C66.81 214.89 81.05 240 128 240a88.1 88.1 0 0 0 88-88v-36a28 28 0 0 0-28-28Zm12 64a72.08 72.08 0 0 1-72 72c-37.63 0-47.84-18-81.68-77.68l-4.69-8.27V138A12 12 0 0 1 46 121.61a11.88 11.88 0 0 1 6-1.6a12 12 0 0 1 10.41 6a1.76 1.76 0 0 0 .14.23l18.67 30A8 8 0 0 0 96 152V44a12 12 0 0 1 24 0v68a8 8 0 0 0 16 0v-12a12 12 0 0 1 24 0v20a8 8 0 0 0 16 0v-4a12 12 0 0 1 24 0Z"/></g></svg>`
          animatedCursor.innerHTML = svg
          sidebarElement.appendChild(animatedCursor)
          // Remove classes after 5 seconds
          setTimeout(() => {
            sidebarElement.classList.remove("hovering")
            animatedCursor.classList.add("opacity-0")
          }, 3000)
          // Remove cursor fully after 6 seconds
          setTimeout(() => {
            animatedCursor.remove()
          }, 3500)
        } else {
          scrollTo(sharedStore.sidebarScrollContainer, sidebarElement, () => null)
          if (focusElement) focusElement.click()
        }
      }
    }
  }

  const handleClickSignatureBlock = (e: Event) => {
    const signatureBlock: HTMLDivElement = (e.target as HTMLDivElement).closest("[data-ref-uuid]")
    if (!signatureBlock) return
    const { refUuid } = signatureBlock.dataset
    if (!data.activeSigningPhase) return
    const signatureBlockUuid = data.signatureBlocks.find((el) => el.ref_uuid === refUuid)?.uuid
    launchSignaturePad(signatureBlockUuid)
  }

  const bindEventsOnSignatureBlocks = () => {

    nextTick(() => {

      const mdu = documentStore.getMdu()
      const activeDocument = documentStore.getDocument()

      if (mdu && activeDocument.stage === DocumentStage.signing && signaturesRequiredByMdu.value?.nextSignatureBlockUuid) {
        const signatoryBlocks = document.querySelectorAll(
          `.signature-block [data-signatory-uuid="${mdu.uuid}"]`,
        )
        signatoryBlocks.forEach(
          (signatoryBlock) => {
            if (signatoryBlock.classList.contains("signed")) {
              // Remove event listeners on already signed blocks
              signatoryBlock.removeEventListener("click", handleClickSignatureBlock)
            } else {
              signatoryBlock.classList.add("mine")
              signatoryBlock.removeEventListener("click", handleClickSignatureBlock)
              signatoryBlock.addEventListener("click", handleClickSignatureBlock)
            }
          },
        )
      } else if (mdu) {
        // If wrong stage or no required signature, remove event listeners
        const signatoryBlocks = document.querySelectorAll(`.signature-block [data-signatory-uuid="${mdu.uuid}"]`)
        signatoryBlocks.forEach((signatoryBlock) => signatoryBlock.removeEventListener("click", handleClickSignatureBlock))
      }
    })
  }

  interface CheckSignatureRes {
    totalCount: number,
    remainingCount: number,
    active: boolean,
    nextInLine: boolean,
  }

  const checkSignature = (documentUserUuid: DocumentUser["uuid"], signatureBlockUuid: SignatureBlock["uuid"]): Signature | PreemptiveSignature => {
    if (!data.activeSigningPhase && !data.preemptiveSignatures?.length) return
    const filteredPreemptiveSignature = data.preemptiveSignatures?.find(
      (el) =>
        el.document_user_uuid === documentUserUuid &&
        el.signature_block_uuid === signatureBlockUuid,
    )
    if (filteredPreemptiveSignature) return filteredPreemptiveSignature
    return data.signatures?.find(
      (el) =>
        el.signing_phase_uuid === data.activeSigningPhase?.uuid &&
        el.created_by_document_user_uuid === documentUserUuid &&
        el.signature_block_uuid === signatureBlockUuid,
    )
  }

  // Check if all required signatures have been completed (e.g. all signature blocks are covered) by a user
  const checkAllSignaturesComplete = (userUuid: DocumentUser["uuid"], partyUuid: Party["uuid"]): CheckSignatureRes => {
    let totalCount = 0
    let remainingCount = 0
    const checkSignatureBlocks: SignatureBlock[] = data.signatureBlocks?.filter((el) => el.party_uuid === partyUuid && !el.deleted_at)
    if (checkSignatureBlocks.length) {
      for (const signatureBlock of checkSignatureBlocks) {
        const hasSignature = data.signatures?.some(
          (el) =>
            el.signing_phase_uuid === data.activeSigningPhase.uuid &&
          el.created_by_document_user_uuid === userUuid &&
          el.signature_block_uuid === signatureBlock?.uuid,
        )
        totalCount++
        if (!hasSignature) remainingCount++
      }
    }

    let allPreviousSigned: boolean

    if (documentStore.currentDocument?.sequential_signing || data.activeSigningPhase?.sequential_signing || documentStore.currentDocument?.signature_security_level === SignatureSecurityLevel.QES) {
      const currentUser = userStore.users?.find((user) => user.uuid === userUuid)
      const previousSignatories = userStore.users?.filter((user) => user.signing_order < currentUser.signing_order)
      allPreviousSigned = previousSignatories.every((user) =>
        checkAllSignaturesComplete(user.uuid, user.party_uuid)?.remainingCount === 0,
      )
    } else {
      allPreviousSigned = true
    }

    return totalCount > 0 && checkSignatureBlocks.length
      ? {
        totalCount,
        remainingCount,
        active: totalCount > remainingCount && remainingCount > 0,
        nextInLine: allPreviousSigned,
      }
      : null
  }

  const checkIfSignatureBlockPlacedInDocument = (signatureBlockUuid: SignatureBlock["uuid"], refUuid: SignatureBlock["ref_uuid"]) => {
    data.signatureBlockPlacedInDocumentSwitch
    if (documentStore.currentDocument?.origin === DocumentOrigin.pdf) {
      return pdfBrickStore.pdfBricks?.some((el) => el.pdf_brickable_type === "App\\Models\\SignatureBlock" && el.pdf_brickable_uuid === signatureBlockUuid && (!el.signing_phase_uuid || el.signing_phase_uuid === data.activeSigningPhase?.uuid))
    } else {
      return editorStore.refUuidsOfSignaturesInEditor?.includes(refUuid)
    }
  }

  return {
    ...toRefs(data),
    unplacedSignatureBlocks,
    signaturesRequiredByMdu,
    signatories,

    // mutations
    setSignatures,
    setPreemptiveSignatures,
    setSignatureBlocks,
    setSigningPhases,
    pushSignature,
    pushSignatureBlock,
    setActiveSigningPhase,
    setSignatureProcess,
    setSignatureBlockIdBeingRemoved,
    setSavingSignatureStatus,
    setIsLoadingCreateQesSignature,

    // actions
    fetchAllSignatures,
    fetchAllSignatureBlocks,
    fetchAllSigningPhases,

    // user signature seal CRUD
    createUserSignatureSeal,
    removeUserSignatureSeal,
    fetchAllUserSignatureSeals,

    // signature CRUD
    createSignature,
    createSignatureBlock,
    createSignatureProcess,
    removeSignatureBlock,
    generateSignatureSeal,
    updateSignatureBlock,

    // ui actions
    launchSignaturePad,
    jumpToSignatureBlock,
    bindEventsOnSignatureBlocks,
    handleClickSignatureBlock,
    checkSignature,
    checkAllSignaturesComplete,
    checkIfSignatureBlockPlacedInDocument,
  }
})
