
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="m13.2 10.35l-2.325-2.325L7.85 5H20v3h-5.8l-1 2.35Zm6.6 12.25l-8.3-8.3l-2 4.7H6.225L9.2 12L1.4 4.2l1.4-1.4l18.4 18.4l-1.4 1.4Z"
    />
  </svg>
</template>
