
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 512 512"
  ><path
    fill="#B9C5C6"
    d="M256.954 250.063L4.811 427.505V159.602c0-26.113 21.164-47.277 47.277-47.277h409.733c26.113 0 47.277 21.164 47.277 47.277v267.902z"
  /><path
    fill="#96A9B2"
    d="M509.864 429.13c1.92-5.188-218.512-171.631-218.512-171.631c-27.596-20.365-42.111-20.686-69.401 0c0 0-220.111 166.308-218.258 171.426l-.045.037c.022.045.06.075.09.116a47.946 47.946 0 0 0 8.031 14.003c1.457 1.767 3.212 3.246 4.901 4.785l-.082.064c.134.12.314.176.448.295c7.157 6.384 16.181 10.518 26.207 11.699c.157.019.284.101.441.12h.605c1.524.146 2.973.512 4.535.512h416.144c20.492 0 37.853-12.887 44.748-30.95c.082-.134.209-.232.284-.37z"
  /><path
    fill="#DCE2E2"
    d="m7.752 142.174l215.277 169.089c27.171 20.051 41.462 20.367 68.331 0l214.94-168.82s-.728-6.749-10.76-18.675s-18.419-10.827-28.658-11.927l-419.837.015c-10.209 1.085-22.262 6.255-28.628 11.912c-6.367 5.656-10.665 18.406-10.665 18.406"
  /></svg>
</template>
